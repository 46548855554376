/** @jsxImportSource @emotion/react */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';

import { Header } from 'src/components';
import { getDateLabel } from 'src/helpers/date';
import { colors } from 'src/constants/variables';

import { CurrentUser } from './CurrentUser';
import { NavigationElement, NavigationElementData } from './NavigationElement';
import { NAVIGATION_ELEMENTS } from './navigationElements';

export const OfferNavigation: React.FC = () => {
    const location = useLocation();

    const isCurrentElementActive = (element: NavigationElementData) => {
        const pathname = location.pathname;
        const isSubPageActive = element.subPage && pathname.includes(element.subPage);
        return pathname.includes(element.link) || isSubPageActive;
    };

    return (
        <div css={styles.wrapper} data-test={'offerNavigation'}>
            <CurrentUser />

            <Header css={styles.header} data-test={'offerNavigationDate'}>{getDateLabel()}</Header>

            <ul css={styles.elementsWrapper} data-test={'offerNavigationElementsWrapper'}>
                {NAVIGATION_ELEMENTS.map((element) => (
                    <NavigationElement
                        {...element}
                        key={element.name}
                        active={!!isCurrentElementActive(element)}
                    />
                ))}
            </ul>
        </div>
    );
};

const styles = {
    wrapper: css`
        height: 100%;
        padding-left: 64px;
        padding-right: 32px;
        padding-top: 32px;
        background-color: ${colors.background};
    `,
    header: css`
        & {
            color: ${colors.fontAppointment};
            margin: 0;
        }
    `,
    elementsWrapper: css`
        margin-top: 112px;
        padding: 0;
    `,
};
