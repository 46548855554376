import React from 'react';
import { Form } from 'antd';

import { RotationSelector } from './RotationSelector';
import { RotationRateFee } from './RotationRateFee';

export type RotationRateProps = {
    rotationRates: CartRotationRate[];
    handleRotationChange: (value?: string) => void;
};

export const RotationRate: React.FC<RotationRateProps> = ({
    rotationRates,
    handleRotationChange,
}) => (
    <>
        <Form.Item name="rotation">
            <RotationSelector
                values={rotationRates.map((item) => item.rotation)}
                handleChange={handleRotationChange}
            />
        </Form.Item>

        <Form.Item name="deliveryPricePerWeek">
            <RotationRateFee />
        </Form.Item>
    </>
);
