/** @jsxImportSource @emotion/react */
import { Radio } from 'antd';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';
import { beautifyName } from '../../helpers/utils';

export const CustomRadio = <P extends unknown = string>({ value, label }: RadioValue<P>) => (
    <Radio value={value} css={styles.radio} data-test={`${beautifyName(label)}_OPTION`}>
        {label}
    </Radio>
);

const styles = {
    radio: css`
        display: inline-flex;
        align-items: center;
        margin-right: 16px;

        span {
            padding: 0;

            color: ${colors.fontHeaderSecondary};
            font-size: ${fontSizes.medium};
            line-height: 1;
        }

        .ant-radio {
            margin-right: 16px;

            :after {
                border-color: ${colors.secondary};
            }

            .ant-radio-inner {
                height: 32px;
                width: 32px;

                border-color: ${colors.fontPrimaryDark};

                :after {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    width: 22px;
                    height: 22px;

                    border-radius: 100%;
                    background-color: ${colors.secondary};
                }
            }
        }

        .ant-radio-input:focus + .ant-radio-inner {
            box-shadow: none;
        }
    `,
};
