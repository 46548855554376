/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import Icon from '@ant-design/icons';

import { Edit } from 'src/assets/icons';
import { colors } from 'src/constants/variables';

export type TileButtonProps = {
    size?: number;
    iconSize?: number;
};

type Props = TileButtonProps & {
    onClick: () => void;
};

export const InfoTileEditButton: React.FC<Props> = ({ onClick, iconSize = 16, size = 32 }) => (
    <div css={styles.editButton(size)} role="button" onClick={onClick} data-test={'infoTileEditButton'}>
        <Icon component={Edit} css={styles.icon(iconSize)} />
    </div>
);

const styles = {
    editButton: (size: number) => css`
        width: ${size}px;
        height: ${size}px;
        background-color: transparent;
        box-shadow: 0 2px 3px ${colors.offerTileShadow};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    `,
    icon: (iconSize: number) => css`
        svg {
            height: ${iconSize}px;
            width: ${iconSize}px;

            path {
                stroke: ${colors.primary};
            }

            polygon {
                stroke: ${colors.primary};
                fill: ${colors.primary};
            }
        }
    `,
};
