import { StatusCodes } from 'http-status-codes';

import { fileDownload } from 'src/helpers/fileDownload';
import { httpService } from 'src/modules/app/service/httpService';
import { notificationService } from './notificationService';
import { endpoints } from 'src/constants/variables';
import { transformCatalogImageUrl } from 'src/helpers/utils';

import { OfferFormValues } from '../components/types';
import { prepareWardrobeOptionsData } from '../../offerPdf/helpers/offerData';
import { VariantItem } from '../../../helpers/types';

const fetchOffer = (offerId: number) =>
    httpService<{
        basics: OfferBasicsWithItems;
        details: OfferDetails;
    }>({
        url: endpoints.offerDetails(offerId),
    });

const submitOffer = (offerData: OfferCreationRequest) =>
    httpService<number>({
        url: endpoints.offers(),
        body: offerData,
        method: 'POST',
    });

const updateOffer = (offerId: number, offerData: OfferUpdateRequest) =>
    httpService<number>({
        url: endpoints.offers(offerId),
        body: offerData,
        method: 'PUT',
    });

const downloadOfferPdf = async (offerId: number, offerName: string) => {
    await fileDownload({
        url: endpoints.offerPdf(offerId),
        fileName: `${offerName}.pdf`,
    });
};

export const offerApi = {
    fetchOffer,
    submitOffer,
    downloadOfferPdf,
    updateOffer,
};

// @ToDo - Move this to the separate common function/service
export async function fetchOfferDetails(offerId: number): Promise<{
    offerBasics: OfferBasics;
    offerDetails: OfferDetails;
    offerItems: VariantItem[];
}> {
    const {
        basics: { items, ...offerBasics },
        details: offerDetails,
    } = await httpService<{
        basics: OfferBasicsWithItems;
        details: OfferDetails;
    }>({
        url: endpoints.offerDetails(offerId, 'BASIC'),
    });

    const offerItems = items.map((item) => ({
        ...item,
        imageLink: transformCatalogImageUrl(item.imageLink, 250),
    }));

    return { offerBasics, offerDetails, offerItems };
}

export type InitOfferItemRequest = {
    modelId: string;
    variantKey: string;
};

export async function initOfferItems(
    initOfferItemRequests: InitOfferItemRequest[],
): Promise<VariantItem[]> {
    const items = await httpService<VariantItem[]>({
        method: 'POST',
        url: endpoints.initOfferItem(),
        body: initOfferItemRequests,
    });

    return items.map((item) => ({
        ...item,
        imageLink: transformCatalogImageUrl(item.imageLink, 250),
        offerItemId: getRandomUniqueId(),
    }));
}

function getRandomUniqueId(): number {
    return Math.ceil(Math.random() * 10000000);
}

export async function fetchOfferOverview(offerId: number): Promise<{
    offerItems: VariantItem[];
    offerAssets: OfferAssets;
    offerBasics: OfferBasics;
    offerDetails: OfferDetails;
    offerSummary: OfferSummaryBrief;
}> {
    const {
        assets: offerAssets,
        basics: { items, ...offerBasics },
        details: offerDetails,
        summary: offerSummary,
    } = await httpService<{
        assets: OfferAssets;
        basics: OfferBasicsWithItems;
        details: OfferDetails;
        summary: OfferSummaryBrief;
    }>({
        url: endpoints.offerEditInit(offerId),
    });

    const offerItems = items.map((item) => ({
        ...item,
        imageLink: transformCatalogImageUrl(item.imageLink, 250),
    }));

    return { offerItems, offerAssets, offerBasics, offerDetails, offerSummary };
}

export async function fetchOfferDataForDuplication(offerId: number): Promise<{
    offerItems: VariantItem[];
    offerAssets: OfferAssets;
    offerBasics: OfferBasics;
    offerDetails: OfferDetails;
    offerSummary: OfferSummaryBrief;
}> {
    const {
        assets: offerAssets,
        basics: { items, ...offerBasics },
        details: offerDetails,
        summary: offerSummary,
    } = await httpService<{
        assets: OfferAssets;
        basics: OfferBasicsWithItems;
        details: OfferDetails;
        summary: OfferSummaryBrief;
    }>({
        url: endpoints.offerDuplicate(offerId),
    });

    const offerItems = items.map((item) => ({
        ...item,
        imageLink: transformCatalogImageUrl(item.imageLink, 250),
    }));

    return { offerItems, offerAssets, offerBasics, offerDetails, offerSummary };
}

export const submitOfferForm = async (
    items: VariantItem[],
    basics: OfferBasics,
    formValues: OfferFormValues,
    afterOfferSubmit: (result: number, name?: string) => void,
    shouldEdit: boolean,
    offerId?: number,
) => {
    if (!!basics && items.length > 0) {
        try {
            const request = !shouldEdit
                ? getOfferCreationRequest(items, basics, formValues)
                : getOfferUpdateRequest(items, basics, formValues);

            const result = !shouldEdit
                ? await offerApi.submitOffer(request as OfferCreationRequest)
                : await offerApi.updateOffer(offerId!, request as OfferUpdateRequest);

            if (result) {
                afterOfferSubmit(result, formValues.name);

                notificationService.success('Das Angebot wurde erfolgreich gespeichert.');
                return true;
            } else {
                notificationService.error('Antrag kann nicht bearbeitet werden');
            }
        } catch (error: any) {
            return handleSubmitConflictError(error);
        }
    } else {
        notificationService.error();
    }
};

const getOfferUpdateRequest = (
    offerItems: VariantItem[],
    offerBasics: OfferBasics,
    formValues: OfferFormValues,
): OfferUpdateRequest => ({
    validUntil: formValues.validUntil,
    backgroundImageUri: formValues.backgroundImageUri,

    customerCompany: offerBasics.customerCompany,
    customerContact: offerBasics.customerContact,
    customerGdpr: offerBasics.customerGdpr,

    employeeContact: offerBasics.employeeContact,
    assistantContact: offerBasics.assistantContact,

    items: mergeItemsWithFormData(offerItems, formValues.itemsList),
    wardrobeOptionsData: prepareWardrobeOptionsData(formValues.services),
    includeTermsAndConditions: formValues.includeTermsAndConditions,
    amountOfWearers: formValues.amountOfWearers,
    kmuDetails: formValues.kmuDetails,

    rotation: formValues.rotation,
    deliveryPricePerWeek: formValues.deliveryPricePerWeek,

    pricePerWearer: formValues.pricePerWearer,
    contactComments: offerBasics.contactComments,
    globalItemsPerWearer: formValues.globalItemsPerWearer,
    globalSurcharge: formValues?.globalSurcharge,
    weeklyTotalPriceAllItems: formValues?.weeklyTotalPriceAllItems,
    weeklyTotalPriceAllItemsValue: formValues?.weeklyTotalPriceAllItemsValue,
    totalPricePerWeek: formValues.totalPricePerWeek,
    totalPricePerWeekValue: formValues.totalPricePerWeekValue,
});

const getOfferCreationRequest = (
    offerItems: VariantItem[],
    offerBasics: OfferBasics,
    formValues: OfferFormValues,
): OfferCreationRequest => ({
    name: formValues.name.trim(),
    validUntil: formValues.validUntil,
    createdAt: '',
    backgroundImageUri: formValues.backgroundImageUri,

    customerCompany: offerBasics.customerCompany,
    customerContact: offerBasics.customerContact,
    customerGdpr: offerBasics.customerGdpr,

    employeeContact: offerBasics.employeeContact,
    assistantContact: offerBasics.assistantContact,
    contactComments: offerBasics.contactComments,

    items: mergeItemsWithFormData(offerItems, formValues.itemsList),

    includeTermsAndConditions: formValues.includeTermsAndConditions,
    wardrobeOptionsData: prepareWardrobeOptionsData(formValues.services),

    pricePerWearer: formValues.pricePerWearer,
    amountOfWearers: formValues.amountOfWearers,

    rotation: formValues.rotation,
    deliveryPricePerWeek: formValues.deliveryPricePerWeek,

    kmuDetails: formValues.kmuDetails,

    globalItemsPerWearer: formValues.globalItemsPerWearer,
    globalSurcharge: formValues?.globalSurcharge,

    totalPricePerWeek: formValues.totalPricePerWeek,
    totalPricePerWeekValue: formValues.totalPricePerWeekValue,
    weeklyTotalPriceAllItems: formValues?.weeklyTotalPriceAllItems,
    weeklyTotalPriceAllItemsValue: formValues?.weeklyTotalPriceAllItemsValue,
});

const mergeItemsWithFormData = (items: VariantItem[], formValues: OfferFormValues['itemsList']) =>
    items.map((item) => {
        const itemId = item.cartItemId || item.offerItemId || undefined;

        return {
            ...item,
            ...(itemId && Object.prototype.hasOwnProperty.call(formValues, itemId)
                ? formValues[itemId]
                : {}),
        };
    });

const handleSubmitConflictError = (error: any) => {
    if (error.code === StatusCodes.CONFLICT) {
        return error.code;
    }
    notificationService.error();
};
