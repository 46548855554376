/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { CustomModal } from 'src/components';
import { colors } from 'src/constants/variables';
import { ProductsCatalogStandalone } from './ProductsCatalogStandalone';
import { ProductsCatalogModalHeader } from './ProductsCatalogModalHeader';
import { VariantItem } from '../../../helpers/types';

type Props = {
    onClick: () => void;
    onAddProducts: (offerItems: VariantItem[]) => void;
    isVisible: boolean;
};

export const ProductsCatalogModal: React.FC<Props> = ({ onClick, isVisible, onAddProducts }) => (
    <CustomModal onClick={onClick} visible={isVisible} modalCss={styles.modal}>
        <ProductsCatalogModalHeader onClick={onClick} />
        <div css={styles.wrapper}>
            <ProductsCatalogStandalone onAddProducts={onAddProducts} />
        </div>
    </CustomModal>
);

const styles = {
    modal: css`
        .ant-modal-content {
            padding: 0% !important;
        }

        .ant-modal-body {
            padding: 0 !important;
        }

        .ant-modal-close {
            display: none;
        }
    `,
    content: css`
        background-color: ${colors.primary};
    `,
    wrapper: css`
        padding: 0px 32px 32px;
    `,
    buttonWrapper: css`
        padding: 0 32px 32px;
    `,
};
