import { css, SerializedStyles } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';
import { elementCustomScrollbar, datePickerStyles } from 'src/style/styles';

export const styles = {
    item: css`
        position: relative;

        .ant-input,
        .ant-select-selector,
        &.ant-form-item-has-error .ant-input,
        &.ant-form-item-has-error .ant-select-selector {
            position: relative;
            margin: 0;

            color: ${colors.fontSecondary};
            background: transparent;

            &,
            :focus,
            :hover {
                border: none;
                border-bottom: 1px solid ${colors.secondaryDark};
                border-radius: 0;
                box-shadow: none;
            }

            ::placeholder {
                color: ${colors.fontPrimaryDark};
                font-size: ${fontSizes.xSmall};
                opacity: 0.5;
            }
        }
    `,
    required: css`
        position: relative;

        ::after {
            content: '*';
            color: ${colors.secondary};
            font-size: ${fontSizes.xxSmall};
            position: absolute;
            top: 0;
            right: 8px;
            text-align: right;
        }
    `,

    inputItem: css`
        input.ant-input {
            height: 48px;
            padding-right: 24px;
        }
    `,
    textareaItem: css`
        textarea.ant-input {
            resize: none;

            &,
            :focus,
            :hover {
                border: none;
            }

            ${elementCustomScrollbar()};
        }
    `,
    checkboxItem: css`
        position: relative;

        && .ant-checkbox-wrapper {
            display: flex;
            font-size: ${fontSizes.xxSmall};
            line-height: 20px;
        }

        && .ant-checkbox {
            border: 1px solid ${colors.secondary};
            height: 32px;
            width: 32px;
            padding: 5px;

            & + span {
                padding-left: 16px;
                padding-right: 24px;
                text-align: justify;
            }
        }

        && .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            border: none;
            border-radius: 0;
            background-color: transparent;

            &::after {
                display: none;
            }
        }

        && .ant-checkbox-checked::after {
            display: none;
        }

        && .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${colors.secondary};

            &::after {
                display: none;
            }
        }
    `,
    radioItem: css`
        && .ant-radio-wrapper {
            display: flex;
        }

        && span.ant-radio + * {
            line-height: 32px;
        }

        && span.ant-radio-checked::after {
            display: none;
        }

        && .ant-radio-inner {
            height: 32px;
            width: 32px;
            background: transparent;

            &,
            ::after {
                border-color: ${colors.secondary};
                border-radius: 50%;
            }

            ::after {
                top: 5px;
                left: 5px;
                height: 20px;
                width: 20px;

                background-color: ${colors.secondary};
            }
        }
    `,
    selectItem: css`
        .ant-select-single:not(.ant-select-customize-input),
        .ant-select:not(.ant-select-customize-input).ant-select-focused:not(.ant-select-disabled) {
            .ant-select-selector {
                height: 48px;

                border: none;
                border-bottom: 1px solid ${colors.secondaryDark};
                border-radius: 0;
                box-shadow: none;

                background: transparent;

                .ant-select-selection-item,
                .ant-select-selection-placeholder {
                    line-height: 48px;
                }
            }
        }
    `,
    selectTitle: (customCss?: SerializedStyles) => css`
        max-width: 167px;

        .ant-select-arrow {
            right: 24px;
        }

        ${customCss};
    `,
    priceItem: css`
        .ant-input-number {
            &,
            &:hover,
            &:focus {
                width: 100%;
                border-color: transparent;
                box-shadow: none;
            }
        }

        .ant-input-number-handler-wrap {
            display: none;
        }

        input.ant-input-number-input {
            height: 48px;
            text-align: right;
        }
    `,
    pickerItem: css`
        .ant-picker {
            width: 100%;
            height: 48px;
            border-radius: 0;
            background: transparent;

            &,
            :focus,
            :hover {
                border: none;
                border-bottom: 1px solid ${colors.secondaryDark};
                box-shadow: none;
            }
        }
    `,
    datePicker: css`
        ${datePickerStyles.dateInput};
    `,
};
