/** @jsxImportSource @emotion/react */
import React from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { css, SerializedStyles } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';

type Props = {
    norms: ProductNorm[];
    customCss?: SerializedStyles;
    tooltipTrigger?: TooltipProps['trigger'];
};

export const ProductNorms: React.FC<Props> = ({ norms, customCss, tooltipTrigger = 'hover' }) => {
    const distinctNorms = norms.filter(
        (value, index, self) => self.findIndex((v) => v.label === value.label) === index,
    );

    return (
        <div css={[styles.wrapper, customCss]}>
            {distinctNorms.map((norm) => (
                <Tooltip
                    key={norm.label}
                    title={norm.label}
                    css={styles.tooltip}
                    destroyTooltipOnHide
                    trigger={tooltipTrigger}
                    getPopupContainer={(trigger) => trigger || document.body}
                >
                    <div css={styles.normIcon}>
                        <img src={norm.imageLink} alt={norm.label} />
                    </div>
                </Tooltip>
            ))}
        </div>
    );
};

const styles = {
    wrapper: css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -1px;
    `,
    normIcon: css`
        padding: 1px 1px;
        img {
            max-width: 75px;
        }
    `,
    tooltip: css`
        text-transform: none;

        .ant-tooltip-inner {
            background: ${colors.secondaryBright};
            color: ${colors.fontHeaderSecondary};
            font-family: 'Optima LT Pro', sans-serif;
            font-weight: normal;
            font-size: ${fontSizes.xSmall};
        }

        .ant-tooltip-arrow-content {
            background: ${colors.secondaryBright};
        }
    `,
};
