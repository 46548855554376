/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';

import { sizes, colors, shadows, fontSizes, zIndices } from 'src/constants/variables';
import { OkAndCancelButtons } from './Buttons';

export type CustomConfirmProps = {
    onCancel: (event?: React.MouseEvent) => void;
    onConfirm: () => void;
    useOverlay?: boolean;
    marginTop?: boolean;
};

export const CustomConfirm = React.forwardRef<
    HTMLDivElement,
    PropsWithChildren<CustomConfirmProps>
>(({ children, onCancel, onConfirm, useOverlay = false, marginTop = false, ...rest }, ref) => (
    <React.Fragment>
        {useOverlay && <div css={styles.overlay} />}
        <div
            role={'dialog'}
            ref={ref}
            css={marginTop ? styles.wrapperMargin : styles.wrapper}
            {...rest}
        >
            <div css={styles.content}>{children}</div>
            <OkAndCancelButtons onOk={onConfirm} onCancel={onCancel} customCss={styles.buttons} />
        </div>
    </React.Fragment>
));

const styles = {
    wrapper: css`
        z-index: ${zIndices.zIndex1200};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: ${sizes.medium};
        margin: ${sizes.small};
        box-shadow: ${shadows.normal};
        border-radius: ${sizes.borderRadius};

        :before {
            content: '';
            opacity: 97%;
            background: ${colors.primary};
            border-radius: ${sizes.borderRadius};
            top: 0;
            height: 100%;
            left: 0;
            width: 100%;
            position: absolute;
        }
    `,
    wrapperMargin: css`
        position: absolute;
        bottom: 25%;
        left: 0;
        right: 0;
        padding: ${sizes.medium};
        margin: ${sizes.small};
        box-shadow: ${shadows.normal};
        border-radius: ${sizes.borderRadius};

        :before {
            content: '';
            opacity: 97%;
            background: ${colors.primary};
            border-radius: ${sizes.borderRadius};
            top: 0;
            height: 100%;
            left: 0;
            width: 100%;
            position: absolute;
        }
    `,
    content: css`
        position: relative;
        line-height: 1.2;
        font-family: unset;
        font-size: ${fontSizes.xSmall};
        margin-right: 8px;
    `,
    buttons: css`
        button {
            margin: 8px 8px 4px;
            line-height: 1;

            &:not(:last-of-type) {
                margin-right: 16px;
            }
        }
    `,
    overlay: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `,
};
