import { useState } from 'react';
import { omit as ramdaOmit } from 'ramda';

import { ItemWarnings } from 'src/helpers/types';

export const useColorChooserDropdown = (
    changeItemColor: (id: number, color: string) => Promise<boolean>,
    initColorWarnings: ItemWarnings = {},
) => {
    const [colorWarnings, setColorWarnings] = useState<ItemWarnings>(initColorWarnings);

    const onItemsColorChange = (color: ProductColor, items: number[]) => {
        const requests = items.map((itemId) =>
            changeItemColor(itemId, color.key).then((result) => ({ itemId, result })),
        );

        return Promise.allSettled(requests).then((responses) => {
            const colorChangeWarnings = responses.reduce(
                (accumulator, response) =>
                    response.status === 'fulfilled' && !response.value.result
                        ? { ...accumulator, [response.value.itemId]: true }
                        : accumulator,
                {},
            );

            setColorWarnings(colorChangeWarnings);

            return colorChangeWarnings;
        });
    };

    const checkColorWarnings = () => !Object.values(colorWarnings).every((item) => !item);

    const clearColorWarning = (id: number) => {
        setColorWarnings((prevWarnings) => ramdaOmit([id.toString()], prevWarnings));
    };

    const clearAllColorWarnings = () => {
        setColorWarnings({});
    };

    return {
        onItemsColorChange,
        checkColorWarnings,
        clearColorWarning,
        clearAllColorWarnings,
        colorWarnings,
    };
};
