import { OfferFormItems } from '../types';
import { VariantItem } from '../../../../helpers/types';

export const rewriteOfferItemsToItemsList = (offerItems: VariantItem[]) =>
    offerItems.reduce((prev, current) => ({ ...prev, ...prepareOfferItemsData(current) }), {});

const prepareOfferItemsData = ({
    cartItemId,
    offerItemId,
    itemsPerWearer,
    basePrice,
    logoBackPrice,
    labelPrice,
    logoFrontPrice,
    amountOfWearers,
}: VariantItem): OfferFormItems => ({
    [(cartItemId || offerItemId) + '']: {
        basePrice,
        logoBackPrice,
        logoFrontPrice,
        labelPrice,
        itemsPerWearer,
        amountOfWearers,
    },
});
