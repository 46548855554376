/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';

export const NoPrice: React.FC = () => <span css={styles.noPrice}>-</span>;

const styles = {
    noPrice: css`
        color: ${colors.backgroundDark}40;
    `,
};
