/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';

type Props = {
    title: string;
    price: string | number;
};

export const OfferPreviewDetailsElement: React.FC<Props> = ({ title, price }) => (
    <div css={offerPreviewDetailsElementStyles.namePriceContainer}>
        <div css={offerPreviewDetailsElementStyles.nameLabel}>{title}</div>
        <div css={offerPreviewDetailsElementStyles.price}>{price}</div>
    </div>
);

export const offerPreviewDetailsElementStyles = {
    price: css`
        color: ${colors.fontHeader};
        font-family: 'OptimaLTPro-DemiBold', sans-serif;
    `,
    nameLabel: css`
        text-align: right;
        width: 85%;
    `,
    namePriceContainer: css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        font-size: ${fontSizes.mSmall};
        line-height: 28px;
        letter-spacing: 0;
        color: ${colors.fontSecondary};
        font-family: 'OptimaLTPro-Medium', sans-serif;
    `,
};
