/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { colors, zIndices } from 'src/constants/variables';
import { parseRGBList } from '../../helpers/colors';

const basicSize = 16;
const borderSize = 2;
const fullCircleSize = basicSize + borderSize;

const circleBorder = (size: number) => css`
    content: '';
    position: absolute;
    border-radius: 50%;

    width: ${size * fullCircleSize}px;
    height: ${size * fullCircleSize}px;
    left: calc(50% - ${size * (fullCircleSize / 2)}px);
    top: calc(50% - ${size * (fullCircleSize / 2)}px);
`;

export const colorMarkStyles = {
    mark: (color: RGBValuesList, size: number) => css`
        margin: 0 auto;
        width: ${size * basicSize}px;
        background-color: ${parseRGBList(color)};
        cursor: pointer;
    `,
    mainCircle: css`
        position: relative;
        z-index: ${zIndices.zIndex010};
    `,
    circle: (size: number) => css`
        height: ${size * basicSize}px;
        border-radius: 50%;
    `,
    dualColor: (size: number, colors: RGBValuesList[]) => css`
        height: ${size * basicSize}px;
        border-radius: 50%;
        background: linear-gradient
            ${parseRGBList(colors[0])} 50%,
            50%,
            ${parseRGBList(colors[1])}
        );
    `,
    tripleColor: (size: number, colors: RGBValuesList[]) => css`
        height: ${size * basicSize}px;
        border-radius: 50%;
        background: linear-gradient(
            ${parseRGBList(colors[0])} 33%,
            33%,
            ${parseRGBList(colors[1])} 66%,
            66%,
            ${parseRGBList(colors[2])}
        );
    `,
    background: (size: number) => css`
        ${circleBorder(size)}
        background-color: ${colors.secondaryDark};
    `,
    selected: (size: number) => css`
        :after {
            ${circleBorder(size)}
            border: 2px solid ${colors.secondary};
        }
    `,
    textColor: css`
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};
