import { createAction, ActionTypes, VariantItem } from 'src/helpers/types';

export const fetchOfferDetailsOverviewRequest = () =>
    createAction('OFFER_DETAILS|FETCH_OVERVIEW_REQUEST', null);

export const fetchOfferDetailsOverviewSuccess = (
    items: VariantItem[],
    basics: OfferBasics,
    details?: OfferDetails,
    summary?: OfferSummaryBrief,
) => createAction('OFFER_DETAILS|FETCH_OVERVIEW_SUCCESS', { items, basics, details, summary });

export const fetchOfferDetailsOverviewFailure = (errorCode?: number) =>
    createAction('OFFER_DETAILS|FETCH_OVERVIEW_FAILURE', errorCode);

export const fetchOfferAssetsSuccess = (assets: OfferAssets) =>
    createAction('OFFER_DETAILS|FETCH_ASSETS_SUCCESS', assets);

export const updateOfferItem = (offerItem: VariantItem) =>
    createAction('OFFER_DETAILS|UPDATE_OFFER_ITEM', offerItem);

export const clearOfferOverview = () => createAction('OFFER_DETAILS|CLEAR_OVERVIEW', null);

export const removeOfferItem = (offerItemId: number) =>
    createAction('OFFER_DETAILS|REMOVE_OFFER_ITEM', { offerItemId });

export const addOfferItems = (offerItems: VariantItem[]) =>
    createAction('OFFER_DETAILS|ADD_OFFER_ITEMS', { offerItems });

export const updateOverviewContactDetails = (
    employeeContact: ContactData,
    assistantContact: ContactData,
    contactComments?: string,
) =>
    createAction('OFFER_DETAILS|UPDATE_CONTACT_DETAILS', {
        employeeContact,
        assistantContact,
        contactComments,
    });

export const updateOverviewCustomerDetails = (
    customerCompany: OfferCompanyData,
    customerContact: ContactData,
) =>
    createAction('OFFER_DETAILS|UPDATE_CUSTOMER_DETAILS', {
        customerCompany,
        customerContact,
    });

export const offerActions = {
    fetchOfferAssetsSuccess,
    fetchOfferDetailsOverviewRequest,
    fetchOfferDetailsOverviewSuccess,
    fetchOfferDetailsOverviewFailure,
    updateOfferItem,
    clearOfferOverview,
    removeOfferItem,
    addOfferItems,
    updateOverviewContactDetails,
    updateOverviewCustomerDetails,
};

export type OfferDetailsActions = ActionTypes<typeof offerActions>;
