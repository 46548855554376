/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Row } from 'antd';

import { ConfirmationButton } from 'src/components';
import { css } from '@emotion/react';

type Props = {
    onClick: () => void;
};

export const ClearFormButton: React.FC<Props> = ({ onClick }) => (
    <Row css={styles.wrapper} justify={'center'}>
        <Col span={24} lg={8}>
            <ConfirmationButton onClick={onClick}>Angaben löschen</ConfirmationButton>
        </Col>
    </Row>
);

const styles = {
    wrapper: css`
        margin-top: 64px;
    `,
};
