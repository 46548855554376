import React from 'react';
import moment from 'moment';

import { MainSection } from 'src/components/MainSection';
import { OfferTilesSection } from './OfferTilesSection';

type Props = Pick<OfferBasics, 'customerCompany' | 'customerContact' | 'employeeContact'> & {
    selectedDate: moment.Moment | null;
    setSelectedDate: (date: moment.Moment | null) => void;
    selectedBackground: string;
    setSelectedBackground: (uri: string) => void;
    onContactDetailsEdit: () => void;
    onCustomerDetailsEdit: () => void;
};

export const MarketingDetails: React.FC<Props> = ({
    customerCompany,
    customerContact,
    employeeContact,
    selectedDate,
    setSelectedDate,
    selectedBackground,
    setSelectedBackground,
    onContactDetailsEdit,
    onCustomerDetailsEdit,
}) => (
    <React.Fragment>
        <MainSection
            title={'UNSER ANGEBOT FÜR SIE'}
            content={customerCompany?.name || ''}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedBackground={selectedBackground}
            setSelectedBackground={setSelectedBackground}
            allowDateClear={false}
        />
        <OfferTilesSection
            employeeContact={employeeContact}
            toggleContactDataModal={onContactDetailsEdit}
            customerCompany={customerCompany}
            customerContact={customerContact}
            toggleCustomerDataModal={onCustomerDetailsEdit}
        />
    </React.Fragment>
);
