/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import moment from 'moment';

import { MainSection } from 'src/components/MainSection';
import { OfferTilesSection } from '../../../../components/MarketingDetails/OfferTilesSection';
import { fontSizes } from 'src/constants/variables';
import { noop } from 'src/helpers/utils';

type Props = Pick<OfferBasics, 'employeeContact' | 'customerCompany' | 'customerContact'> & {
    selectedDate: moment.Moment | null;
    selectedBackground: string;
};

export const MarketingDetailsReadonly: React.FC<Props> = ({
    employeeContact,
    customerCompany,
    customerContact,
    selectedBackground,
    selectedDate,
}) => (
    <>
        <MainSection
            readonly
            title="UNSER ANGEBOT FÜR SIE"
            content={customerCompany?.name || ''}
            selectedDate={null}
            setSelectedDate={noop}
            selectedBackground={selectedBackground}
            setSelectedBackground={noop}
            allowDateClear={false}
            companyNameFooter={
                <span css={styles.companyFooter}>
                    ANGEBOT GÜLTIG BIS {selectedDate?.format('DD.MM.YYYY')}
                </span>
            }
        />

        <OfferTilesSection
            employeeContact={employeeContact}
            customerCompany={customerCompany}
            customerContact={customerContact}
            readOnly
            employeeTitle="MEWA ANSPRECHPARTNER"
            customerTitle="KUNDE KONTAKTDATEN"
        />
    </>
);

const styles = {
    companyFooter: css`
        font-family: OptimaLTPro-Roman, sans-serif;
        font-size: ${fontSizes.mSmall};
    `,
};
