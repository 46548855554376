/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Row, Col } from 'antd';
import { EmptyProductsSection, EmptyProductsSectionProps } from './EmptyProductSection';
import { FillProductsSection, FillProductsSectionProps } from './FillProductsSection';
import { ProductsSectionColumnTitle } from './ProductsSectionColumnTitle';
import { productRowInputStyles } from './ProductRow/ProductRowInputs';

type Props = FillProductsSectionProps & EmptyProductsSectionProps;

export const ProductsSection: React.FC<Props> = ({
    offerItems,
    onAddProductsFromCatalogButtonClick,
    ...props
}) =>
    offerItems.length ? (
        <div css={styles.wrapper} data-test={'productsSections'}>
            <Row gutter={[22, 0]}>
                <Col offset={8} span={16}>
                    <Row>
                        <Col css={productRowInputStyles.col}>
                            <ProductsSectionColumnTitle title="Einzukleidende Personen" />
                        </Col>
                        <Col css={productRowInputStyles.col}>
                            <ProductsSectionColumnTitle title="Ausstattung x-fach" />
                        </Col>
                        <Col css={productRowInputStyles.col}>
                            <ProductsSectionColumnTitle title="Stückpreis" />
                        </Col>
                        <Col css={productRowInputStyles.col}>
                            <ProductsSectionColumnTitle title="Brustlogo" />
                        </Col>
                        <Col css={productRowInputStyles.col}>
                            <ProductsSectionColumnTitle title="Rückenlogo" />
                        </Col>
                        <Col css={productRowInputStyles.col}>
                            <ProductsSectionColumnTitle title="Namensschild" />
                        </Col>
                        <Col css={productRowInputStyles.col}>
                            <ProductsSectionColumnTitle title="Preis je Mitarbeiter pro Woche" />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <FillProductsSection offerItems={offerItems} {...props} />
        </div>
    ) : (
        <EmptyProductsSection
            onAddProductsFromCatalogButtonClick={onAddProductsFromCatalogButtonClick}
        />
    );

const styles = {
    wrapper: css`
        margin-left: 32px;
    `,
};
