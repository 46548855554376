import kzfSchonbezuge from './kzfSchonbezuge.jpg';
import lenkradschoner1 from './LenkradschonerTOPTEX.jpg';
import lenkradschoner2 from './LenkradschonerTOPTEX2.jpg';
import fubraumschoner from './FubraumschonerTOPTEX.jpg';
import autositzschonbezug from './AutositzschonbezugTOPTEX.jpg';

export const kzfSchonbezugeImages = {
    kzfSchonbezuge,
    lenkradschoner1,
    lenkradschoner2,
    fubraumschoner,
    autositzschonbezug,
};
