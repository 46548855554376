/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Row } from 'antd';
import { css } from '@emotion/react';

import { ColorMark } from 'src/components/ColorMark';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    colors: ProductColor[];
    onClick?: (colorKey: string) => void;
    selectedColorKey?: string;
};

export const Colors: React.FC<Props> = ({ colors, onClick, selectedColorKey }) => (
    <Row gutter={0} css={styles.container}>
        {colors
            .filter((color) => !color.discontinued)
            .map((color, index) => (
                <Col
                    key={`${index}-${color.key}`}
                    span={3}
                    data-test={`${beautifyName(color.key)}_COLOR_CIRCLE`}
                >
                    <ColorMark
                        color={color}
                        size={1}
                        onClick={onClick}
                        selected={color.key === selectedColorKey}
                    />
                </Col>
            ))}
    </Row>
);

const styles = {
    container: css`
        margin-bottom: 20px;
    `,
};
