import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';

import { ProductListItem } from 'src/modules/offerPdf/templates/pdfPages/productListPage/productListItem';
import { DeliveryData } from '../../../types';
import { CUSTOMIZED_ITEMS_NOTE } from '../../offerPdfTemplate';
import { ProductListPageSummary } from './productListPageSummary';
import { VariantItem } from '../../../../../helpers/types';

const styles = StyleSheet.create({
    productList: {
        flexBasis: '100%',
        minWidth: '100%',
        width: '100%',
    },
    productListHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        fontSize: '6px',
        borderBottom: `0.5px solid ${colors.secondaryDark}`,
        marginTop: '15px',
        fontFamily: 'GebauerBold',
    },
    bottomProductListInfo: {
        marginTop: '10px',
        color: `${colors.darkRed}`,
        fontSize: '6px',
    },
    productListTableCol34: {
        flexBasis: '34%',
        maxWidth: '34%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    productListTableCol9: {
        flexBasis: 'calc(9.42% - 4px)',
        maxWidth: 'calc(9.42% - 4px)',
        padding: '2px',
        textAlign: 'center',
    },
    customizedItemsNote: {
        marginBottom: '-18px',
        fontSize: '6px',
    },
});

type Props = {
    itemList: VariantItem[];
    delivery: DeliveryData;
    hasCustomizedItems: boolean;
    details: OfferDetails | undefined;
    services: WardrobeOption[] | undefined;
    displayCategory: boolean;
};

export const ProductList: React.FC<Props> = ({
    itemList,
    delivery,
    hasCustomizedItems,
    details,
    displayCategory,
    services,
}) => (
    <View style={styles.productList}>
        <View style={styles.productListHeader} fixed>
            <View style={styles.productListTableCol34}></View>
            <View style={styles.productListTableCol9}>
                <Text>Einzukleidende Personen</Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>Ausstattung</Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>Stückpreis</Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>Brustlogo</Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>Rückenlogo</Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>Namensschild</Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>Preis je Mitarbeiter</Text>
                <Text>pro Woche</Text>
            </View>
        </View>

        <View>
            {itemList &&
                itemList.map((item: VariantItem, i: number) =>
                    itemList.length - 1 === i ? (
                        <View key={i} wrap={false}>
                            <ProductListItem item={item} displayCategory={displayCategory} />
                            <View>
                                <Text style={styles.bottomProductListInfo}>
                                    {delivery.detailedText}
                                </Text>
                                {hasCustomizedItems && (
                                    <Text style={styles.customizedItemsNote}>
                                        {CUSTOMIZED_ITEMS_NOTE}
                                    </Text>
                                )}
                            </View>
                            <ProductListPageSummary details={details} services={services} />
                        </View>
                    ) : (
                        <ProductListItem key={i} item={item} displayCategory={displayCategory} />
                    ),
                )}
        </View>
    </View>
);
