/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';
type Props = {
    text: string;
};
export const InfoText: React.FC<Props> = ({ text }) => <span css={styles.text}>{text}</span>;

const styles = {
    text: css`
        color: ${colors.secondary};
        font-size: ${fontSizes.mSmall};
        font-family: 'Optima LT Pro Medium', sans-serif;
    `,
};
