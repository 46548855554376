/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useMediaQuery } from '@react-hook/media-query';
import { css, SerializedStyles } from '@emotion/react';

import { impressionsImages, defaultImpressionsImages } from 'src/assets/images/impressions';
import { wideScreenBreakpoint, colors } from 'src/constants/variables';

import { CatalogNavigation } from 'src/modules/productsCatalog/components/CatalogNavigation';

import { CollectionsDropdown, CollectionsList } from './CollectionSelector';
import { ImpressionsCarousel } from './ImpressionsCarousel';
import { NavigationControls } from './NavigationControls';
import { ProductsList } from './ProductsList';

type Props = {
    productGroups: ProductGroup[];
    productCategories: ProductCatalogItemsList;
    productCollections: ProductCollectionsList;
    currentCollectionId: ProductCollection['id'];
    onCollectionSelect: (collection: ProductCollection) => void;
    getItemDetailsLink: (item: CollectionItem) => string;
    selectedProducts: CollectionItem[];
    setSelectedProducts: (selectedProducts: CollectionItem[]) => void;
    customCss?: {
        navigationWrapper?: SerializedStyles;
        navigationControls?: SerializedStyles;
    };
};

export const ProductsCatalog: React.FC<Props> = ({
    productGroups,
    productCategories,
    productCollections,
    currentCollectionId,
    onCollectionSelect,
    getItemDetailsLink,
    selectedProducts,
    setSelectedProducts,
    customCss,
}) => {
    const [currentGroup, setCurrentGroup] = useState<ProductGroup>();
    const [currentCategory, setCurrentCategory] = useState<ProductCatalogItem>();

    const [showGroupRow, setShowGroupRow] = useState<boolean>(true);
    const [isNavigationModalVisible, setNavigationModalVisibility] = useState<boolean>(false);

    const isWide = useMediaQuery('only screen and (min-width: ' + wideScreenBreakpoint);

    const currentCollection = useMemo(
        () =>
            Object.values(productCollections)
                .flat()
                .find((collection) => collection.id === currentCollectionId) || null,
        [productCollections, currentCollectionId],
    );

    const collectionImpressions =
        impressionsImages[currentCollectionId] || defaultImpressionsImages;

    const addToSelectedProducts = (product: CollectionItem) => {
        selectedProducts.includes(product)
            ? setSelectedProducts(selectedProducts.filter((item) => item !== product))
            : setSelectedProducts([...selectedProducts, product]);
    };

    useEffect(() => {
        const category = Object.values(productCategories)
            .flat()
            .find((category) => category.id === currentCollection?.parentId);
        setCurrentCategory(category);

        const group = productGroups.find((group) => group.id === category?.parentId);
        setCurrentGroup(group);
    }, [productGroups, productCategories, productCollections, currentCollection]);

    const renderNavigationControls = () => (
        <NavigationControls
            currentGroupName={currentGroup?.name}
            currentCategoryName={currentCategory?.name}
            currentCategoryImageUrl={currentCategory?.imageUrl}
            onClickCurrentGroup={onClickCurrentGroup}
            onClickCurrentCategory={onClickCurrentCategory}
            customCss={customCss?.navigationControls}
        />
    );

    const renderCollection = () => (
        <ProductsList
            collection={currentCollection}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            addToSelectedProducts={addToSelectedProducts}
            collectionImpressions={collectionImpressions}
            getItemLink={getItemDetailsLink}
        />
    );

    const onClickCurrentGroup = () => {
        setShowGroupRow(true);
        setNavigationModalVisibility(true);
    };

    const onClickCurrentCategory = () => {
        setShowGroupRow(false);
        setNavigationModalVisibility(true);
    };

    return (
        <React.Fragment>
            {isWide ? (
                <Row gutter={[16, 32]}>
                    <Col span={24} lg={4}>
                        {renderNavigationControls()}
                    </Col>

                    <Col span={4}>
                        <ImpressionsCarousel collectionImpressions={collectionImpressions} />
                        <CollectionsList
                            collectionsList={productCollections[currentCategory?.id || 0]}
                            currentCollectionId={currentCollection?.id}
                            onCollectionSelect={onCollectionSelect}
                        />
                    </Col>

                    <Col span={24} lg={16}>
                        {renderCollection()}
                    </Col>
                </Row>
            ) : (
                <>
                    {renderNavigationControls()}
                    <div css={styles.collectionDropdown}>
                        <CollectionsDropdown
                            collectionsList={productCollections[currentCategory?.id || 0]}
                            currentCollectionId={currentCollection?.id}
                            onCollectionSelect={onCollectionSelect}
                        />
                    </div>
                    <Row gutter={[16, 32]}>
                        <Col span={24} lg={16}>
                            {renderCollection()}
                        </Col>
                    </Row>
                </>
            )}
            <CatalogNavigation
                open={isNavigationModalVisible}
                showGroups={showGroupRow}
                onClose={() => setNavigationModalVisibility(false)}
                selectedCollectionId={currentCollection?.id || '0'}
                selectedGroupId={currentGroup?.id}
                onCollectionSelect={onCollectionSelect}
                productCollections={productCollections}
                productCategories={productCategories}
                productGroups={productGroups}
                customCss={customCss?.navigationWrapper}
            />
        </React.Fragment>
    );
};

const styles = {
    collectionDropdown: css`
        top: 101px;
        position: sticky;
        opacity: 97%;
        background: ${colors.background};
    `,
};
