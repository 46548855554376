import { basicsImpressions } from './basics';
import { basicsComfortFitImpressions } from './basicsComfortFit';
import { basicsRegularFitImpressions } from './basicsRegularFit';
import { basicsSlimFitImpressions } from './basicsSlimFit';
import { dynamicAllroundImpressions } from './dynamicAllround';
import { dynamicConstructImpressions } from './dynamicConstruct';
import { dynamicElementsImpressions } from './dynamicElements';
import { dynamicFlameImpressions } from './dynamicFlame';
import { dynamicFlameAdvancedImpressions } from './dynamicFlameAdvanced';
import { dynamicFlameExtremeImpressions } from './dynamicFlameExtreme';
import { dynamicIndustryImpressions } from './dynamicIndustry';
import { dynamicOutdoorImpressions } from './dynamicOutdoor';
import { dynamicReflectImpressions } from './dynamicReflect';
import { eurocleanImpressions } from './euroclean';
import { moveImpressions } from './move';
import { moveCleanImpressions } from './moveClean';
import { nugidealImpressions } from './nugideal';
import { nugImpressions } from './nug';
import { olympComfortFitImpressions } from './olympComfortFit';
import { olympModernFitImpressions } from './olympModernFit';
import { olympRegularFitImpressions } from './olympRegularFit';
import { outdoorImpressions } from './outdoor';
import { outdoorReflectImpressions } from './outdoorReflect';
import { peakImpressions } from './peak';
import { traditionImpressions } from './tradition';
import { trendlineImpressions } from './trendline';
import { basicsAirImpressions } from './basicsAir';
import { basicsReflectImpressions } from './basicsReflect';
import { dynamicElementsPlusImpressions } from './dynamicElementsPlus';
import { dynamicAllroundChemImpressions } from './dynamicAllroundChem';
import { dynamicAllroundArcImpressions } from './dynamicAllroundArc';
import { idealImpressions } from './ideal';
import { amondoImpressions } from './amondo';
import { basicsBusinessImpressions } from './basicsBusiness';
import { blacklineImpressions } from './blackline';
import { classicLineSImpressions } from './classicLineS';
import { greiffPremiumRfImpressions } from './greiffPremiumRf';
import { greiffPremiumCfImpressions } from './greiffPremiumCf';
import { greiffPremiumSfImpressions } from './greiffPremiumSf';
import { greiffCasualRfImpressions } from './greiffCasualRf';
import { greiffModernRfImpressions } from './greiffModernRf';
import { greiffModernSfImpressions } from './greiffModernSf';
import { hechterCasualImpressions } from './hechterCasual';
import { hechterTailoredImpressions } from './hechterTailored';
import { JsonObject } from 'src/helpers/types';
import { ergocareImpressions } from './ergocare';
import { ticinoImpressions } from './ticino';
import { spiceImpressions } from './spice';
import { dynamicAllroundChemArcImpressions } from './dynamicAllroundChemArc';
import { esdImpressions } from './esd';
import { hivisImpressions } from './hivis';
import { idealProtectCrossfireImpressions } from './idealProtectCrossfire';
import { exclusiveFerroImpressions } from './exclusiveFerro';
import { multiwearImpressions } from './multiwear';
import { idealProtectSpecialImpressions } from './idealProtectSpecial';
import { dynamicConstructImageImpressions } from './dynamicConstructImage';
import { fleischerImpressions } from './fleischer';
import { basicsEpaImpressions } from './basicsEpa';
import { schweisserImpressions } from './schweisser';
import { twinstarProtect } from './twinstarProtect';
import { idealProtect } from './idealProtect';
import { comet } from './comet';
import { proban } from './proban';
import { twinstarProtectUltra } from './twinstarProtectUltra';
import { twinstarProtectComplete } from './twinstarProtectComplete';
import { twinstarProtectCrossfire } from './twinstarProtectCrossfire';
import { twinstarProtectSpecialImpressions } from './twinstarProtectSpecial';
import { idealProtectExtraImpressions } from './idealProtectExtra';
import { exclusiveAluImpressions } from './exclusiveAlu';
import { exclusiveCementImpressions } from './exclusiveCement';
import { exclusiveGlasImpressions } from './exclusiveGlas';
import { dynamicIndustryImageImpressions } from './dynamicIndustryImage';
import { dynamicIndustryImageKfzImpressions } from './dynamicIndustryImageKfz';
import { dynamicIndustryImageKfz2Impressions } from './dynamicIndustryImageKfz2';
import { dynamicIndustryImageKfz3Impressions } from './dynamicIndustryImageKfz3';
import { epaImpressions } from './epa';
import { psaMultinorm1Impressions } from './psaMultinorm1';
import { dynamicImageImpressions } from './dynamicImage';
import { gerustbauerImpressions } from './gerustbauer';
import { esdImageImpressions } from './esdImage';
import { mewaPureImpressions } from './mewaPure';
import { twinstarImpressions } from './twinstar';
import { generationCoreImpressions } from './generationCore';
import { climaSKurzImpressions } from './climaSKurz';
import { climaSLangImpressions } from './climaSLang';
import { olympSuperSlimImpressions } from './olympSuperSlim';
import { schoffeImpressions } from './schoffe';
import { MBPJeansImpressions } from './MBPJeans';
import { casualImpressions } from './casual';
import { olympBodyFitImpressions } from './olympBodyFit';
import { olympPilotImpressions } from './olympPilot';
import { greiffStrickImpressions } from './greiffStrick';

export const defaultImpressionsImages = dynamicConstructImpressions;

type impressionsImagesType = {
    [key: string]: JsonObject;
};

export const impressionsImages: impressionsImagesType = {
    '1478': basicsImpressions,
    '7707': basicsAirImpressions,
    '9222': basicsReflectImpressions,
    '2872': basicsComfortFitImpressions,
    '1972': basicsRegularFitImpressions,
    '4277': basicsSlimFitImpressions,
    '5522': dynamicAllroundImpressions,
    '5950': dynamicAllroundArcImpressions,
    '5949': dynamicAllroundChemImpressions,
    '4784': dynamicConstructImpressions,
    '5859': dynamicElementsImpressions,
    '5957': dynamicElementsPlusImpressions,
    '5858': dynamicFlameImpressions,
    '5955': dynamicFlameAdvancedImpressions,
    '5956': dynamicFlameExtremeImpressions,
    '4785': dynamicIndustryImpressions,
    '12401': dynamicOutdoorImpressions,
    '5467': dynamicReflectImpressions,
    '0198': eurocleanImpressions,
    '5405': ergocareImpressions,
    '7400': moveImpressions,
    '7726': moveCleanImpressions,
    '0459': nugidealImpressions,
    '0245': nugImpressions,
    '2799': olympComfortFitImpressions,
    '2043': olympModernFitImpressions,
    '1806': olympRegularFitImpressions,
    '6765': outdoorImpressions,
    '7864': outdoorReflectImpressions,
    '7725': peakImpressions,
    '7681': spiceImpressions,
    '5863': traditionImpressions,
    '3545': trendlineImpressions,
    '7497': ticinoImpressions,
    '0213': idealImpressions,
    '5027': amondoImpressions,
    '7084': basicsBusinessImpressions,
    '3574': blacklineImpressions,
    '1626': classicLineSImpressions,
    '4736': greiffPremiumCfImpressions,
    '4733': greiffPremiumRfImpressions,
    '5101': greiffPremiumSfImpressions,
    '9154': greiffCasualRfImpressions,
    '9153': greiffModernRfImpressions,
    '9152': greiffModernSfImpressions,
    '8897': hechterCasualImpressions,
    '9101': hechterTailoredImpressions,
    '5951': dynamicAllroundChemArcImpressions,
    '0134': esdImpressions,
    '3146': hivisImpressions,
    '3371': idealProtectCrossfireImpressions,
    '1480': exclusiveFerroImpressions,
    '2362': multiwearImpressions,
    '2158': idealProtectSpecialImpressions,
    '6669': dynamicConstructImageImpressions,
    '0201': fleischerImpressions,
    '9227': basicsEpaImpressions,
    '0838': schweisserImpressions,
    '1313': twinstarProtect,
    '1357': idealProtect,
    '0935': comet,
    '1486': proban,
    '2384': twinstarProtectUltra,
    '3786': twinstarProtectComplete,
    '3372': twinstarProtectCrossfire,
    '2157': twinstarProtectSpecialImpressions,
    '5876': idealProtectExtraImpressions,
    '1477': exclusiveAluImpressions,
    '1614': exclusiveCementImpressions,
    '2342': exclusiveGlasImpressions,
    '5337': dynamicIndustryImageImpressions,
    '8065': dynamicIndustryImageKfzImpressions,
    '8252': dynamicIndustryImageKfz2Impressions,
    '8478': dynamicIndustryImageKfz3Impressions,
    '9244': epaImpressions,
    '2425': psaMultinorm1Impressions,
    '5908': dynamicImageImpressions,
    '6606': gerustbauerImpressions,
    '3906': esdImageImpressions,
    '9297': mewaPureImpressions,
    '0159': twinstarImpressions,
    '1884': generationCoreImpressions,
    '3685': climaSKurzImpressions,
    '1444': climaSLangImpressions,
    '7973': olympSuperSlimImpressions,
    '8891': schoffeImpressions,
    '3299': MBPJeansImpressions,
    '4762': casualImpressions,
    '7792': olympBodyFitImpressions,
    '3705': olympPilotImpressions,
    '6454': greiffStrickImpressions,
};
