/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Col, Form, Row } from 'antd';
import { EmployeeDetailsForm } from './EmployeeDetailsForm';
import { offerUserCustomerOverviewStyles } from 'src/modules/offer/components/styles';
import { Input } from 'src/components//FormElements';
import { colors } from 'src/constants/variables';
import { elementCustomScrollbar } from 'src/style/styles';
import { ConfirmationButton } from '../MewaCustomButton';

export type ContactDetailsFormProps = {
    onSubmit: (values: ContactDetailsValues) => void;
    initialValues?: ContactDetailsValues;
    buttonText: string;
    isButtonValid?: (values: ContactDetailsValues) => boolean;
};

export type ContactDetailsValues = {
    employeeContact?: Partial<ContactData>;
    assistantContact?: Partial<ContactData>;
    contactComments?: string;
};

export const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
    onSubmit,
    initialValues,
    buttonText,
    isButtonValid = () => true,
}) => (
    <Form
        initialValues={initialValues}
        onFinish={onSubmit}
        preserve={false}
        data-test={'contactDetailsFormWrapper'}
    >
        <Row gutter={[32, 0]}>
            <Col span={24} lg={8}>
                <h4
                    css={offerUserCustomerOverviewStyles.formTitle}
                    data-test={'employeeContactTitle'}
                >
                    HAUPTANSPRECHPARTNER/IN
                </h4>
                <EmployeeDetailsForm
                    prefix="employeeContact"
                    required={['title', 'name', 'surname', 'email', 'phone']}
                />
            </Col>
            <Col span={24} lg={8}>
                <h4
                    css={offerUserCustomerOverviewStyles.formTitle}
                    data-test={'assistantContactTitle'}
                >
                    WEITERE/R ANSPRECHPARTNER/IN
                </h4>
                <EmployeeDetailsForm
                    prefix="assistantContact"
                    required={['title', 'name', 'surname', 'phone']}
                />
            </Col>
            <Col span={24} lg={8}>
                <h4
                    css={offerUserCustomerOverviewStyles.formTitle}
                    data-test={'contactCommentsTitle'}
                >
                    ANMERKUNGEN
                </h4>
                <Input.TextArea
                    name="contactComments"
                    formItemCss={styles.commentsText}
                    placeholder="Fügen Sie Ihren Text hier ein…"
                    rows={8}
                    data-test={'contactComments'}
                />

                <Form.Item shouldUpdate>
                    {({ getFieldsValue }) => (
                        <ConfirmationButton
                            disabled={!isButtonValid(getFieldsValue())}
                            data-test={'saveChangesButton'}
                        >
                            {buttonText}
                        </ConfirmationButton>
                    )}
                </Form.Item>
            </Col>
        </Row>
    </Form>
);

const styles = {
    commentsText: css`
        textarea.ant-input {
            background-color: ${colors.secondaryBright};
            line-height: 1.75;
            padding: 8px;

            ${elementCustomScrollbar()};
        }
    `,
};
