import { createAction, ActionTypes } from 'src/helpers/types';
import { fakeHttpService } from 'src/modules/app/service/httpService';
import { ThunkAction } from 'src/store';

// @ToDo - in final version this will be replaced with data from backend
import { productGroupsList } from '../../data/resources';

export const setProductGroupsList = (resources: ProductGroup[]) =>
    createAction('SET_PRODUCT_GROUPS_LIST', resources);

export const fetchProductGroupsList = (): ThunkAction => async (dispatch) => {
    try {
        // @ToDo - in final version update this with backend request
        const result = await fakeHttpService(productGroupsList);
        dispatch(setProductGroupsList(result));
    } catch (error) {
        console.error('fetchProductGroupsList -> error', error);
    }
};

const productGroupsActions = {
    setProductGroupsList,
};

export type ProductGroupsActionsType = ActionTypes<typeof productGroupsActions>;
