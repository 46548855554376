import moment from 'moment';
import { PartialDeep } from 'type-fest';

import { DEFAULT_OFFER_SERVICE_PRICE } from 'src/constants';
import { ContactDetailsValues } from 'src/components/ContactDetailsModal';
import { CustomerDetailsValues } from 'src/components/CustomerDetailsModal';
import { KmuFormValues } from 'src/components/KmuModal';
import { OfferFormItems, OfferFormProps, OfferFormValues } from '../components/types';

import { isEmpty } from 'ramda';

const MIN_PRICE = 0;

export const isContactFormValid = (values: ContactDetailsValues): boolean =>
    !!values.employeeContact?.title &&
    !!values.employeeContact?.name &&
    !!values.employeeContact?.surname &&
    !!values.employeeContact?.email &&
    !!values.employeeContact?.phone &&
    !!values.assistantContact?.title &&
    !!values.assistantContact?.name &&
    !!values.assistantContact?.surname &&
    !!values.assistantContact?.phone;

export const isCustomerFormValid = (values: Partial<CustomerDetailsValues>): boolean =>
    !!values.customerCompany?.name &&
    !!values.customerCompany?.street &&
    !!values.customerCompany?.address &&
    !!values.customerContact?.title &&
    !!values.customerContact?.surname &&
    !!values.customerContact?.phone;

export const isOfferKmuFormValid = (values: Partial<KmuFormValues>) => values.intro && values.outro;

export const getOfferFormDefaultValues = (basics?: OfferBasics): PartialDeep<OfferFormProps> => ({
    services: (basics?.wardrobeOptions || []).reduce(
        (output, service) => ({
            ...output,
            [service.id]: DEFAULT_OFFER_SERVICE_PRICE,
        }),
        {},
    ),
});

export const getDuplicateFormInitValues = (
    details?: OfferDetails,
    summary?: OfferSummary,
): PartialDeep<OfferFormValues> => ({
    name: getOfferDuplicateName(details?.name),
    validUntil: getDuplicateValidUntilDate(summary?.createAt, details?.validUntil),
    backgroundImageUri: details?.backgroundImageUri,
    services: (details?.wardrobeOptionsData || []).reduce(
        (output, service) => ({
            ...output,
            [service.id]: service.price,
        }),
        {},
    ),
    pricePerWearer: details?.pricePerWearer,
    amountOfWearers: details?.amountOfWearers,
    rotation: details?.rotation,
    deliveryPricePerWeek: details?.deliveryPricePerWeek,
    globalSurcharge: details?.globalSurcharge,

    kmuDetails: details?.kmuDetails,
    includeTermsAndConditions: details?.includeTermsAndConditions,

    globalItemsPerWearer: details?.globalItemsPerWearer,

    totalPricePerWeek: details?.totalPricePerWeek,
    totalPricePerWeekValue: details?.totalPricePerWeekValue,
    weeklyTotalPriceAllItems: details?.weeklyTotalPriceAllItems,
    weeklyTotalPriceAllItemsValue: details?.weeklyTotalPriceAllItemsValue,
});

export const getOfferDuplicateName = (currentName?: string): string => {
    if (currentName?.match(/(\(\d+\))$/)) {
        return currentName.replace(
            /(\()(\d+)(\))$/,
            (_, ...params) => `(${Number.parseInt(params[1]) + 1})`,
        );
    } else {
        return `${currentName} (1)`;
    }
};

const getDuplicateValidUntilDate = (createAt?: string, validUntil?: string) => {
    const validPeriod = moment(validUntil).startOf('day').diff(createAt);
    return moment().add(validPeriod).format('YYYY-MM-DD');
};

const isBaseFormValid = (amountOfWearers: number, items: OfferFormItems) =>
    amountOfWearers && isItemsListWithAllData(items);

const areServicesValid = (services: OfferFormProps['services'], selectedServices: number[]) => {
    if (services) {
        const selectedPrices = selectedServices.map((id) => services[id]);
        return selectedPrices.every((el) => el > 0);
    }
    return true;
};

export const isSubmitButtonDisabled = (
    { amountOfWearers, itemsList, services }: OfferFormProps,
    kmuValues: Partial<KmuFormValues>,
    contactValues: ContactDetailsValues,
    customerValues: Partial<CustomerDetailsValues>,
    selectedServices: number[],
): boolean =>
    !(
        isBaseFormValid(amountOfWearers, itemsList) &&
        isOfferKmuFormValid(kmuValues) &&
        customerValues.customerCompany &&
        customerValues.customerContact &&
        isContactFormValid(contactValues) &&
        isCustomerFormValid(customerValues) &&
        areServicesValid(services, selectedServices)
    );

export const isItemsListWithAllData = (items?: OfferFormItems): boolean => {
    if (items) {
        const arePricesOk = checkBasePricesAreSet(items);
        const areItemsPerWearerOk = isEveryItemsPerWearersSet(items);
        const areAmountOfWearersOk = isEveryAmountPerWearersSet(items);
        return areItemsPerWearerOk && arePricesOk && areAmountOfWearersOk;
    }

    return false;
};

export const checkBasePricesAreSet = (items: OfferFormItems): boolean =>
    items && !isEmpty(items) && haveItemsAllPricesValid(items);

const isEveryItemsPerWearersSet = (items: OfferFormItems = {}) =>
    Object.keys(items).every((key) => !!items[key].itemsPerWearer);

const isEveryAmountPerWearersSet = (items: OfferFormItems = {}) =>
    Object.keys(items).every((key) => !!items[key].amountOfWearers);

const haveItemsAllPricesValid = (items: OfferFormItems = {}) => {
    const keys = Object.keys(items);
    return keys.every((key) => {
        const { basePrice, labelPrice, logoBackPrice, logoFrontPrice } = items[key];
        return (
            basePrice &&
            basePrice > MIN_PRICE &&
            (!labelPrice || labelPrice >= MIN_PRICE) &&
            (!logoBackPrice || logoBackPrice >= MIN_PRICE) &&
            (!logoFrontPrice || logoFrontPrice >= MIN_PRICE)
        );
    });
};
