import { createFixture } from 'src/tests/utils';
import { productColors } from 'src/constants/colors';
import { VariantItem } from '../helpers/types';

export const offerItemFixture = createFixture<VariantItem>({
    cartItemId: null,
    offerItemId: 88,

    modelId: 'TEST-ProductModel-ID',
    modelName: 'Test ProductModel full name',
    category: 'MBS',
    variantKey: 'TEST-VariantKey',
    variantName: 'Test VariantName with details',
    variantMaterial: 'Test Variant Material name',
    variantColor: productColors[0],
    imageLink: 'http://Product-Image-URI/',

    psa: true,
    itemsPerWearer: 3,

    basePrice: 4.71,
    surcharge: 2.0,
    labelPrice: 0.41,
    logoBackPrice: 0.42,
    logoFrontPrice: 0.43,
    stampPrice: 0.45,
    totalPrice: 8.56,

    //To be clarify on offer level
    enNorm: 'TEST-EN-Norm-123',
    norms: [],
    discontinued: false,
});
