import { ThunkAction } from 'src/store';
import { fakeHttpService } from 'src/modules/app/service/httpService';
import { createAction, ActionTypes } from 'src/helpers/types';

// @ToDo - in final version this will be replaced with data from backend
import { productCollectionsList } from '../../data/resources';

export const setProductCollectionsLoading = () => createAction('PRODUCT_COLLECTIONS_LOADING', null);

export const setProductCollectionsList = (data: ProductCollectionsList) =>
    createAction('SET_PRODUCT_COLLECTIONS_LIST', data);

export const fetchProductCollectionsList = (): ThunkAction => async (dispatch) => {
    try {
        dispatch(setProductCollectionsLoading());

        // @ToDo - in final version replace this with backend request
        const result = await fakeHttpService(productCollectionsList);

        dispatch(setProductCollectionsList(result));
    } catch (error) {
        console.error('fetchProductCollectionsList -> error', error);
    }
};

export const productCollectionsActions = {
    setProductCollectionsList,
    setProductCollectionsLoading,
};

export type ProductCollectionsActionsType = ActionTypes<typeof productCollectionsActions>;
