/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import homeScreenBackground from 'src/assets/images/home_screen_background.png';
import { darkenImage, textOverflowEllipsis } from 'src/style/styles';
import { colors, fontSizes } from 'src/constants/variables';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    productGroups: ProductGroup[];
    onSelect: (id: string) => void;
};

export const GroupColumn: React.FC<Props> = ({ productGroups, onSelect }) => (
    <div css={styles.wrapper} onClick={(e) => e.stopPropagation()} data-test={'groupColumnWrapper'}>
        {productGroups.map((group) => (
            <React.Fragment key={group.id}>
                <div css={styles.divider} />
                <div
                    onClick={() => !group.disabled && onSelect(group.id)}
                    css={[styles.item, !!group.disabled && styles.disabled]}
                    data-test={`${beautifyName(group.name)}_GROUP_ITEM`}
                >
                    {group.name}
                </div>
            </React.Fragment>
        ))}
    </div>
);

const styles = {
    wrapper: css`
        height: 100%;
        width: 100%;
        min-height: 340px;
        max-height: calc(55vh - 80px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px 10px;
        align-items: center;
        border-radius: 5px;

        ${darkenImage(homeScreenBackground, { opacity: 1 })};

        ::after {
            opacity: 0.5;
            border-radius: 5px;
        }
    `,
    divider: css`
        :first-of-type {
            display: none;
        }

        background-color: ${colors.secondary};
        height: 2px;
        width: 70%;
        margin: 5px 0;
        opacity: 0.5;
    `,
    item: css`
        color: ${colors.fontPrimary};
        font-size: ${fontSizes.xSmall};
        font-family: OptimaLTPro-DemiBold, sans-serif;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;

        max-width: 100%;
        min-height: 22px;
        line-height: 22px;
        ${textOverflowEllipsis};
    `,
    disabled: css`
        opacity: 0.25;
        color: ${colors.favourite};
    `,
};
