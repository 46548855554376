/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';
import { horizontalMark, wideScreenStyles, textOverflowEllipsis } from 'src/style/styles';
import { beautifyName } from '../../../../helpers/utils';

export type Props = {
    label: string;
    onClick: () => void;
    imageUrl?: string;
    secondary?: boolean;
};

export const ImageButton: React.FC<Props> = ({ onClick, imageUrl, label, secondary = false }) => (
    <div
        css={[styles.wrapper(secondary), imageUrl && styles.backgroundImage(imageUrl)]}
        onClick={onClick}
        data-test={`${beautifyName(label)}_IMAGE_BUTTON`}
    >
        <span css={[styles.name]}>{label}</span>
    </div>
);

const styles = {
    wrapper: (secondary: boolean) => css`
        display: flex;
        align-items: flex-end;
        border-radius: 5px;

        width: 100%;
        height: 75px;
        padding: 0 16px 16px;

        cursor: pointer;
        margin-bottom: 16px;

        ${wideScreenStyles(css`
            height: ${secondary ? '75' : '150'}px;
        `)};
    `,
    backgroundImage: (imageUrl: string) => css`
        background-image: url(${imageUrl});
        background-position: center;
        background-size: cover;
        position: relative;

        ::before {
            border-radius: 5px;
            background-color: rgba(0, 0, 0, ${0.3});
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
        }
    `,
    name: css`
        width: 100%;

        color: ${colors.fontPrimary};
        font-size: ${fontSizes.xSmall};
        font-family: OptimaLTPro-Medium, sans-serif;
        line-height: 1;
        text-transform: uppercase;

        ${horizontalMark(css`
            background-color: ${colors.primary};
        `)}

        padding-top: 16px;

        ${textOverflowEllipsis};
    `,
};
