import { VariantItem } from '../../../helpers/types';

export const createNewOfferItemDetailsFromCartResponse = (
    item: VariantItem,
    response: VariantItem,
) => {
    const newItem = Object.assign(item);
    const newResponse = Object.assign(response);

    delete Object.assign(newResponse, { weight: newResponse.variantWeight })['variantWeight'];
    delete Object.assign(newResponse, { imageLink: newResponse.imageUrl })['imageLink'];

    for (const key in newResponse)
        if (key in newItem) {
            newItem[key] = newResponse[key];
        }

    return newItem;
};
