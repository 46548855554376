/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { DataField } from 'src/components/DataField';
import { RadioGroup } from 'src/components/RadioGroup';
import { fontSizes } from 'src/constants/variables';

type Props = {
    value?: string;
    onChange?: (value?: string) => void;
    values: string[];
    handleChange: (value?: string) => void;
};

const prepareValues = (values: string[]) => values.map((value) => ({ label: value, value }));

export const RotationSelector: React.FC<Props> = ({ values, onChange, handleChange, value }) => {
    const onRotationChange = (value: string) => {
        onChange?.(value);
        handleChange(value);
    };

    return (
        <DataField name="Lieferrhythmus" data-test={'deliverySchedule'}>
            <RadioGroup<string>
                values={prepareValues(values)}
                customCss={styles.radioGroup}
                onChange={onRotationChange}
                value={value}
            />
        </DataField>
    );
};

const styles = {
    radioGroup: css`
        && .ant-radio {
            .ant-radio-inner {
                height: 24px;
                width: 24px;

                :after {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        && .ant-radio + span {
            font-size: ${fontSizes.small};
        }
    `,
};
