/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { listBasicStyles } from 'src/style/styles';

type Props = Pick<ProductVariant, 'sizes'>;

export const Sizes: React.FC<Props> = ({ sizes }) => (
    <ul css={listTable}>
        {sizes.map((size) => (
            <li key={size}>{size}</li>
        ))}
    </ul>
);

const listTable = css`
    ${listBasicStyles};

    display: flex;
    flex-wrap: wrap;

    li {
        padding: 4px 0;
        width: 25%;
    }
`;
