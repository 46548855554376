/** @jsxImportSource @emotion/react */
import React from 'react';

import { MAX_PRICE_VALUE } from 'src/constants';
import { DataField } from 'src/components/DataField';
import { NumberInput, simpleInputCss } from 'src/components/DataInputs';

type Props = {
    value?: number;
    onChange?: (rotationRateFee?: number) => void;
};

export const RotationRateFee: React.FC<Props> = ({ value, onChange }) => {
    const setRotationRateFee = (value?: number | string | null) => {
        const feeValue = value ? Number(value) : undefined;
        onChange && onChange(feeValue);
    };

    return (
        <DataField name="Servicepauschale" data-test={'rotationRateFee'}>
            <NumberInput
                min={0.0}
                max={MAX_PRICE_VALUE}
                step={0.01}
                precision={2}
                value={value}
                allowNegative={false}
                onChange={setRotationRateFee}
                currencySuffix
                css={simpleInputCss}
                addComma
            />
        </DataField>
    );
};
