import { AsyncResource, defaultAsyncResource } from 'src/helpers/types';
import { ProductCollectionsActionsType } from '../actions/productCollectionsActions';

export type ProductCollectionsState = AsyncResource<ProductCollectionsList>;

const initialState: ProductCollectionsState = defaultAsyncResource;

export function productCollectionsReducer(
    state: ProductCollectionsState = initialState,
    action: ProductCollectionsActionsType,
): ProductCollectionsState {
    switch (action.type) {
        case 'PRODUCT_COLLECTIONS_LOADING':
            return {
                ...initialState,
                loading: true,
            };
        case 'SET_PRODUCT_COLLECTIONS_LIST':
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        default:
            return state;
    }
}
