/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { ArrowNext } from 'src/assets/icons';
import { DataField } from 'src/components/DataField';
import { NumberInput } from 'src/components/DataInputs';
import { wideScreenStyles } from 'src/style/styles';
import { colors } from 'src/constants/variables';

type Props = {
    name?: string;
    totalPrice?: number;
    placeholder?: string;
};

export const TotalPrice: React.FC<Props> = ({
    name = 'Wöchentlicher Mietpreis je Mitarbeiter',
    totalPrice = 0,
    placeholder = '0,00',
}) => {
    const [visible, setVisible] = useState(false);

    return visible ? (
        <DataField name={name} css={styles.field} onNameClick={() => setVisible(false)}>
            <NumberInput
                readOnly
                value={totalPrice}
                precision={2}
                currencySuffix
                css={styles.totalPrice}
                placeholder={placeholder}
            />
        </DataField>
    ) : (
        <Icon component={ArrowNext} css={styles.arrowIcon} onClick={() => setVisible(true)} />
    );
};

const styles = {
    field: css`
        cursor: pointer;
        ${wideScreenStyles(css`
            margin: 0 0 16px;
        `)};
    `,
    arrowIcon: css`
        display: flex;
        height: 56px;
        margin: 16px 0;

        ${wideScreenStyles(css`
            margin: 0 0 16px;
        `)};

        svg {
            width: 32px;
            height: auto;

            path {
                opacity: 0.5;
                fill: ${colors.primaryDark};
            }
        }
    `,
    totalPrice: css`
        color: ${colors.fontSecondaryDark};

        /* Safari */
        -webkit-text-fill-color: ${colors.fontHeaderSecondary};
        opacity: 1;

        &,
        && input {
            cursor: text;
        }
    `,
};
