/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { DataField } from 'src/components/DataField';
import { Form } from 'antd';
import ItemsPerWearerCartInput from '../../AmountInput';
import { RotationRate } from 'src/modules/offer/components/RotationRate';
import { NumberInput, simpleInputCss } from 'src/components/DataInputs';
import { fourCharacters } from 'src/components/DataInputs/NumberInput';
import { ColorChooserDropdown, WeightChooserDropdown } from 'src/components';

export type FirstColumnProps = {
    availableColors?: ProductColor[];
    onColorChange: (color: ProductColor) => void;
    colorWarning: boolean;
    availableWeights?: number[];
    onWeightChange: (weight: number) => void;
    weightWarning: boolean;
    rotationRates: CartRotationRate[];
    handleRotationChange: (value?: string) => void;
    handleGlobalItemsPerWearerChange: (value?: number) => void;
};

const DEFAULT_AMOUNT_OF_WEARERS = 1;

export const FirstColumn: React.FC<FirstColumnProps> = ({
    rotationRates,
    handleRotationChange,
    availableColors,
    onColorChange,
    colorWarning,
    availableWeights,
    onWeightChange,
    weightWarning,
    handleGlobalItemsPerWearerChange,
}) => (
    <>
        {availableColors && (
            <ColorChooserDropdown
                colors={availableColors}
                onColorChange={onColorChange}
                warning={colorWarning}
            />
        )}

        {availableWeights && (
            <WeightChooserDropdown
                weights={availableWeights}
                onWeightChange={onWeightChange}
                warning={weightWarning}
            />
        )}

        <DataField name="Ausstattung x-fach" data-test={'globalItemsPerWearer'}>
            <Form.Item name="globalItemsPerWearer">
                <ItemsPerWearerCartInput
                    allowEmpty
                    handleChange={handleGlobalItemsPerWearerChange}
                />
            </Form.Item>
        </DataField>

        <div css={styles.wrapper}>
            <DataField name="Einzukleidende Personen" data-test={'amountOfWearers'}>
                <Form.Item name="amountOfWearers">
                    <NumberInput
                        required
                        allowEmpty
                        min={DEFAULT_AMOUNT_OF_WEARERS}
                        precision={0}
                        placeholder="0"
                        css={simpleInputCss}
                        allowNegative={false}
                        parser={fourCharacters}
                    />
                </Form.Item>
            </DataField>

            <RotationRate
                rotationRates={rotationRates}
                handleRotationChange={handleRotationChange}
            />
        </div>
    </>
);

const styles = {
    wrapper: css`
        .ant-form-item {
            margin-bottom: 0;
        }
    `,
};
