/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { listBasicStyles } from 'src/style/styles';
import { colors } from 'src/constants/variables';

type Props = Pick<ProductVariant, 'features'>;

export const Features: React.FC<Props> = ({ features }) => (
    <ul css={listBasicStyles}>
        {features.map((feature) => (
            <li css={listColorful} key={feature.name}>
                <span>{feature.name}</span>
                <span>{feature.value}</span>
            </li>
        ))}
    </ul>
);

const listColorful = css`
    display: flex;
    padding: 4px 0;

    span {
        flex: 50%;
    }

    :nth-of-type(odd) {
        background-color: ${colors.secondaryBright};
    }
`;
