/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';

import { ProductInfo } from './ProductInfo';
import { WeightSelector } from './WeightSelector';
import { MaterialSelector } from './MaterialSelector';

type Props = {
    product: Product;
    productVariant: ProductVariant;
    availableWeights: number[];
    availableMaterials: string[];
    onWeightChange: (weight: number) => void;
    onMaterialChange: (material: string) => void;
    withProductInfo?: boolean;
};

export const VariantSection: React.FC<Props> = ({
    product,
    productVariant,
    availableWeights,
    onWeightChange,
    onMaterialChange,
    availableMaterials,
    withProductInfo = true,
}) => (
    <div css={styles.container}>
        {withProductInfo && (
            <div css={styles.wrapper}>
                <ProductInfo productVariant={productVariant} product={product} />
            </div>
        )}
        <WeightSelector
            onChange={onWeightChange}
            selectedWeight={productVariant.weight}
            weights={availableWeights}
        />
        <MaterialSelector
            onChange={onMaterialChange}
            selectedMaterial={productVariant.material}
            materials={availableMaterials}
        />
    </div>
);

const styles = {
    wrapper: css`
        margin-bottom: 32px;
    `,
    container: css`
        background-color: ${colors.primary};
        padding: 0 16px 32px;
        background-color: transparent;
    `,
};
