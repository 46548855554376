/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { darkenImage, wideScreenStyles, desktopScreenStyles } from 'src/style/styles';
import { sizes } from 'src/constants/variables';

interface Props {
    image: string;
}

export const ImpressionImage: React.FC<Props> = ({ image }) => <div css={styles.image(image)} />;

const styles = {
    image: (image: string) => css`
        height: 550px;
        position: relative;

        ${darkenImage(image, { opacity: 0.01 })};

        ${wideScreenStyles(css`
            height: ${sizes.contentHeight};
        `)};

        ${desktopScreenStyles(css`
            // css height calculation covers page padding and header height
            height: calc(${sizes.contentDesktopHeight} - 32px - 126px);
        `)};
    `,
};
