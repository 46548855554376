import React from 'react';

import { ServicesProps } from '../../Offer/Services/Services';
import { TotalPriceCheckbox } from './TotalPriceCheckbox';

type Props = Pick<ServicesProps, 'selectedServices'> & {
    checked?: boolean | undefined;
    handleTotalPriceChange: (isSet?: boolean) => void;
};

export const TotalPricePerWeekElement: React.FC<Props> = ({ checked, handleTotalPriceChange }) => (
    <TotalPriceCheckbox
        label="WÖCHENTLICHER MIETPREIS/GESAMT"
        onChange={() => handleTotalPriceChange(!checked)}
        value={checked}
        valueFieldName="totalPricePerWeekValue"
    />
);
