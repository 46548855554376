import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { colors, fontSizes, shadows, sizes, zIndices } from '../constants/variables';
import { CancelButton, ConfirmationButton } from './MewaCustomButton';

type DialogBoxProps = {
    title: string;
    text: string;
    acceptButtonLabel?: string;
    acceptButtonAction?: () => void;
    cancelButtonLabel?: string;
    cancelButtonAction?: () => void;
};

export const ConfirmationDialog: React.FC<DialogBoxProps> = ({
    title,
    text,
    acceptButtonAction,
    acceptButtonLabel,
    cancelButtonLabel,
    cancelButtonAction,
}) => {
    return (
        <div css={styles.backdrop}>
            <div css={styles.container}>
                <div css={styles.content}>
                    <p css={styles.title}>{title}</p>
                    <p>{text}</p>
                    <p>Möchten Sie fortfahren?</p>
                </div>
                <div css={styles.buttonGroup}>
                    <CancelButton onClick={cancelButtonAction}>{cancelButtonLabel}</CancelButton>
                    <ConfirmationButton onClick={acceptButtonAction}>
                        {acceptButtonLabel}
                    </ConfirmationButton>
                </div>
            </div>
        </div>
    );
};

export const styles = {
    title: css`
        font-size: ${fontSizes.large};
    `,

    container: css`
        display: flex;
        padding: 48px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        background: ${colors.primary};
        box-shadow: ${shadows.card};
        z-index: ${zIndices.zIndex1200};
        width: ${sizes.dialog};
    `,

    content: css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    `,

    buttonGroup: css`
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 16px;
        align-self: stretch;
    `,

    backdrop: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: ${zIndices.zIndex1200};
    `,
};
