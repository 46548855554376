/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { AddToCart as AddToCartIcon } from 'src/assets/icons';
import { floatCartButton } from 'src/style/styles';
import { colors } from 'src/constants/variables';

export const AddToCartButton: React.FC<ButtonProps> = (props) => (
    <Button css={styles.button} shape="circle" {...props}>
        <Icon component={AddToCartIcon} />
    </Button>
);

const styles = {
    button: css`
        ${floatCartButton(colors.secondary)}

        svg {
            width: 32px;
            height: auto;

            path {
                fill: ${colors.primary};
            }
        }
    `,
};
