import { Auth } from 'aws-amplify';

import { delay, noop } from 'src/helpers/utils';
import { notificationService } from 'src/modules/offer/service/notificationService';
import {
    storeTokenInDatabase,
    storeCustomerIdInDatabase,
    getTokenFromDatabase,
} from 'src/helpers/database';

const cognitoSignIn = () => {
    Auth.federatedSignIn().catch(notificationService.error);
};

const getAccessToken = async () => {
    let token = (await getTokenFromDatabase()) || '';

    try {
        const currentSession = await Auth.currentSession();
        if (!currentSession) {
            return cognitoSignIn();
        }
        token = currentSession.getAccessToken().getJwtToken();

        // store token in IndexedDB, to make sure that also Service Workers have access to it
        getTokenFromDatabase().then((oldToken) => {
            if (token !== oldToken) {
                storeTokenInDatabase(token).then();
            }
        });
    } catch (e) {
        noop();
    }

    return token;
};

const refreshAccessToken = async (): Promise<{
    name: string;
    role: UserRole;
    email?: string;
}> => {
    // The Current session is not available immediately after signing in with the Hosted UI,
    // so we use delay to decrease console errors and app refreshments.
    // Using Hub.listen() might be a better solution in the future.
    await delay(200);

    const currentSession = await Auth.currentSession();
    const idTokenPayload = currentSession.getIdToken().decodePayload();

    const token = currentSession.getAccessToken().getJwtToken();
    await storeTokenInDatabase(token);

    const employeeId = idTokenPayload['custom:employee_id'];
    await storeCustomerIdInDatabase(employeeId);

    const employeeName = [idTokenPayload['name'], idTokenPayload['family_name']].join(' ');
    const employeeRole = idTokenPayload['custom:position'];
    const employeeEmail = idTokenPayload['email'] ?? undefined;

    return {
        name: employeeName,
        role: employeeRole,
        email: employeeEmail,
    };
};

const dropAccessToken = async (): Promise<void> => {
    await Auth.signOut();
};

export const authService = { cognitoSignIn, getAccessToken, refreshAccessToken, dropAccessToken };
