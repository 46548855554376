import React, { useState } from 'react';
import { Prompt, useHistory } from 'react-router';
import { Location } from 'history';

type Props = {
    when?: boolean;
    children: (onConfirm: () => void, onCancel: () => void) => React.ReactNode;
};

export const CustomPrompt: React.FC<Props> = ({ when = true, children }) => {
    const history = useHistory();
    const [nextLocation, setNextLocation] = useState<Location | null>(null);

    const onCancel = () => {
        setNextLocation(null);
    };

    const onConfirm = () => {
        nextLocation && history.push(nextLocation.pathname, nextLocation.state);
        setNextLocation(null);
    };

    return (
        <>
            <Prompt
                when={when && !nextLocation}
                message={(location) => {
                    setNextLocation(location);
                    return false;
                }}
            />
            {!!nextLocation && children(onConfirm, onCancel)}
        </>
    );
};
