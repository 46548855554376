import React from 'react';
import moment from 'moment';
import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';
import { ProductList } from 'src/modules/offerPdf/templates/pdfPages/productListPage/productList';
import { DeliveryData } from 'src/modules/offerPdf/types';

import { PageNumber } from 'src/modules/offerPdf/components/pageNumber';
import { pageStyles, PageTitle } from 'src/modules/offerPdf/components/pageTitle';
import { VariantItem } from '../../../../../helpers/types';

const styles = StyleSheet.create({
    mainTitle: {
        fontSize: '16px',
        fontFamily: 'GebauerBold',
        color: `${colors.darkRed}`,
    },
    secondaryTitle: {
        fontSize: '10px',
        fontFamily: 'GebauerRegular',
        color: `${colors.darkRed}`,
    },
});

type Props = {
    itemList: VariantItem[];
    details: OfferDetails | undefined;
    services: WardrobeOption[] | undefined;
    delivery: DeliveryData;
    hasCustomizedItems: boolean;
    displayCategory: boolean;
};

const RAW_DATE_FORMAT = 'DD.MM.YYYY';

export const ProductListPage: React.FC<Props> = ({
    itemList,
    details,
    services,
    delivery,
    displayCategory,
    hasCustomizedItems,
}) => (
    <Page size="A4" wrap={false} style={pageStyles.page}>
        <View fixed={true}>
            <PageTitle />
            <View>
                <Text style={styles.mainTitle}>Ihre Produktauswahl</Text>
                <Text style={styles.secondaryTitle}>
                    Unser individuelles Angebot für Sie - gültig bis{' '}
                    {moment(details?.validUntil).format(RAW_DATE_FORMAT)}
                </Text>
            </View>
        </View>

        <ProductList
            itemList={itemList}
            delivery={delivery}
            hasCustomizedItems={hasCustomizedItems}
            details={details}
            displayCategory={displayCategory}
            services={services}
        />

        <PageNumber />
    </Page>
);
