/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { CustomModal, Header } from 'src/components';
import { ModalFormProps } from 'src/hooks/useModalForm';
import { colors } from 'src/constants/variables';

import { offerUserCustomerOverviewStyles } from 'src/modules/offer/components/styles';

import { KmuDetailsForm, KmuFormValues } from './KmuDetailsForm';

type Props = ModalFormProps<KmuFormValues>;

export const KmuDetailsModal: React.FC<Props> = ({ initial, visible, onSubmit, onClose }) => (
    <CustomModal.Inline modalCss={styles.modal} visible={visible} onClick={onClose} destroyOnClose>
        <Header css={styles.header}>KMU ANSCHREIBEN</Header>
        <KmuDetailsForm initialValues={initial} onSubmit={onSubmit} />
    </CustomModal.Inline>
);

const styles = {
    modal: css`
        .ant-modal-body {
            padding-top: 144px;
            padding-bottom: 10px;
        }

        .ant-modal-close {
            right: 32px;
            opacity: 0.5;
            svg path {
                fill: ${colors.modalIconClose};
            }
        }
    `,
    header: css`
        ${offerUserCustomerOverviewStyles.title};

        & {
            margin: 0;
            padding-bottom: 18px;
        }
    `,
};
