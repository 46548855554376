/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form, FormInstance } from 'antd';
import { css } from '@emotion/react';
import { parseToPrice } from 'src/helpers/utils';
import { colors, fontSizes } from 'src/constants/variables';

import { OfferFormProps, OfferFormItem } from '../../types';
import { calculatePricePerItem } from '../../../helpers/offerPrices';
import { VariantItem } from '../../../../../helpers/types';

type ItemId = VariantItem['cartItemId'] | VariantItem['offerItemId'];

type Props = {
    itemId: ItemId;
};

const preparePrice = (itemValues?: OfferFormItem) => {
    const itemPrice = itemValues && calculatePricePerItem(itemValues);

    return itemPrice ? parseToPrice(itemPrice) : undefined;
};

export const ProductRowSummaryPrice: React.FC<Props> = ({ itemId }) => (
    <Form.Item shouldUpdate css={styles.container}>
        {({ getFieldsValue }: FormInstance<OfferFormProps>) => (
            <div css={styles.wrapper}>
                <div css={styles.value}>
                    {preparePrice(getFieldsValue().itemsList?.[itemId + '']) || (
                        <span css={styles.noData}>-</span>
                    )}
                </div>
                <span css={styles.unit}>€</span>
            </div>
        )}
    </Form.Item>
);

const styles = {
    container: css`
        margin: 0;
        width: 100%;
    `,
    wrapper: css`
        width: 100%;
        height: 56px;
        line-height: 56px;
        font-family: OptimaLTPro-Medium, sans-serif;
        text-align: center;
        font-size: ${fontSizes.mSmall};
        display: flex;
        justify-content: center;
    `,
    value: css`
        color: ${colors.fontHeader};
        text-align: center;
    `,
    unit: css`
        position: absolute;
        right: 0;
    `,
    noData: css`
        color: ${colors.backgroundDark}40;
    `,
};
