import { AsyncResource } from 'src/helpers/types';
import { CollectionItemsActionsType } from './productCollectionItemsActions';

export type ProductCollectionItemsState = AsyncResource<CollectionItem[]> & {
    id: string | null;
    name: string | null;
};

const initialState: ProductCollectionItemsState = {
    id: null,
    name: null,
    data: null,
    error: false,
    loading: false,
};
export const productCollectionItems = (
    state: ProductCollectionItemsState = initialState,
    action: CollectionItemsActionsType,
): ProductCollectionItemsState => {
    switch (action.type) {
        case 'COLLECTION_ITEMS_ERROR':
            return {
                ...initialState,
                error: true,
            };

        case 'COLLECTION_ITEMS_LOADING':
            return {
                ...initialState,
                loading: true,
            };

        case 'SET_COLLECTION_ITEMS_DATA':
            return {
                ...state,
                ...action.payload,
                error: false,
                loading: false,
            };

        case 'CLEAR_COLLECTION_ITEMS_DATA':
            return initialState;

        default:
            return state;
    }
};
