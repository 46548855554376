/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { paths, zIndices } from 'src/constants/variables';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import {
    CommonRectangularButton,
    ButtonTypes,
} from 'src/components/Buttons/CommonRectangularButton';

type Props = {
    addProducts: () => Promise<boolean>;
    addDisabled: boolean;
    addFavoritesDisabled?: boolean;
    customWrapperStyle?: SerializedStyles;
};

interface StateType {
    redirectedFrom: string;
}

export enum RedirectedFrom {
    OFFER_FORM_PAGE = 'newOffer',
}

export const ProductsActionButtons: React.FC<Props> = ({
    addProducts,
    addDisabled,
    customWrapperStyle = css``,
}) => {
    const history = useHistory();

    const redirect = (redirectTo: string) => {
        history.push(generatePath(paths[redirectTo as keyof typeof paths]));
    };

    const { state } = useLocation<StateType>();

    const typeButton = () => {
        switch (state?.redirectedFrom) {
            case RedirectedFrom.OFFER_FORM_PAGE:
                return (
                    <>
                        <CommonRectangularButton
                            name="ZUM ANGEBOT"
                            type={ButtonTypes.darkBorderColor}
                            onClick={() => redirect(RedirectedFrom.OFFER_FORM_PAGE)}
                        />
                        <CommonRectangularButton
                            name="EINFÜGEN"
                            disabled={addDisabled}
                            type={ButtonTypes.redBgColor}
                            onClick={addProducts}
                        />
                    </>
                );
            default:
                return (
                    <CommonRectangularButton
                        name="EINFÜGEN"
                        disabled={addDisabled}
                        type={ButtonTypes.redBgColor}
                        onClick={addProducts}
                    />
                );
        }
    };

    return (
        <div
            data-test={'productsButtonWrapper'}
            css={css`
                ${styles.wrapper};
                ${customWrapperStyle};
            `}
        >
            {typeButton()}
        </div>
    );
};

const styles = {
    wrapper: css`
        bottom: 0;
        position: sticky;
        z-index: ${zIndices.zIndex010};
        padding: 32px 0;
        background: rgba(240, 240, 240, 0.97);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    `,
};
