import { Col, Row } from 'antd';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { paths } from 'src/constants/variables';

import { AddProductTile } from '../AddProductTile';
import { RedirectedFrom } from 'src/modules/offer/components/ProductsActionButtons';

export type EmptyProductsSectionProps = {
    onAddProductsFromCatalogButtonClick?: () => void;
};

export const EmptyProductsSection: React.FC<EmptyProductsSectionProps> = ({
    onAddProductsFromCatalogButtonClick,
}) => {
    const history = useHistory();

    const redirectAction = () => {
        history.push(generatePath(paths.products), {
            redirectedFrom: RedirectedFrom.OFFER_FORM_PAGE,
        });
    };

    return (
        <Row gutter={[32, 0]}>
            <Col span={24}>
                <AddProductTile
                    text={'Produkte aus Katalog hinzufügen'}
                    onButtonClick={onAddProductsFromCatalogButtonClick || redirectAction}
                />
            </Col>
        </Row>
    );
};
