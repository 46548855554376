/** @jsxImportSource @emotion/react */
import React from 'react';
import { Row, Col } from 'antd';
import { css } from '@emotion/react';

import { Input, Select } from 'src/components/FormElements';

type Props = {
    prefix: string;
    required?: string[];
};

export const EmployeeDetailsForm: React.FC<Props> = ({ prefix, required = [] }) => (
    <>
        <Row gutter={[32, 0]}>
            <Col span={12}>
                <Select.Title
                    name={[prefix, 'title']}
                    required={required.indexOf('title') >= 0}
                    formItemCss={styles.doubleInput}
                    getPopupContainer={(trigger) => trigger ?? document.body}
                    data-test={'personTitle'}
                />
            </Col>
        </Row>
        <Row gutter={[32, 0]}>
            <Col span={12}>
                <Input.Text
                    name={[prefix, 'surname']}
                    placeholder="Name"
                    required={required.indexOf('surname') >= 0}
                    formItemCss={styles.doubleInput}
                    data-test={'surname'}
                />
            </Col>
            <Col span={12}>
                <Input.Text
                    name={[prefix, 'name']}
                    placeholder="Vorname"
                    required={required.indexOf('name') >= 0}
                    formItemCss={styles.doubleInput}
                    data-test={'firstname'}
                />
            </Col>
        </Row>
        <Input.Text
            name={[prefix, 'position']}
            placeholder="Position"
            required={required.indexOf('position') >= 0}
            data-test={'personPosition'}
        />
        <Input.Text
            name={[prefix, 'email']}
            placeholder="E-Mail"
            required={required.indexOf('email') >= 0}
            data-test={'personEmail'}
        />
        <Input.Phone name={[prefix, 'phone']} required={required.indexOf('phone') >= 0} data-test={'personPhone'} />
    </>
);

const styles = {
    doubleInput: css`
        &&&& {
            max-width: unset;
            margin-bottom: 24px;
        }
    `,
};
