import { Amplify } from 'aws-amplify';

export const configureAmplify = () =>
    Amplify.configure({
        Auth: {
            region: process.env.REACT_APP_COGNITO_REGION,
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
            userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            oauth: {
                domain: process.env.REACT_APP_OAUTH_DOMAIN,
                scope: ['email', 'openid', 'profile'],
                redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
                redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
                responseType: 'code',
            },
        },
    });
