/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { wideScreenStyles, mobileScreenStyles } from 'src/style/styles';
import { colors, fontSizes } from 'src/constants/variables';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    productCollections: ProductCollectionsList;
    selectedCategoryId: string;
    selectedCollectionId: string;
    onCollectionClick: (collection: ProductCatalogItem) => void;
};

export const CollectionColumn: React.FC<Props> = ({
    productCollections,
    selectedCategoryId,
    selectedCollectionId,
    onCollectionClick,
}) => (
    <div css={styles.wrapper} data-test={'collectionsColumnWrapper'}>
        {productCollections[selectedCategoryId].map((collection) => (
            <div
                onClick={() => !collection.disabled && onCollectionClick(collection)}
                css={[
                    styles.item,
                    !!collection.disabled && styles.disabled,
                    collection.id === selectedCollectionId && styles.selected,
                ]}
                key={collection.id}
                data-test={`${beautifyName(collection.name)}_COLLECTION_ITEM`}
            >
                {collection.name}
            </div>
        ))}
    </div>
);

const styles = {
    wrapper: css`
        max-height: 100%;
        width: 100%;
        overflow-y: auto;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        background-color: ${colors.primary};
        padding: 24px;

        ${mobileScreenStyles(css`
            margin-top: -2px;
        `)};
        ${wideScreenStyles(css`
            padding: 0;
        `)}
    `,
    divider: css`
        background-color: ${colors.secondaryDark};
        height: 2px;
        min-height: 2px;
        width: 70%;
        margin: 5px 0;
        opacity: 0.5;
    `,
    item: css`
        color: ${colors.fontPrimaryDark};
        font-size: ${fontSizes.xSmall};
        font-family: OptimaLTPro-Roman, sans-serif;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 600;
        padding: 6px 0;
        width: 100%;

        ${wideScreenStyles(css`
            width: 50%;
        `)}
    `,
    selected: css`
        color: ${colors.fontSecondaryDark};
        font-family: OptimaLTPro-DemiBold, sans-serif;
    `,
    disabled: css`
        opacity: 0.25;
        cursor: default;
    `,
};
