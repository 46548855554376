import { openDB } from 'idb';

export const openTokenDatabase = async () =>
    await openDB('db', 2, {
        upgrade(upgradeDB, oldVersion) {
            if (oldVersion < 1) {
                upgradeDB.createObjectStore('token', { autoIncrement: true });
            }

            if (oldVersion < 2) {
                upgradeDB.createObjectStore('employeeId', { autoIncrement: true });
            }
        },
    });

const getTokenStore = async () => {
    const db = await openTokenDatabase();

    const tx = db.transaction('token', 'readwrite');

    return tx.objectStore('token');
};

const getEmployeeIdStore = async () => {
    const db = await openTokenDatabase();

    const tx = db.transaction('employeeId', 'readwrite');

    return tx.objectStore('employeeId');
};

export const getTokenFromDatabase = async () => {
    const store = await getTokenStore();

    const token: string = await store.get('token');

    return token;
};

export const getEmployeeIdFromDatabase = async () => {
    const store = await getEmployeeIdStore();

    const employeeId: string = await store.get('employeeId');

    return employeeId;
};

export const storeTokenInDatabase = async (token: string) => {
    const store = await getTokenStore();

    await store.clear();
    await store.put(token, 'token');
};

export const storeCustomerIdInDatabase = async (employeeId: string) => {
    const store = await getEmployeeIdStore();

    await store.put(employeeId, 'employeeId');
};
