/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { wideScreenStyles, mobileScreenStyles } from 'src/style/styles';
import { colors, fontSizes } from 'src/constants/variables';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    productCategories: ProductCatalogItemsList;
    selectedGroup: string;
    selectedCategory: string;
    onSelect: (id: string) => void;
};

export const CategoryColumn: React.FC<Props> = ({
    productCategories,
    selectedGroup,
    selectedCategory,
    onSelect,
}) => (
    <div
        css={styles.wrapper}
        onClick={(e) => e.stopPropagation()}
        data-test={'categoryColumnWrapper'}
    >
        {productCategories[selectedGroup].map((category) => (
            <React.Fragment key={category.id}>
                <div css={styles.divider} />
                <div
                    onClick={() => !category.disabled && onSelect(category.id)}
                    css={[
                        styles.item,
                        !!category.disabled && styles.disabled,
                        category.id === selectedCategory && styles.selected,
                    ]}
                    data-test={`${beautifyName(category.name)}_CATEGORY_ITEM`}
                >
                    {category.name}
                </div>
            </React.Fragment>
        ))}
    </div>
);

const styles = {
    wrapper: css`
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        background-color: ${colors.primary};
        justify-content: center;

        ${mobileScreenStyles(css`
            margin-top: -2px;
        `)};
        ${wideScreenStyles(css`
            justify-content: start;
        `)}
    `,
    divider: css`
        :first-of-type {
            display: none;
        }

        background-color: ${colors.secondaryDark};
        height: 2px;
        width: 70%;
        margin: 5px 0;
        opacity: 0.5;
    `,
    item: css`
        color: ${colors.backgroundDark};
        font-size: ${fontSizes.xSmall};
        font-family: OptimaLTPro-DemiBold, sans-serif;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px 0;
        cursor: pointer;
    `,
    selected: css`
        color: ${colors.secondary};
    `,
    disabled: css`
        opacity: 0.25;
        cursor: default;
    `,
};
