import { createSelector } from 'reselect';
import { RootState } from 'src/store';

import { ProductCollectionItemsState } from './productCollectionItemsReducer';

const getCollectionItemsState = (state: Pick<RootState, 'productCollectionItems'>) =>
    state.productCollectionItems;

export const getCollectionLoadingStatus = createSelector(
    getCollectionItemsState,
    (state: ProductCollectionItemsState) => state.loading,
);
export const getCollectionLoadingError = createSelector(
    getCollectionItemsState,
    (state: ProductCollectionItemsState) => state.error,
);
export const getCollectionId = createSelector(
    getCollectionItemsState,
    (state: ProductCollectionItemsState) => state.id,
);
export const getCollectionName = createSelector(
    getCollectionItemsState,
    (state: ProductCollectionItemsState) => state.name,
);
export const getCollectionItems = createSelector(
    getCollectionItemsState,
    (state: ProductCollectionItemsState) => state.data,
);
