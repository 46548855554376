/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form, Checkbox as AntdCheckbox } from 'antd';
import { CheckboxProps as AntdCheckboxProps } from 'antd/lib/checkbox';

import { FormItemProps } from './types';

import { styles } from './styles';

type CheckboxProps = FormItemProps & Omit<AntdCheckboxProps, 'name'>;

export const Checkbox: React.FC<CheckboxProps> = ({
    name,
    children,
    formItemCss,
    required = false,
    ...rest
}) => (
    <Form.Item
        name={name}
        valuePropName="checked"
        css={[styles.checkboxItem, formItemCss, required && styles.required]}
    >
        <AntdCheckbox defaultChecked={false} {...rest} data-test={`addItemCheckbox`}>
            {children}
        </AntdCheckbox>
    </Form.Item>
);
