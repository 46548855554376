/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';
import { ProductNormsText } from '../../../../product/components/psaNorms';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    item: VariantItem;
};

export const FormItemDetails: React.FC<Props> = ({ item }) => (
    <div css={styles.details}>
        <div css={styles.name}>{item.modelName}</div>
        <div css={styles.info}>{`${item.variantColor.name} / ${item.variantMaterial}`}</div>
        <div css={styles.variantWeight}>
            {item.variantKey} / {item.variantWeight}
            <span css={styles.weightUnits}>
                g/m<sup>2</sup>
            </span>
        </div>
        {item.norms.length > 0 ? <ProductNormsText norms={item.norms} /> : null}
    </div>
);

const styles = {
    details: css`
        font-family: 'Optima LT Pro DemiBold', sans-serif;
        font-size: ${fontSizes.xxSmall};
        line-height: 18px;
        text-transform: uppercase;
    `,
    name: css`
        text-transform: uppercase;
        font-size: ${fontSizes.mSmall};
    `,
    icon: css`
        margin-left: 4px;
        svg {
            fill: ${colors.success};
        }
    `,
    info: css`
        font-size: ${fontSizes.xSmall};
        color: ${colors.fontPrimaryDark};
        font-family: OptimaLTPro-Medium, sans-serif;
    `,
    variantWeight: css`
        color: ${colors.backgroundDark};
        font-size: ${fontSizes.xSmall};
        font-family: 'Optima LT Pro DemiBold', sans-serif;
        font-weight: 700;
    `,
    weightUnits: css`
        text-transform: lowercase;
        padding-left: 5px;
    `,
};
