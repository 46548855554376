/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { useRef } from 'react';
import { colors } from 'src/constants/variables';
import { ItemDetails } from '../../Offer/ProductRow/ItemDetails';
import ImagePlaceHolderSVG from 'src/assets/icons/no_image_placeholder.svg';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    item: VariantItem;
    customCss?: SerializedStyles;
};

export const HeaderColumn: React.FC<Props> = ({ item, customCss }) => {
    const ref = useRef(null);

    return (
        <div css={[styles.wrapper, customCss]} ref={ref}>
            <div css={styles.image}>
                <img
                    src={item.imageLink || ImagePlaceHolderSVG}
                    alt={`${item.modelName} | ${item.variantKey}`}
                />
            </div>

            <ItemDetails item={item} />
        </div>
    );
};

const styles = {
    wrapper: css`
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 20px;
        padding-left: 8px;
        padding-bottom: 16px;
    `,
    image: css`
        width: 64px;
        height: 64px;
        min-width: 64px;
        cursor: pointer;
        position: relative;
        margin-right: 19px;
        background-color: ${colors.fontPrimary};

        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        img {
            width: 64px;
        }
    `,
};
