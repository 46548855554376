/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import { scrollToDOMElement } from 'src/helpers/scroll';
import { colors } from 'src/constants/variables';

type Props = {
    anchor: string;
    name: string;
    mobile: boolean;
};

export const BrandHeaderAnchor: React.FC<Props> = ({ anchor, name, mobile }) => (
    <div
        css={[mobile && styles.menuItemMobile, !mobile && styles.menuItem]}
        onClick={() => {
            scrollToDOMElement(anchor);
        }}
    >
        {name}
    </div>
);

const styles = {
    menuItem: css`
        margin: 0 5%;
        cursor: pointer;
    `,

    menuItemMobile: css`
        height: 58px;
        padding: 21px;
        color: ${colors.link};

        &:hover {
            background-color: ${colors.secondary}80;
            color: ${colors.primary};
        }
    `,
};
