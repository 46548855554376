import React from 'react';
import { Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import OutroPageBg from 'src/assets/images/pdfBackgrounds/outro-bg.png';
import { colors } from '../../../../constants/variables';
import { OfferForPdf } from '../../types';

import { PageNumber } from 'src/modules/offerPdf/components/pageNumber';

const styles = StyleSheet.create({
    section: {
        position: 'relative',
        minHeight: '100%',
        height: '100%',
    },
    pageBackground: {
        position: 'absolute',
        width: '100%',
        height: '55%',
    },
    greetingContainer: {
        position: 'absolute',
        left: '40px',
        top: '60%',
    },
    greetingHeader: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'GebauerBold',
        color: colors.darkRed,
    },
    greetingText: {
        fontSize: '11px',
        paddingTop: '20px',
        maxWidth: '85%',
        fontFamily: 'GebauerRegular',
        color: colors.darkGrey,
    },
});

type Props = {
    offer: OfferForPdf;
};

export const OutroPage: React.FC<Props> = ({ offer }) => {
    const customerContact = offer?.basics?.customerContact;
    const greetingHeaderPrefix = `Sehr geehrte${customerContact?.title === 'Herr' ? 'r' : ''}`;
    return (
        <Page size="A4">
            <View style={styles.section}>
                <Image style={styles.pageBackground} src={OutroPageBg} />
                <div style={styles.greetingContainer}>
                    <Text style={styles.greetingHeader}>
                        {`${greetingHeaderPrefix} ${customerContact?.title} ${customerContact?.surname},`}
                    </Text>
                    <Text style={styles.greetingText}>{offer?.details?.kmuDetails?.outro}</Text>
                </div>
                <PageNumber />
            </View>
        </Page>
    );
};
