/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { css } from '@emotion/react';
import { acceptButton, cancelButton } from 'src/style/styles';
import { colors, shadows } from '../constants/variables';

type Props = {
    onClick?: () => void;
    htmlType?: 'button' | 'submit' | 'reset';
    variant?: 'accept' | 'cancel';
};

const MewaCustomButton: React.FC<Props & ButtonProps> = ({
    children,
    htmlType = 'submit',
    variant = 'accept',
    ...rest
}) => {
    let additionalStyles = null;
    switch (variant) {
        case 'accept':
            additionalStyles = acceptButtonStyles;
            break;
        case 'cancel':
            additionalStyles = cancelButtonStyles;
            break;
        default:
            break;
    }

    return (
        <Button css={[buttonStyles, additionalStyles]} htmlType={htmlType} {...rest}>
            {children}
        </Button>
    );
};

export const ConfirmationButton: React.FC<Props & ButtonProps> = (props) => (
    <MewaCustomButton {...props} variant="accept" />
);

export const CancelButton: React.FC<Props & ButtonProps> = (props) => (
    <MewaCustomButton {...props} variant="cancel" />
);

const buttonStyles = css`
    height: 56px;
    border: none;
    border-radius: unset;
`;

const acceptButtonStyles = css`
    ${acceptButton};

    :hover,
    :focus {
        background: ${colors.secondary};
        color: ${colors.fontPrimary};
    }
`;

const cancelButtonStyles = css`
    ${cancelButton};
    box-shadow: ${shadows.card};
`;
