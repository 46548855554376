import { createAction, ActionTypes, ItemWarnings, VariantItem } from 'src/helpers/types';
import { OfferPersistState } from '../reducers';

export type PersistOfferValuesArgs = Pick<
    OfferPersistState,
    'form' | 'includeTermsAndConditions' | 'kmuDetails' | 'background' | 'expirationDate'
>;
export type PersistOfferWarningsArgs = {
    colorWarnings?: ItemWarnings;
    weightWarnings?: ItemWarnings;
};

export const persistOfferValues = (offerValues: PersistOfferValuesArgs) =>
    createAction('OFFER|PERSIST_OFFER_VALUES', offerValues);

export const persistOfferWarnings = (offerWarnings: PersistOfferWarningsArgs) =>
    createAction('OFFER|PERSIST_OFFER_WARNINGS', offerWarnings);

export const resetOfferForm = () => createAction('OFFER|RESET_PERSISTED_VALUES', null);

export const loadOverviewRequest = () => createAction('OFFER|LOAD_OVERVIEW_REQUEST', null);

export const loadOverviewSuccess = () => createAction('OFFER|LOAD_OVERVIEW_SUCCESS', null);

export const loadOverviewFailure = (errorCode?: number) =>
    createAction('OFFER|LOAD_OVERVIEW_FAILURE', errorCode);

export const updateOverviewItems = (items: VariantItem[]) =>
    createAction('OFFER|UPDATE_OVERVIEW_ITEMS', items);

export const updateOverviewBasics = (
    basics: Pick<OfferBasics, 'includeTermsAndConditions' | 'wardrobeOptions'>,
) => createAction('OFFER|UPDATE_OVERVIEW_BASICS', basics);

export const removeOfferItemSuccess = (cartItemId: number) =>
    createAction('OFFER|REMOVE_ITEM_SUCCESS', cartItemId);

export const clearOfferProductsSuccess = () => createAction('OFFER|CLEAR_CART_SUCCESS', null);

export const fetchOffersListRequest = () => createAction('OFFER|FETCH_OFFERS_LIST_REQUEST', null);

export const fetchOffersListSuccess = (offers: OfferSummary[]) =>
    createAction('OFFER|FETCH_OFFERS_LIST_SUCCESS', offers);

export const fetchOffersListFailure = () => createAction('OFFER|FETCH_OFFERS_LIST_FAILURE', null);

export const updateOfferItem = (offerItem: VariantItem) =>
    createAction('OFFER_DETAILS|UPDATE_OFFER_ITEM', offerItem);

export const updateCustomerDetails = (
    customerCompany: OfferCompanyData,
    customerContact: ContactData,
) =>
    createAction('OFFER|UPDATE_CUSTOMER_DETAILS', {
        customerCompany,
        customerContact,
    });

export const updateContactDetails = (
    employeeContact: ContactData,
    assistantContact: ContactData,
    contactComments?: string,
) =>
    createAction('OFFER|UPDATE_CONTACT_DETAILS', {
        employeeContact,
        assistantContact,
        contactComments,
    });

export const updateOverviewAssets = (
    availableColors?: ProductColor[],
    availableWeights?: number[],
    rotationRates?: CartRotationRate[],
) =>
    createAction('OFFER|UPDATE_OVERVIEW_ASSETS', {
        availableColors,
        availableWeights,
        rotationRates,
    });

export const offerActions = {
    loadOverviewRequest,
    loadOverviewSuccess,
    loadOverviewFailure,
    updateOverviewItems,
    updateOverviewAssets,
    updateOverviewBasics,
    updateContactDetails,
    updateCustomerDetails,
    removeOfferItemSuccess,
    clearOfferProductsSuccess,
    resetOfferForm,
    fetchOffersListRequest,
    fetchOffersListSuccess,
    fetchOffersListFailure,
    persistOfferValues,
    persistOfferWarnings,
    updateOfferItem,
};

export type OfferActions = ActionTypes<typeof offerActions>;
