/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Form } from 'antd';
import { fontSizes } from 'src/constants/variables';
import { NumberColumnWrapper } from './NumberColumnWrapper';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    cartItemId: VariantItem['cartItemId'];
    children: React.ReactNode;
    name: keyof VariantItem;
};

export const PriceColumn: React.FC<Props> = ({ cartItemId, children, name }) => (
    <NumberColumnWrapper>
        <div css={styles.wrapper}>
            <Form.Item shouldUpdate css={styles.input}>
                <Form.Item name={['itemsList', `${cartItemId}`, name]}>{children}</Form.Item>
            </Form.Item>
        </div>
    </NumberColumnWrapper>
);

const styles = {
    wrapper: css`
        .ant-form-item {
            margin: 0;
        }
    `,
    input: css`
        * {
            font-size: ${fontSizes.mSmall} !important;
        }

        .ant-form-item {
            margin: 0;
        }
    `,
};
