import React from 'react';
import { Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';
import { OfferForPdf } from '../../types';
import { backgrounds } from '../../../../components/BackgroundSelectionModal/backgrounds';

import background_1_large from 'src/assets/images/offer/backgrounds/background_1_large.jpg';
import background_2_large from 'src/assets/images/offer/backgrounds/background_2_large.jpg';
import background_3_large from 'src/assets/images/offer/backgrounds/background_3_large.jpg';
import background_4_large from 'src/assets/images/offer/backgrounds/background_4_large.jpg';
import background_5_large from 'src/assets/images/offer/backgrounds/background_5_large.jpg';
import background_6_large from 'src/assets/images/offer/backgrounds/background_6_large.jpg';

import MewaLogoRed from 'src/assets/images/logo/mewa-logo_red.png';
import MewaLogoWhite from 'src/assets/images/logo/mewa-logo_white.png';
import { prepareContactFullNameWithTitle } from '../../helpers/offerData';
import { PageNumber } from '../../components/pageNumber';

const largeBackgroundImages: { [index: string]: string } = {
    background_1: background_1_large,
    background_2: background_2_large,
    background_3: background_3_large,
    background_4: background_4_large,
    background_5: background_5_large,
    background_6: background_6_large,
};

type Props = {
    offer: OfferForPdf;
};

export const IntroPage: React.FC<Props> = ({ offer }) => {
    let largeBackgroundImage = largeBackgroundImages[0];
    let mewaLogoColor = backgrounds[0].logoColor;
    let textColor = backgrounds[0].textColor;

    const backgroundImageUri = offer?.details?.backgroundImageUri;
    const customerCompany = offer?.basics?.customerCompany;
    const customerContact = offer?.basics?.customerContact;
    const employeeContact = offer?.basics?.employeeContact;
    const assistantContact = offer?.basics?.assistantContact;
    const greetingHeaderPrefix = `Sehr geehrte${customerContact?.title === 'Herr' ? 'r' : ''}`;

    if (backgroundImageUri) {
        const imageData = backgrounds.find((aElement) => aElement.id === backgroundImageUri);

        if (imageData) {
            mewaLogoColor = imageData.logoColor;
            textColor = imageData.textColor;
        }

        largeBackgroundImage = largeBackgroundImages[backgroundImageUri];
    }

    // Disable hyphenation for the whole pdf document to prevent hyphenate people's names.
    Font.registerHyphenationCallback((aWord) => [aWord]);

    const styles = StyleSheet.create({
        page: {
            fontFamily: 'GebauerRegular',
        },
        section: {
            position: 'relative',
            minHeight: '100%',
            height: '100%',
        },
        introPic: {
            objectFit: 'fill',
            width: '100%',
        },
        logoPic: {
            position: 'absolute',
            top: '35px',
            left: '45px',
            width: '150px',
        },
        customerInfoItem: {
            fontSize: '9px',
            paddingTop: '1px',
            fontFamily: 'GebauerRegular',
        },
        salesInfoContainer: {
            position: 'absolute',
            left: '375px',
            top: '134px',
            maxWidth: '185px',
            color: textColor,
            fontFamily: 'GebauerRegular',
        },
        salesInfoHeader: {
            maxWidth: '65%',
            fontSize: '9px',
            fontWeight: 'bold',
            fontFamily: 'GebauerBold',
        },
        salesInfoItem: {
            fontSize: '9.5px',
            fontFamily: 'GebauerRegular',
        },
        link: {
            color: textColor,
            textDecoration: 'none',
        },
        greetingContainer: {
            position: 'absolute',
            left: '0',
            top: '60%',
            backgroundColor: colors.white,
            maxWidth: '100%',
            height: '40%',
            padding: '42px 75px 20px 40px',
            fontFamily: 'GebauerRegular',
        },
        greetingHeader: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: 'GebauerBold',
            color: colors.darkRed,
        },
        greetingText: {
            fontSize: '11px',
            paddingTop: '20px',
            fontFamily: 'GebauerRegular',
            color: colors.darkGrey,
        },
        dateContainer: {
            position: 'absolute',
            left: '490px',
            top: '44px',
            color: colors.black,
            fontFamily: 'GebauerRegular',
            fontSize: '11px',
        },
    });

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Image style={styles.introPic} src={largeBackgroundImage} />
                <Image
                    style={styles.logoPic}
                    src={mewaLogoColor === colors.darkRed ? MewaLogoRed : MewaLogoWhite}
                />

                <div style={styles.salesInfoContainer}>
                    <Text style={styles.salesInfoHeader}>IHRE MEWA ANSPRECHPARTNER VOR ORT</Text>
                    <Text style={{ ...styles.salesInfoItem, paddingTop: '7px' }}>
                        {prepareContactFullNameWithTitle(employeeContact)}
                    </Text>
                    <Text style={styles.salesInfoItem}>{employeeContact?.position}</Text>
                    <Text style={styles.salesInfoItem}>{`Fon: ${employeeContact?.phone}`}</Text>
                    <Text style={styles.salesInfoItem}>
                        <Link style={styles.link} src={`mailto:${employeeContact?.email || ''}`}>
                            {employeeContact?.email}
                        </Link>
                    </Text>

                    <Text style={{ ...styles.salesInfoItem, paddingTop: '12px' }}>
                        {prepareContactFullNameWithTitle(assistantContact)}
                    </Text>
                    <Text style={styles.salesInfoItem}>{assistantContact?.position}</Text>
                    <Text style={styles.salesInfoItem}>{`Fon: ${assistantContact?.phone}`}</Text>
                    <Text style={styles.salesInfoItem}>
                        <Link style={styles.link} src={`mailto:${assistantContact?.email || ''}`}>
                            {assistantContact?.email}
                        </Link>
                    </Text>
                    <Text
                        style={{
                            ...styles.salesInfoItem,
                            paddingTop: '4px',
                            paddingBottom: '120px',
                            fontFamily: 'GebauerBold',
                        }}
                    >
                        <Link style={styles.link} src="https://www.mewa.de/">
                            www.mewa.de
                        </Link>
                    </Text>
                    <Text style={styles.customerInfoItem}>{customerCompany?.name}</Text>
                    <Text style={styles.customerInfoItem}>
                        {prepareContactFullNameWithTitle(customerContact)}
                    </Text>
                    <Text style={styles.customerInfoItem}>{customerCompany?.street}</Text>
                    <Text style={styles.customerInfoItem}>{customerCompany?.address}</Text>
                </div>
                <div style={styles.greetingContainer}>
                    <Text style={styles.greetingHeader}>
                        {`${greetingHeaderPrefix} ${customerContact?.title} ${customerContact?.surname},`}
                    </Text>
                    <div style={styles.dateContainer}>
                        <Text>{offer?.details?.createdAt}</Text>
                    </div>
                    <Text style={styles.greetingText}>{offer?.details?.kmuDetails?.intro}</Text>
                </div>
            </View>
            <PageNumber />
        </Page>
    );
};
