import React from 'react';

import { ReactComponent as PlusCircleSVG } from './plusCircle.svg';
import { ReactComponent as AddToCartSVG } from './addToCart.svg';
import { ReactComponent as DuplicateSVG } from './duplicate.svg';
import { ReactComponent as MagnifyingGlassSVG } from './magnifyingGlass.svg';
import { ReactComponent as PencilSVG } from './pencil.svg';
import { ReactComponent as ResizeSVG } from './resize.svg';
import { ReactComponent as TickSVG } from './tick2.svg';
import { ReactComponent as TrashSVG } from './trash.svg';
import { ReactComponent as EyeCrossedSVG } from './eye-crossed.svg';
import { ReactComponent as EyeOpenSVG } from './eye-open.svg';
import { ReactComponent as UserSVG } from './user.svg';

export * from './contact';
export * from './controls';
export * from './documents';
export * from './navigation';
export * from './datePicker';

export const PlusCircle: React.FC = (props) => <PlusCircleSVG {...props} />;

export const AddToCart: React.FC = (props) => <AddToCartSVG {...props} />;

export const Edit: React.FC = (props) => <PencilSVG {...props} />;

export const Duplicate: React.FC = (props) => <DuplicateSVG {...props} />;

export const MagnifyingGlass: React.FC = (props) => <MagnifyingGlassSVG {...props} />;

export const Resize: React.FC = (props) => <ResizeSVG {...props} />;

export const Tick: React.FC = (props) => <TickSVG {...props} />;

export const Trash: React.FC = (props) => <TrashSVG {...props} />;

export const EyeCrossed: React.FC = (props) => <EyeCrossedSVG {...props} />;

export const EyeOpen: React.FC = (props) => <EyeOpenSVG {...props} />;

export const User: React.FC = (props) => <UserSVG {...props} />;
