/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { css } from '@emotion/react';

import { BrandHeader, Header } from 'src/components';
import ItemModal from 'src/components/ItemModal';
import { ContactDetailsValues } from 'src/components/ContactDetailsModal';
import { DEFAULT_OFFER_SERVICE_PRICE } from 'src/constants';
import { defaultRotationPrice, noop } from 'src/helpers/utils';
import { useColorChooserDropdown, useModalForm, useWeightChooserDropdown } from 'src/hooks';
import { notificationService } from 'src/modules/offer/service';
import { colors, zIndices } from 'src/constants/variables';

import {
    DEFAULT_OFFER_KMU_HINTS,
    DEFAULT_OFFER_KMU_INTRO,
    DEFAULT_OFFER_KMU_OUTRO,
} from 'src/constants/defaults';
import { checkBasePricesAreSet, isSubmitButtonDisabled } from '../../helpers/offerForm';
import {
    calculatePricePerItem,
    calculateTotalPricePerWeek,
    calculateTotalWeeklyPriceAllItems,
} from '../../helpers/offerPrices';
import { OfferFormProps, OfferFormValues } from '../types';
import { PageWrapper } from '../PageWrapper';
import { OfferButtons } from '../Offer/OfferButtons';
import { ProductsSection } from '../Offer/ProductsSection';
import { ProductDetails } from './ProductDetails';
import { MarketingDetails } from '../../../../components/MarketingDetails';
import {
    CustomerDetailsModal,
    CustomerDetailsValues,
} from '../../../../components/CustomerDetailsModal';
import { OfferContactDetailsModal } from '../ContactDetailsModal';
import { OfferFinishModal } from '../Offer/OfferNameModal/OfferFinishModal';
import { KmuDetailsModal, KmuFormValues } from '../../../../components/KmuModal';
import { OfferLayoutProps } from './types';
import { rewriteOfferItemsToItemsList } from './tools';
import { EmptyProductsSectionProps } from '../Offer/EmptyProductSection';
import {
    ButtonTypes,
    CommonRectangularButton,
} from 'src/components/Buttons/CommonRectangularButton';
import { PlusOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import { ConfirmationButton } from '../../../../components/MewaCustomButton';

const DATE_FORMAT = 'YYYY-MM-DD';
const defaultDateSpan = 14;
const offerNameError409 =
    'Dieser Angebotsname ist bereits in Nutzung, bitte geben ' +
    'Sie einen neuen unbenutzten Namen für Ihr Angebot an';

function filterMaxServiceId(serviceIds?: string[]) {
    if (!serviceIds || !serviceIds.length) {
        return [];
    }
    const maxId: number = serviceIds.reduce((prev, cur) => Math.max(prev, Number(cur)), 0);
    return [maxId];
}

type Props = OfferLayoutProps & EmptyProductsSectionProps;

export const OfferLayout: React.FC<Props> = ({
    offerItems,
    offerDetails,
    customerCompany,
    customerContact,
    employeeContact,
    offerServices,
    availableColors,
    availableWeights,
    initFormValues = {},
    initFormWarnings = {
        colorWarnings: {},
        weightWarnings: {},
    },
    assistantContact,
    contactComments,
    onContactDetailsSubmit,
    onCustomerDetailsSubmit,
    changeItemColor,
    changeItemWeight,
    onOfferProductsClear,
    submitOfferItem,
    removeOfferItem,
    onOfferItemClick = noop,
    onOfferSubmit,
    onItemInlineChange,
    rotationRates,
    onOfferItemCancelEdit,
    selectedItemId,
    changeItemPricing,
    persistOfferValues,
    persistOfferWarnings,
    approveOfferOnSave,
    onAddProductsFromCatalogButtonClick,
    showAddProductsButton = false,
    shouldEdit,
}) => {
    const [form] = Form.useForm<OfferFormProps>();
    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(() => {
        if (initFormValues?.validUntil) {
            return moment(initFormValues?.validUntil, DATE_FORMAT);
        }
        return moment().add(defaultDateSpan, 'day');
    });
    const [selectedBackground, setSelectedBackground] = useState<string>(
        () => initFormValues?.backgroundImageUri || 'background_1',
    );
    const [addTermsAndConditions, setAddTermsAndConditions] = useState<boolean>(false);

    const [showOfferFinishModal, setShowOfferFinishModal] = useState(false);
    const [showUpdateOfferFinishModal, setShowUpdateOfferFinishModal] = useState(false);
    const [offerNameErrorMsg, setOfferNameErrorMsg] = useState<false | string>(false);
    const [selectedServices, setSelectedServices] = useState<number[]>(
        filterMaxServiceId(Object.keys(initFormValues?.services || [])),
    );

    const [kmuDetails, setKmuDetails] = useState<OfferKmu>(() => ({
        intro: initFormValues?.kmuDetails?.intro || DEFAULT_OFFER_KMU_INTRO,
        outro: initFormValues?.kmuDetails?.outro || DEFAULT_OFFER_KMU_OUTRO,
        hints: initFormValues?.kmuDetails?.hints || DEFAULT_OFFER_KMU_HINTS,
    }));
    const onOfferKmuSubmit = (kmuDetails: OfferKmu) => {
        setKmuDetails(kmuDetails);
        persistOfferValues?.({ kmuDetails });
    };

    const handleGlobalItemsPerWearerChange = useDebouncedCallback(
        (globalItemsPerWearer?: number) => {
            globalItemsPerWearer &&
                offerItems.forEach((item) => {
                    changeItemPricing(item, { itemsPerWearer: globalItemsPerWearer }).then();
                    const itemId = item.cartItemId || item.offerItemId;
                    form.setFieldsValue({
                        itemsList: { [`${itemId}`]: { itemsPerWearer: globalItemsPerWearer } },
                    });
                });
        },
        250,
    );

    const customerValues = {
        customerCompany,
        customerContact,
    };
    const customerDetailsModal = useModalForm<CustomerDetailsValues>(
        CustomerDetailsModal,
        onCustomerDetailsSubmit,
        customerValues,
    );

    const contactValues = {
        assistantContact,
        contactComments,
        employeeContact,
    };
    const contactDetailsModal = useModalForm<ContactDetailsValues>(
        OfferContactDetailsModal,
        onContactDetailsSubmit,
        contactValues,
    );

    const kmuDetailsModal = useModalForm<KmuFormValues>(
        KmuDetailsModal,
        onOfferKmuSubmit,
        kmuDetails,
    );

    const {
        onItemsColorChange,
        checkColorWarnings,
        clearColorWarning,
        clearAllColorWarnings,
        colorWarnings,
    } = useColorChooserDropdown(changeItemColor, initFormWarnings.colorWarnings);

    useEffect(() => {
        persistOfferWarnings?.({ colorWarnings });
    }, [colorWarnings, persistOfferWarnings]);

    const {
        onItemsWeightChange,
        checkWeightWarnings,
        clearWeightWarning,
        clearAllWeightWarnings,
        weightWarnings,
    } = useWeightChooserDropdown(changeItemWeight, initFormWarnings.weightWarnings);

    useEffect(() => {
        persistOfferWarnings?.({ weightWarnings });
    }, [weightWarnings, persistOfferWarnings]);

    const formInitialValues = {
        pricePerWearer: false,
        totalPricePerWeek: false,
        weeklyTotalPriceAllItems: false,
        rotation: rotationRates[0]?.rotation,
        deliveryPricePerWeek: rotationRates[0]?.rotationFee,
        itemsList: rewriteOfferItemsToItemsList(offerItems),
        ...initFormValues,
    };

    const persistOfferFormValues = (offerFormValues: OfferFormProps) => {
        const { itemsList, ...formValues } = offerFormValues;
        persistOfferValues?.({ form: formValues });
    };

    const onOfferButtonClick = () => {
        if (!shouldEdit) {
            setOfferNameErrorMsg(false);
            setShowOfferFinishModal(true);
        } else {
            setShowUpdateOfferFinishModal(true);
        }
    };

    const onExpirationDateChange = (expirationDate: moment.Moment | null) => {
        setSelectedDate(expirationDate);
        persistOfferValues?.({ expirationDate });
    };

    const onBackgroundImageChange = (background: string) => {
        setSelectedBackground(background);
        persistOfferValues?.({ background });
        notificationService.success('Das Hintergrundbild wurde erfolgreich geändert.');
    };

    const onRotationChange = (value?: string) => {
        const rotationFee =
            rotationRates.find(({ rotation }) => rotation === value)?.rotationFee ?? undefined;
        form.setFieldsValue({ deliveryPricePerWeek: rotationFee });
        calculateTotalPricePerWeekValue();
    };

    const onWeeklyTotalPriceAllItems = (isSet?: boolean) => {
        form.setFieldsValue({ weeklyTotalPriceAllItems: isSet });
        persistOfferFormValues(form.getFieldsValue());
    };

    const onTotalPriceChange = (isSet?: boolean) => {
        form.setFieldsValue({ totalPricePerWeek: isSet });
        persistOfferFormValues(form.getFieldsValue());
    };

    const calculateTotalPricePerWeekValue = () => {
        const { itemsList, services } = form.getFieldsValue();
        const rotationRateFee = form.getFieldValue('deliveryPricePerWeek');
        const selectedRotation = form.getFieldValue('rotation');
        const amountOfWearers = form.getFieldValue('amountOfWearers');
        const areBasePricesOk = checkBasePricesAreSet(itemsList);
        const isPriceOk = areBasePricesOk && !!amountOfWearers;
        const defaultRotationRateFee = defaultRotationPrice(rotationRates, selectedRotation);
        const price =
            isPriceOk &&
            calculateTotalPricePerWeek(
                itemsList,
                amountOfWearers,
                rotationRateFee || defaultRotationRateFee,
                selectedServices,
                services,
            );
        form.setFieldsValue({
            totalPricePerWeekValue: price ? price : undefined,
        });
    };

    const calculateWeeklyTotalPriceAllItemsValue = () => {
        const { itemsList, services } = form.getFieldsValue();
        const arePricesOk = checkBasePricesAreSet(itemsList);
        const price =
            arePricesOk && calculateTotalWeeklyPriceAllItems(itemsList, selectedServices, services);
        form.setFieldsValue({
            weeklyTotalPriceAllItemsValue: price ? price : undefined,
        });
    };

    const onSelectedAGBsChange = (checkedTermsAndConditions: boolean) => {
        setAddTermsAndConditions(checkedTermsAndConditions);
        persistOfferValues?.({ includeTermsAndConditions: addTermsAndConditions });
    };

    const cleanGlobalItemsPerWearer = () => {
        form.setFieldsValue({ globalItemsPerWearer: undefined });
        persistOfferFormValues(form.getFieldsValue());
    };

    const cleanGlobalPrices = () => {
        form.setFieldsValue({ totalPricePerWeekValue: undefined });
        form.setFieldsValue({ weeklyTotalPriceAllItemsValue: undefined });
        persistOfferFormValues(form.getFieldsValue());
    };

    const cleanCheckboxes = () => {
        form.setFieldsValue({ totalPricePerWeek: false });
        form.setFieldsValue({ weeklyTotalPriceAllItems: false });
        persistOfferFormValues(form.getFieldsValue());
    };

    const onOfferItemSubmit = (product: Product, variant: ProductVariant) => {
        const itemValues =
            (selectedItemId && form.getFieldsValue().itemsList[selectedItemId]) || {};
        const itemTotalPrice = calculatePricePerItem(itemValues) || 0;
        return submitOfferItem(product, variant, itemValues, itemTotalPrice);
    };

    const onRemoveOfferItem = async (itemId: number) => {
        const isLastItem = offerItems.length === 1;
        await removeOfferItem?.(itemId);
        clearColorWarning(itemId);
        clearWeightWarning(itemId);

        if (isLastItem) {
            cleanCheckboxes();
            cleanGlobalItemsPerWearer();
        }
    };

    const onSelectedServicesChange = (selectedServices: number[]) => {
        const servicesData = form.getFieldsValue().services || {};

        const newServicesData = selectedServices.reduce(
            (output, serviceId) => ({
                ...output,
                [serviceId]:
                    servicesData[serviceId] === undefined
                        ? DEFAULT_OFFER_SERVICE_PRICE
                        : servicesData[serviceId],
            }),
            {},
        );
        setSelectedServices(selectedServices);
        form.setFieldsValue({ services: newServicesData });
        persistOfferFormValues({ ...form.getFieldsValue(), services: newServicesData });
    };

    const onFormValuesChange = async (values: any) => {
        calculateWeeklyTotalPriceAllItemsValue();
        calculateTotalPricePerWeekValue();
        persistOfferFormValues(form.getFieldsValue());

        if (Object.prototype.hasOwnProperty.call(values, 'name')) {
            setOfferNameErrorMsg(false);
        }
    };

    const handleSubmitOfferStatus = (submitOfferStatus: true | number | void) => {
        if (submitOfferStatus === true) {
            setShowOfferFinishModal(false);
        } else if (submitOfferStatus === StatusCodes.CONFLICT) {
            setOfferNameErrorMsg(offerNameError409);
        }
    };

    const handleOfferProductsClear = () => {
        cleanGlobalItemsPerWearer();
        cleanCheckboxes();
        cleanGlobalPrices();
        onOfferProductsClear?.();
        clearAllColorWarnings();
        clearAllWeightWarnings();
    };

    const onFinish = async (values: OfferFormProps) => {
        const validUntilDate = (selectedDate as any as moment.Moment).format(DATE_FORMAT);

        const offerData: OfferFormValues = {
            ...values,
            deliveryPricePerWeek:
                form.getFieldValue('deliveryPricePerWeek') ||
                defaultRotationPrice(rotationRates, values.rotation),
            validUntil: validUntilDate,
            backgroundImageUri: selectedBackground,
            kmuDetails: kmuDetails,
            includeTermsAndConditions: addTermsAndConditions,
            weeklyTotalPriceAllItems: form.getFieldValue('weeklyTotalPriceAllItems'),
            weeklyTotalPriceAllItemsValue: form.getFieldValue('weeklyTotalPriceAllItemsValue') || 0,
            totalPricePerWeek: form.getFieldValue('totalPricePerWeek'),
            totalPricePerWeekValue: form.getFieldValue('totalPricePerWeekValue') || 0,
        };

        const submitOfferStatus = await onOfferSubmit(offerData);
        handleSubmitOfferStatus(submitOfferStatus);
    };

    const clearWarnings = (id: number, type: 'weight' | 'color') => {
        if (type === 'color') {
            clearColorWarning(id);
        }
        if (type === 'weight') {
            clearWeightWarning(id);
        }
    };

    const onColorChange = (color: ProductColor) => {
        const itemIds = offerItems.map((item) => item.cartItemId! || item.offerItemId!);
        onItemsColorChange(color, itemIds).then(() => {
            itemIds.map((itemId) => clearWeightWarning(itemId));
        });
    };

    const onWeightChange = async (weight: number) => {
        const itemIds = offerItems.map((item) => item.cartItemId! || item.offerItemId!);
        onItemsWeightChange(weight, itemIds).then(noop);
    };

    useEffect(() => {
        if (offerDetails) {
            offerDetails?.includeTermsAndConditions &&
                setAddTermsAndConditions(offerDetails?.includeTermsAndConditions);
            offerDetails?.amountOfWearers &&
                form.setFieldsValue({ amountOfWearers: offerDetails?.amountOfWearers });
            offerDetails?.kmuDetails && setKmuDetails(offerDetails?.kmuDetails);
        }
    }, []);

    useEffect(() => {
        calculateTotalPricePerWeekValue();
        calculateWeeklyTotalPriceAllItemsValue();
    }, [offerItems, selectedServices]);

    const shouldApproveOnSave = !!approveOfferOnSave;

    const initialTermsCheckbox =
        offerDetails?.includeTermsAndConditions != undefined
            ? offerDetails.includeTermsAndConditions
            : false;
    return (
        <>
            <PageWrapper contentCss={styles.mainContent}>
                <BrandHeader headerLogoCss={styles.headerLogo} />

                <MarketingDetails
                    selectedDate={selectedDate}
                    setSelectedDate={onExpirationDateChange}
                    selectedBackground={selectedBackground}
                    setSelectedBackground={onBackgroundImageChange}
                    employeeContact={employeeContact}
                    onContactDetailsEdit={contactDetailsModal.openModal}
                    customerCompany={customerCompany}
                    customerContact={customerContact}
                    onCustomerDetailsEdit={customerDetailsModal.openModal}
                />

                <div css={styles.productsWrapper}>
                    <Header css={styles.sectionHeader} data-test={'productsHeader'}>
                        Ihre Produktauswahl
                        {showAddProductsButton && !!offerItems?.length && (
                            <CommonRectangularButton
                                name={
                                    <span>
                                        <PlusOutlined /> Produkte hinzufügen
                                    </span>
                                }
                                type={ButtonTypes.darkBorderColor}
                                onClick={() => onAddProductsFromCatalogButtonClick?.()}
                                extraCss={styles.addProductsButton}
                            />
                        )}
                    </Header>
                    <Form
                        form={form}
                        name="offer"
                        onFinish={onFinish}
                        initialValues={{ ...formInitialValues }}
                        onValuesChange={onFormValuesChange}
                    >
                        <OfferFinishModal
                            visible={showOfferFinishModal}
                            onClick={() => {
                                setShowOfferFinishModal(false);
                            }}
                            nameErrorMsg={offerNameErrorMsg}
                            shouldEdit={false}
                        />

                        <OfferFinishModal
                            visible={showUpdateOfferFinishModal}
                            onClick={() => {
                                setShowUpdateOfferFinishModal(false);
                            }}
                            shouldEdit={true}
                            footer={[
                                <Button
                                    className="update-cancel-button"
                                    key="back"
                                    onClick={() => setShowUpdateOfferFinishModal(false)}
                                >
                                    Cancel
                                </Button>,
                                <Form.Item shouldUpdate>
                                    <ConfirmationButton>Ja</ConfirmationButton>
                                </Form.Item>,
                            ]}
                        />

                        <ProductsSection
                            offerItems={offerItems}
                            onOfferItemClick={onOfferItemClick}
                            onRemove={removeOfferItem && onRemoveOfferItem}
                            onInlineChange={onItemInlineChange}
                            colorWarnings={colorWarnings}
                            weightWarnings={weightWarnings}
                            onAddProductsFromCatalogButtonClick={
                                onAddProductsFromCatalogButtonClick
                            }
                        />

                        <ProductDetails
                            offerServices={offerServices}
                            selectedServices={selectedServices}
                            setSelectedServices={onSelectedServicesChange}
                            initialTermsAndConditions={initialTermsCheckbox}
                            setTermsAndConditions={onSelectedAGBsChange}
                            rotationRates={rotationRates}
                            handleRotationChange={onRotationChange}
                            availableColors={availableColors}
                            onColorChange={onColorChange}
                            colorWarning={checkColorWarnings()}
                            availableWeights={availableWeights}
                            onWeightChange={onWeightChange}
                            weightWarning={checkWeightWarnings()}
                            onKmuEdit={kmuDetailsModal.openModal}
                            handleGlobalItemsPerWearerChange={handleGlobalItemsPerWearerChange}
                            handleTotalPriceChange={onTotalPriceChange}
                            handleWeeklyTotalPriceAllItemsChange={onWeeklyTotalPriceAllItems}
                        />

                        <Row gutter={[32, 0]}>
                            <Col span={8} offset={16}>
                                <Form.Item shouldUpdate>
                                    {({ getFieldsValue }) => (
                                        <OfferButtons
                                            customCss={
                                                shouldApproveOnSave
                                                    ? styles.twoButtonsSpacing
                                                    : undefined
                                            }
                                            onFormSubmit={onOfferButtonClick}
                                            onProductsClear={
                                                onOfferProductsClear && handleOfferProductsClear
                                            }
                                            disableRemoveButton={!offerItems.length}
                                            disableSubmitButton={isSubmitButtonDisabled(
                                                getFieldsValue(),
                                                kmuDetails,
                                                contactValues,
                                                customerValues,
                                                selectedServices,
                                            )}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {contactDetailsModal.render()}
                {customerDetailsModal.render()}
                {kmuDetailsModal.render()}
            </PageWrapper>

            <ItemModal
                visible={!!selectedItemId}
                onClick={onOfferItemCancelEdit}
                onFormSubmit={onOfferItemSubmit}
                hideLogos
                hidePriceSection
                currentId={selectedItemId}
                clearWarnings={clearWarnings}
            />
        </>
    );
};

const styles = {
    headerLogo: css`
        top: 0;
        z-index: ${zIndices.zIndex050};
        position: sticky;
        background-color: ${colors.primary};
        height: 145px;
    `,
    sectionHeader: css`
        text-transform: none;
        color: ${colors.newOfferTitle};

        :before {
            background-color: ${colors.fontHeaderSecondary};
        }

        & {
            margin-top: 0;
        }
    `,
    twoButtonsSpacing: css`
        margin-top: 16px;
    `,

    mainContent: css`
        padding-bottom: 180px;
    `,
    productsWrapper: css`
        position: relative;
        padding: 90px 32px 0 0;
    `,
    addProductsButton: css`
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        width: fit-content;
        background: white;
        padding: 0 20px 0 20px;
        margin-right: 0;
        height: fit-content;
        border-radius: 5px;
    `,
};
