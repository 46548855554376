import { useState, useEffect } from 'react';

const DEFAULT_LONG_MS = 500;

export const useLongTouchPress = (callback: () => void) => {
    const [startLongPress, setStartLongPress] = useState(false);

    useEffect(() => {
        let timerId: NodeJS.Timeout;

        if (startLongPress) {
            timerId = setTimeout(callback, DEFAULT_LONG_MS);
        } else {
            clearTimeout(timerId!);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [callback, startLongPress]);

    return {
        onTouchStart: () => setStartLongPress(true),
        onTouchEnd: () => setStartLongPress(false),
    };
};
