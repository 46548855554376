import sample_1 from './100_15204.jpg';
import sample_2 from './100_15216.jpg';
import sample_3 from './100_19106.jpg';
import sample_4 from './100_19783.jpg';
import sample_5 from './100_19801.jpg';

export const basicsImpressions = {
    sample_1,
    sample_2,
    sample_3,
    sample_4,
    sample_5,
};
