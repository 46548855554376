import wischmopps19512 from './100_19512.jpg';
import wischmopps19518 from './100_19518.jpg';
import wischmopps19519 from './100_19519.jpg';
import wischmopps19528 from './100_19528.jpg';
import wischmopps38807 from './100_38807.jpg';
import wischmopps38809 from './100_38809.jpg';

export const wischmoppsImages = {
    wischmopps19512,
    wischmopps19518,
    wischmopps19519,
    wischmopps19528,
    wischmopps38807,
    wischmopps38809,
};
