import { useState } from 'react';
import { omit as ramdaOmit } from 'ramda';

import { ItemWarnings } from 'src/helpers/types';

export const useWeightChooserDropdown = (
    changeCartItemWeight: (id: number, weight: number) => Promise<boolean>,
    initWeightWarnings: ItemWarnings = {},
) => {
    const [weightWarnings, setWeightWarnings] = useState<ItemWarnings>(initWeightWarnings);

    const onItemsWeightChange = (weight: number, items: number[]) => {
        const requests = items.map((itemId) =>
            changeCartItemWeight(itemId, weight).then((result) => ({ itemId, result })),
        );

        return Promise.allSettled(requests).then((responses) => {
            const weightChangeWarnings = responses.reduce(
                (accumulator, response) =>
                    response.status === 'fulfilled' && !response.value.result
                        ? { ...accumulator, [response.value.itemId]: true }
                        : accumulator,
                {},
            );

            setWeightWarnings(weightChangeWarnings);

            return weightChangeWarnings;
        });
    };

    const checkWeightWarnings = () => !Object.values(weightWarnings).every((item) => !item);

    const clearWeightWarning = (id: number) => {
        setWeightWarnings((prevWarnings) => ramdaOmit([id.toString()], prevWarnings));
    };

    const clearAllWeightWarnings = () => {
        setWeightWarnings({});
    };

    return {
        onItemsWeightChange,
        checkWeightWarnings,
        clearWeightWarning,
        clearAllWeightWarnings,
        weightWarnings,
    };
};
