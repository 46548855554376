/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { textOverflowEllipsis } from 'src/style/styles';

export const serviceItemName = (serviceLabel: WardrobeOption['label']) => (
    <>
        <div css={styles.serviceLabel}>{serviceLabel.substring(0, serviceLabel.indexOf(' '))}</div>
        <div css={styles.serviceLabel}>{serviceLabel.substring(serviceLabel.indexOf(' ') + 1)}</div>
    </>
);

const styles = {
    serviceLabel: css`
        ${textOverflowEllipsis}
    `,
};
