/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { css, SerializedStyles } from '@emotion/react';
import { acceptButton } from 'src/style/styles';
import { colors } from 'src/constants/variables';

type Props = {
    name: string | ReactElement;
    onClick: () => Promise<boolean> | void;
    disabled?: boolean;
    type: ButtonTypes;
    extraCss?: SerializedStyles;
};

export enum ButtonTypes {
    redBgColor = 'redBgColor',
    darkBgColor = 'darkBgColor',
    darkBorderColor = 'darkBorderColor',
}

export const CommonRectangularButton: React.FC<Props> = ({
    name,
    disabled,
    type,
    onClick,
    extraCss = css``,
}) => (
    <Button
        css={css`
            ${styles[type]};
            ${extraCss};
        `}
        onClick={onClick}
        disabled={disabled}
    >
        {name}
    </Button>
);

const styles = {
    darkBorderColor: css`
        ${acceptButton};
        width: 20%;
        height: 55px;
        border-color: ${colors.secondaryButton};
        background-color: ${colors.background};
        color: ${colors.secondaryButton};

        border-radius: 5px;
        margin-right: 15px;

        :hover,
        :focus {
            border-color: ${colors.secondaryButton};
            background-color: ${colors.background};
            color: ${colors.secondaryButton};
        }
    `,
    redBgColor: css`
        ${acceptButton};
        width: 20%;
        height: 55px;
        border-radius: 5px;

        :hover,
        :focus {
            border-color: ${colors.primary};
            background-color: ${colors.secondary};
            color: ${colors.primary};
        }

        :disabled {
            border-color: ${colors.primary};
            background-color: ${colors.secondary};
            color: ${colors.primary};
            opacity: 0.25;

            :hover,
            :focus {
                border-color: ${colors.primary};
                background-color: ${colors.secondary};
                color: ${colors.primary};
            }
        }
    `,
    darkBgColor: css`
        ${acceptButton};
        background-color: ${colors.fontSecondaryDark};
        color: ${colors.primary};
        width: 20%;
        height: 55px;
        border-radius: 5px;
        padding-left: 15px;
        padding-right: 15px;

        :hover,
        :focus {
            background-color: ${colors.fontSecondaryDark};
            color: ${colors.primary};
        }
        :disabled {
            border-color: ${colors.primary};
            background-color: ${colors.fontSecondaryDark};
            color: ${colors.primary};
            opacity: 0.25;

            :hover,
            :focus {
                border-color: ${colors.primary};
                background-color: ${colors.fontSecondaryDark};
                color: ${colors.primary};
            }
        }
    `,
};
