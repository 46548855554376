/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import { Carousel } from 'antd';
import Icon from '@ant-design/icons';
import { ResponsiveObject } from '@ant-design/react-slick';
import { css } from '@emotion/react';

import { ArrowLeft, ArrowRight } from 'src/assets/icons';
import { colors } from 'src/constants/variables';

import { ColorMark } from '../ColorMark';
import { colorChooserResponsiveRules } from './responsive';

type Props = {
    colors: ProductColor[];
    onColorChange: (colorKey: string) => void;
    currentVariant?: ProductVariant;
    colorMarkerSize?: number;
    responsiveRules?: ResponsiveObject[];
    slidesToShow?: number;
};

export const SLIDES_TO_SHOW = 6;

export const ColorChooser: React.FC<Props> = ({
    colors,
    onColorChange,
    currentVariant,
    colorMarkerSize = 1,
    responsiveRules = colorChooserResponsiveRules,
    slidesToShow = SLIDES_TO_SHOW,
}) => {
    const initialSlide = colors.findIndex((color) => color.key === currentVariant?.colorKey);
    const ref = useRef(null);
    const additionalCarouselCss =
        slidesToShow <= SLIDES_TO_SHOW && styles.carouselWithLessSlidesToShow(slidesToShow);

    return (
        <div ref={ref}>
            <div css={styles.wrapper}>
                <Carousel
                    infinite
                    adaptiveHeight
                    arrows
                    dots={false}
                    initialSlide={initialSlide}
                    slidesToShow={slidesToShow}
                    css={[styles.carousel, additionalCarouselCss]}
                    prevArrow={<CustomArrow component={ArrowLeft} />}
                    nextArrow={<CustomArrow component={ArrowRight} />}
                    responsive={responsiveRules}
                >
                    {colors
                        .filter((color) => !color.discontinued)
                        .map((color) => (
                            <ColorMark
                                key={color.key}
                                color={color}
                                onClick={onColorChange}
                                selected={color.key === currentVariant?.colorKey}
                                size={colorMarkerSize}
                                css={styles.colorCircle}
                                selectedParentRef={ref}
                                data-testid="colorMark"
                            />
                        ))}
                </Carousel>
            </div>
        </div>
    );
};

declare type ThemeType = 'filled' | 'outlined' | 'twoTone';

interface CoreIconProps {
    tabIndex?: number;
    className?: string;
    theme?: ThemeType;
    title?: string;
    onKeyUp?: React.KeyboardEventHandler<HTMLElement>;
    onClick?: React.MouseEventHandler<HTMLElement>;
    twoToneColor?: string;
    viewBox?: string;
    spin?: boolean;
    rotate?: number;
    style?: React.CSSProperties;
    role?: string;
}

interface CustomIconComponentProps {
    width: string | number;
    height: string | number;
    fill: string;
    viewBox?: string;
    className?: string;
    style?: React.CSSProperties;
    spin?: boolean;
    rotate?: number;
    ['aria-hidden']?: React.AriaAttributes['aria-hidden'];
}

interface IconProps extends CoreIconProps {
    type?: string;
    component?: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>>;
}

const CustomArrow: React.FC<IconProps> = ({ component, className, style, onClick }) => (
    <Icon
        component={component}
        className={className}
        style={style}
        onClick={onClick}
        css={styles.arrow}
    />
);

const styles = {
    colorCircle: css`
        pointer-events: auto;
    `,
    wrapper: css`
        position: relative;
        flex-grow: 1;
        display: flex;
        justify-content: center;
    `,
    carousel: css`
        position: initial;
        margin: 0 36px;

        & .slick-prev {
            left: -36px;
        }

        & .slick-next {
            right: -36px;
        }
    `,
    carouselWithLessSlidesToShow: (slidesToShow: number) => css`
        min-width: ${slidesToShow * 16 + 2 * slidesToShow * 13}px;
    `,
    arrow: css`
        &.slick-prev,
        &.slick-next {
            padding-top: 4px;
        }

        svg {
            width: auto;
            height: 12px;

            polygon {
                fill: ${colors.secondary};
            }
        }
    `,
};
