export const DELIVERY_7T = {
    detailedText: `3-fach = 1x Wechsel in der Woche | 5-fach = 2x Wechsel in der Woche | 7-fach = 3x Wechsel in der Woche | 9-fach = 4x Wechsel in der Woche | 11-fach = 5x Wechsel in der Woche`,
    option1: {
        iconCount: 1,
        mainHeader: '1 Garnitur',
        mainText:
            'Wechseln Sie Ihre Berufsbekleidung 1 Mal in der Woche, benötigen Sie insgesamt 3 Garnituren bei einer wöchentlichen Anfahrt.',
        details: [
            {
                header: '1 Garnitur',
                text: 'wird von Ihnen getragen in Woche 1',
            },
            {
                header: '1 Garnitur',
                text: 'wird von Mewa gepflegt',
            },
            {
                header: 'Letzte Garnitur',
                text: 'ist in der Wechselwoche bei Ihnen vor Ort.',
            },
        ],
    },
    option2: {
        iconCount: 2,
        mainHeader: '2 Garnituren',
        mainText:
            'Wechseln Sie Ihre Berufsbekleidung 2 Mal in der Woche, benötigen Sie insgesamt 5 Garnituren bei einer wöchentlichen Anfahrt.',
        details: [
            {
                header: '2 Garnituren',
                text: 'wird von Ihnen getragen in Woche 1',
            },
            {
                header: '2 Garnituren',
                text: 'wird von Mewa gepflegt',
            },
            {
                header: 'Letzte Garnitur',
                text: 'ist in der Wechselwoche bei Ihnen vor Ort.',
            },
        ],
    },
    option3: {
        iconCount: 3,
        mainHeader: '3 Garnituren',
        mainText:
            'Wechseln Sie Ihre Berufsbekleidung 3 Mal in der Woche, benötigen Sie insgesamt 7 Garnituren bei einer wöchentlichen Anfahrt.',
        details: [
            {
                header: '3 Garnituren',
                text: 'wird von Ihnen getragen in Woche 1',
            },
            {
                header: '3 Garnituren',
                text: 'wird von Mewa gepflegt',
            },
            {
                header: 'Letzte Garnitur',
                text: 'ist in der Wechselwoche bei Ihnen vor Ort.',
            },
        ],
    },
};

export const DELIVERY_14T = {
    detailedText: `5-fach = 1x Wechsel in der Woche | 9-fach = 2x Wechsel in der Woche | 13-fach = 3x Wechsel in der Woche | 17-fach = 4x Wechsel in der Woche | 21-fach = 5x Wechsel in der Woche`,
    option1: {
        iconCount: 1,
        mainHeader: '1 Garnitur',
        mainText:
            'Wechseln Sie Ihre Berufsbekleidung 1 Mal in der Woche, benötigen Sie insgesamt 5 Garnituren bei einer zweiwöchigen Anfahrt.',
        details: [
            {
                header: '1 Garnitur',
                text: 'wird von Ihnen getragen in Woche 1',
            },
            {
                header: '1 Garnitur',
                text: 'wird von Ihnen getragen in Woche 2',
            },
            {
                header: '2 Garnituren',
                text: 'wird von Mewa gepflegt',
            },
            {
                header: 'Letzte Garnitur',
                text: 'ist in der Wechselwoche bei Ihnen vor Ort.',
            },
        ],
    },
    option2: {
        iconCount: 2,
        mainHeader: '2 Garnituren',
        mainText:
            'Wechseln Sie Ihre Berufsbekleidung 2 Mal in der Woche, benötigen Sie insgesamt 9 Garnituren bei einer zweiwöchigen Anfahrt.',
        details: [
            {
                header: '2 Garnituren',
                text: 'wird von Ihnen getragen in Woche 1',
            },
            {
                header: '2 Garnituren',
                text: 'wird von Ihnen getragen in Woche 2',
            },
            {
                header: '4 Garnituren',
                text: 'wird von Mewa gepflegt',
            },
            {
                header: 'Letzte Garnitur',
                text: 'ist in der Wechselwoche bei Ihnen vor Ort.',
            },
        ],
    },
    option3: {
        iconCount: 3,
        mainHeader: '3 Garnituren',
        mainText:
            'Wechseln Sie Ihre Berufsbekleidung 3 Mal in der Woche, benötigen Sie insgesamt 13 Garnituren bei einer zweiwöchigen Anfahrt.',
        details: [
            {
                header: '3 Garnituren',
                text: 'wird von Ihnen getragen in Woche 1',
            },
            {
                header: '3 Garnituren',
                text: 'wird von Ihnen getragen in Woche 2',
            },
            {
                header: '6 Garnituren',
                text: 'wird von Mewa gepflegt',
            },
            {
                header: 'Letzte Garnitur',
                text: 'ist in der Wechselwoche bei Ihnen vor Ort.',
            },
        ],
    },
};
