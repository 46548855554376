import { ProductActions } from './productActions';
import { VariantItem } from '../../../helpers/types';

export type ProductState = {
    model: Product | null;
    values?: ItemDetails | VariantItem;
    currentWeight: number;
    currentMaterial: string;
    currentColorKey: string;

    error: boolean;
    loading: boolean;
};

// TODO: Use ImmerJS or other solution for nested state handling
//       and move async resource to 'product' property

export const defaultState: ProductState = {
    model: null,
    values: undefined,
    currentWeight: 0,
    currentMaterial: '',
    currentColorKey: '',
    error: false,
    loading: false,
};

export function productReducer(state = defaultState, action: ProductActions): ProductState {
    switch (action.type) {
        case 'FETCH_PRODUCT_SUCCESS':
            return {
                ...defaultState,
                ...prepareNewState(action.payload),
            };
        case 'FETCH_CART_ITEM_SUCCESS':
            return {
                ...defaultState,
                ...prepareNewState(action.payload.model, action.payload.cartItem),
            };
        case 'FETCH_OFFER_ITEM_SUCCESS':
            return {
                ...defaultState,
                ...prepareNewState(action.payload.model, action.payload.offerItem),
            };

        case 'SET_COLOR_KEY':
            return { ...state, currentColorKey: action.payload };
        case 'SET_WEIGHT_VALUE':
            return { ...state, currentWeight: action.payload };
        case 'SET_MATERIAL_VALUE':
            return { ...state, currentMaterial: action.payload };

        case 'PRODUCT|ASYNC_RESOURCE_REQUEST':
            return { ...state, error: false, loading: true };
        case 'PRODUCT|ASYNC_RESOURCE_FAILURE':
            return { ...state, error: true, loading: false };
        case 'PRODUCT|CLEAR_STATE':
            return defaultState;
    }

    return state;
}

const prepareNewState = (
    model: Product,
    values?: ItemDetails | VariantItem,
): Pick<
    ProductState,
    'model' | 'values' | 'currentWeight' | 'currentColorKey' | 'currentMaterial'
> => {
    const variant = !values
        ? model.variants[0]
        : model.variants.find(({ key }) => key === values.variantKey);

    return {
        model,
        values,
        currentWeight: variant!.weight || 0,
        currentMaterial: variant!.material || '',
        currentColorKey: variant!.colorKey || '',
    };
};
