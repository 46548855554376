import React from 'react';
import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { DeliveryOption } from './delivery/deliveryOption';
import { colors } from 'src/constants/variables';
import { DeliveryData } from '../../types';
import { PageNumber } from '../../components/pageNumber';
import { pageStyles, PageTitle } from '../../components/pageTitle';

const styles = StyleSheet.create({
    section: {
        position: 'relative',
        minHeight: '100%',
        height: '100%',
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '20px',
        position: 'relative',
        left: '-20px',
    },
    headerContainer: {
        paddingLeft: '20px',
    },
    text: {
        fontSize: '11px',
        fontFamily: 'GebauerRegular',
    },
    redBold: {
        fontFamily: 'GebauerBold',
        color: colors.darkRed,
    },
    bold: {
        fontFamily: 'GebauerBold',
    },
    hintsContainer: {
        position: 'relative',
        paddingTop: '50px',
        paddingRight: '30px',
    },
});

type Props = {
    delivery: DeliveryData;
    hints: string | null | undefined;
};

export const DeliverySchemePage: React.FC<Props> = ({ delivery, hints }) => (
    <Page size="A4">
        <View style={styles.section}>
            <View style={pageStyles.page}>
                <PageTitle />
                <Text style={{ ...styles.redBold, fontSize: '15px' }}>
                    System für Berufskleidung
                </Text>
                <div style={styles.optionsContainer}>
                    <DeliveryOption data={delivery.option1} addBorderRight />
                    <DeliveryOption data={delivery.option2} addBorderRight />
                    <DeliveryOption data={delivery.option3} />
                </div>

                {!!hints && (
                    <div style={styles.hintsContainer}>
                        <Text style={{ ...styles.redBold, fontSize: '12px' }}>Hinweise:</Text>
                        <Text style={{ fontSize: '10px', paddingTop: '2px' }}>
                            {hints.split('')}
                        </Text>
                    </div>
                )}
            </View>
            <PageNumber />
        </View>
    </Page>
);
