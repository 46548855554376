/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

import { Checkbox } from 'src/components/FormElements';
import { colors } from 'src/constants/variables';

import { ProductCardContent } from './ProductCardContent';
import { productService } from 'src/modules/product/store/productService';
import { notificationService } from '../../../offer/service';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    defaultVariant: CollectionItem;
    addToSelectedProducts: (item: CollectionItem) => void;
    selectedProducts: CollectionItem[];
    setSelectedProducts: (items: CollectionItem[]) => void;
    getDetailsLink?: (item: CollectionItem) => string;
};

export const ProductCard: React.FC<Props> = ({
    defaultVariant,
    selectedProducts,
    setSelectedProducts,
    addToSelectedProducts,
    getDetailsLink,
}) => {
    const [curVariant, setCurVariant] = useState(defaultVariant);
    const [loading, setLoading] = useState(false);
    const itemDetailsLink = getDetailsLink?.(curVariant);

    const productDetailsRef = useRef<Product | null>(null);

    const loadProductDetailsIfNeeded = useCallback(async () => {
        let productDetails = productDetailsRef.current;

        try {
            if (!productDetails) {
                setLoading(true);
                productDetails = await productService.fetchProduct(curVariant.modelId);
                productDetailsRef.current = productDetails;
            }
        } catch (error) {
            notificationService.error();
        }

        return productDetails;
    }, [curVariant]);

    useEffect(() => {
        let mounted = true;

        loadProductDetailsIfNeeded().finally(() => {
            if (mounted) {
                setLoading(false);
            }
        });

        return () => {
            mounted = false;
        };
    }, []);

    const convertVariantToCollectionItem = (variant: ProductVariant): CollectionItem => {
        const productDetails = productDetailsRef.current;
        return {
            modelId: productDetails?.modelId || '',
            modelName: productDetails?.modelName || '',
            variantKey: variant.key,
            image: variant.imageLinks[0],
            colors: productDetails?.colors || [],
        };
    };

    function setSelectedVariantInStore(newVariant: ProductVariant) {
        // map the selected products again when color changed to update the state later correctly
        const modifiedItems = selectedProducts.map((aItem: CollectionItem) => {
            if (aItem.modelId === curVariant.modelId && newVariant) {
                const newItem = aItem;
                newItem.variantKey = newVariant.key;

                return newItem;
            }

            return aItem;
        });

        // update the state and set selected products with updated variantKey
        setSelectedProducts(modifiedItems);
    }

    const onColorClick = async (colorKey: string) => {
        try {
            const productDetails = await loadProductDetailsIfNeeded();

            const curVariantDetails = productDetails?.variants.find(
                (variant) => variant.key === curVariant.variantKey,
            );

            const currentWeight = curVariantDetails?.weight;

            let newVariant = productDetails?.variants.find(
                (variant) => variant.colorKey === colorKey && variant.weight === currentWeight,
            );

            if (!newVariant) {
                newVariant = productDetails?.variants.find(
                    (variant) => variant.colorKey === colorKey,
                );
            }

            if (newVariant) {
                setSelectedVariantInStore(newVariant);
                setCurVariant(convertVariantToCollectionItem(newVariant));
            }
        } catch (error) {
            notificationService.error();
        }
    };

    const selectedColorKey = (productDetailsRef.current?.variants || []).find(
        (variant) => variant.key === curVariant.variantKey,
    )?.colorKey;

    const showItemDetailsLink = !!itemDetailsLink;

    return (
        <React.Fragment>
            <div
                css={styles.checkboxWrapper}
                data-test={`${beautifyName(curVariant.modelName)}_ADD_BUTTON`}
            >
                <Checkbox
                    formItemCss={styles.checkboxInput}
                    checked={!!selectedProducts.find((item) => item.modelId === curVariant.modelId)}
                    onClick={() => addToSelectedProducts(curVariant)}
                />
            </div>
            {showItemDetailsLink && itemDetailsLink ? (
                <Link to={itemDetailsLink}>
                    <ProductCardContent
                        product={curVariant}
                        onColorClick={onColorClick}
                        selectedColorKey={selectedColorKey}
                        isLoading={loading}
                    />
                </Link>
            ) : (
                <ProductCardContent
                    product={curVariant}
                    onColorClick={onColorClick}
                    selectedColorKey={selectedColorKey}
                    isLoading={loading}
                />
            )}
        </React.Fragment>
    );
};

const styles = {
    checkboxWrapper: css`
        position: absolute;
        top: 12px;
        right: 18px;

        svg {
            width: 22px;
            height: auto;
        }
    `,
    checkboxInput: css`
        && .ant-checkbox {
            width: 22px;
            height: 22px;
            padding: 2px;
            border-radius: 5px;
            border-color: ${colors.fontPrimaryDark};
        }

        && .ant-checkbox-inner {
            width: 16px;
            height: 16px;
            border-radius: 3px;
        }
    `,
};
