/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { BrandHeader } from 'src/components/BrandHeader';

export const ContentHeader: React.FC = () => (
    <div css={styles.wrapper}>
        <BrandHeader headerLogoCss={styles.headerLogoCss} />
    </div>
);

const styles = {
    headerLogoCss: css`
        padding: 0 32px 30px 32px;
        height: 145px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    `,
    wrapper: css`
        img {
            height: 60px;
            width: auto;
        }
    `,
};
