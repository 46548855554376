/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo } from 'react';
import { Empty } from 'antd';
import { groupBy } from 'ramda';
import { css } from '@emotion/react';

import moment from 'moment';
import 'moment/locale/de';

import { utcToLocalFormat } from 'src/helpers/utils';
import { colors } from 'src/constants/variables';

import { PageHeader } from './PageHeader';
import { OffersList } from './OffersList';

type Props = {
    offers: OfferSummary[];
    offerActions: ManageOffer;
};

const RAW_DATE_FORMAT = 'YYYY.MM.DD';
const LABEL_DATE_FORMAT = 'dddd DD.MM.YYYY';

export const OffersListLayout: React.FC<Props> = ({ offers, offerActions }) => {
    const [offersByDates, setOffersByDates] = useState<{ [key: string]: OfferSummary[] }>({});

    const isEmptyList = useMemo(() => offers.length === 0, [offers]);

    useEffect(() => {
        const offerByDates = groupBy(
            (offer) => utcToLocalFormat(offer.createAt, RAW_DATE_FORMAT),
            offers,
        );
        setOffersByDates(offerByDates);
    }, [offers]);

    const datesList = useMemo(
        () =>
            Object.keys(offersByDates || {})
                .sort()
                .reverse(),
        [offersByDates],
    );

    const sortOffers = (offers: OfferSummary[]) => offers.sort((a, b) =>
            moment.utc(a.createAt).unix() > moment.utc(b.createAt).unix() ? -1 : 1,
        );

    return (
        <div css={styles.wrapper}>
            <PageHeader />

            {isEmptyList && <Empty css={styles.emptyList} description="Kein Angebot vorhanden." />}

            {datesList.map((date) => {
                const label = moment(date, RAW_DATE_FORMAT).format(LABEL_DATE_FORMAT);
                const offers = sortOffers(offersByDates[date] || []);
                return (
                    <OffersList
                        key={date}
                        label={label}
                        offers={offers}
                        offerActions={offerActions}
                    />
                );
            })}
        </div>
    );
};

const styles = {
    wrapper: css`
        margin-bottom: 128px;
    `,
    emptyList: css`
        color: ${colors.fontHeader};
        font-family: OptimaLTPro-Roman, sans-serif;
        font-size: 20px;
        text-transform: uppercase;

        .ant-empty-image {
            display: none;
        }
    `,
};
