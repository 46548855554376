import moment from 'moment';

export function getTime(timestamp: number | string): string {
    const date = moment(timestamp);
    const hours = formatTimeEntry(date.hours());
    const minutes = formatTimeEntry(date.minutes());

    return `${hours}:${minutes}`;
}

export function formatTimeEntry(timeEntry: number): string {
    return `${timeEntry < 10 ? '0' : ''}${timeEntry}`;
}

export function getDateLabel(timestamp?: number): string {
    const date = moment(timestamp).locale('de');
    return date.format('dddd, DD.MM');
}

export function getDateWithYearLabel(timestamp?: number): string {
    const date = moment(timestamp).locale('de');
    return date.format('dddd, DD.MM.YYYY');
}

export type GetDateRangeProps = {
    range: moment.unitOfTime.StartOf;
    date?: moment.Moment;
    format?: string;
};
