/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Link } from 'react-router-dom';

import { InfoTileEditButton } from 'src/components';
import { colors, fontSizes } from 'src/constants/variables';

type Props = {
    title?: string;
    description?: string;

    companyName?: string;
    companyAddress?: string;

    name?: string;
    phone?: string;
    email?: string;
    position?: string;

    footer?: ReactElement;
    backgroundColor?: string;
    backgroundImage?: string;
    onClickEdit?: () => void;
    descriptionStyle?: SerializedStyles;
};

type WithLinkProps = Props & {
    linkTo?: string;
};

type WrapperProps = {
    WithExternalLink: React.FC<WithLinkProps>;
};

export const InfoTile: React.FC<Props> & WrapperProps = ({
    title,
    description,
    companyName,
    companyAddress,
    name,
    phone,
    email,
    position,
    footer,
    backgroundColor,
    backgroundImage,
    onClickEdit,
    descriptionStyle,
}) => (
    <div css={styles.container(backgroundColor, backgroundImage)} data-test={`${title?.replace(' ', '_')}_Tile`}>
        {onClickEdit && (
            <div css={styles.editIcon}>
                <InfoTileEditButton onClick={onClickEdit} size={54} iconSize={24}/>
            </div>
        )}

        <div css={styles.line} />

        {title && (
            <div css={styles.title}>
                <span css={styles.required} data-test={'infoTileTitle'}>{title}</span>
            </div>
        )}

        {description && <div css={descriptionStyle}>{description}</div>}

        {companyName && (
            <div css={[styles.marginTop, styles.fontPrimary, styles.uppercase, styles.breakWord]}>
                {companyName}
            </div>
        )}

        {companyAddress && <div css={[styles.fontPrimary, styles.breakWord]}>{companyAddress}</div>}

        {name && (
            <div css={[styles.marginTop, styles.fontPrimary, styles.uppercase, styles.breakWord]}>
                {name}
            </div>
        )}

        {position && <div css={styles.fontSecondary}>{position}</div>}

        {phone && (
            <a href={`tel:${phone}`} css={[styles.marginTop, styles.interactiveLink]}>
                {phone}
            </a>
        )}

        {email && (
            <a href={`mailto:${email}`} css={styles.interactiveLink}>
                {email}
            </a>
        )}

        {footer && footer}
    </div>
);

const WithExternalLink: React.FC<WithLinkProps> = ({ linkTo, ...props }) => (
    <Link to={{ pathname: linkTo }} target="_blank" css={styles.link}>
        <InfoTile {...props} descriptionStyle={styles.externalDescription} />
    </Link>
);

InfoTile.WithExternalLink = WithExternalLink;

const styles = {
    container: (backgroundColor?: string, backgroundImage?: string) => css`
        height: 340px;
        padding: 90px 32px 16px 32px;

        color: ${colors.primary};
        font-size: ${fontSizes.mSmall};
        font-family: 'Open Sans', sans-serif;
        line-height: 18px;

        ${backgroundColor
            ? css`
                  background-color: ${backgroundColor};
              `
            : null};
        ${backgroundImage
            ? css`
                  background-image: url(${backgroundImage});
                  background-size: cover;
              `
            : null};
    `,
    editIcon: css`
        top: 16px;
        right: 32px;
        position: absolute;
    `,
    line: css`
        height: 3px;
        width: 50px;
        background-color: ${colors.primary};
    `,
    title: css`
        font-size: ${fontSizes.mSmall};
        font-family: 'OptimaLTPro-DemiBold', sans-serif;
        text-transform: uppercase;
        padding: 32px 0 6px;
    `,
    required: css`
        position: relative;

        ::after {
            content: '*';
            color: ${colors.secondary};
            font-size: ${fontSizes.xxSmall};
            position: absolute;
            top: 0;
            right: -8px;
            text-align: right;
        }
    `,
    link: css`
        cursor: pointer;
        padding-top: 90px;
    `,
    interactiveLink: css`
        &:hover,
        &:active,
        &:link {
            color: ${colors.primary};
        }
    `,
    externalDescription: css`
        margin-top: 8px;
        line-height: 28px;
        font-size: ${fontSizes.medium};
        font-family: 'OptimaLTPro-DemiBold', sans-serif;
    `,

    uppercase: css`
        text-transform: uppercase;
    `,
    marginTop: css`
        display: block;
        margin-top: 12px;
    `,
    fontPrimary: css`
        font-family: 'Optima LT Pro DemiBold', sans-serif;
    `,
    fontSecondary: css`
        font-family: 'Optima LT Pro Medium', sans-serif;
    `,
    breakWord: css`
        word-wrap: break-word;
    `,
};
