/** @jsxImportSource @emotion/react */
import React from 'react';

import { ReactComponent as ArrowSVG } from './arrow.svg';
import { ReactComponent as MinusSVG } from './minus.svg';
import { ReactComponent as PlusSVG } from './plus.svg';

export const ArrowNext: React.FC = (props) => <ArrowSVG {...props} />;

export const Minus: React.FC = (props) => <MinusSVG {...props} />;

export const Plus: React.FC = (props) => <PlusSVG {...props} />;
