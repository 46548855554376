/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import { BrandHeaderAnchor } from './BrandHeaderAnchor';
import { colors } from 'src/constants/variables';

type Props = {
    mobile: boolean;
};

export const BrandHeaderAnchors: React.FC<Props> = ({ mobile }) => (
    <React.Fragment>
        <BrandHeaderAnchor anchor="#offerProducts" name="PRODUKTAUSWAHL" mobile={mobile} />
        {mobile && <div css={styles.divider} />}
        <BrandHeaderAnchor anchor="#offerVideo" name="DIENTLEISTUNGSSYSTEM" mobile={mobile} />
        {mobile && <div css={styles.divider} />}
        <BrandHeaderAnchor anchor="#offerMeetingSection" name="TERMINANFRAGE" mobile={mobile} />
    </React.Fragment>
);

const styles = {
    bottomRow: css`
        width: 100%;
        background-color: ${colors.fontHeader};
        color: ${colors.link};
        height: 50px;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-family: 'OptimaLTPro-Medium', sans-serif;
    `,
    divider: css`
        height: 1px;
        width: 90%;
        background-color: ${colors.primaryDark};
        margin: auto;
    `,
};
