import borstematten38815 from './Borstematten/100_38815.jpg';
import borstematten38984 from './Borstematten/100_38984.jpg';

import fubmatten from './Fubmatten/Fubmatten.jpg';
import fubmatten19584 from './Fubmatten/100_19584.jpg';
import fubmatten38812 from './Fubmatten/100_38812.jpg';

export const schmutzfangmattenImages = {
    borstematten38815,
    borstematten38984,
    fubmatten,
    fubmatten19584,
    fubmatten38812,
};
