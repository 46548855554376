import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const getOfferGeneralState = (state: Pick<RootState, 'offer'>) => state.offer;

export const getOfferState = createSelector(
    getOfferGeneralState,
    (checkoutState) => checkoutState.offerData,
);

export const getOfferPersistState = createSelector(
    getOfferGeneralState,
    (state) => state.offerGeneralValues,
);

export const getOverviewAssets = createSelector(
    getOfferGeneralState,
    (state) => state.overviewAssets,
);

export const getOverviewAvailableColors = createSelector(
    getOverviewAssets,
    (state) => state.availableColors,
);

export const getOverviewAvailableWeights = createSelector(
    getOverviewAssets,
    (state) => state.availableWeights,
);

export const getOverviewRotationRates = createSelector(
    getOverviewAssets,
    (state) => state.rotationRates,
);

export const getOffersList = createSelector(
    getOfferGeneralState,
    (state) => state.offerList.data || [],
);

export const getOfferPreviewState = createSelector(
    getOfferGeneralState,
    (state) => state.offerDataDuplication,
);

export const getOffersListStatus = createSelector(getOfferGeneralState, (state) => ({
    error: state.offerList.error,
    loading: state.offerList.loading,
}));
