import React from 'react';
import { Result, ResultProps } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ResultStatusType } from 'antd/lib/result';

export type ErrorProps = {
    status?: ResultStatusType;
} & Pick<ResultProps, 'title' | 'subTitle' | 'extra'>;

export const ResultServerError: React.FC<ErrorProps> = ({ title, subTitle, status, extra }) => {
    const offline = !navigator.onLine ? 'Anwendung arbeitet im Offline-Modus' : null;
    return (
        <Result
            status={status || 500}
            title={offline || title || 'Server-Verbindungsfehler'}
            subTitle={subTitle || 'Bitte, versuchen Sie es später noch einmal'}
            extra={extra}
            icon={<EditOutlined />}
        />
    );
};
