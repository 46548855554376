import React, { useEffect, useMemo, useState, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { Form, FormInstance, Input } from 'antd';

import { AcceptButton, AddToCartButton } from 'src/components/Buttons';

import { getCartItemTotalPrice } from '../helpers';
import { getCurrentVariant } from '../store/productSelectors';
import { PriceSection } from './priceSection';
import { InfoSection } from './infoSection';
import { VariantSection } from './variantSection';
import { noop } from 'src/helpers/utils';

const FIELDS = [
    'basePrice',
    'surcharge',
    'labelStampPrice',
    'selectedLabelPrice',
    'selectedLogoBackPrice',
    'selectedLogoFrontPrice',
    'itemsPerWearer',
];

export type ProductForm = {
    psa: boolean;
    itemsPerWearer: number;
    basePrice: number;
    rotationValue: string;
    surcharge: number;
    labelStamp: boolean;
    labelStampPrice?: number;
    selectedLabelPrice: number;
    selectedLabelOptions: SelectedOptions;
    selectedLogoFrontPrice: number;
    selectedLogoFrontOptions: SelectedOptions;
    selectedLogoBackPrice: number;
    selectedLogoBackOptions: SelectedOptions;
    itemsGroupId: number | null;
};

export type SelectedOptions = {
    [index: number]: SelectedLogo;
};

type SelectedLogo = {
    isSelected: boolean;
    shape: string;
    positionX: number;
    positionY: number;
};

export type ProductLayoutDataPanelProps = {
    product: Product;
    variant: ProductVariant;
    itemValues?: Partial<ItemDetails>;
    availableWeights: number[];
    availableMaterials: string[];
    setWeightValue: (weight: number) => void;
    setMaterialValue: (material: string) => void;
    onFormSubmit: (values: ProductForm, totalPrice: number) => void;
    button?: (props: any) => React.ReactNode;
    setEditMode?: (value: boolean) => void;
    setSlickGoTo: (value: number) => void;
    formRef: RefObject<FormInstance<ProductForm>>;
    withAcceptChangesButton?: boolean;
    withProductInfo?: boolean;
    hideLogos?: boolean;
    hidePriceSection?: boolean;
};

type WrapperProps = {
    WithAcceptChangesButton: React.FC<ProductLayoutDataPanelProps>;
};

export const ProductLayoutDataPanel: WrapperProps & React.FC<ProductLayoutDataPanelProps> = ({
    product,
    variant,
    itemValues,
    availableWeights,
    availableMaterials,
    setWeightValue,
    setMaterialValue,
    onFormSubmit,
    button,
    withProductInfo,
    formRef,
    hidePriceSection,
}) => {
    const [form] = Form.useForm<ProductForm>();
    const [totalPrice, setTotalPrice] = useState(0);

    const prepareInitialValuesProductLayoutDataPanel = (
        product: Product,
        itemValues?: Partial<ItemDetails>,
    ) => ({
        itemsGroupId: itemValues?.itemsGroupId,
        psa: itemValues?.psa || false,
        labelStamp: !!itemValues?.stampPrice,
        labelStampPrice: itemValues?.stampPrice,
        basePrice: itemValues?.basePrice || undefined,
        itemsPerWearer: itemValues?.itemsPerWearer || undefined,
        surcharge: itemValues?.surcharge,
        selectedLabelPrice: itemValues?.labelPrice,
        selectedLogoFrontPrice: itemValues?.logoFrontPrice,
        selectedLogoBackPrice: itemValues?.logoBackPrice,
    });

    const formInitialValues = prepareInitialValuesProductLayoutDataPanel(product, itemValues);

    useEffect(() => {
        const allValues = form.getFieldsValue();
        setTotalPrice(getCartItemTotalPrice(allValues));
    }, [form.getFieldsValue(FIELDS)]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const formInitialValues = prepareInitialValuesProductLayoutDataPanel(product, itemValues);
        form.setFieldsValue(formInitialValues);
    }, [product, itemValues]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <VariantSection
                product={product}
                productVariant={variant}
                availableWeights={availableWeights}
                availableMaterials={availableMaterials}
                onWeightChange={setWeightValue}
                onMaterialChange={setMaterialValue}
                withProductInfo={withProductInfo}
            />

            <Form
                form={form}
                ref={formRef}
                name="product"
                preserve={false}
                onFinish={(values) => onFormSubmit(values, totalPrice)}
                initialValues={formInitialValues}
                onValuesChange={noop}
            >
                <Form.Item name="itemsGroupId">
                    <Input type={'hidden'} />
                </Form.Item>

                {!hidePriceSection && <PriceSection totalPrice={totalPrice} />}

                <InfoSection
                    availableIn={variant.availableIn}
                    features={variant.features}
                    information={variant.information}
                    norms={variant.norms}
                    sizes={variant.sizes}
                    variantKey={variant.key}
                />

                <Form.Item shouldUpdate>
                    {button ? button : <AddToCartButton htmlType="submit" />}
                </Form.Item>
            </Form>
        </>
    );
};

const WithAcceptChangesButton: React.FC<ProductLayoutDataPanelProps> = (props) => {
    const variant = useSelector(getCurrentVariant);
    const initialVariant = useMemo(() => props.variant, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isVariantChanged = () =>
        initialVariant.colorKey !== variant?.colorKey ||
        initialVariant.weight !== variant?.weight ||
        initialVariant.material !== variant?.material;

    return (
        <ProductLayoutDataPanel
            {...props}
            button={({ isFieldsTouched }) => (
                <>
                    <AcceptButton
                        disabled={!(isFieldsTouched() || isVariantChanged())}
                        onClick={noop}
                        htmlType="submit"
                    />
                </>
            )}
        />
    );
};

ProductLayoutDataPanel.WithAcceptChangesButton = WithAcceptChangesButton;
