/** @jsxImportSource @emotion/react */
import React from 'react';
import moment from 'moment';

import { css } from '@emotion/react';

import { mobileScreenStyles } from 'src/style/styles';
import { colors } from 'src/constants/variables';

import { DatePickerFooter } from './DatePickerFooter';

type Props = {
    title: string;
    content: string;
    selectedDate: moment.Moment | null;
    setSelectedDate: (date: moment.Moment | null) => void;
    allowDateClear?: boolean;
    footer?: React.ReactNode;
};

export const CompanyNameElement: React.FC<Props> = ({
    title,
    content,
    selectedDate,
    setSelectedDate,
    allowDateClear,
    footer,
}) => (
    <div css={styles.infoBox} data-test={'companyInfoBox'}>
        <div css={styles.title} data-test={'companyInfoBoxTitle'}>{title}</div>
        <div css={styles.main} data-test={'companyInfoBoxMainSection'}>{content}</div>
        <div css={styles.footNote} data-test={'companyInfoBoxFootNote'}>
            {footer ? (
                footer
            ) : (
                <DatePickerFooter
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    allowDateClear={allowDateClear}
                />
            )}
        </div>
    </div>
);

const styles = {
    infoBox: css`
        width: 50%;
        color: ${colors.primary};
        font-size: 16px;

        ${mobileScreenStyles(css`
            width: 100%;
            left: 0;
            padding: 32px 16px 0;
        `)};
    `,
    title: css`
        height: 50px;
        background-color: rgba(184, 33, 33, 0.5);
        padding: 19px;
        width: 53%;
        position: relative;
        text-transform: uppercase;

        &&:after {
            content: '';
            position: absolute;
            display: block;
            right: -20px;
            top: 0;
            width: 0;
            height: 0;
            border-left: solid 20px rgba(184, 33, 33, 0.5);
            border-top: solid 50px transparent;
        }
    `,
    main: css`
        min-height: 108px;
        background-color: rgba(184, 33, 33, 0.5);
        padding: 22px;
        font-size: 40px;
        word-wrap: break-word;
    `,
    dateBy: css`
        margin-right: 4px;
    `,
    footNote: css`
        height: 45px;
        background-color: ${colors.offerMarketing};
        display: flex;
        align-items: center;
        justify-content: center;
    `,
};
