/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { CloseButton, CloseButtonProps } from 'src/components/Buttons/CloseButton';
import { colors } from 'src/constants/variables';

type Props = CloseButtonProps;

export const ProductsCatalogModalHeader: React.FC<Props> = ({ onClick }) => (
    <div css={styles.wrapper}>
        <div css={styles.closeButtonWrapper}>
            <CloseButton onClick={onClick} />
        </div>
    </div>
);

const styles = {
    wrapper: css`
        top: 0;
        position: sticky;
        height: 100px;
        width: 100%;
        background-color: ${colors.primary}F7;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    `,
    closeButtonWrapper: css`
        position: absolute;
        top: calc(50% - 20px);
        right: 40px;
    `,
};
