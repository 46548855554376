/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import { Form } from 'antd';
import { css } from '@emotion/react';

import { CustomModal } from 'src/components';
import { horizontalMark, mobileScreenStyles } from 'src/style/styles';
import { colors, fontSizes } from 'src/constants/variables';

import { OfferFormProps } from '../../types';
import { isItemsListWithAllData } from '../../../helpers/offerForm';
import { OfferName } from './OfferName';
import { ConfirmationButton } from '../../../../../components/MewaCustomButton';

type Props = {
    visible: boolean;
    onClick: () => void;
    nameErrorMsg?: false | string;
    shouldEdit: boolean;
    footer?: React.ReactNode;
};

export const OfferFinishModal: React.FC<Props> = ({
    visible,
    onClick,
    nameErrorMsg,
    shouldEdit,
    footer = null,
}) => {
    const containerRef = useRef(null);

    const isSubmitButtonActive = ({ name, amountOfWearers, itemsList }: OfferFormProps) =>
        name && amountOfWearers && isItemsListWithAllData(itemsList);

    return (
        <div ref={containerRef}>
            <CustomModal
                onClick={onClick}
                visible={visible}
                modalCss={styles.modal}
                maskStyle={styles.maskStyle}
                getContainer={() => containerRef.current || document.body}
                footer={footer}
                destroyOnClose
            >
                <h2 css={styles.title}>ANGEBOT SPEICHERUNG</h2>
                <div css={styles.wrapper}>
                    {!shouldEdit ? (
                        <div css={styles.bodyWrapper}>
                            <OfferName errorMsg={nameErrorMsg} />

                            <div css={styles.buttonWrapper}>
                                <Form.Item shouldUpdate>
                                    {({ getFieldsValue }) => (
                                        <ConfirmationButton
                                            css={styles.button}
                                            disabled={
                                                !!nameErrorMsg ||
                                                !isSubmitButtonActive(getFieldsValue())
                                            }
                                        >
                                            ANGEBOT SPEICHERN
                                        </ConfirmationButton>
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    ) : (
                        <div css={styles.updateBodyWrapper}>
                            Sind Sie sich sicher, dass Sie das Angebot überschreiben wollen?
                        </div>
                    )}
                </div>
            </CustomModal>
        </div>
    );
};

const styles = {
    buttonWrapper: css`
        .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;
        }
    `,
    button: css`
        width: 400px;
        border-radius: 5px;

        background-color: ${colors.fontSecondaryDark};
        color: ${colors.primary};

        ${mobileScreenStyles(css`
            width: 100%;
            max-width: 400px;
        `)}
        :hover,
        :focus {
            background-color: ${colors.fontSecondaryDark};
            color: ${colors.primary};
        }

        &.ant-btn[disabled] {
            background-color: ${colors.fontSecondaryDark};
            color: ${colors.primary};
            opacity: 0.25;
        }
    `,
    maskStyle: {
        left: '480px',
        backgroundColor: colors.primary,
    },
    modal: css`
        left: 480px;

        .ant-modal-content,
        .ant-modal-body {
            height: auto;
        }

        .ant-modal-footer {
            display: flex;
            border-top: none;
            justify-content: center;

            .update-cancel-button {
                width: 120px;
                height: 56px;
                text-align: center;
                font-family: OptimaLTPro-Roman, sans-serif;
                font-size: ${fontSizes.medium};
                color: ${colors.fontSecondary};
                text-transform: uppercase;

                border-color: ${colors.secondaryButton};
                background-color: ${colors.background};
                color: ${colors.secondaryButton};

                :hover,
                :focus {
                    border-color: ${colors.secondaryButton};
                    background-color: ${colors.background};
                    color: ${colors.secondaryButton};
                }
            }

            .ant-btn {
                width: 120px;
                margin-right: 16px;
                border-radius: 5px;
            }
        }
    `,
    wrapper: css`
        margin-top: 32px;
        height: 100%;
        display: flex;
        justify-content: center;
    `,
    bodyWrapper: css`
        width: 400px;

        ${mobileScreenStyles(css`
            width: 100%;
            max-width: 400px;
        `)}
    `,
    updateBodyWrapper: css`
        margin: 64px 0 32px 0;
    `,
    updateButtonWrapper: css`
        display: flex;
        margin-top: 16px;
    `,
    title: css`
        color: ${colors.primaryDark};
        font-family: OptimaLTPro-Medium, sans-serif;
        font-size: ${fontSizes.xMedium};
        line-height: 1;
        text-transform: uppercase;
        padding-top: 18px;
        ${horizontalMark()};
    `,
};
