import { ThunkAction } from 'src/store';
import { fakeHttpService } from 'src/modules/app/service/httpService';
import { createAction, ActionTypes } from 'src/helpers/types';

// @ToDo - in final version this will be replaced with data from backend
import { productCategoriesList } from '../../data/resources';

export const setProductCategoriesList = (data: ProductCategoriesList) =>
    createAction('SET_PRODUCT_CATEGORIES_LIST', data);

export const setProductCategoriesLoading = () => createAction('PRODUCT_CATEGORIES_LOADING', null);

export const fetchProductCategoriesList = (): ThunkAction => async (dispatch) => {
    try {
        dispatch(setProductCategoriesLoading());

        // @ToDo - in final version replace this with backend request
        const result = await fakeHttpService(productCategoriesList);

        dispatch(setProductCategoriesList(result));
    } catch (error) {
        console.error('fetchProductCategoriesList -> error', error);
    }
};

export const productCategoriesActions = {
    setProductCategoriesList,
    setProductCategoriesLoading,
};

export type ProductCategoriesActionsType = ActionTypes<typeof productCategoriesActions>;
