/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Col, Row } from 'antd';

import { CategoryColumn } from './CategoryColumn';
import { CollectionColumn } from './CollectionColumn';
import { NavigationProps } from './types';
import { navigationStyles } from './styles';

export const NavigationWithCategories: React.FC<NavigationProps> = ({
    productGroups,
    productCategories,
    productCollections,
    selectedCollectionId,
    onCollectionSelect,
    onClose,
    currentGroupId,
}) => {
    const firstGroupId = productGroups[0].id;
    const firstCategoryId = productCategories[firstGroupId][0].id;
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>();

    const onCollectionClick = (collection: ProductCatalogItem) => {
        onCollectionSelect(collection);
        onClose();
    };

    return (
        <Col span={16} offset={4}>
            <Row css={navigationStyles.content} onClick={(e) => e.stopPropagation()}>
                <Col span={8} offset={0}>
                    <CategoryColumn
                        productCategories={productCategories}
                        onSelect={setSelectedCategoryId}
                        selectedGroup={currentGroupId || firstGroupId}
                        selectedCategory={selectedCategoryId || firstCategoryId}
                    />
                </Col>
                <Col span={16} offset={0}>
                    <CollectionColumn
                        productCollections={productCollections}
                        selectedCategoryId={selectedCategoryId || firstCategoryId}
                        selectedCollectionId={selectedCollectionId}
                        onCollectionClick={onCollectionClick}
                    />
                </Col>
            </Row>
        </Col>
    );
};
