import { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { CarouselRef } from 'antd/lib/carousel';

export const useCarousel = () => {
    const history = useHistory<{ index: number }>();
    const location = history?.location;
    const ref = useRef<CarouselRef | null>(null);

    useEffect(() => {
        if (location?.state && ref.current) {
            ref.current.goTo(location.state.index, true);
        }
    }, [location?.state]);

    return ref;
};
