/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { ColorChooser } from 'src/components/ColorChooser';
import { setVariantColor } from '../../store/productActions';
import { mobileScreenStyles } from 'src/style/styles';

import { zIndices } from 'src/constants/variables';

type Props = {
    colors: Product['colors'];
    currentVariant: ProductVariant;
    onColorChange: typeof setVariantColor;
    slidesToShow?: number;
};

export const ProductColorChooser: React.FC<Props> = (props) => (
    <div css={[styles.container]}>
        <ColorChooser {...props} colorMarkerSize={1} responsiveRules={[]} />
    </div>
);

const styles = {
    container: css`
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 16px;
        z-index: ${zIndices.zIndex050};
        ${mobileScreenStyles(css`
            margin-left: 0;
        `)};

        height: 48px;
        bottom: 48px;

        .ant-carousel {
            max-width: 320px;
            flex-grow: 1;

            .slick-list {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .slick-track {
                    margin: auto;
                }
            }
        }
    `,
    hidden: css`
        visibility: hidden;
        opacity: 0;
    `,
};
