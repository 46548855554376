/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { horizontalLine } from 'src/style/styles';

export const HorizontalLineElement: React.FC = () => <div css={styles.divider}></div>;

const styles = {
    divider: css`           
        ${horizontalLine(css`
            margin: 0;
        `)};
        }
    `,
};
