/** @jsxImportSource @emotion/react */
import React from 'react';
import { getDateLabel } from 'src/helpers/date';

import { offerUserCustomerOverviewStyles } from 'src/modules/offer/components/styles';
import { ContactDetailsForm, ContactDetailsFormProps } from './ContactDetailsForm';

export type ContactDetailsModalBodyProps = Pick<
    ContactDetailsFormProps,
    'onSubmit' | 'initialValues' | 'isButtonValid' | 'buttonText'
> & {
    withDate?: boolean;
};

export const ContactDetailsModalBody: React.FC<ContactDetailsModalBodyProps> = ({
    withDate = true,
    ...props
}) => (
    <>
        {withDate && <h2 css={offerUserCustomerOverviewStyles.date}>{getDateLabel()}</h2>}
        <h2 css={offerUserCustomerOverviewStyles.title} data-test={'offerUserCustomerOverviewTitle'}>MEWA ANSPRECHPARTNER INFORMATIONEN</h2>

        <ContactDetailsForm {...props} />
    </>
);
