import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { parseToPrice } from 'src/helpers/utils';
import { WardrobeOptionsServiceListPdf } from '../wardrobeOptionsServiceListPdf';

const styles = StyleSheet.create({
    summaryContainer: {
        marginTop: '20px',
    },
    namePriceContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        fontSize: '8px',
        lineHeight: '1.5',
        textAlign: 'right',
    },
    nameLabel: {
        width: '78%',
        fontFamily: 'GebauerRegular',
        fontSize: '8px',
    },
    priceLabel: {
        width: '22%',
        fontFamily: 'GebauerMedium',
        fontSize: '8px',
    },
});

type Props = {
    details: OfferDetails | undefined;
    services: WardrobeOption[] | undefined;
};

const COMMA = ',';

export const ProductListPageSummary: React.FC<Props> = ({ details, services }) => (
    <View style={styles.summaryContainer}>
        <View>
            {details?.wardrobeOptionsData && (
                <WardrobeOptionsServiceListPdf
                    wardrobeOptionsData={details?.wardrobeOptionsData}
                    services={services!}
                    styles={styles}
                />
            )}
            <View style={{ ...styles.namePriceContainer, marginBottom: '10px' }}>
                <Text style={styles.nameLabel}>Einzukleidende Personen</Text>
                <Text style={styles.priceLabel}>{details?.amountOfWearers || '-'}</Text>
            </View>
            {details?.weeklyTotalPriceAllItems && (
                <View style={styles.namePriceContainer}>
                    <Text style={styles.nameLabel}>Wöchentlicher Mietpreis/Person</Text>
                    <Text style={styles.priceLabel}>
                        {details?.weeklyTotalPriceAllItemsValue
                            ? parseToPrice(details?.weeklyTotalPriceAllItemsValue, COMMA)
                            : '-'}
                        €
                    </Text>
                </View>
            )}
            <View style={styles.namePriceContainer}>
                <Text style={styles.nameLabel}>Servicepauschale pro Woche je Anfahrstelle</Text>
                <Text style={styles.priceLabel}>
                    {details?.deliveryPricePerWeek
                        ? parseToPrice(details?.deliveryPricePerWeek, COMMA)
                        : '-'}
                    €
                </Text>
            </View>
            {details?.totalPricePerWeek && (
                <View style={styles.namePriceContainer}>
                    <Text style={styles.nameLabel}>Wöchentlicher Mietpreis/Gesamt</Text>
                    <Text style={styles.priceLabel}>
                        {details?.totalPricePerWeekValue
                            ? parseToPrice(details?.totalPricePerWeekValue, COMMA)
                            : '-'}
                        €
                    </Text>
                </View>
            )}
        </View>
    </View>
);
