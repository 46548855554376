import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    bottomPageNumber: {
        position: 'absolute',
        bottom: '25px',
        right: '40px',
        fontSize: '8px',
        fontFamily: 'GebauerBold',
    },
});

export const PageNumber: React.FC = () => (
    <Text render={({ pageNumber }) => `${pageNumber}`} style={styles.bottomPageNumber} fixed />
);
