import React from 'react';
import { Text, StyleSheet, Image, View } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';
import SuitIcon from 'src/assets/icons/suit.png';
import { DeliveryOptionData } from '../../../types';

type Props = {
    data: DeliveryOptionData;
    addBorderRight?: boolean;
};

function getIcons(count: number) {
    const icons = [];
    for (let i = 0; i < count; i++) {
        icons.push(<Image key={i} style={styles.icon} src={SuitIcon} />);
    }
    return icons;
}

export const DeliveryOption: React.FC<Props> = ({ data, addBorderRight }) => (
    <div style={{ ...styles.container, ...(addBorderRight ? styles.borderRight : {}) }}>
        <div style={styles.headerContainer}>
            <div style={styles.headerTextContainer}>
                <Text style={{ fontSize: '11px', ...styles.redBold }}>{data.mainHeader}</Text>
                <Text style={styles.text}>pro Woche</Text>
            </div>

            {getIcons(data.iconCount)}
        </div>
        <Text style={{ ...styles.text, fontSize: '10px', paddingTop: '20px' }}>
            {data.mainText}
        </Text>
        {data.details.map((detail, i: number) => (
            <View key={i}>
                <Text style={{ ...styles.text, ...styles.redBold, paddingTop: '18px' }}>
                    {detail.header}
                </Text>
                <Text style={{ ...styles.text }}>{detail.text}</Text>
            </View>
        ))}
    </div>
);

const styles = StyleSheet.create({
    container: {
        width: '35%',
        marginTop: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    text: {
        fontSize: '11px',
        color: colors.fontSecondaryDark,
        fontFamily: 'GebauerRegular',
    },
    borderRight: {
        borderRight: `1.6px solid ${colors.grey}`,
    },
    redBold: {
        fontFamily: 'GebauerBold',
        color: colors.darkRed,
    },
    icon: {
        maxWidth: '40px',
        maxHeight: '40px',
        position: 'relative',
        top: '-5px',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerTextContainer: {
        paddingRight: '10px',
    },
});
