/** @jsxImportSource @emotion/react */
import React, { useContext, useRef } from 'react';
import { DatePicker } from 'antd';
import Icon from '@ant-design/icons';
import locale from 'antd/lib/calendar/locale/de_DE';
import { css } from '@emotion/react';

import { DatePickerCalendar, DatePickerClose } from 'src/assets/icons';
import { colors } from 'src/constants/variables';
import moment from 'moment';
import { ReadOnlyContext } from 'src/helpers/ReadOnlyContext';

type Props = {
    selected: moment.Moment | null;
    setSelected: (date: moment.Moment | null) => void;
    allowClear?: boolean;
};

const disabledDate = (current: moment.Moment) =>
    current.startOf('day').isBefore(moment().startOf('day'));

const MainSectionDatePicker: React.FC<Props> = ({ selected, setSelected, allowClear = true }) => {
    const parentRef = useRef(null);
    const readOnly = useContext(ReadOnlyContext);

    return (
        <div css={styles.wrapper} ref={parentRef} data-test={'companyInfoBoxDatePickerWrapper'}>
            <DatePicker
                locale={locale}
                inputReadOnly
                value={selected}
                onChange={setSelected}
                suffixIcon={<Icon component={DatePickerCalendar} css={styles.calendarIcon} data-test={'companyInfoBoxDatePickerIcon'} />}
                placeholder="TT.MM.JJJJ*"
                format="DD.MM.YYYY"
                clearIcon={<Icon component={DatePickerClose} css={styles.clearIcon} />}
                allowClear={allowClear}
                getPopupContainer={() => parentRef.current || document.body}
                disabledDate={disabledDate}
                disabled={readOnly}
                data-test={'companyInfoBoxDatePicker'}
            />
        </div>
    );
};

export default MainSectionDatePicker;

const iconSize = 20;

const styles = {
    wrapper: css`
        .ant-picker {
            font-family: OptimaLTPro-Medium, sans-serif;
            border: none;
            background: none;
            box-shadow: none;

            margin: 0;
            padding: 0;

            input,
            input::placeholder {
                font-family: OptimaLTPro-Roman, sans-serif;
                font-size: 16px;
                color: ${colors.primary};
                width: 90px;
            }
        }

        .ant-picker-input {
            cursor: pointer;
        }

        .ant-picker-disabled {
            &,
            input,
            .ant-picker-input {
                cursor: default;
            }
        }

        .ant-picker-suffix {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .ant-picker-clear {
            width: ${iconSize}px;
            height: ${iconSize}px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: ${colors.offerMarketing};
        }

        .ant-picker-footer {
            display: none;
        }

        .ant-picker-cell-inner {
            border-radius: 5px;
            background: none !important;
        }

        .ant-picker-cell-inner {
            &:hover {
                border: 1px solid ${colors.secondary};
                background: transparent !important;
                box-sizing: border-box;
            }
        }

        .ant-picker-cell-today .ant-picker-cell-inner {
            &::before {
                border: none;
            }
        }

        .ant-picker-cell-selected .ant-picker-cell-inner {
            background: rgba(184, 33, 33, 0.25) !important;
            border: 1px solid #b82121;
            color: #000;
        }

        button.ant-picker-year-btn,
        button.ant-picker-month-btn {
            pointer-events: none;
        }
    `,
    calendarIcon: css`
        cursor: pointer;

        svg {
            height: ${iconSize}px;
            width: ${iconSize}px;
            fill: ${colors.primary};
        }
    `,
    clearIcon: css`
        cursor: pointer;
        background-color: ${colors.offerMarketing};

        svg {
            height: 12px;
            width: 12px;
            fill: ${colors.primary};
        }
    `,
};
