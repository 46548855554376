import { api } from '../../../constants/variables';
import { delay } from '../../../helpers/utils';
import { authService } from 'src/modules/auth/authService';
import { JsonObject } from 'src/helpers/types';

type HttpServiceArgs = {
    url: string;
    body?: JsonObject;
    method?: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';
    responseType?: 'json' | 'blob';
    customAuthHeaders?: HeadersInit | null;
};

export class HttpServiceError extends Error {
    code: number;

    constructor(message: string, code = 500) {
        super(message);
        this.code = code;
        Object.setPrototypeOf(this, HttpServiceError.prototype);
    }
}

export async function httpService<T>({
    body,
    method = 'GET',
    url,
    responseType = 'json',
    customAuthHeaders = null,
}: HttpServiceArgs) {
    const headers = Object.assign(
        { 'Content-Type': 'application/json' },
        customAuthHeaders || (await getAuthHeader()),
    );
    const response = await fetch(`${api}${url}`, {
        method,
        headers,
        body: body && JSON.stringify(body),
    });

    if (!response.ok) {
        throw new HttpServiceError(response.statusText, response.status);
    }

    const data = await response[responseType]().catch(() => null);

    return data as T;
}

export async function httpStatus({
    body,
    method = 'GET',
    url,
    customAuthHeaders,
}: HttpServiceArgs) {
    const headers = Object.assign(
        { 'Content-Type': 'application/json' },
        customAuthHeaders || (await getAuthHeader()),
    );
    const response = await fetch(`${api}${url}`, {
        method,
        headers,
        body: body && JSON.stringify(body),
    });

    return response.status;
}

export async function fakeHttpService<R>(response: R, timeout = 200) {
    await delay(timeout);

    return Promise.resolve(response);
}

async function getAuthHeader(): Promise<HeadersInit> {
    const token = await authService.getAccessToken();

    return {
        Authorization: `Bearer ${token}`,
    };
}
