/** @jsxImportSource @emotion/react */
import React from 'react';

import { ReactComponent as ArrowLeftSVG } from './arrowLeft.svg';
import { ReactComponent as ArrowRightSVG } from './arrowRight.svg';
import { ReactComponent as CloseSVG } from './close.svg';
import { ReactComponent as MenuSVG } from './menu.svg';

export const ArrowLeft: React.FC = (props) => <ArrowLeftSVG {...props} />;

export const ArrowRight: React.FC = (props) => <ArrowRightSVG {...props} />;

export const Close: React.FC = (props) => <CloseSVG {...props} />;

export const Menu: React.FC = (props) => <MenuSVG {...props} />;
