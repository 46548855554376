/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { fontSizes } from '../../../../constants/variables';

type Props = {
    norms: ProductNorm[];
    customCss?: SerializedStyles;
};

export const ProductNormsText: React.FC<Props> = ({ norms, customCss }) => {
    const distinctNorms = norms.filter(
        (value, index, self) => self.findIndex((v) => v.label === value.label) === index,
    );

    return (
        <div css={[styles.wrapper, customCss]}>
            {distinctNorms.map((norm) => (
                <p key={norm.label} css={styles.normText}>
                    {norm.label}
                </p>
            ))}
        </div>
    );
};

const styles = {
    wrapper: css`
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: 0 -1px;
    `,
    normText: css`
        margin-bottom: 0 !important;
        font-size: ${fontSizes.xxxSmall};
    `,
};
