/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Col, Row } from 'antd';

import { HeaderColumn } from './HeaderColumn';
import { ProductRowInputs } from './ProductRowInputs';
import { JsonObject, VariantItem } from 'src/helpers/types';

type Props = {
    product: VariantItem;
    onOfferItemClick: () => void;
    onRemove?: () => void;
    form?: boolean;
    onInlineChange: (item: VariantItem, value?: JsonObject) => void;
    warnings: boolean[];
};

export const ProductRow: React.FC<Props> = ({
    product,
    onOfferItemClick,
    onRemove,
    onInlineChange,
    warnings,
    form,
}) => (
    <Row>
        <Col span={8}>
            <HeaderColumn
                onOfferItemClick={onOfferItemClick}
                item={product}
                onRemove={onRemove}
                warnings={warnings}
                form={form}
            />
        </Col>

        <Col span={16}>
            <Row css={styles.row}>
                <ProductRowInputs product={product} onInlineChange={onInlineChange} />
            </Row>
        </Col>
    </Row>
);

const styles = {
    row: css`
        height: 100%;
    `,
};
