/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';

export const BrandHeaderLink: React.FC = () => (
    <a href="https://mewa.de" css={[brandLogoLinkStyles.bottomRow, brandLogoLinkStyles.link]}
    data-test={'brandHeaderLink'}>
        www.mewa.de
    </a>
);

export const brandLogoLinkStyles = {
    link: css`
        text-align: right;
        padding-right: 32px;
        justify-content: flex-end;
    `,
    bottomRow: css`
        width: 100%;
        background-color: ${colors.fontHeader};
        color: ${colors.link};
        height: 50px;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-family: 'OptimaLTPro-Medium', sans-serif;
    `,
};
