/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Menu from 'antd/lib/menu';
import { RadioGroup } from 'src/components/RadioGroup';
import { css } from '@emotion/react';

import { OkAndCancelButtons } from 'src/components/Buttons';
import { textOverflowEllipsis } from 'src/style/styles';
import { offerDetailsStyles } from '../styles';
import { colors, fontSizes } from '../../../../constants/variables';

export const ServicesDropdownMenu: React.FC<{
    services: WardrobeOption[];
    selectedServices: number[];
    setSelectedServices: (ids: number[]) => void;
    visible: boolean;
    onClose: () => void;
}> = ({ services, selectedServices, setSelectedServices, visible, onClose }) => {
    const [currentlySelected, setCurrentlySelected] = useState<number[]>([]);
    useEffect(() => {
        setCurrentlySelected(selectedServices);
    }, [selectedServices]);

    useEffect(() => {
        if (visible) {
            setCurrentlySelected(selectedServices);
        }
    }, [visible, selectedServices]);

    const changeSelection = (serviceId: number) => {
        setCurrentlySelected([serviceId]);
    };

    const submitSelection = () => {
        setSelectedServices(currentlySelected);
        onClose();
    };

    const discardChanges = () => {
        setCurrentlySelected(selectedServices);
        onClose();
    };

    const values = services.map((sr) => ({ label: sr.label, value: sr.id }));

    return (
        <div css={styles.dropdownWrapper}>
            <Menu selectable={false} css={offerDetailsStyles.dropdownMenu}>
                <RadioGroup<number>
                    values={values}
                    value={currentlySelected[0]}
                    onChange={changeSelection}
                    customCss={styles.radio}
                />
            </Menu>
            <OkAndCancelButtons
                onOk={submitSelection}
                onCancel={discardChanges}
                customCss={styles.buttons}
            />
        </div>
    );
};

const styles = {
    dropdownWrapper: css`
        width: fit-content;
        ${offerDetailsStyles.dropdownWrapper};
    `,
    radio: css`
        display: flex;
        flex-direction: column;

        .ant-radio-wrapper {
            width: 100%;
            ${textOverflowEllipsis};
            height: 55px;
            line-height: 22px;
            padding: 16px;
            margin: 0 16px 0 0;

            &:not(:last-of-type) {
                border-bottom: 1px solid ${colors.secondaryDark};
            }
        }

        && .ant-radio {
            .ant-radio-inner {
                height: 24px;
                width: 24px;

                :after {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        && .ant-radio + span {
            color: ${colors.fontSecondaryDark};
            font-size: ${fontSizes.xSmall};
        }
    `,
    buttons: css`
        padding: 16px 16px 0;
    `,
};
