/** @jsxImportSource @emotion/react */
import React from 'react';
import { Collapse } from 'antd';
import { css } from '@emotion/react';

import { CustomExpandIcon } from 'src/components/CustomExpandIcon';
import { colors } from 'src/constants/variables';

import { CollapsePanel } from './CollapsePanel';
import { Information } from './Information';
import { PsaNorms } from './PsaNorms';
import { Features } from './Features';
import { Availability } from './Availability';
import { Sizes } from './Sizes';

type Props = Pick<ProductVariant, 'features' | 'sizes'> & {
    availableIn: string[];
    information: string[];
    norms: ProductNorm[];
    variantKey: string;
};

export const InfoSection: React.FC<Props> = ({
    availableIn,
    features,
    information,
    norms,
    sizes,
    variantKey,
}) => (
    <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) => <CustomExpandIcon css={collapseIcon} isActive={isActive} />}
        expandIconPosition="left"
        css={collapse}
    >
        <CollapsePanel header={'Produkt informationen'} key={2}>
            <Information
                information={information}
                warningText1={norms.length > 0}
                warningText2={PsaNormsWarningMessage2products.indexOf(variantKey) > -1}
            />
        </CollapsePanel>
        {norms.length > 0 ? (
            <CollapsePanel header={'PSA Informationen'} key={3}>
                <PsaNorms
                    norms={norms}
                    warningText1={norms.length > 0}
                    warningText2={PsaNormsWarningMessage2products.indexOf(variantKey) > -1}
                />
            </CollapsePanel>
        ) : null}
        <CollapsePanel header={'Produkteingenschaften'} key={4}>
            <Features features={features} />
        </CollapsePanel>
        <CollapsePanel header={'Freigegeben für'} key={5}>
            <Availability availableIn={availableIn} />
        </CollapsePanel>
        <CollapsePanel header={'Verfügbare Größen'} key={6}>
            <Sizes sizes={sizes} />
        </CollapsePanel>
    </Collapse>
);

const collapse = css`
    background-color: transparent;
    margin: 0 16px;

    svg {
        color: ${colors.secondary};
    }
`;
const collapseIcon = css`
    &&&&.ant-collapse-arrow {
        top: 1.5em;
    }
`;

const PsaNormsWarningMessage2products: Array<string> = [
    '7310060',
    '7310062',
    '7310064',
    '7310066',
    '7310068',
    '7310070',
    '8910024',
    '111215',
    '111219',
    '111221',
    '111223',
    '111227',
    '111232',
    '8730044',
    '7670078',
    '7670082',
    '7670084',
    '7670086',
    '7670088',
    '7670080',
    '8780034',
    '7310156',
    '7310164',
    '7310163',
    '7310157',
    '7310203',
    '8910023',
    '8910036',
    '111372',
    '111373',
    '111351',
    '111350',
    '111497',
    '8730043',
    '8730053',
    '7670186',
    '7670187',
    '7670176',
    '7670178',
    '7670237',
    '8780033',
    '8780049',
    '8780032',
    '8910022',
    '8730040',
    '7670167',
    '7670166',
    '7670169',
    '7670168',
    '8780051',
    '7310147',
    '7310150',
    '7310149',
    '7310148',
    '8910038',
    '8730055',
    '111346',
    '111347',
    '111345',
    '111348',
    '7310141',
    '7310143',
    '7310144',
    '7670158',
    '7670160',
    '7670161',
    '111328',
    '111332',
    '111334',
];
