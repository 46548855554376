import React from 'react';
import { connect } from 'react-redux';

import { AsyncResourceHandler } from 'src/modules/app';
import { MapDispatch, MapState } from 'src/store';
import { fetchProductGroupsList } from 'src/modules/productsCatalog/store/actions/productGroupsActions';
import {
    getProductGroupsList,
    getProductGroupsLoadingError,
    getProductGroupsLoadingStatus,
} from 'src/modules/productsCatalog/store/selectors/productGroupsSelectors';
import { fetchProductCategoriesList } from 'src/modules/productsCatalog/store/actions/productCategoriesActions';
import {
    getProductCategoriesList,
    getProductCategoriesLoadingError,
    getProductCategoriesLoadingStatus,
} from 'src/modules/productsCatalog/store/selectors/productCategoriesSelectors';
import { fetchProductCollectionsList } from 'src/modules/productsCatalog/store/actions/productCollectionsActions';
import {
    getCollectionsList,
    getCollectionsLoadingError,
    getCollectionsLoadingStatus,
} from 'src/modules/productsCatalog/store/selectors/productCollectionsSelectors';
import { getDefaultCollection } from 'src/modules/productsList/helpers';

import { ProductsCatalogLayout } from './ProductsCatalogLayout';

type StateProps = {
    productGroups: ProductGroup[] | null;
    productCategories: ProductCategoriesList | null;
    productCollections: ProductCollectionsList | null;
    loadingGroups: boolean;
    loadingCategories: boolean;
    loadingCollections: boolean;
    errorGroups: boolean;
    errorCategories: boolean;
    errorCollections: boolean;
};
type DispatchProps = {
    fetchProductGroupsList: () => void;
    fetchProductCategoriesList: () => void;
    fetchProductCollectionsList: () => void;
};
type OwnProps = {
    selectedProducts: CollectionItem[];
    setSelectedProducts: (selectedProducts: CollectionItem[]) => void;
    collectionId?: string | null;
    onCollectionSelect: (collection: ProductCollection) => void;
};

type Props = StateProps & DispatchProps & OwnProps;

type State = {
    selectedProducts: CollectionItem[];
};

class ProductsCatalogComponent extends React.PureComponent<Props, State> {
    componentDidMount() {
        this.props.fetchProductGroupsList();
        this.props.fetchProductCategoriesList();
        this.props.fetchProductCollectionsList();
    }

    componentDidUpdate() {
        const { collectionId, productCollections, onCollectionSelect } = this.props;
        if (!collectionId && productCollections) {
            onCollectionSelect({
                id: getDefaultCollection(productCollections).id,
            } as ProductCollection);
        }
    }

    render() {
        const {
            productGroups,
            productCategories,
            productCollections,
            onCollectionSelect,
        } = this.props;
        const { errorGroups, errorCategories, errorCollections } = this.props;
        const { loadingGroups, loadingCategories, loadingCollections } = this.props;

        const currentCollectionId = this.props.collectionId || null;

        return (
            <AsyncResourceHandler
                data={{ productGroups, productCategories, productCollections, currentCollectionId }}
                error={errorGroups || errorCategories || errorCollections}
                loading={loadingGroups || loadingCategories || loadingCollections}
            >
                {(resources) => (
                    <ProductsCatalogLayout
                        productGroups={resources.productGroups}
                        productCategories={resources.productCategories}
                        productCollections={resources.productCollections}
                        currentCollectionId={resources.currentCollectionId}
                        onCollectionSelect={onCollectionSelect}
                        selectedProducts={this.props.selectedProducts}
                        setSelectedProducts={this.props.setSelectedProducts}
                    />
                )}
            </AsyncResourceHandler>
        );
    }
}

const mapStateToProps: MapState<StateProps> = (state) => ({
    errorGroups: getProductGroupsLoadingError(state),
    loadingGroups: getProductGroupsLoadingStatus(state),
    productGroups: getProductGroupsList(state),

    errorCategories: getProductCategoriesLoadingError(state),
    loadingCategories: getProductCategoriesLoadingStatus(state),
    productCategories: getProductCategoriesList(state),

    errorCollections: getCollectionsLoadingError(state),
    loadingCollections: getCollectionsLoadingStatus(state),
    productCollections: getCollectionsList(state),
});

const mapDispatchToProps: MapDispatch<DispatchProps> = (dispatch) => ({
    fetchProductGroupsList: () => dispatch(fetchProductGroupsList()),
    fetchProductCategoriesList: () => dispatch(fetchProductCategoriesList()),
    fetchProductCollectionsList: () => dispatch(fetchProductCollectionsList()),
});

export const ProductsCatalogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsCatalogComponent);
