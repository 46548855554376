import {
    set as ramdaSet,
    view as ramdaView,
    update as ramdaUpdate,
    lensPath as ramdaLensPath,
} from 'ramda';

import { AsyncResource, defaultAsyncResource, VariantItem } from 'src/helpers/types';
import { OfferActions } from 'src/modules/offer/store/actions/offerActions';
import { OfferDetailsActions } from 'src/modules/offer/store/actions/offerDetailsActions';

const offerBasicsLens = ramdaLensPath(['data', 'basics']);

export type DetailsOfferState = AsyncResource<{
    items: VariantItem[];
    basics: OfferBasics | undefined;
    details: OfferDetails | undefined;
    summary: OfferSummaryBrief | undefined;
}> & {
    errorCode: number | undefined;
};

const initialDetailsOfferState: DetailsOfferState = {
    ...defaultAsyncResource,
    errorCode: undefined,
};

export function offerDetailsReducer(
    state = initialDetailsOfferState,
    action: OfferDetailsActions | OfferActions,
): DetailsOfferState {
    switch (action.type) {
        case 'OFFER_DETAILS|FETCH_OVERVIEW_REQUEST':
            return {
                ...initialDetailsOfferState,
                error: false,
                loading: true,
            };

        case 'OFFER_DETAILS|FETCH_OVERVIEW_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload,
            };

        case 'OFFER_DETAILS|FETCH_OVERVIEW_FAILURE':
            return {
                ...initialDetailsOfferState,
                loading: false,
                error: true,
            };

        case 'OFFER_DETAILS|UPDATE_CONTACT_DETAILS':
        case 'OFFER_DETAILS|UPDATE_CUSTOMER_DETAILS':
            return ramdaSet(
                offerBasicsLens,
                { ...ramdaView(offerBasicsLens, state), ...action.payload },
                state,
            );

        case 'OFFER_DETAILS|UPDATE_OFFER_ITEM': {
            const indexToUpdate = state.data?.items.findIndex(
                (item) =>
                    (action.payload.cartItemId && item.cartItemId === action.payload.cartItemId) ||
                    (action.payload.offerItemId && item.offerItemId === action.payload.offerItemId),
            );
            return indexToUpdate !== undefined && indexToUpdate >= 0
                ? {
                      ...state,
                      data: state.data && {
                          ...state.data,
                          items: ramdaUpdate(indexToUpdate, action.payload, state.data.items || []),
                      },
                  }
                : state;
        }

        case 'OFFER_DETAILS|CLEAR_OVERVIEW':
            return {
                ...initialDetailsOfferState,
            };

        case 'OFFER_DETAILS|REMOVE_OFFER_ITEM':
            return {
                ...state,
                data: state.data && {
                    ...state.data,
                    items: state.data.items.filter(
                        (item) => item.offerItemId !== action.payload.offerItemId,
                    ),
                },
            };

        case 'OFFER_DETAILS|ADD_OFFER_ITEMS':
            return {
                ...state,
                data: state.data && {
                    ...state.data,
                    items: [...state.data.items, ...action.payload.offerItems],
                },
            };
    }
    return state;
}
