import putztucher from './Putztucher.jpg';
import RABCPutztucher from './RABCPutztucher.jpg';
import saconFix from './SaconFix.jpg';
import safetyContainers from './SafetyContainers.jpg';

export const putztucherImages = {
    putztucher,
    RABCPutztucher,
    saconFix,
    safetyContainers,
};
