/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Dispatch } from 'react';
import { useState } from 'react';
import { HeaderSticky } from '../HeaderSticky';
import { CustomModal, CustomModalProps } from 'src/components/CustomModal';
import { backgrounds } from './backgrounds';
import { ImageTile } from './ImageTile';
import { noop } from 'src/helpers/utils';

type Props = Omit<CustomModalProps, 'onClick'> & {
    close?: () => void;
    setSelected: Dispatch<string>;
    selected: string;
};

export const BackgroundSelectionModal: React.FC<Props> = ({
    close = noop,
    setSelected,
    selected,
    ...rest
}) => {
    const [checked, setChecked] = useState<string>('');

    const onClose = (image: {
        id: string;
        description: string;
        textColor: string;
        logoColor: string;
    }) => {
        setSelected(image.id);
        setChecked('');
        close();
    };

    const onClick = (image: {
        id: string;
        description: string;
        textColor: string;
        logoColor: string;
    }) => {
        setChecked(image.id);
        setTimeout(() => onClose(image), 600);
    };

    return (
        <CustomModal onClick={close} {...rest}>
            <HeaderSticky css={styles.header} wrapperStyles={styles.headerWrapper} data-test={'backgroundSelectionHeaderTitle'}>
                HINTERGRUNDBILD
            </HeaderSticky>
            <div css={styles.container}>
                {backgrounds.map((image) => (
                    <ImageTile
                        description={image.description}
                        image={image.id}
                        key={image.id}
                        selected={selected}
                        onClick={() => onClick(image)}
                        checked={checked}
                    />
                ))}
            </div>
        </CustomModal>
    );
};

const styles = {
    headerWrapper: css`
        padding-top: 24px;
        background: unset;
    `,
    header: css`
        padding-left: 32px;
        font-size: 24px;

        &&&& {
            margin: 0;
            padding-top: 16px;
            padding-bottom: 0;

            &&:before {
                left: unset;
            }
        }
    `,
    container: css`
        padding-left: 32px;
        padding-right: 32px;
        width: 100%;

        display: flex;
        flex-wrap: wrap;
    `,
};
