/** @jsxImportSource @emotion/react */
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';

export const Loader: React.FC = () => <LoadingOutlined spin css={styles} />;

const styles = css`
    width: 100%;

    svg {
        height: auto;
        width: 64px;

        fill: ${colors.secondary};
    }
`;
