/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { fontSizes } from 'src/constants/variables';
import { ProductNorms } from '../psaNorms';
import { warningText } from '../../../../style/styles';

type Props = { norms: ProductNorm[]; warningText1: boolean; warningText2: boolean };

export const PsaNorms: React.FC<Props> = ({ norms, warningText1, warningText2 }) => (
    <>
        <label css={styles.label}>PSA Zertifikate</label>
        <ProductNorms norms={norms} tooltipTrigger={'none'} />

        {warningText1 ? (
            <label css={warningText}>
                Wichtig: Die Datierung der Norm im jeweiligen gelieferten Produkt entspricht wegen
                regelmäßiger Re-Zertifizierung der Produkte nicht notwendigerweise der aktuellen
                Version.
            </label>
        ) : null}

        {warningText1 && warningText2 ? (
            <>
                <br />
                <br />
            </>
        ) : null}

        {warningText2 ? (
            <label css={warningText}>
                ** Die Schutzkleidung hat reflektierende Designelemente; sie ist kein Warnschutz.
            </label>
        ) : null}
    </>
);

const styles = {
    label: css`
        && {
            font-size: ${fontSizes.small};
        }
    `,
};
