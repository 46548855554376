function compareCollectionsByNameAndAvailability(a: ProductCollection, b: ProductCollection) {
    if (a.disabled && b.disabled) {
        return a.name.localeCompare(b.name);
    } else if (a.disabled) {
        return 1;
    } else if (b.disabled) {
        return -1;
    }
    return a.name.localeCompare(b.name);
}

// CategoryID: 11 - Arbeitskleidung
const arbeitskleidungCollections: ProductCollection[] = [
    {
        id: '4784',
        parentId: 'C11',
        name: 'DYNAMIC CONSTRUCT',
    },
    {
        id: '5908',
        parentId: 'C11',
        name: 'DYNAMIC IMAGE',
    },
    {
        id: '4785',
        parentId: 'C11',
        name: 'DYNAMIC INDUSTRY',
    },
    {
        id: '3545',
        parentId: 'C11',
        name: 'TRENDLINE',
    },
    {
        id: '1478',
        parentId: 'C11',
        name: 'BASICS',
    },
    {
        id: '7707',
        parentId: 'C11',
        name: 'BASICS AIR',
    },
    {
        id: '7400',
        parentId: 'C11',
        name: 'MOVE',
    },
    {
        id: '6765',
        parentId: 'C11',
        name: 'OUTDOOR',
    },
    {
        id: '7725',
        parentId: 'C11',
        name: 'PEAK',
    },
    {
        id: '5863',
        parentId: 'C11',
        name: 'TRADITION',
    },
    {
        id: '0213',
        parentId: 'C11',
        name: 'IDEAL',
    },
    {
        id: '5337',
        parentId: 'C11',
        name: 'Dynamic Industry Image',
    },
    {
        id: '8065',
        parentId: 'C11',
        name: 'Dynamic Industry Image KFZ',
    },
    {
        id: '8252',
        parentId: 'C11',
        name: 'Dynamic Industry Image KFZ 2',
    },
    {
        id: '8478',
        parentId: 'C11',
        name: 'Dynamic Industry Image KFZ 3',
    },
    {
        id: '6606',
        parentId: 'C11',
        name: 'Gerüstbauer',
    },
    {
        id: '3906',
        parentId: 'C11',
        name: 'ESD Image',
    },
    {
        id: '0159',
        parentId: 'C11',
        name: 'TWINSTAR',
    },
    {
        id: '1884',
        parentId: 'C11',
        name: 'GENERATION CORE',
    },
    {
        id: '11001',
        parentId: 'C11',
        name: 'PERFORMANCE',
        disabled: true,
    },
    {
        id: '11002',
        parentId: 'C11',
        name: 'GENERATION CORE',
        disabled: true,
    },
].sort(compareCollectionsByNameAndAvailability);

// CategoryID: 12 - Schutzkleidung
const schutzkleidungCollections: ProductCollection[] = [
    {
        id: '5522',
        parentId: 'C12',
        name: 'DYNAMIC ALLROUND',
    },
    {
        id: '5858',
        parentId: 'C12',
        name: 'DYNAMIC FLAME',
    },
    {
        id: '5955',
        parentId: 'C12',
        name: 'DYNAMIC FLAME ADVANCED',
    },
    {
        id: '5956',
        parentId: 'C12',
        name: 'DYNAMIC FLAME EXTREME',
    },
    {
        id: '5859',
        parentId: 'C12',
        name: 'DYNAMIC ELEMENTS',
    },
    {
        id: '5467',
        parentId: 'C12',
        name: 'DYNAMIC REFLECT',
    },
    {
        id: '7864',
        parentId: 'C12',
        name: 'OUTDOOR REFLECT',
    },
    {
        id: '9222',
        parentId: 'C12',
        name: 'BASICS REFLECT',
    },
    {
        id: '8340',
        parentId: 'C12',
        name: 'DYNAMIC ALLROUND REFLECT',
    },
    {
        id: '8335',
        parentId: 'C12',
        name: 'DYNAMIC ALLROUND CHEM REFLECT',
    },
    {
        id: '7853',
        parentId: 'C12',
        name: 'DYNAMIC ALLROUND CHEM IMAGE',
    },
    {
        id: '0838',
        parentId: 'C12',
        name: 'SCHWEISSER',
    },
    {
        id: '9227',
        parentId: 'C12',
        name: 'BASICS EPA',
    },
    {
        id: '6669',
        parentId: 'C12',
        name: 'DYNAMIC CONSTRUCT IMAGE',
    },
    {
        id: '0201',
        parentId: 'C12',
        name: 'FLEISCHER',
    },
    {
        id: '5950',
        parentId: 'C12',
        name: 'DYNAMIC ALLROUND ARC',
    },
    {
        id: '5949',
        parentId: 'C12',
        name: 'DYNAMIC ALLROUND CHEM',
    },
    {
        id: '5951',
        parentId: 'C12',
        name: 'DYNAMIC ALLROUND CHEM ARC',
    },
    {
        id: '5957',
        parentId: 'C12',
        name: 'DYNAMIC ELEMENTS PLUS',
    },
    {
        id: '1357',
        parentId: 'C12',
        name: 'IDEAL PROTECT',
    },
    {
        id: '5876',
        parentId: 'C12',
        name: 'IDEAL PROTECT EXTRA',
    },
    {
        id: '3371',
        parentId: 'C12',
        name: 'IDEAL PROTECT CROSSFIRE',
    },
    {
        id: '2158',
        parentId: 'C12',
        name: 'IDEAL PROTECT SPECIAL',
    },
    {
        id: '1313',
        parentId: 'C12',
        name: 'TWINSTAR PROTECT',
    },

    {
        id: '3372',
        parentId: 'C12',
        name: 'TWINSTAR PROTECT CROSSFIRE',
    },
    {
        id: '2384',
        parentId: 'C12',
        name: 'TWINSTAR PROTECT ULTRA',
    },
    {
        id: '3786',
        parentId: 'C12',
        name: 'TWINSTAR PROTECT COMPLETE',
    },
    {
        id: '2157',
        parentId: 'C12',
        name: 'TWINSTAR PROTECT SPECIAL',
    },
    {
        id: '0935',
        parentId: 'C12',
        name: 'COMET (BAUMWOLLE/ARAMID)',
    },
    {
        id: '1486',
        parentId: 'C12',
        name: 'PROBAN',
    },
    {
        id: '3146',
        parentId: 'C12',
        name: 'HI-VIS',
    },
    {
        id: '2362',
        parentId: 'C12',
        name: 'MULTIWEAR',
    },
    {
        id: '12401',
        parentId: 'C12',
        name: 'DYNAMIC OUTDOOR',
        disabled: true,
    },
    {
        id: '12402',
        parentId: 'C12',
        name: 'CLIMA S',
        disabled: true,
    },
    {
        id: '0134',
        parentId: 'C12',
        name: 'ESD',
    },
    {
        id: '2342',
        parentId: 'C12',
        name: 'EXCLUSIVE GLAS',
    },
    {
        id: '1477',
        parentId: 'C12',
        name: 'EXCLUSIVE ALU',
    },
    {
        id: '1480',
        parentId: 'C12',
        name: 'EXCLUSIVE FERRO',
    },
    {
        id: '1614',
        parentId: 'C12',
        name: 'EXCLUSIVE CEMENT',
    },
    {
        id: '9244',
        parentId: 'C12',
        name: 'EPA',
    },
    {
        id: '2425',
        parentId: 'C12',
        name: 'PSA Multinorm 1',
    },
    {
        id: '3685',
        parentId: 'C12',
        name: 'CLIMA S KURZ',
    },
    {
        id: '1444',
        parentId: 'C12',
        name: 'CLIMA S LANG',
    },
].sort(compareCollectionsByNameAndAvailability);

// CategoryID: 13 - Hygienekleidung
const hygienekleidungCollections: ProductCollection[] = [
    {
        id: '0459',
        parentId: 'C13',
        name: 'NUG Ideal',
    },
    {
        id: '0245',
        parentId: 'C13',
        name: 'NUG',
    },
    {
        id: '7726',
        parentId: 'C13',
        name: 'Move clean',
    },
    {
        id: '0198',
        parentId: 'C13',
        name: 'Euroclean',
    },
].sort(compareCollectionsByNameAndAvailability);

// CategoryID: 15 - Gesundheits und Servicekleidung
const gesundheitsUndServicekleidungCollections: ProductCollection[] = [
    {
        id: '5405',
        parentId: 'C15',
        name: 'Ergocare',
    },
    {
        id: '3574',
        parentId: 'C15',
        name: 'Blackline',
    },
    {
        id: '7497',
        parentId: 'C15',
        name: 'Ticino',
    },
    {
        id: '7681',
        parentId: 'C15',
        name: 'Spice',
    },
    {
        id: '5027',
        parentId: 'C15',
        name: 'Amondo',
    },
    {
        id: '7084',
        parentId: 'C15',
        name: 'Basics Business',
    },
    {
        id: '1626',
        parentId: 'C15',
        name: 'Classicline S',
    },
    {
        id: '9297',
        parentId: 'C15',
        name: 'Mewa Pure',
    },
].sort(compareCollectionsByNameAndAvailability);

// CategoryID: 14 - Businesskleidung
const businesskleidungCollections: ProductCollection[] = [
    {
        id: '4277',
        parentId: 'C14',
        name: 'BASICS SLIM FIT',
    },
    {
        id: '2872',
        parentId: 'C14',
        name: 'BASICS COMFORT FIT',
    },
    {
        id: '1972',
        parentId: 'C14',
        name: 'BASICS REGULAR FIT',
    },
    {
        id: '2799',
        parentId: 'C14',
        name: 'OLYMP COMFORT FIT',
    },
    {
        id: '2043',
        parentId: 'C14',
        name: 'OLYMP MODERN FIT',
    },
    {
        id: '1806',
        parentId: 'C14',
        name: 'OLYMP REGULAR FIT',
    },
    {
        id: '14001',
        parentId: 'C14',
        name: 'CASUAL',
        disabled: true,
    },
    {
        id: '14002',
        parentId: 'C14',
        name: 'DANIEL HECHTER',
        disabled: true,
    },
    {
        id: '14003',
        parentId: 'C14',
        name: 'MEWA STRICK',
        disabled: true,
    },
    {
        id: '14004',
        parentId: 'C14',
        name: 'MEWA JEANS',
        disabled: true,
    },
    {
        id: '14005',
        parentId: 'C14',
        name: 'MEWA CLASSIC',
        disabled: true,
    },
    {
        id: '4736',
        parentId: 'C14',
        name: 'GREIFF PREMIUM CF',
    },
    {
        id: '4733',
        parentId: 'C14',
        name: 'GREIFF PREMIUM RF',
    },
    {
        id: '5101',
        parentId: 'C14',
        name: 'GREIFF PREMIUM SF',
    },
    {
        id: '9154',
        parentId: 'C14',
        name: 'GREIFF CASUAL RF',
    },
    {
        id: '9153',
        parentId: 'C14',
        name: 'GREIFF MODERN 37.5 RF',
    },
    {
        id: '9152',
        parentId: 'C14',
        name: 'GREIFF MODERN 37.5 SF',
    },
    {
        id: '8897',
        parentId: 'C14',
        name: 'HECHTER CASUAL',
    },
    {
        id: '9101',
        parentId: 'C14',
        name: 'HECHTER TAILORED',
    },
    {
        id: '8891',
        parentId: 'C14',
        name: 'SCHÖFFEL',
    },
    {
        id: '3299',
        parentId: 'C14',
        name: 'MBP JEANS',
    },
    {
        id: '4762',
        parentId: 'C14',
        name: 'CASUAL',
    },
    {
        id: '7792',
        parentId: 'C14',
        name: 'OLYMP BODY FIT',
    },
    {
        id: '7973',
        parentId: 'C14',
        name: 'OLYMP SUPER SLIM',
    },
    {
        id: '3705',
        parentId: 'C14',
        name: 'OLYMP PILOT',
    },
    {
        id: '6454',
        parentId: 'C14',
        name: 'GREIFF STRICK',
    },
].sort(compareCollectionsByNameAndAvailability);

export const productCollectionsList: ProductCollectionsList = {
    C11: [...arbeitskleidungCollections],
    C12: [...schutzkleidungCollections],
    C13: [...hygienekleidungCollections],
    C14: [...businesskleidungCollections],
    C15: [...gesundheitsUndServicekleidungCollections],
};
