/** @jsxImportSource @emotion/react */
import React from 'react';
import { Input, InputProps } from 'antd';
import { css, SerializedStyles } from '@emotion/react';

export type TimeInputProps = Omit<InputProps, 'value' | 'onChange'> & {
    value?: string;
    onChange?: (value: string) => void;
    suffixCss?: SerializedStyles;
    suffixIcon?: React.ReactNode;
};

export const TimeInput: React.FC<TimeInputProps> = ({
    value,
    onChange,
    placeholder = 'hh:mm',
    suffixIcon,
    suffixCss,
    ...rest
}) => {
    const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const matchTimeFormatChars = /[^\d:]/g;
        const value = event.target.value.replace(matchTimeFormatChars, '');

        let colon = value.match(/:$/) ? ':' : '';
        let [hours, minutes] = value.split(':');

        if (hours.length === 1 && (Number.parseInt(hours) > 2 || !!colon)) {
            hours = hours.padStart(2, '0');
        } else if (hours.length === 2 && Number.parseInt(hours) >= 24) {
            hours = hours.slice(0, 1);
        } else if (hours.length > 2) {
            minutes = hours.slice(2);
            hours = hours.slice(0, 2);
            colon = ':';
        }

        if (hours.length === 2 && minutes !== undefined) {
            colon = ':';
        }

        if (minutes) {
            if (minutes.length === 1 && Number.parseInt(minutes) > 5) {
                minutes = minutes.padStart(2, '0');
            } else if (minutes.length > 2) {
                minutes = minutes.slice(0, 2);
            }
        } else {
            minutes = '';
        }

        onChange?.(`${hours}${colon}${minutes}`);
    };

    return (
        <div css={styles.wrapper}>
            <Input value={value} onChange={onTimeChange} placeholder={placeholder} {...rest} />
            {suffixIcon ? <span css={[styles.suffixWrapper, suffixCss]}>{suffixIcon}</span> : null}
        </div>
    );
};

const styles = {
    wrapper: css`
        position: relative;
    `,
    suffixWrapper: css`
        position: absolute;

        top: 50%;
        transform: translateY(-50%);
        right: 0;
        margin-right: 4px;

        line-height: 1;
    `,
};
