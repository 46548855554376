import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';

export const offerRowStyles = {
    row: css`
        border-bottom: 1px solid ${colors.favourite};
        padding: 17px;
        display: flex;

        justify-content: space-between;
    `,
    content: css`
        width: 60%;
        padding-right: 8px;
    `,
    bottomRow: css`
        display: flex;
        justify-content: space-between;
        width: 100%;
    `,
    date: css`
        color: ${colors.fontPrimaryDark};
        font-size: ${fontSizes.xxSmall};
        font-family: OptimaLTPro-Roman, sans-serif;
    `,
    title: (color: string) => css`
        font-family: OptimaLTPro-Roman, sans-serif;
        font-size: ${fontSizes.mSmall};
        text-transform: uppercase;
        color: ${color};
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    `,
};
