/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { beautifyName, utcToLocalFormat } from 'src/helpers/utils';
import { textOverflowEllipsis } from 'src/style/styles';
import { colors } from 'src/constants/variables';

import { offerRowStyles } from './styles';

import { OfferActions } from './OfferOptions';

type Props = {
    offer: OfferSummary;
    actions: ManageOffer;
};

export const OfferRow: React.FC<Props> = ({ offer, actions }) => (
    <div css={styles.row} data-test={`${beautifyName(offer.name)}_OFFERS_ITEM`}>
        <div css={styles.content}>
            <div
                css={styles.title}
                onClick={() => {
                    actions.preview?.(offer);
                }}
            >
                <span title={offer.name} data-test={`${beautifyName(offer.name)}_OFFERS_ITEM_NAME`}>
                    {offer.name}
                </span>
            </div>
            <div css={styles.bottomRow}>
                <div
                    css={offerRowStyles.date}
                    data-test={`${beautifyName(offer.name)}_OFFERS_ITEM_DATE`}
                >
                    {utcToLocalFormat(offer.createAt, 'DD.MM.YYYY')}
                </div>
                <div
                    css={offerRowStyles.date}
                    data-test={`${beautifyName(offer.name)}_OFFERS_ITEM_TIME`}
                >
                    {utcToLocalFormat(offer.createAt, 'HH:mm:ss')}
                </div>
            </div>
        </div>

        <OfferActions
            onClickPdfDoc={() => actions.getPdf?.(offer.id, offer.name)}
            onClickDuplicate={() => actions.duplicate?.(offer.id)}
            onClickEdit={() => actions.edit?.(offer.id)}
        />
    </div>
);

const styles = {
    row: css`
        ${offerRowStyles.row};
        padding-right: 24px;

        *[role='dialog'] {
            top: unset;
            bottom: 0;
        }
    `,
    content: css`
        ${offerRowStyles.content};
        width: calc(100% - 154px);
    `,
    title: css`
        ${offerRowStyles.title(colors.secondary)};
        ${textOverflowEllipsis};
    `,
    bottomRow: css`
        ${offerRowStyles.bottomRow};
        justify-content: start;

        & > div {
            font-family: 'Open Sans', sans-serif;
        }

        & > div:not(:last-of-type) {
            margin-right: 32px;
        }
    `,
};
