import React, { useEffect, useRef } from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Close } from '../assets/icons';
import { colors, fontSizes } from '../constants/variables';

type DialogBoxProps = {
    title: string;
    text: string;
    closeDialog: () => void;
};

export const PopupDialog: React.FC<DialogBoxProps> = ({ title, text, closeDialog }) => {
    const dialogRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutsideDialogBox(event: MouseEvent): void {
            const targetAsNode = event.target instanceof Node ? event.target : null;

            if (dialogRef.current && !dialogRef.current.contains(targetAsNode)) {
                closeDialog();
            }
        }

        document.addEventListener('mousedown', handleClickOutsideDialogBox);

        return (): void => {
            document.removeEventListener('mousedown', handleClickOutsideDialogBox);
        };
    }, [closeDialog]);

    return (
        <div css={styles.backdrop}>
            <div css={styles.container} ref={dialogRef}>
                <div css={styles.content}>
                    <div css={styles.closeContainer} onClick={closeDialog} onKeyDown={closeDialog}>
                        <Close css={styles.icon} />
                    </div>
                    <p css={styles.title}>{title}</p>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
};

export const styles = {
    closeContainer: css`
        padding-left: 415px;
    `,
    icon: css`
        height: 30px;
        padding: 7px;
        width: 100%;
        cursor: pointer;
        border: 2px solid ${colors.fontMarketingInfo};

        path {
            fill: ${colors.modalIconClose};
        }
    `,

    title: css`
        font-size: ${fontSizes.large};
        font-weight: bold;
        margin-bottom: -5px;
        margin-top: -10px;
    `,

    container: css`
        display: flex;
        padding: 27px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        background: white;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 20%);
        z-index: 1001;
        width: 500px;
        height: 230px;
    `,

    content: css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    `,

    closeButton: css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 16px;
        padding: 6px;
        border-style: solid;
        border-width: 15px;
        // border-color: $c-grey-grey-500;
    `,

    closeButtonIcon: css`
        // fill: $c-grey-grey-500;
    `,

    buttonGroup: css`
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 16px;
        align-self: stretch;
    `,

    primaryButton: css`
        //background: $c-accent-colors-accent;
        flex: 1 0 0;
    `,

    backdrop: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    `,
};
