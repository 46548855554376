/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';

import { colors, fontSizes } from 'src/constants/variables';

type Props = {
    product: Partial<Product>;
    productVariant: ProductVariant;
};

export const ProductInfo: React.FC<Props> = ({ product, productVariant }) => {
    const colorName = useMemo(
        () => product.colors?.filter((color) => color.key === productVariant.colorKey)[0].name,
        [productVariant.colorKey, product.colors],
    );

    return (
        <p>
            <span css={styles.name}>{product.modelName}</span>
            <span css={styles.material}>{`${colorName} / ${productVariant.material}`}</span>
            <span css={styles.key}>{productVariant.key}</span>
        </p>
    );
};

const styles = {
    name: css`
        display: block;
        color: ${colors.fontHeader};
        font-size: ${fontSizes.medium};
        font-family: OptimaLTPro-Roman;
        line-height: 1;
        text-transform: uppercase;
    `,
    material: css`
        display: block;
        color: ${colors.fontPrimaryDark};
        font-size: ${fontSizes.xSmall};
        font-family: OptimaLTPro-Roman;
        line-height: 1;
        text-transform: uppercase;
    `,
    key: css`
        display: block;
        color: ${colors.fontHeader};
        font-size: ${fontSizes.medium};
        font-family: OptimaLTPro-Medium;
        text-transform: uppercase;
    `,
};
