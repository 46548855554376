/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { wideScreenStyles } from 'src/style/styles';
import { ProductsCatalog } from 'src/modules/productsCatalog/components';
import { useSelector } from 'react-redux';
import { getCollectionLoadingStatus } from 'src/modules/productsList/store/productCollectionItemsSelectors';

import { zIndices } from 'src/constants/variables';

type Props = {
    productGroups: ProductGroup[];
    productCategories: ProductCatalogItemsList;
    productCollections: ProductCollectionsList;
    currentCollectionId: string;
    onCollectionSelect: (collection: ProductCollection) => void;
    selectedProducts: CollectionItem[];
    setSelectedProducts: (selectedProducts: CollectionItem[]) => void;
};

export const ProductsCatalogLayout: React.FC<Props> = ({
    productGroups,
    productCategories,
    productCollections,
    currentCollectionId,
    onCollectionSelect,
    selectedProducts,
    setSelectedProducts,
}) => {
    const loading = useSelector(getCollectionLoadingStatus);

    return (
        <>
            {loading && <div css={styles.opacityCover} />}

            <ProductsCatalog
                productGroups={productGroups}
                productCategories={productCategories}
                productCollections={productCollections}
                currentCollectionId={currentCollectionId}
                onCollectionSelect={onCollectionSelect}
                getItemDetailsLink={() => ''}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                customCss={{
                    navigationWrapper: styles.catalogNavigationWrapper,
                    navigationControls: styles.catalogNavigationControls,
                }}
            />
        </>
    );
};

const styles = {
    opacityCover: css`
        background-color: transparent;
        width: calc(100% - 32px);
        height: calc(100% - 168px);
        position: absolute;
        z-index: ${zIndices.zIndex050};
    `,
    catalogNavigationWrapper: css`
        top: 148px;
        height: calc(100vh - 148px);
        ${wideScreenStyles(css`
            top: 168px;
            height: calc(100vh - 168px);
        `)};
    `,
    catalogNavigationControls: css`
        top: 148px;
        ${wideScreenStyles(css`
            top: 168px;
        `)};
    `,
};
