/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from 'react';
import { Col, Row, FormInstance } from 'antd';
import { css } from '@emotion/react';

import { wideScreenStyles, desktopScreenStyles } from 'src/style/styles';
import { setMaterialValue, setVariantColor, setWeightValue } from '../store/productActions';
import { ImageSection, ProductColorChooser } from './imageSection';

import {
    ProductLayoutDataPanel,
    ProductForm,
    ProductLayoutDataPanelProps,
} from './ProductLayoutDataPanel';
import { SLIDES_TO_SHOW } from 'src/components/ColorChooser/ColorChooser';

type Props = {
    product: Product;
    variant: ProductVariant;
    itemValues?: Partial<ItemDetails>;

    availableWeights: number[];
    availableMaterials: string[];

    setColorKey: typeof setVariantColor;
    setWeightValue: typeof setWeightValue;
    setMaterialValue: typeof setMaterialValue;

    onFormSubmit: (values: ProductForm, totalPrice: number) => void;
    button?: (props: any) => React.ReactNode;

    modalProductLayout?: boolean;
    withAcceptChangesButton?: boolean;

    hideLogos?: boolean;
    hidePriceSection?: boolean;
};

type WrapperProps = {
    WithAcceptChangesButton: React.FC<Props>;
};

export const updatePosition = (form: FormInstance, position: any, emblemId: number): void => {
    const emblemOptions = [
        'selectedLogoFrontOptions',
        'selectedLabelOptions',
        'selectedLogoBackOptions',
    ];
    const allValues = form.getFieldsValue();
    let group = '';

    emblemOptions.forEach((option) => {
        if (allValues[option] && Object.keys(allValues[option]).includes(emblemId.toString()))
            group = option;
    });

    const currentValue = allValues[group][emblemId.toString()];

    form.setFieldsValue({
        [group]: {
            [emblemId.toString()]: {
                isSelected: currentValue.isSelected,
                shape: currentValue.shape,
                positionX: position.x,
                positionY: position.y,
            },
        },
    });
};

export const ProductLayout: WrapperProps & React.FC<Props> = ({
    product,
    variant,
    itemValues,
    availableWeights,
    availableMaterials,
    setColorKey,
    setWeightValue,
    setMaterialValue,
    onFormSubmit,
    button,
    withAcceptChangesButton,
    hideLogos = false,
    hidePriceSection = false,
}) => {
    const formRef = useRef<FormInstance<ProductForm>>(null);
    const [slickGoTo, setSlickGoTo] = useState(0);

    const props: ProductLayoutDataPanelProps = {
        product,
        variant,
        itemValues,
        availableWeights,
        availableMaterials,
        setWeightValue,
        setMaterialValue,
        onFormSubmit,
        button,
        setSlickGoTo,
        formRef,
        hideLogos,
        hidePriceSection,
    };

    const slidesToShow =
        product.colors.length < SLIDES_TO_SHOW ? product.colors.length : SLIDES_TO_SHOW;

    return (
        <Row css={styles.row} gutter={[0, 32]}>
            <Col span={24} lg={12} xl={16} css={styles.picture}>
                <ImageSection
                    productVariant={variant}
                    updateFormPosition={(position, emblem) =>
                        formRef.current && updatePosition(formRef.current, position, emblem)
                    }
                    slickGoTo={slickGoTo}
                />
                <ProductColorChooser
                    slidesToShow={slidesToShow}
                    colors={product.colors}
                    onColorChange={setColorKey}
                    currentVariant={variant}
                />
            </Col>

            <Col span={24} lg={12} xl={8} css={styles.details}>
                {withAcceptChangesButton ? (
                    <ProductLayoutDataPanel.WithAcceptChangesButton {...props} />
                ) : (
                    <ProductLayoutDataPanel {...props} />
                )}
            </Col>
        </Row>
    );
};

const WithAcceptChangesButton: React.FC<Props> = (props) => (
    <ProductLayout {...props} withAcceptChangesButton />
);

ProductLayout.WithAcceptChangesButton = WithAcceptChangesButton;

const styles = {
    row: css`
        ${wideScreenStyles(css`
            height: 100%;
            padding-top: 24px;
            position: relative;
        `)};
        ${desktopScreenStyles(css`
            padding-top: 40px;
            position: relative;
        `)};
    `,
    picture: css`
        max-height: calc(100% - 32px);
        ${wideScreenStyles(css`
            height: calc(100% - 32px);
        `)};
    `,
    details: css`
        width: 100%;

        ${wideScreenStyles(css`
            height: 100%;
            overflow: auto;

            padding-right: 32px;
        `)};
    `,
};
