import { createSelector } from 'reselect';

import { RootState } from 'src/store';

const getAuthState = (state: Pick<RootState, 'auth'>) => state.auth;

export const getIsTokenChecked = createSelector(
    getAuthState,
    (authState) => authState.isTokenChecked,
);

export const getCurrentUserName = createSelector(
    getAuthState,
    (authState) => authState.user?.name || '',
);

export const getCurrentUserRole = createSelector(
    getAuthState,
    (authState) => authState.user?.role || null,
);

export const getCurrentUserEmail = createSelector(
    getAuthState,
    (authState) => authState.user?.email,
);
