import { SerializedStyles } from '@emotion/react';
import React from 'react';

import { CustomModal } from 'src/components';
import { ModalFormProps } from 'src/hooks/useModalForm';

import { ContactDetailsValues } from './ContactDetailsForm';
import { ContactDetailsModalBody } from './ContactDetailsModalBody';

export type ContactDetailsModalAdditionalProps = {
    inline?: boolean;
    buttonText: string;
    withDate?: boolean;
    modalCss?: SerializedStyles;
    isButtonValid?: (values: ContactDetailsValues) => boolean;
};

type Props = ModalFormProps<ContactDetailsValues> & ContactDetailsModalAdditionalProps;

export const ContactDetailsModal: React.FC<Props> = ({
    visible,
    onClose,
    inline,
    initial,
    buttonText = '',
    modalCss,
    ...props
}) => {
    const modalProps = {
        visible,
        onClick: onClose,
        destroyOnClose: true,
        modalCss,
    };

    return inline ? (
        <CustomModal.Inline {...modalProps}>
            <ContactDetailsModalBody initialValues={initial} buttonText={buttonText} {...props} />
        </CustomModal.Inline>
    ) : (
        <CustomModal {...modalProps}>
            <ContactDetailsModalBody initialValues={initial} buttonText={buttonText} {...props} />
        </CustomModal>
    );
};
