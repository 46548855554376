import sample_1 from './100_19067.jpg';
import sample_2 from './100_19081.jpg';
import sample_3 from './100_19086.jpg';
import sample_4 from './100_38904.jpg';

export const dynamicIndustryImpressions = {
    sample_1,
    sample_2,
    sample_3,
    sample_4,
};
