import clothes from './clothes.png';
import arbeitskleidung from './arbeitskleidung.jpg';
import businesskleidung from './businesskleidung.jpg';
import gesundheitServicekleidung from './gesundheitServicekleidung.jpg';
import hygienekleidung from './hygienekleidung.jpg';
import schutzkleidung from './schutzkleidung.jpg';

export const clothesImage = clothes;
export const collectionImages = {
    arbeitskleidungImage: arbeitskleidung,
    businesskleidungImage: businesskleidung,
    gesundheitsUndServicekleidungImage: gesundheitServicekleidung,
    hygienekleidungImage: hygienekleidung,
    schutzkleidungImage: schutzkleidung,
};
