/** @jsxImportSource @emotion/react */
import React from 'react';

import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { Menu } from 'src/assets/icons';
import { colors } from 'src/constants/variables';

type Props = {
    onClickIcon: () => void;
};

export const BrandHeaderIcon: React.FC<Props> = ({ onClickIcon }) => (
    <Icon component={Menu} css={styles.menuIcon} onClick={onClickIcon} />
);

const styles = {
    menuIcon: css`
        svg {
            margin-left: 21px;
            width: 28px;
            height: 36px;

            g path {
                stroke: ${colors.iconTime};
                fill: ${colors.iconTime};
            }

            text {
                fill: ${colors.iconTime};
            }
        }
    `,
};
