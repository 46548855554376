/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import Icon from '@ant-design/icons';
import { css, SerializedStyles } from '@emotion/react';

import { Trash } from 'src/assets/icons';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { colors, sizes } from 'src/constants/variables';

import { CustomConfirm, CustomConfirmProps } from '../CustomConfirm';

interface Props extends CustomConfirmProps {
    message: string;
    customCss?: SerializedStyles;
}

export const DeletePrompt: React.FC<Props> = ({ message, onCancel, onConfirm, customCss }) => {
    const ref = useOutsideClick(
        useCallback(() => {
            onCancel();
        }, [onCancel]),
    );

    return (
        <CustomConfirm
            ref={ref}
            css={customCss}
            onCancel={onCancel}
            onConfirm={onConfirm}
            useOverlay
            data-test={'deletePromptWrapper'}
        >
            <div css={styles.promptMessage} data-test={'deletePromptMessage'}>
                <Icon component={Trash} css={styles.icon} />
                <span>{message}</span>
            </div>
        </CustomConfirm>
    );
};

const promptIconCss = css`
    width: 36px;
    height: 36px;
    padding: 10px;
    border-radius: 50%;
    margin-right: ${sizes.small};

    svg {
        width: 16px;
        height: 16px;
    }
`;

const styles = {
    promptMessage: css`
        display: flex;
        margin-bottom: 18px;
    `,
    icon: css`
        ${promptIconCss};
        background: ${colors.secondary};

        svg path {
            fill: ${colors.primary};
        }
    `,
};
