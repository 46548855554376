/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Form } from 'antd';

import { colors, fontSizes } from 'src/constants/variables';
import { Services, ServicesProps } from '../../Offer/Services/Services';
import { HorizontalLineElement } from './HorizontalLineElement';
import { TotalPricePerWeekElement } from './TotalPricePerWeekElement';

export type SecondColumnProps = Pick<ServicesProps, 'selectedServices'> & {
    offerServices: ServicesProps['services'];
    setSelectedServices: ServicesProps['setServices'];
    handleTotalPriceChange: (isSet?: boolean) => void;
    handleWeeklyTotalPriceAllItemsChange: (isSet?: boolean) => void;
};

export const SecondColumn: React.FC<SecondColumnProps> = ({
    offerServices,
    selectedServices,
    setSelectedServices,
    handleTotalPriceChange,
}) => (
    <>
        <Services
            services={offerServices}
            selectedServices={selectedServices}
            setServices={setSelectedServices}
        />
        <HorizontalLineElement />
        <Form.Item shouldUpdate css={styles.checkboxWrapper}>
            {({ getFieldValue }) => (
                <TotalPricePerWeekElement
                    checked={getFieldValue('totalPricePerWeek')}
                    handleTotalPriceChange={handleTotalPriceChange}
                    selectedServices={selectedServices}
                />
            )}
        </Form.Item>
    </>
);

const styles = {
    checkboxWrapper: css`
        margin: 0;
    `,
    checkbox: css`
        && .ant-checkbox {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            margin-top: 2px;
            margin-right: 13px;
            border-color: ${colors.iconTime};
        }

        && .ant-checkbox + span {
            padding-left: 0;
            padding-right: 8px;
            font-size: ${fontSizes.mSmall};
            color: ${colors.newOfferCheckbox};
            font-family: OptimaLTPro-Medium, sans-serif;
            user-select: none;
        }

        && .ant-checkbox-inner {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            background-color: unset;
            color: ${colors.newOfferCheckbox};
        }
    `,
};
