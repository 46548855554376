/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import InputNumber, { InputNumberProps } from 'antd/lib/input-number';
import { css } from '@emotion/react';
import * as uuid from 'uuid';

import { colors, fontSizes } from 'src/constants/variables';
import { useDebouncedCallback } from 'use-debounce';

type Props = Omit<InputNumberProps, 'onChange'> & {
    onChange?: (value?: number) => void;
    addComma?: boolean;
    currencySuffix?: boolean;
    allowEmpty?: boolean;
    allowNegative?: boolean;
    debounce?: number;
};

export const fourCharacters = (value?: string): string => value?.substring(0, 4) || '';

export const NumberInput = React.forwardRef<InputNumberProps, Props>(
    (
        {
            value,
            onChange,
            currencySuffix,
            required,
            addComma,
            parser,
            allowEmpty = false,
            allowNegative = true,
            debounce = 0,
            id,
            ...rest
        },
        ref,
    ) => {
        const idRef = useRef(uuid.v4());
        if (id) {
            idRef.current = id;
        }
        const handleChange = useDebouncedCallback((value?: number | string | null) => {
            if (typeof value === 'string' && value !== '') {
                return;
            }

            if (allowEmpty && (value === '' || value === null)) {
                return onChange?.();
            }

            if (!allowNegative && Number(value) < 0) {
                return onChange?.();
            }

            return onChange?.(Number(value));
        }, debounce);

        const initial0CommaParser = (value?: string) => {
            if (value?.length === 2 && value?.startsWith('0')) {
                return value.slice(0, 1) + '.' + value.slice(1, value.length);
            }

            handleChange(value);
            return value || '';
        };

        const onBlur = () => {
            const ele = document.getElementById(idRef.current) as HTMLInputElement;
            if (ele) {
                const curValue = ele.value;
                ele.value = '';
                ele.value = curValue;
            }
        };

        return (
            <InputNumber
                id={idRef.current}
                onBlur={onBlur}
                ref={ref}
                type="number"
                value={value}
                onChange={handleChange}
                css={[
                    styles.input,
                    currencySuffix && styles.currencySuffix,
                    required && styles.isRequired,
                ]}
                parser={(displayValue) => {
                    const preParsedValue = addComma
                        ? initial0CommaParser(displayValue)
                        : displayValue || '';

                    return parser ? parser(preParsedValue) : preParsedValue;
                }}
                data-test={'amountInput'}
                {...rest}
            />
        );
    },
);

const styles = {
    input: css`
        width: 100%;
        height: 100%;

        background-color: transparent;
        color: ${colors.fontPrimaryDark};
        font-size: ${fontSizes.medium};

        border: none;
        border-radius: 0;
        box-shadow: none;

        .ant-input-number-handler-wrap {
            display: none;
        }

        :hover,
        :focus {
            box-shadow: none;
        }

        input {
            height: 56px;
            line-height: 56px;
            font-family: OptimaLTPro-Medium, sans-serif;
            text-align: center;
        }
    `,
    isRequired: css`
        &:before {
            content: '*';
            position: absolute;
            top: 0;
            right: 0;
            color: ${colors.secondary};
            font-family: OptimaLTPro-Medium, sans-serif;
            font-size: ${fontSizes.xSmall};
        }
    `,
    currencySuffix: css`
        :after {
            content: '€';
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            font-family: OptimaLTPro-Medium, sans-serif;
        }

        input {
            padding: 0 32px 0 16px;
        }
    `,
};
