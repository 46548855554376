/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    title: string;
};

export const ProductsSectionColumnTitle: React.FC<Props> = ({ title }) => (
    <div css={styles.wrapper} data-test={`${beautifyName(title)}_TAB_HEAD`}>
        {title}
    </div>
);

const styles = {
    wrapper: css`
        background-color: ${colors.primary};
        height: 100%;
        text-align: center;
        font-size: ${fontSizes.xxxSmall};
        color: ${colors.backgroundDark};
        font-weight: bold;
        padding-bottom: 18px;
    `,
};
