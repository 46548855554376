/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { horizontalMark, desktopScreenStyles } from 'src/style/styles';
import { colors, fontSizes } from 'src/constants/variables';

export const Header: React.FC = ({ children, ...props }) => (
    <h2 css={styles} {...props}>
        {children}
    </h2>
);

export const basicHeaderStyles = css`
    padding-top: 32px;
    margin: 32px 16px;

    color: ${colors.fontHeader};
    font-family: OptimaLTPro-Roman;
    font-size: ${fontSizes.xLarge};
    line-height: 1;
`;

const styles = css`
    ${basicHeaderStyles};
    text-transform: uppercase;

    ${horizontalMark()};

    ${desktopScreenStyles(css`
        margin: 32px;
    `)}
`;
