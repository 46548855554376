/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';

import { CollectionSelectorProps } from './types';
import { beautifyName } from '../../../../helpers/utils';

export const CollectionsList: React.FC<CollectionSelectorProps> = ({
    collectionsList = [],
    currentCollectionId,
    onCollectionSelect,
}) => (
    <div css={styles.wrapper} data-test={'collectionListWrapper'}>
        {collectionsList
            .filter((collection) => !collection.disabled && collection.id !== currentCollectionId)
            .map((collection) => (
                <React.Fragment key={collection.id}>
                    <div
                        css={[styles.item, styles.font]}
                        onClick={() => onCollectionSelect?.(collection)}
                        data-test={`${beautifyName(collection.name)}_COLLECTION_ITEM`}
                    >
                        {collection.name}
                    </div>
                    <div css={styles.divider} />
                </React.Fragment>
            ))}
    </div>
);

const styles = {
    wrapper: css`
        margin-top: 36px;
    `,
    font: css`
        text-transform: uppercase;
        text-align: center;
        font-family: OptimaLTPro-Roman, sans-serif;
        font-size: ${fontSizes.xxSmall};
    `,
    item: css`
        width: 100%;
        padding: 8px 0;
        color: ${colors.backgroundDark};
        cursor: pointer;
    `,
    divider: css`
        width: 100%;
        margin: 0;
        border: 0;
        border-top: 1px solid ${colors.secondaryDark};

        &:last-of-type {
            display: none;
        }
    `,
};
