/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { PdfDoc, Duplicate, Edit } from 'src/assets/icons';
import { colors } from 'src/constants/variables';

type ActionsProps = {
    onClickPdfDoc: () => void;
    onClickDuplicate: () => void;
    onClickEdit: () => void;
};

export const OfferActions: React.FC<ActionsProps> = ({
    onClickPdfDoc,
    onClickDuplicate,
    onClickEdit,
}) => (
    <div role={'menu'} css={styles.container} data-test={`offersItemActionsWrapper`}>
        <Icon
            role={'menuitem'}
            component={PdfDoc}
            css={styles.iconAction}
            onClick={onClickPdfDoc}
        />
        <Icon
            component={Duplicate}
            role={'menuitem'}
            css={[styles.iconAction, styles.iconGray]}
            onClick={onClickDuplicate}
        />
        <Icon
            component={Edit}
            role={'menuitem'}
            css={[styles.iconAction, styles.iconGray]}
            onClick={onClickEdit}
        />
    </div>
);

const styles = {
    container: css`
        display: flex;
        position: relative;
        align-items: center;
        overflow-y: visible;

        .anticon {
            cursor: pointer;
            position: relative;

            svg {
                width: auto;
                height: 24px;
            }
        }

        .anticon:last-of-type {
            svg {
                margin-right: 0;
            }
        }
    `,
    menu: css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: 22px;
        height: 100%;
        padding: 4px 32px 4px 0;
        overflow-x: hidden;

        ::before {
            content: '';
            opacity: 97%;
            background: ${colors.background};
            top: 0;
            height: 100%;
            left: 0;
            width: 100%;
            position: absolute;
        }

        *[role='menuitem']:first-of-type {
            margin-left: 8px;
        }
    `,
    menuInline: css``,
    iconAction: css`
        svg {
            margin-right: 32px;
        }
    `,
    iconGray: css`
        svg {
            path {
                fill: ${colors.fontSecondary};
            }
        }
    `,
};
