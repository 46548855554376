/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { GroupColumn } from './GroupColumn';
import { CategoryColumn } from './CategoryColumn';
import { CollectionColumn } from './CollectionColumn';
import { NavigationProps } from './types';

export const listStates = {
    GROUP: 'group',
    CATEGORY: 'category',
    COLLECTION: 'collection',
};

type Props = NavigationProps & {
    currentList: string;
};

export const NavigationForMobile: React.FC<Props> = ({
    productGroups,
    productCollections,
    selectedCollectionId,
    productCategories,
    onCollectionSelect,
    onClose,
    currentGroupId,
    currentList,
}) => {
    const firstGroupId = productGroups[0].id;
    const firstCategoryId = productCategories[firstGroupId][0].id;
    const [selectedGroupId, setSelectedGroupId] = useState<string>(firstGroupId);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
    const [currentListState, setCurrentListState] = useState<string>(
        currentList || listStates.GROUP,
    );

    const onCollectionClick = (collection: ProductCatalogItem) => {
        onCollectionSelect(collection);
        onClose();
    };

    switch (currentListState) {
        case listStates.GROUP:
            return (
                <GroupColumn
                    productGroups={productGroups}
                    onSelect={(id) => {
                        setSelectedGroupId(id);
                        setCurrentListState(listStates.CATEGORY);
                    }}
                />
            );
        case listStates.CATEGORY:
            return (
                <CategoryColumn
                    productCategories={productCategories}
                    onSelect={(id) => {
                        setSelectedCategoryId(id);
                        setCurrentListState(listStates.COLLECTION);
                    }}
                    selectedGroup={selectedGroupId || currentGroupId || firstGroupId}
                    selectedCategory={selectedCategoryId || firstCategoryId}
                />
            );
        case listStates.COLLECTION:
            return (
                <CollectionColumn
                    productCollections={productCollections}
                    selectedCategoryId={selectedCategoryId || firstCategoryId}
                    selectedCollectionId={selectedCollectionId}
                    onCollectionClick={onCollectionClick}
                />
            );
        default:
            return null;
    }
};
