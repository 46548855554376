export type Casted<T, S> = { [P in keyof T]: S };

type Action<T extends string, P> = { type: T; payload: P };

export const createAction = <T extends string, P>(type: T, payload: P): Action<T, P> => ({
    type,
    payload,
});

export type ActionTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<
    T[keyof T]
>;

export type AsyncResource<T> = { data: T | null; loading: boolean; error: boolean };

export const defaultAsyncResource = { data: null, loading: false, error: false };

export type MapObjectValuesToNullable<T> = { [K in keyof T]: T[K] | null };

export type ItemWarnings = { [key: string]: boolean };

export type JsonObject = { [key: string]: any };

export type VariantItem = {
    modelId: string;
    variantKey: string;

    // @deprecated
    psa: boolean;
    norms: ProductNorm[];

    itemsPerWearer: number | null;

    basePrice: number;
    surcharge?: number;
    labelPrice?: number;
    logoBackPrice?: number;
    logoFrontPrice?: number;
    stampPrice?: number;
    totalPrice: number;
    amountOfWearers?: number;

    //To be clarified on offer level
    enNorm?: string;

    cartItemId: number | null;
    offerItemId: number | null;
    category: ModelCategory;
    modelName: string;
    variantName: string;
    variantMaterial?: string;
    variantWeight?: number;
    variantColor: ProductColor;
    imageLink: string;
    discontinued: boolean;
};
