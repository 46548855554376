/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { Row } from 'antd';

import { navigationStyles } from './styles';
import { SerializedStyles } from '@emotion/react';

type Props = {
    visible: boolean;
    onClose: () => void;
    customCss?: SerializedStyles;
};

export const NavigationWrapper: React.FC<Props> = ({ visible, onClose, customCss, children }) => {
    const wrapperRef = useRef<HTMLSpanElement>(null);
    const [parentWidth, setParentWidth] = useState(0);

    const getParentWidth = () => {
        if (wrapperRef.current?.parentElement) {
            const parentComputedStyles = getComputedStyle(wrapperRef.current.parentElement);
            const parentWidth =
                wrapperRef.current.parentElement.clientWidth -
                Number.parseInt(parentComputedStyles.paddingRight) -
                Number.parseInt(parentComputedStyles.paddingLeft);
            setParentWidth(parentWidth);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', getParentWidth);
        return () => {
            window.removeEventListener('resize', getParentWidth);
        };
    }, []);

    useLayoutEffect(() => {
        getParentWidth();
    }, [visible]);

    return (
        <React.Fragment>
            <span ref={wrapperRef} />
            {visible ? (
                <div css={[navigationStyles.modal(parentWidth), customCss]}>
                    <div css={navigationStyles.background} onClick={onClose} />
                    <Row gutter={0} onClick={onClose} css={navigationStyles.mainRow}>
                        {children}
                    </Row>
                </div>
            ) : null}
        </React.Fragment>
    );
};
