import { ThunkAction } from 'src/store';
import { createAction, ActionTypes } from 'src/helpers/types';

import { productCollectionItemsService } from './productCollectionItemsService';

export const setCollectionItemsLoading = () => createAction('COLLECTION_ITEMS_LOADING', null);

export const setCollectionItemsError = () => createAction('COLLECTION_ITEMS_ERROR', null);

export const setCollectionItemsList = (id: string, name: string, data: CollectionItem[]) =>
    createAction('SET_COLLECTION_ITEMS_DATA', { id, name, data });

export const clearCollectionItemsList = () => createAction('CLEAR_COLLECTION_ITEMS_DATA', null);

export const fetchCollectionItemsList = (id: string): ThunkAction => async (dispatch) => {
    try {
        dispatch(setCollectionItemsLoading());

        const result = await productCollectionItemsService.fetchCollectionItemsList(id);

        if (!result) {
            dispatch(setCollectionItemsError());
        }

        dispatch(setCollectionItemsList(id, result.name, result.modelInfos));
    } catch (error) {
        dispatch(setCollectionItemsError());
    }
};

export const collectionItemsActions = {
    setCollectionItemsList,
    clearCollectionItemsList,
    setCollectionItemsError,
    setCollectionItemsLoading,
};
export type CollectionItemsActionsType = ActionTypes<typeof collectionItemsActions>;
