/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { CloseButton, CloseButtonProps } from 'src/components/Buttons/CloseButton';
import { colors, fontSizes } from 'src/constants/variables';

import { NAVIGATION_ELEMENTS } from 'src/modules/offer/components/OfferNavigation/navigationElements';

type Props = CloseButtonProps & {
    creationDate: string;
    creationTime: string;
    offerName: string;
};

export const Header: React.FC<Props> = ({ onClick, creationDate, creationTime, offerName }) => {
    const previewPageParent = NAVIGATION_ELEMENTS.find(
        (item) => item.link === window.location.pathname,
    );

    return (
        <div css={styles.wrapper}>
            <div>
                <div css={styles.title}>{previewPageParent?.name} - KMU ANSCHREIBEN</div>

                <div css={styles.details}>
                    <div css={styles.name}>{offerName}</div>
                    <div css={styles.date}>
                        <span>{creationDate}</span>
                        <span css={styles.time}>{creationTime}</span>
                    </div>
                </div>
            </div>

            <CloseButton onClick={onClick} />
        </div>
    );
};

const styles = {
    wrapper: css`
        top: 0;
        position: sticky;

        padding: 40px 32px;
        background-color: ${colors.primary}F7;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    `,
    title: css`
        color: ${colors.fontHeader};
        font-size: ${fontSizes.mSmall};
        line-height: 1;
        margin-bottom: 16px;

        min-width: 437px;

        font-family: 'Optima LT Pro DemiBold', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
    `,
    name: css`
        color: ${colors.fontHeader};
        margin-bottom: 4px;

        font-family: OptimaLTPro-Roman, sans-serif;
        text-transform: uppercase;
        line-height: 1;
        font-size: ${fontSizes.mSmall};
    `,
    details: css`
        padding: 9px 0 9px 16px;
        border-top: 1px solid ${colors.favourite};
        border-bottom: 1px solid ${colors.favourite};
    `,
    date: css`
        font-size: ${fontSizes.xxSmall};
        color: ${colors.fontPrimaryDark};
    `,
    time: css`
        margin-left: 17px;
    `,
};
