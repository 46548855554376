/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Close } from 'src/assets/icons';
import { colors, fontSizes } from 'src/constants/variables';

export type CloseButtonProps = {
    onClick: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => (
    <div css={styles.wrapper}>
        <div onClick={onClick} role="button">
            <Close css={styles.icon} />
        </div>
    </div>
);

const styles = {
    wrapper: css`
        display: flex;
        align-items: center;
    `,
    info: css`
        margin-right: 34px;
        font-size: ${fontSizes.xxSmall};
        color: ${colors.secondaryDark};
    `,
    icon: css`
        height: 18px;
        width: 18px;
        cursor: pointer;
        opacity: 0.5;
        path {
            fill: ${colors.modalIconClose};
        }
    `,
};
