import React from 'react';
import { Image, Page, StyleSheet, View, Text, Link } from '@react-pdf/renderer';
import Page2Img from 'src/assets/images/pdfBackgrounds/PDF_page_2@2x.jpg';
import QRImg from 'src/assets/images/offer/qr.png';

const styles = StyleSheet.create({
    section: {
        position: 'relative',
        minHeight: '100%',
        height: '100%',
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        height: '100%',
        width: '100%',
    },
    urlContainer: {
        position: 'absolute',
        top: '17px',
        left: '80%',
        width: '120px',
    },
    qr: {
        width: '80px',
        height: '80px',
    },
    moreInfoText: {
        fontSize: '7px',
        fontFamily: 'GebauerRegular',
        color: 'white',
    },
    link: {
        color: 'white',
        textDecoration: 'none',
    },
});

export const PageTwo: React.FC = () => (
    <Page size="A4">
        <View style={styles.section}>
            <Image style={styles.pageBackground} src={Page2Img} />
            <div style={styles.urlContainer}>
                <Image style={styles.qr} src={QRImg} />
                <Text style={{ ...styles.moreInfoText, paddingTop: '5px' }}>
                    Mehr zum Mewa-System aufrufen - Hier geht’s zum Video!
                </Text>
                <Text style={{ ...styles.moreInfoText, paddingTop: '1px' }}>
                    <Link style={styles.link} src="https://mkt.mewa.de/das-mewa-system/">
                        mkt.mewa.de/das-mewa-system/
                    </Link>
                </Text>
            </div>
        </View>
    </Page>
);
