import { ProductForm, SelectedOptions } from './components';

const DEFAULT_ITEMS_PER_WEARER = 1;

export const getCartItemTotalPrice = ({
    basePrice,
    surcharge = 0,
    labelStamp = false,
    labelStampPrice = 0,
    selectedLabelPrice = 0,
    selectedLabelOptions = [],
    selectedLogoBackPrice = 0,
    selectedLogoBackOptions = [],
    selectedLogoFrontPrice = 0,
    selectedLogoFrontOptions = [],
    itemsPerWearer = DEFAULT_ITEMS_PER_WEARER,
}: ProductForm): number =>
    calculateItemTotalPrice({
        basePrice,
        surcharge,
        labelStampPrice: labelStamp ? labelStampPrice : undefined,
        selectedLabelPrice: hasSelectedOption(selectedLabelOptions) ? selectedLabelPrice : 0,
        selectedLogoBackPrice: hasSelectedOption(selectedLogoBackOptions)
            ? selectedLogoBackPrice
            : 0,
        selectedLogoFrontPrice: hasSelectedOption(selectedLogoFrontOptions)
            ? selectedLogoFrontPrice
            : 0,
        itemsPerWearer: itemsPerWearer || DEFAULT_ITEMS_PER_WEARER,
    });

export const calculateItemTotalPrice = ({
    basePrice,
    surcharge = 0,
    labelStampPrice = 0,
    selectedLabelPrice = 0,
    selectedLogoBackPrice = 0,
    selectedLogoFrontPrice = 0,
    itemsPerWearer,
}: Partial<
    Pick<
        ProductForm,
        | 'basePrice'
        | 'surcharge'
        | 'labelStampPrice'
        | 'selectedLabelPrice'
        | 'selectedLogoBackPrice'
        | 'selectedLogoFrontPrice'
        | 'itemsPerWearer'
    >
>): number => {
    if (!basePrice || !itemsPerWearer) return 0;

    const totalItemPrice =
        basePrice +
        labelStampPrice +
        selectedLabelPrice +
        selectedLogoBackPrice +
        selectedLogoFrontPrice;

    const itemsPrice = itemsPerWearer * totalItemPrice;

    return Math.round((itemsPrice + surcharge) * 100) / 100;
};

const hasSelectedOption = (labels?: SelectedOptions): boolean =>
    Object.entries(labels || {})
        .filter(([_, value]) => value?.shape && value?.isSelected)
        .map(([_, value]) => value).length > 0;
