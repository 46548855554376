import { createSelector } from 'reselect';

import { getProductsCatalogState } from '../productsCatalogSelectors';

const getProductCategoriesState = createSelector(
    getProductsCatalogState,
    (productsCatalogState) => productsCatalogState.categories,
);

export const getProductCategoriesList = createSelector(
    getProductCategoriesState,
    (productCategoriesState) => productCategoriesState.data,
);

export const getProductCategoriesLoadingStatus = createSelector(
    getProductCategoriesState,
    (productCategoriesState) => productCategoriesState.loading,
);

export const getProductCategoriesLoadingError = createSelector(
    getProductCategoriesState,
    (productCategoriesState) => productCategoriesState.error,
);

export const getProductCategoriesByGroupId = (groupId: number) =>
    createSelector(
        getProductCategoriesList,
        (productCategoriesList) => productCategoriesList && productCategoriesList[groupId],
    );
