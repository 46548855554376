import { ResponsiveObject } from '@ant-design/react-slick';
import { breakpoints } from 'src/constants/variables';

export const colorChooserResponsiveRules: ResponsiveObject[] = [
    { breakpoint: Number.parseInt(breakpoints.small) - 1, settings: { slidesToShow: 5 } },
    { breakpoint: Number.parseInt(breakpoints.medium) - 1, settings: { slidesToShow: 7 } },
    { breakpoint: Number.parseInt(breakpoints.large) - 1, settings: { slidesToShow: 10 } },
    { breakpoint: Number.parseInt(breakpoints.xLarge) - 1, settings: { slidesToShow: 5 } },
    { breakpoint: Number.parseInt(breakpoints.xxLarge) - 1, settings: { slidesToShow: 6 } },
    { breakpoint: 9999, settings: { slidesToShow: 7 } }, // since Infinity is not properly handled
];
