/** @jsxImportSource @emotion/react */
import React from 'react';

import { NumberInput, simpleInputCss } from 'src/components/DataInputs';
import { fourCharacters } from 'src/components/DataInputs/NumberInput';
import { DEFAULT_AMOUNT_OF_WEARERS } from 'src/constants/defaults';

type ItemsPerWearerCartInputProps = {
    value?: number;
    onChange?: (value?: number) => void;
    handleChange?: (value?: number) => void;
    required?: boolean;
    allowEmpty?: boolean;
};

const AmountInput: React.FC<ItemsPerWearerCartInputProps> = ({
    value,
    onChange,
    handleChange,
    required,
    allowEmpty,
}) => {
    const onChangeValue = (value?: number) => {
        onChange?.(value);
        handleChange?.(value);
    };

    return (
        <NumberInput
            min={DEFAULT_AMOUNT_OF_WEARERS}
            precision={0}
            placeholder="0"
            css={simpleInputCss}
            parser={fourCharacters}
            onChange={onChangeValue}
            value={value}
            allowNegative={false}
            debounce={200}
            required={required}
            allowEmpty={allowEmpty}
            data-test={'amountInput'}
        />
    );
};

export default AmountInput;
