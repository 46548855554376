/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { ItemWarnings, JsonObject, VariantItem } from 'src/helpers/types';
import { ProductRow } from './ProductRow';
import { colors } from 'src/constants/variables';

export type FillProductsSectionProps = {
    offerItems: VariantItem[];
    onOfferItemClick: (itemId: number, item: VariantItem) => void;
    onRemove?: (itemId: number) => void;
    onInlineChange: (item: VariantItem, value?: JsonObject) => void;
    colorWarnings: ItemWarnings;
    weightWarnings: ItemWarnings;
};

export const FillProductsSection: React.FC<FillProductsSectionProps> = ({
    offerItems,
    onOfferItemClick,
    onRemove,
    onInlineChange,
    colorWarnings,
    weightWarnings,
}) => (
    <>
        {offerItems.map((item: VariantItem) => {
            const itemId = item.cartItemId || item.offerItemId;
            return (
                <div css={styles.rowWrapper} key={itemId} data-test={'productRowWrapper'}>
                    <ProductRow
                        product={item}
                        onOfferItemClick={() => onOfferItemClick(itemId!, item)}
                        onRemove={onRemove && (() => onRemove?.(itemId!))}
                        onInlineChange={onInlineChange}
                        warnings={[colorWarnings[itemId!], weightWarnings[itemId!]]}
                        form
                    />
                </div>
            );
        })}
    </>
);

const styles = {
    rowWrapper: css`
        border-radius: 5px;

        &:nth-of-type(odd) {
            background-color: ${colors.primary}
        }

        &:nth-of-type(even) {
            background-color: ${colors.favourite}40
        }
    }
    `,
};
