/** @jsxImportSource @emotion/react */
import React from 'react';
import { without } from 'ramda';

import { ServicesDropdown } from 'src/modules/offer/components/OfferServices/ServicesDropdown';
import { ServiceItem } from './ServiceItem';

export type ServicesProps = {
    services: WardrobeOption[];
    selectedServices: number[];
    setServices: (value: number[]) => void;
};

export const Services: React.FC<ServicesProps> = ({ services, selectedServices, setServices }) => {
    const onServiceRemove = (serviceId: WardrobeOption['id']) =>
        setServices(without([serviceId], selectedServices));

    return (
        <div>
            <ServicesDropdown
                services={services}
                selectedServices={selectedServices}
                setSelectedServices={setServices}
                horizontalLineAfter={false}
                label="SERVICELEISTUNGEN &#10;(je Mitarbeiter pro Woche)"
            />
            {services
                .filter((service) => selectedServices.indexOf(service.id) >= 0)
                .map((service) => (
                    <ServiceItem
                        key={service.id}
                        serviceId={service.id}
                        serviceLabel={service.label}
                        onServiceRemove={onServiceRemove}
                    />
                ))}
        </div>
    );
};
