import { AsyncResource, defaultAsyncResource } from 'src/helpers/types';
import { OfferActions } from '../actions/offerActions';

export type OffersListState = AsyncResource<OfferSummary[]>;

const initialState: OffersListState = defaultAsyncResource;

export function offersListReducer(state = initialState, action: OfferActions): OffersListState {
    switch (action.type) {
        case 'OFFER|FETCH_OFFERS_LIST_REQUEST':
            return {
                ...defaultAsyncResource,
                loading: true,
            };
        case 'OFFER|FETCH_OFFERS_LIST_SUCCESS':
            return {
                data: action.payload,
                error: false,
                loading: false,
            };
        case 'OFFER|FETCH_OFFERS_LIST_FAILURE':
            return {
                ...state,
                error: true,
                loading: false,
            };
    }

    return state;
}
