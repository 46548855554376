/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

import homeScreenBackground from 'src/assets/images/home_screen_background.png';
import { wideScreenStyles } from 'src/style/styles';

import { ImageButton } from './ImageButton';

export type NavigationControlsProps = {
    currentGroupName?: string;
    onClickCurrentGroup: () => void;

    currentCategoryName?: string;
    currentCategoryImageUrl?: string;
    onClickCurrentCategory: () => void;

    customCss?: SerializedStyles;
    isNavigationOpen?: boolean;
};

export const NavigationControls: React.FC<NavigationControlsProps> = ({
    currentGroupName,
    onClickCurrentGroup,
    currentCategoryName,
    currentCategoryImageUrl,
    onClickCurrentCategory,
    customCss,
}) => (
    <div css={[styles.wrapper(), customCss]}>
        {currentGroupName ? (
            <ImageButton
                label={currentGroupName}
                onClick={onClickCurrentGroup}
                imageUrl={homeScreenBackground}
            />
        ) : null}
        {currentCategoryName ? (
            <ImageButton
                label={currentCategoryName}
                onClick={onClickCurrentCategory}
                imageUrl={currentCategoryImageUrl}
                secondary
            />
        ) : null}
    </div>
);

const styles = {
    wrapper: () => css`
        ${wideScreenStyles(css`
            top: 115px;
            position: sticky;
        `)};
    `,
};
