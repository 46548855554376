/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { CustomModal, Header } from 'src/components';
import { ModalFormProps } from 'src/hooks/useModalForm';
import { offerUserCustomerOverviewStyles } from 'src/modules/offer/components/styles';

import { CustomerDetailsForm, CustomerDetailsValues } from './CustomerDetailsForm';

type Props = ModalFormProps<CustomerDetailsValues>;

export const CustomerDetailsModal: React.FC<Props> = ({ visible, initial, onClose, onSubmit }) => (
    <CustomModal.Inline modalCss={styles.modal} visible={visible} onClick={onClose} destroyOnClose>
        <Header css={styles.header} data-test={'customerContactDetailsTitle'}>INTERESSENT / KUNDE INFORMATIONEN</Header>
        <CustomerDetailsForm initialValues={initial} onSubmit={onSubmit} />
    </CustomModal.Inline>
);

const styles = {
    modal: css`
        .ant-modal-body {
            padding-top: 144px;
            padding-bottom: 128px;
        }

        .ant-modal-close {
            right: 32px;
        }
    `,
    header: css`
        ${offerUserCustomerOverviewStyles.title};

        & {
            margin: 0;
        }
    `,
};
