/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { css, SerializedStyles } from '@emotion/react';
import { ButtonProps } from 'antd/lib/button/button';

import { Close, Tick } from 'src/assets/icons';
import { colors } from 'src/constants/variables';

type Props = {
    onOk: ButtonProps['onClick'];
    onCancel: ButtonProps['onClick'];
    customCss?: SerializedStyles;
};

export const OkAndCancelButtons: React.FC<Props> = ({ onOk, onCancel, customCss }) => (
    <div css={[styles.buttons, customCss]}>
        <Button type={'text'} htmlType={'button'} onClick={onCancel} data-test={'cancelButton'}>
            <Icon component={Close} css={styles.iconCancel}/>
        </Button>
        <Button type={'text'} htmlType={'button'} onClick={onOk} data-test={'okButton'}>
            <Icon component={Tick} css={styles.iconOk}/>
        </Button>
    </div>
);

const styles = {
    buttons: css`
        text-align: right;

        button {
            padding: 0;
            height: auto;

            &:not(:last-of-type) {
                margin-right: 25px;
            }
        }

        svg {
            height: 18px;
        }
    `,
    iconOk: css`
        svg {
            width: 24px;

            polygon {
                fill: ${colors.confirm};
            }
        }
    `,
    iconCancel: css`
        svg {
            width: 18px;

            path {
                fill: ${colors.secondary};
            }
        }
    `,
};
