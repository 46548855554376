import React from 'react';
import { PartialDeep } from 'type-fest';

import { JsonObject } from 'src/helpers/types';

export type ModalFormProps<T> = {
    visible: boolean;
    onClose: () => void;
    onSubmit: (values: T) => void;
    initial?: PartialDeep<T>;
};

export const useModalForm = <T, PT = JsonObject>(
    component: React.FC<ModalFormProps<T> & PT>,
    onFormSubmit: (values: T) => void,
    initFormValues?: PartialDeep<T>,
) => {
    const [isVisible, setIsVisible] = React.useState(false);

    const openModal = () => setIsVisible(true);

    const closeModal = () => setIsVisible(false);

    const submitForm = (values: T) => {
        onFormSubmit(values);
        closeModal();
    };

    const ModalFormComponent: React.ComponentType<ModalFormProps<T> & PT & any> = component;
    const renderModal = (modalExtraProps: PT = {} as PT): React.ReactNode => (
        <ModalFormComponent
            visible={isVisible}
            initial={initFormValues}
            onClose={closeModal}
            onSubmit={submitForm}
            {...modalExtraProps}
        />
    );

    return {
        openModal,
        render: renderModal,
    };
};
