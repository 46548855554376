/** @jsxImportSource @emotion/react */
import React, { FormEvent } from 'react';
import {
    Form,
    Input as AntdInput,
    InputNumber as AntdInputNumber,
    DatePicker as AntdDatePicker,
} from 'antd';
import { FormItemProps as AntdFormItemProps } from 'antd/lib/form';
import { DatePickerProps as AntdDatePickerProps } from 'antd/lib/date-picker';
import { InputProps as AntdInputProps, TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';
import { InputNumberProps as AntdInputNumberProps } from 'antd/lib/input-number';
import locale from 'antd/lib/calendar/locale/de_DE';

import { TimeInput, TimeInputProps } from '../DataInputs';

import { styles } from './styles';
import { FormItemProps } from './types';

export type InputProps = FormItemProps & Omit<AntdInputProps, 'name' | 'required'>;

const Text: React.FC<InputProps> = ({ name, formItemCss, required = false, ...rest }) => (
    <Form.Item
        name={name}
        css={[styles.item, styles.inputItem, formItemCss, required && styles.required]}
    >
        <AntdInput {...rest} />
    </Form.Item>
);

const Phone: React.FC<InputProps> = ({ placeholder = 'Telefon', ...rest }) => (
    <Text maxLength={45} placeholder={placeholder} {...rest} />
);

type TextAreaProps = FormItemProps &
    Omit<AntdTextAreaProps, 'name' | 'required'> & { maxNumOfLines?: number };

const TextArea: React.FC<TextAreaProps> = ({
    name,
    formItemCss,
    maxNumOfLines = 0,
    required = false,
    ...rest
}) => {
    const onChangeCapture = (event: FormEvent<HTMLTextAreaElement>) => {
        if (maxNumOfLines > 0) {
            event.currentTarget.value = event.currentTarget.value
                .split('\n')
                .slice(0, maxNumOfLines)
                .join('\n');
        }
    };

    return (
        <Form.Item
            name={name}
            css={[styles.item, styles.textareaItem, formItemCss, required && styles.required]}
        >
            <AntdInput.TextArea onChangeCapture={onChangeCapture} {...rest} />
        </Form.Item>
    );
};

export type PriceInputProps = FormItemProps & Omit<AntdInputNumberProps, 'name' | 'required'>;

const Price: React.FC<PriceInputProps> = ({ name, formItemCss, required = false, ...rest }) => (
    <Form.Item
        name={name}
        css={[styles.item, styles.priceItem, formItemCss, required && styles.required]}
    >
        <AntdInputNumber min={0} precision={2} type="number" {...rest} />
    </Form.Item>
);

type TimeProps = FormItemProps & TimeInputProps;

const Time: React.FC<TimeProps> = ({ name, formItemCss, required = false, ...rest }) => (
    <Form.Item
        name={name}
        fieldKey={'time'}
        css={[styles.item, styles.inputItem, formItemCss, required && styles.required]}
    >
        <TimeInput {...rest} />
    </Form.Item>
);

export type DatePickerProps = FormItemProps & AntdDatePickerProps;

const DatePicker: React.FC<DatePickerProps> = ({ name, formItemCss, required, ...rest }) => (
    <Form.Item
        name={name}
        css={[styles.item, styles.pickerItem, formItemCss, required && styles.required]}
    >
        <AntdDatePicker
            locale={locale}
            format="DD.MM.YYYY"
            css={styles.datePicker}
            {...rest}
            inputReadOnly
        />
    </Form.Item>
);

type CustomInputProps = FormItemProps & Pick<AntdFormItemProps, 'children'>;

const CustomInput: React.FC<CustomInputProps> = ({
    name,
    formItemCss,
    required = false,
    children,
}) => (
    <Form.Item name={name} css={[styles.item, formItemCss, required && styles.required]}>
        {children}
    </Form.Item>
);

export const Input = { CustomInput, DatePicker, Phone, Price, Text, TextArea, Time };
