/** @jsxImportSource @emotion/react */
import React, { useRef, CSSProperties } from 'react';
import { css, ClassNames, SerializedStyles } from '@emotion/react';
import { Modal, ModalProps } from 'antd';

import { Close } from 'src/assets/icons';
import Logo from 'src/assets/images/logo/mewa-logo_red.png';

export type CustomModalProps = {
    visible: boolean;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    modalCss?: SerializedStyles | SerializedStyles[];
    footer?: React.ReactNode;
} & Pick<
    ModalProps,
    | 'width'
    | 'zIndex'
    | 'centered'
    | 'closable'
    | 'maskClosable'
    | 'getContainer'
    | 'destroyOnClose'
    | 'maskStyle'
>;

type CustomModalCompound = {
    Inline: React.FC<InlineModalProps>;
    WithLogo: React.FC<CustomModalProps>;
};

export const CustomModal: React.FC<CustomModalProps> & CustomModalCompound = ({
    onClick,
    children,
    visible,
    centered = true,
    closable = true,
    maskClosable = true,
    width = '100%',
    modalCss,
    maskStyle = defaultMaskStyle,
    zIndex,
    getContainer,
    destroyOnClose,
    footer = null,
}) => (
    <ClassNames>
        {({ css }) => (
            <Modal
                wrapClassName={css(styles.modal, modalCss)}
                centered={centered}
                footer={footer}
                visible={visible}
                closable={closable}
                maskClosable={maskClosable}
                onCancel={onClick}
                closeIcon={<Close />}
                maskStyle={maskStyle}
                width={width}
                zIndex={zIndex}
                getContainer={getContainer}
                destroyOnClose={destroyOnClose}
                data-test={'kmuIntroductoryLetterModal'}
            >
                {children}
            </Modal>
        )}
    </ClassNames>
);

type InlineModalProps = CustomModalProps & {
    modalCss?: SerializedStyles;
};

const InlineModal: React.FC<InlineModalProps> = ({ children, modalCss = css``, ...modalProps }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={wrapperRef} data-test={'inlineModal'}>
            <CustomModal
                {...modalProps}
                centered={false}
                modalCss={[styles.inline, modalCss]}
                maskStyle={inlineMaskStyle}
                maskClosable={false}
                getContainer={() => wrapperRef.current?.parentElement || document.body}
                destroyOnClose
            >
                {children}
            </CustomModal>
        </div>
    );
};

CustomModal.Inline = InlineModal;

const ModalWithLogo: React.FC<CustomModalProps> = ({ children, ...modalProps }) => (
    <CustomModal {...modalProps}>
        <a href="https://www.mewa.de/">
            <img src={Logo} alt="logo" css={styles.logo} />
        </a>
        {children}
    </CustomModal>
);

CustomModal.WithLogo = ModalWithLogo;

const defaultMaskStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.98)',
};

const inlineMaskStyle: CSSProperties = {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.98)',
};

const styles = {
    modal: css`
        .ant-modal {
            height: 100vh;
            margin: 0;
            max-width: none;
        }

        .ant-modal-content {
            padding: 112px 0 78px;

            box-shadow: none;
            background-color: transparent;
        }

        .ant-modal-body {
            padding: 84px 32px 0;
            font-family: 'Open Sans', sans-serif;
        }

        .ant-modal-close {
            top: 96px;
            right: 16px;

            .ant-modal-close-x {
                svg {
                    width: 22px;
                    height: auto;
                    opacity: 0.5;
                }
            }
        }
    `,
    logo: css`
        height: 50px;
        width: 150px;

        position: absolute;
        top: 96px;
        left: 50px;
    `,
    inline: css`
        position: absolute;

        .ant-modal {
            top: 0;
            height: unset;
            padding: 64px;
            position: absolute;
        }

        .ant-modal-content {
            padding: 0;
            height: 100%;
        }

        .ant-modal-body {
            padding: 0 32px;
        }

        .ant-modal-close {
            top: 0;
            right: 0;

            .ant-modal-close-x {
                width: 48px;
                height: 48px;
            }
        }
    `,
};
