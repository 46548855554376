/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import { Col } from 'antd';

import { NumberColumnWrapper } from './NumberColumnWrapper';
import { PriceColumn } from './PriceColumn';
import { BasePriceField } from './BasePriceField';
import { ProductRowSummaryPrice } from './ProductRowSummaryPrice';
import AmountInput from '../../AmountInput';
import { JsonObject, VariantItem } from 'src/helpers/types';

type Props = {
    product: VariantItem;
    onInlineChange: (item: VariantItem, value?: JsonObject) => void;
};

export const ProductRowInputs: React.FC<Props> = ({ product, onInlineChange }) => {
    const cartItemId = product.cartItemId || product.offerItemId;

    return (
        <>
            <Col css={productRowInputStyles.col} data-test={'amountOfWearersColumnWrapper'}>
                <PriceColumn cartItemId={cartItemId} name="amountOfWearers">
                    <AmountInput
                        required
                        handleChange={(amountOfWearers) => {
                            onInlineChange(product, { amountOfWearers });
                        }}
                    />
                </PriceColumn>
            </Col>

            <Col css={productRowInputStyles.col} data-test={'itemsPerWearerColumnWrapper'}>
                <PriceColumn cartItemId={cartItemId} name="itemsPerWearer">
                    <AmountInput
                        required
                        handleChange={(itemsPerWearer) => {
                            onInlineChange(product, { itemsPerWearer });
                        }}
                    />
                </PriceColumn>
            </Col>

            <Col css={productRowInputStyles.col} data-test={'basePriceColumnWrapper'}>
                <PriceColumn cartItemId={cartItemId} name="basePrice">
                    <BasePriceField
                        required
                        onChange={(basePrice) => onInlineChange(product, { basePrice })}
                    />
                </PriceColumn>
            </Col>

            <Col css={productRowInputStyles.col} data-test={'logoFrontPriceColumnWrapper'}>
                <PriceColumn cartItemId={cartItemId} name="logoFrontPrice">
                    <BasePriceField
                        allowEmpty
                        onChange={(logoFrontPrice) => onInlineChange(product, { logoFrontPrice })}
                    />
                </PriceColumn>
            </Col>

            <Col css={productRowInputStyles.col} data-test={'logoBackPriceColumnWrapper'}>
                <PriceColumn cartItemId={cartItemId} name="logoBackPrice">
                    <BasePriceField
                        allowEmpty
                        onChange={(logoBackPrice) => onInlineChange(product, { logoBackPrice })}
                    />
                </PriceColumn>
            </Col>

            <Col css={productRowInputStyles.col} data-test={'labelPriceColumnWrapper'}>
                <PriceColumn cartItemId={cartItemId} name="labelPrice">
                    <BasePriceField
                        allowEmpty
                        onChange={(labelPrice) => onInlineChange(product, { labelPrice })}
                    />
                </PriceColumn>
            </Col>

            <Col css={productRowInputStyles.col} data-test={'pricePerWorkerPerWeekColumnWrapper'}>
                <NumberColumnWrapper>
                    <ProductRowSummaryPrice itemId={cartItemId} />
                </NumberColumnWrapper>
            </Col>
        </>
    );
};

export const productRowInputStyles = {
    col: css`
        width: calc(100% / 7);
    `,
};
