/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

import { colors } from 'src/constants/variables';
import { acceptButton } from 'src/style/styles';
import { ClearButton } from 'src/components/Buttons';
import { ConfirmationButton } from '../../../../components/MewaCustomButton';

type Props = {
    onFormSubmit: () => void;
    onProductsClear?: () => void;
    disableRemoveButton: boolean;
    disableSubmitButton?: boolean;
    customCss?: SerializedStyles;
};

export const OfferButtons: React.FC<Props> = ({
    onFormSubmit,
    onProductsClear,
    disableRemoveButton,
    disableSubmitButton = false,
    customCss,
}) => (
    <>
        {onProductsClear && (
            <div css={styles.clear} data-test={'productsClearButtonWrapper'}>
                <ClearButton
                    confirm="Möchten Sie den Warenkorb leeren?"
                    onConfirm={onProductsClear}
                    disabled={disableRemoveButton}
                />
            </div>
        )}

        <ConfirmationButton
            css={[styles.submitButton, customCss]}
            onClick={onFormSubmit}
            disabled={disableSubmitButton}
            htmlType="button"
            data-test={'continueButton'}
        >
            ANGEBOT SPEICHERN
        </ConfirmationButton>
    </>
);

const styles = {
    clear: css`
        margin-top: 90px;

        button {
            margin-bottom: 0;
        }
    `,
    generateButton: css`
        margin-top: 89px;
        border-radius: 5px;
    `,
    submitButton: css`
        ${acceptButton};
        background-color: ${colors.fontSecondaryDark};
        color: ${colors.primary};

        height: 56px;
        margin-top: 24px;

        border: none;
        border-radius: 5px;

        :hover,
        :focus {
            background-color: ${colors.fontSecondaryDark};
            color: ${colors.primary};
        }

        &.ant-btn[disabled] {
            background-color: ${colors.fontSecondaryDark};
            color: ${colors.primary};
            opacity: 0.25;
        }
    `,
};
