import { Document } from '@react-pdf/renderer';
import { OfferForPdf } from '../types';

import { IntroPage } from 'src/modules/offerPdf/templates/pdfPages/introPage';
import { PageTwo } from 'src/modules/offerPdf/templates/pdfPages/PageTwo';
import { ProductListPage } from 'src/modules/offerPdf/templates/pdfPages/productListPage/productListPage';
import { OutroPage } from 'src/modules/offerPdf/templates/pdfPages/outroPage';
import { DeliverySchemePage } from './pdfPages/deliverySchemePage';
import { DELIVERY_14T, DELIVERY_7T } from './pdfPages/delivery/deliveryOptionsData';
import { VariantItem } from '../../../helpers/types';

export const CUSTOMIZED_ITEMS_NOTE =
    'Individualisierte Ware ist von Umtausch/Rückgabe ausgeschlossen!';

export function offerPdfTemplate(offerOverview: OfferForPdf) {
    const delivery = offerOverview?.details?.rotation === '14T' ? DELIVERY_14T : DELIVERY_7T;

    return (
        <Document>
            <IntroPage offer={offerOverview} />
            <PageTwo />
            {offerOverview && (
                <ProductListPage
                    itemList={offerOverview.items}
                    details={offerOverview.details}
                    services={offerOverview.basics?.wardrobeOptions}
                    displayCategory={offerOverview.displayCategory}
                    delivery={delivery}
                    hasCustomizedItems={!!hasCustomizedItems(offerOverview.items)}
                />
            )}
            <DeliverySchemePage
                delivery={delivery}
                hints={offerOverview?.details?.kmuDetails?.hints}
            />
            <OutroPage offer={offerOverview} />
        </Document>
    );
}

export function hasCustomizedItems(items: VariantItem[]): string {
    if (
        items.filter(
            (item) =>
                (item.logoBackPrice && item.logoBackPrice > 0) ||
                (item.logoFrontPrice && item.logoFrontPrice > 0) ||
                (item.labelPrice && item.labelPrice > 0),
        ).length > 0
    ) {
        return CUSTOMIZED_ITEMS_NOTE;
    }
    return '';
}
