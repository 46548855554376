import React from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps, generatePath } from 'react-router-dom';
import { colors, paths } from 'src/constants/variables';
import { ProductsCatalogContainer } from '../offer/components/ProductsCatalogContainer';
import { css } from '@emotion/react';
import { PageWrapper } from '../offer/components/PageWrapper';
import { PageHeader } from '../offer/components/PageHeader';
import { cartService } from '../cart/store/cartService';
import { notificationService } from '../offer/service';
import { ProductsActionButtons } from '../offer/components/ProductsActionButtons';

type Props = RouteComponentProps<{ id?: string }>;

type State = {
    selectedProducts: CollectionItem[];
};

class ProductsCatalog extends React.PureComponent<Props, State> {
    state = {
        selectedProducts: [],
        redirectedFrom: this.props.location.state,
    };

    private onCollectionSelect = (collection: ProductCollection) => {
        this.props.history.push(
            generatePath(paths.products, { id: collection.id }),
            this.state.redirectedFrom,
        );
    };

    private addProductToCart = async (item: CollectionItem) => {
        try {
            await cartService.addCartItem({
                modelId: item.modelId,
                variantKey: item.variantKey,
                itemsGroupId: null,
            });

            return true;
        } catch (error) {
            notificationService.error('Etwas ging schief, versuchen Sie es später noch einmal.');
        }
        return false;
    };

    private addProducts = async () => {
        const addToCartRequests = this.state.selectedProducts.map((item) =>
            this.addProductToCart(item),
        );
        const values = await Promise.all(addToCartRequests);

        this.setState({ selectedProducts: [] });
        notificationService.success(`Produkt(e) wurde(n) erfolgreich hinzugefügt.`);

        return values.every(Boolean);
    };

    render() {
        const { selectedProducts } = this.state;
        const currentCollectionId = this.props.match.params.id || null;

        return (
            <PageWrapper contentCss={styles.contentWrapper}>
                <PageHeader />
                <ProductsCatalogContainer
                    collectionId={currentCollectionId}
                    onCollectionSelect={this.onCollectionSelect}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={(selectedProducts: CollectionItem[]) => {
                        this.setState({ selectedProducts });
                    }}
                />
                <ProductsActionButtons
                    addProducts={this.addProducts}
                    addDisabled={!selectedProducts.length}
                />
            </PageWrapper>
        );
    }
}

const styles = {
    contentWrapper: css`
        padding-right: 32px;
        background-color: ${colors.background};
    `,
};

export const ProductsCatalogPage = withRouter(ProductsCatalog);
