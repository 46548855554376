/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { InfoText } from './InfoText';
import { Header } from 'src/components';
import { colors, fontSizes } from 'src/constants/variables';
import { Col, Row } from 'antd';
import { ColumnTitle } from '../../Offer/ProductRow/ColumnTitle';
import { ItemsList } from './ItemsList';
import { DeliveryData } from 'src/modules/offerPdf/types';
import {
    hasCustomizedItems,
    CUSTOMIZED_ITEMS_NOTE,
} from '../../../../offerPdf/templates/offerPdfTemplate';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    items: VariantItem[];
    delivery: DeliveryData;
};

export const ItemsArea: React.FC<Props> = ({ items, delivery }) => (
    <div>
        <Header css={styles.sectionHeader}>Ihre Produktauswahl</Header>
        <Row>
            <Col span={2} offset={10}>
                <ColumnTitle title="Einzukleidende Personen" />
            </Col>
            <Col span={2}>
                <ColumnTitle title="Ausstattung" />
            </Col>
            <Col span={2}>
                <ColumnTitle title="Stückpreis" />
            </Col>
            <Col span={2}>
                <ColumnTitle title="Brustlogo" />
            </Col>
            <Col span={2}>
                <ColumnTitle title="Rückenlogo" />
            </Col>
            <Col span={2}>
                <ColumnTitle title="Namensschild" />
            </Col>
            <Col span={2}>
                <ColumnTitle title="Preis je Mitarbeiter pro Woche" />
            </Col>
        </Row>

        <div css={styles.itemsList}>
            <ItemsList items={items.sort((a, b) => a.offerItemId! - b.offerItemId!)} />
        </div>

        <InfoText text={delivery.detailedText} />
        {!!hasCustomizedItems(items) && (
            <p css={styles.customizedItemsNote}>{CUSTOMIZED_ITEMS_NOTE}</p>
        )}
    </div>
);

const styles = {
    sectionHeader: css`
        text-transform: none;
        color: ${colors.newOfferTitle};

        :before {
            background-color: ${colors.fontHeaderSecondary};
        }

        & {
            margin-top: 0;
            margin-left: 0;
        }
    `,
    itemsList: css`
        border-top: 1px solid ${colors.backgroundDark}40;
        border-bottom: 1px solid ${colors.backgroundDark}40;
        margin-bottom: 16px;
    `,
    customizedItemsNote: css`
        font-size: ${fontSizes.mSmall};
        font-family: 'Optima LT Pro Medium', sans-serif;
    `,
};
