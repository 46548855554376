/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { colors, fontSizes } from 'src/constants/variables';

type Props = {
    title: string;
};

export const ColumnTitle: React.FC<Props> = ({ title }) => <div css={styles.wrapper}>{title}</div>;

const styles = {
    wrapper: css`
        background-color: ${colors.primary};
        height: 100%;
        text-align: center;
        font-size: ${fontSizes.xxSmall};
        font-family: 'Optima LT Pro DemiBold', sans-serif;
        font-weight: 900;
        padding-bottom: 18px;
    `,
};
