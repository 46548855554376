import { ProductGroupsActionsType } from '../actions/productGroupsActions';

export type ProductGroupsState = {
    data: ProductGroup[];
    loading: boolean;
    error: boolean;
};

const initialState: ProductGroupsState = {
    data: [],
    loading: false,
    error: false,
};

export function productGroupsReducer(
    state: ProductGroupsState = initialState,
    action: ProductGroupsActionsType,
): ProductGroupsState {
    switch (action.type) {
        case 'SET_PRODUCT_GROUPS_LIST':
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
}
