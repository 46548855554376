import { endpoints } from 'src/constants/variables';
import { httpService, httpStatus } from 'src/modules/app/service/httpService';
import { transformCatalogImageUrl } from 'src/helpers/utils';
import { transformProductImages } from 'src/modules/product/store/productService';
import { VariantItem } from '../../../helpers/types';

const addCartItem = async (
    cartItemData: ItemDetails | Pick<ItemDetails, 'modelId' | 'variantKey' | 'itemsGroupId'>,
) => {
    await httpService({
        url: endpoints.cart,
        body: cartItemData,
        method: 'POST',
    });
};

const clearCart = async () => {
    await httpService({ url: endpoints.clearCart, method: 'DELETE' });
};

const editCartItem = async (cartItemId: number, cartItemData: ItemDetails) => {
    const result = await httpService<VariantItem>({
        url: endpoints.cartItem(cartItemId),
        body: cartItemData,
        method: 'PUT',
    });

    return transformCartItemImages(result);
};

const editCartItemColor = async (cartItemId: number, colorKey: string): Promise<VariantItem> => {
    const result = await httpService<VariantItem>({
        url: endpoints.cartItemColor(cartItemId),
        body: { colorKey },
        method: 'PATCH',
    });

    return transformCartItemImages(result);
};

const editCartItemWeight = async (cartItemId: number, weight: number): Promise<VariantItem> => {
    const result = await httpService<VariantItem>({
        url: endpoints.cartItemWeight(cartItemId),
        body: { weight },
        method: 'PATCH',
    });

    return transformCartItemImages(result);
};

const editCartItemPricing = async (
    cartItemId: VariantItem['cartItemId'],
    cartItemPricing: CartItemPricing & Pick<VariantItem, 'totalPrice'>,
) => {
    const result = await httpService<VariantItem>({
        url: endpoints.cartItemPricing(cartItemId!),
        body: cartItemPricing,
        method: 'PATCH',
    });

    return transformCartItemImages(result);
};

const fetchCart = async (): Promise<FetchCartResponse> => {
    const result = await httpService<FetchCartResponse>({ url: endpoints.cart });

    if (!result) throw Error();

    const mappedItems = result.items.map(transformCartItemImages);

    return { ...result, items: mappedItems };
};

const fetchCartItem = async (cartItemId: number): Promise<FetchCartItemResponse> => {
    const result = await httpService<FetchCartItemResponse>({
        url: endpoints.cartItem(cartItemId),
    });

    return {
        ...result,
        model: transformProductImages(result.model),
    };
};

const removeCartItem = async (cartItemId: number) => {
    await httpService({ url: endpoints.cartItem(cartItemId), method: 'DELETE' });
};

const fetchCartStatus = async () => await httpStatus({ url: endpoints.cartStatus });

// Transform TIFF -> JPG
function transformCartItemImages(item: VariantItem): VariantItem {
    return {
        ...item,
        imageLink: transformCatalogImageUrl(item.imageLink, 125),
    };
}

export const cartService = {
    addCartItem,
    clearCart,
    editCartItem,
    editCartItemColor,
    editCartItemWeight,
    editCartItemPricing,
    fetchCart,
    fetchCartItem,
    fetchCartStatus,
    removeCartItem,
};
