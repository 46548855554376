import React from 'react';
import { ProductsCatalogContainer } from './ProductsCatalogContainer';
import { InitOfferItemRequest, initOfferItems } from 'src/modules/offer/service/offerServices';
import { notificationService } from '../service';
import { ProductsActionButtons } from './ProductsActionButtons';
import { css } from '@emotion/react';
import { colors } from 'src/constants/variables';
import { VariantItem } from '../../../helpers/types';

type State = {
    selectedProducts: CollectionItem[];
    collectionId: string | null;
};

type Props = {
    onAddProducts: (offerItems: VariantItem[]) => void;
};

export class ProductsCatalogStandalone extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedProducts: [],
            collectionId: null,
        };
    }

    onCollectionSelect = (collection: ProductCollection) => {
        this.setState({ collectionId: collection.id });
    };

    private addProducts = async () => {
        const initOfferItemRequests: InitOfferItemRequest[] = this.state.selectedProducts.map(
            (item) => ({
                modelId: item.modelId,
                variantKey: item.variantKey,
            }),
        );

        const newOfferItems = await initOfferItems(initOfferItemRequests);

        this.props.onAddProducts(newOfferItems);

        this.setState({ selectedProducts: [] });
        notificationService.success(`Produkt(e) wurde(n) erfolgreich hinzugefügt.`);
        return true;
    };

    render() {
        const { selectedProducts } = this.state;
        return (
            <>
                <ProductsCatalogContainer
                    collectionId={this.state.collectionId}
                    onCollectionSelect={this.onCollectionSelect}
                    selectedProducts={this.state.selectedProducts}
                    setSelectedProducts={(selectedProducts: CollectionItem[]) => {
                        this.setState({ selectedProducts });
                    }}
                />
                <ProductsActionButtons
                    addProducts={this.addProducts}
                    addDisabled={!selectedProducts.length}
                    customWrapperStyle={styles.wrapper}
                />
            </>
        );
    }
}

const styles = {
    wrapper: css`
        background-color: ${colors.primary}F7;
    `,
};
