import { AsyncResource, defaultAsyncResource, VariantItem } from 'src/helpers/types';
import { CartActions } from './cartActions';

export type CartState = AsyncResource<VariantItem[]> & {
    hasContent: boolean;
    rotationRates: CartRotationRate[];
    availableColors?: ProductColor[];
    availableWeights?: number[];
    itemsGroupId: number | null;
    errorCode?: number;
};

export const defaultState: CartState = {
    ...defaultAsyncResource,
    hasContent: false,
    itemsGroupId: null,
    rotationRates: [],
};

export function cartReducer(state = defaultState, action: CartActions): CartState {
    switch (action.type) {
        case 'CART|CLEAR_SUCCEEDED':
            return {
                ...state,
                data: [],
                itemsGroupId: null,
                loading: false,
                hasContent: false,
                error: false,
                errorCode: undefined,
            };

        case 'CART|FETCH_REQUESTED':
            return {
                ...state,
                loading: true,
                error: false,
                errorCode: undefined,
            };

        case 'CART|FETCH_SUCCEEDED':
            return {
                ...state,
                loading: false,
                data: action.payload.items,
                rotationRates: action.payload.rotationRates,
                availableColors: action.payload.availableColors,
                availableWeights: action.payload.availableWeights,
            };

        case 'CART|FETCH_FAILED':
            return {
                ...state,
                loading: false,
                error: true,
                errorCode: action.payload,
            };

        case 'CART|SET_CONTENT_STATUS':
            return {
                ...state,
                hasContent: action.payload,
            };

        case 'CART|UPDATE_ITEM': {
            const { data } = state;
            const { cartItemId, cartItem } = action.payload;

            const mapItem = (item: VariantItem) =>
                item.cartItemId === cartItemId ? { ...item, ...cartItem } : item;

            return data
                ? {
                      ...state,
                      data: data.map(mapItem),
                  }
                : state;
        }

        case 'CART|REMOVE_ITEM_SUCCEEDED':
            return {
                ...state,
                data: state.data!.filter((item) => item.cartItemId !== action.payload),
            };

        default:
            return state;
    }
}
