/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { ModalFormProps } from 'src/hooks/useModalForm';
import { offerUserCustomerOverviewStyles } from 'src/modules/offer/components/styles';

import { ContactDetailsModal, ContactDetailsValues } from 'src/components/ContactDetailsModal';
import { isContactFormValid } from 'src/modules/offer/helpers/offerForm';

type Props = ModalFormProps<ContactDetailsValues>;

export const OfferContactDetailsModal: React.FC<Props> = ({
    visible,
    initial,
    onClose,
    onSubmit,
}) => (
    <ContactDetailsModal
        visible={visible}
        onSubmit={onSubmit}
        onClose={onClose}
        initial={initial}
        modalCss={styles.modal}
        buttonText="Daten einfügen"
        withDate={false}
        inline
        isButtonValid={isContactFormValid}
    />
);

const styles = {
    modal: css`
        .ant-modal-body {
            padding-top: 144px;
            padding-bottom: 128px;
        }

        .ant-modal-close {
            right: 32px;
        }

        .ant-form {
            .ant-form-item:last-of-type {
                margin-bottom: 0;
            }
        }

        button.ant-btn {
            margin-top: 40px;
            border-radius: 5px;
        }

        textarea.ant-input {
            padding: 16px;
            height: 200px;
        }

        h2 {
            ${offerUserCustomerOverviewStyles.title};

            & {
                margin: 0;
            }
        }
    `,
};
