/** @jsxImportSource @emotion/react */
import React from 'react';
import { Row, Col } from 'antd';
import { css } from '@emotion/react';
import 'moment/locale/de';

import { colors } from 'src/constants/variables';

import { OfferRow } from './OfferRow';
import { beautifyName } from '../../../../helpers/utils';

type Props = {
    label: string;
    offers: OfferSummary[];
    offerActions: ManageOffer;
};

export const OffersList: React.FC<Props> = ({ label, offers, offerActions }) =>
    offers.length > 0 ? (
        <div css={styles.wrapper} data-test={`${beautifyName(label)}_OFFERS_SECTION`}>
            <div css={styles.dateLabel} data-test={`${beautifyName(label)}_OFFERS_SECTION_DATE`}>
                {label}
            </div>
            <Row gutter={[32, 0]}>
                {offers.map((offer) => (
                    <Col key={offer.id} span={12} xl={8} css={styles.offerItem}>
                        <OfferRow offer={offer} actions={offerActions} />
                    </Col>
                ))}
            </Row>
        </div>
    ) : null;

const styles = {
    wrapper: css`
        margin-bottom: 40px;
    `,
    dateLabel: css`
        color: ${colors.fontHeader};
        font-family: OptimaLTPro-Roman, sans-serif;
        font-size: 24px;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 16px;
    `,
    offerItem: css`
        & > div {
            padding: 16px;
        }

        &:nth-of-type(-n + 3) > div {
            border-top: 1px solid ${colors.favourite};
        }
    `,
};
