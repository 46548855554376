/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Row, Col } from 'antd';
import { OfferNavigation } from './OfferNavigation';

type Props = {
    contentCss?: SerializedStyles;
};
export const PageWrapper: React.FC<Props> = ({ children, contentCss }) => (
    <Row
        css={
            process.env.REACT_APP_ENVIRONMENT === 'production'
                ? styles.row
                : styles.rowWithEnvBanner
        }
    >
        <Col span={6}>
            <OfferNavigation />
        </Col>
        <Col span={18} css={[styles.content, contentCss]}>
            {children}
        </Col>
    </Row>
);

const styles = {
    row: css`
        height: 100vh;
    `,
    rowWithEnvBanner: css`
        height: calc(100vh - 24px); // The full view height without the environment banner
    `,
    content: css`
        height: 100%;
        overflow-y: auto;
        padding-left: 32px;
    `,
};
