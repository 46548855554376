import { createSelector } from 'reselect';

import { getProductsCatalogState } from '../productsCatalogSelectors';

const getProductGroupsState = createSelector(
    getProductsCatalogState,
    (productsCatalogState) => productsCatalogState.groups,
);

export const getProductGroupsList = createSelector(
    getProductGroupsState,
    (productGroupsState) => productGroupsState.data,
);

export const getProductGroupsLoadingStatus = createSelector(
    getProductGroupsState,
    (productGroupsState) => productGroupsState.loading,
);

export const getProductGroupsLoadingError = createSelector(
    getProductGroupsState,
    (productGroupsState) => productGroupsState.error,
);
