/** @jsxImportSource @emotion/react */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';

import { CustomModal } from 'src/components';
import { AsyncResourceHandler } from 'src/modules/app';
import { horizontalMark, wideScreenStyles } from 'src/style/styles';
import { colors, fontSizes } from 'src/constants/variables';

import { ProductForm, ProductLayout } from 'src/modules/product/components';
import {
    setWeightValue,
    setVariantColor,
    setMaterialValue,
} from 'src/modules/product/store/productActions';
import {
    getProductState,
    getItemValues,
    getCurrentVariant,
    getAvailableWeights,
    getAvailableMaterials,
} from 'src/modules/product/store/productSelectors';

type Props = {
    visible: boolean;
    onClick: () => void;
    onFormSubmit: (
        product: Product,
        variant: ProductVariant,
        formData: ProductForm,
        totalPrice: number,
    ) => void;
    clearWarnings?: (id: number, type: 'weight' | 'color') => void;
    currentId?: number | null;
    hideLogos?: boolean;
    hidePriceSection?: boolean;
};

const ItemModal: React.FC<Props> = ({
    visible,
    onClick,
    onFormSubmit,
    clearWarnings,
    currentId,
    hideLogos,
    hidePriceSection,
}) => {
    const dispatch = useDispatch();
    const productState = useSelector(getProductState);
    const currentVariant = useSelector(getCurrentVariant);
    const itemValues = useSelector(getItemValues);
    const availableWeights = useSelector(getAvailableWeights);
    const availableMaterials = useSelector(getAvailableMaterials);

    const setWeight = (weightValue: number) => {
        clearWarnings && currentId && clearWarnings(currentId, 'weight');
        return dispatch(setWeightValue(weightValue));
    };

    const setMaterial = (materialValue: string) => dispatch(setMaterialValue(materialValue));

    const setColorKey = (colorKey: string) => {
        clearWarnings && currentId && clearWarnings(currentId, 'color');
        return dispatch(setVariantColor(colorKey));
    };

    const onItemFormSubmit = (formData: ProductForm, totalPrice: number) => {
        onFormSubmit(productState.model!, currentVariant!, formData, totalPrice);
    };

    return (
        <CustomModal onClick={onClick} visible={visible}>
            <AsyncResourceHandler
                data={{ product: productState.model, variant: currentVariant }}
                error={productState.error}
                errorDetails={{ status: 404, title: 'Product Not Found' }}
                loading={productState.loading}
            >
                {(resources) => (
                    <div css={styles.wrapper}>
                        <h2 css={styles.title}>PRODUKTÄNDERUNG</h2>
                        <ProductLayout.WithAcceptChangesButton
                            {...resources}
                            itemValues={itemValues}
                            availableWeights={availableWeights}
                            availableMaterials={availableMaterials}
                            setColorKey={setColorKey}
                            setWeightValue={setWeight}
                            setMaterialValue={setMaterial}
                            onFormSubmit={onItemFormSubmit}
                            hideLogos={hideLogos}
                            hidePriceSection={hidePriceSection}
                        />
                    </div>
                )}
            </AsyncResourceHandler>
        </CustomModal>
    );
};

const styles = {
    wrapper: css`
        ${wideScreenStyles(css`
            #imageSection {
                .ant-carousel {
                    height: 76vh;
                }
            }
        `)};
    `,
    title: css`
        color: ${colors.primaryDark};
        font-family: OptimaLTPro-Medium, serif;
        font-size: ${fontSizes.xMedium};
        line-height: 1;
        text-transform: uppercase;
        padding-top: 19px;
        ${horizontalMark()};
    `,
};

export default ItemModal;
