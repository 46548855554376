/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { Tick as AcceptIcon } from 'src/assets/icons';
import { floatCartButton } from 'src/style/styles';
import { colors } from 'src/constants/variables';

export const AcceptButton: React.FC<ButtonProps> = (props) => (
    <Button css={styles.button} shape="circle" {...props}>
        <Icon component={AcceptIcon} />
    </Button>
);

const styles = {
    button: css`
        ${floatCartButton(colors.success)}
        svg {
            width: 22px;
            height: auto;

            polygon {
                fill: ${colors.primary};
            }
        }
    `,
};
