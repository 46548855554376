import { combineReducers } from 'redux';
import { offerDataReducer } from './offerDataReducer';
import { offerValuesReducer } from './offerValuesReducer';
import { offerDetailsReducer } from './offerDetailsReducer';
import { offersListReducer } from './offersListReducer';
import { overviewAssetsReducer } from './overviewAssetsReducer';

export type OfferState = ReturnType<typeof offerReducer>;

export const offerReducer = combineReducers({
    offerData: offerDataReducer, // offer data used for new offers
    offerGeneralValues: offerValuesReducer, // offer global data, for all items
    offerDataDuplication: offerDetailsReducer, // offer data used for duplication
    offerList: offersListReducer, // offer list data
    overviewAssets: overviewAssetsReducer, // offer overview of assets for al products contained
});
