/** @jsxImportSource @emotion/react */
import React from 'react';
import { parseToPrice } from 'src/helpers/utils';

type Props = {
    wardrobeOptionsData: WardrobeOptionData[] | undefined;
    services: WardrobeOption[];
    styles: any;
};

export const WardrobeOptionsServiceList: React.FC<Props> = ({
    wardrobeOptionsData,
    services,
    styles,
}) => {
    const getServiceById = (id: number) => wardrobeOptionsData?.find((el) => el.id === id && el);
    const serviceExtraInfo = '(je Mitarbeiter pro Woche)';

    const servicesOptions = services.map((service, index) => {
        const servicePrice = getServiceById(service.id!)?.price;
        const price = servicePrice ? `${parseToPrice(servicePrice)} €` : '-';

        return (
            <div css={styles.namePriceContainer} key={`service-${index}`}>
                <div css={styles.nameLabel}>
                    {service.label} {serviceExtraInfo}
                </div>
                <div css={styles.price}>{price}</div>
            </div>
        );
    });

    return <>{servicesOptions}</>;
};
