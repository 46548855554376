/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { notification } from 'antd';
import Icon, { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';

import { Close } from 'src/assets/icons';

const openNotification = (
    text: string,
    type: 'info' | 'error' | 'success',
    iconComponent: ReactNode,
) => {
    notification.destroy();
    notification.open({
        type,
        message: text,
        className: `system-message system-message-${type}`,
        icon: iconComponent,
        closeIcon: <Icon component={Close} css={closeIconStyles} />,
        style: {
            width: '100%',
            maxWidth: 'unset',
        },
        top: 8,
        placement: 'topLeft',
        duration: 3,
    });
};

const errorNotification = (text?: string) => {
    if (!text) {
        text = !navigator.onLine
            ? 'Anwendung funktioniert im Offline-Modus, versuchen Sie es später erneut'
            : 'Etwas ging schief, versuchen Sie es später noch einmal';
    }
    return openNotification(text, 'error', <CloseCircleFilled />);
};

const infoNotification = (text: string) => openNotification(text, 'info', <InfoCircleFilled />);

const successNotification = (text: string) =>
    openNotification(text, 'success', <CheckCircleFilled />);

export const notificationService = {
    error: errorNotification,
    info: infoNotification,
    success: successNotification,
};

const closeIconStyles = css`
    svg {
        width: 12px;
        height: auto;

        path {
            fill: #ffffff;
        }
    }
`;
