/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { redCustomCheckboxStyle } from '../styles';

export type TermsAndConditionsProps = {
    setTermsAndConditions: (checkedTermsAndConditions: boolean) => void;
    initialTermsAndConditions: boolean;
};

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
    initialTermsAndConditions,
    setTermsAndConditions,
}) => {
    const [isChecked, setIsChecked] = useState<boolean>(initialTermsAndConditions);

    const handleCheckboxChange = (e: CheckboxChangeEvent): void => {
        setIsChecked(e.target.checked);
        setTermsAndConditions(e.target.checked);
    };
    return (
        <>
            <div css={redCustomCheckboxStyle.priceCheckbox} data-test={'termsAndConditionsWrapper'}>
                <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    css={redCustomCheckboxStyle.checkbox}
                    data-test={'termsAndConditionsCheckbox'}
                >
                    AGB
                </Checkbox>
            </div>
        </>
    );
};
