/** @jsxImportSource @emotion/react */
import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

type Props = {
    norms: ProductNorm[];
};

export const PdfNorms: React.FC<Props> = ({ norms }) => {
    const distinctNorms = norms.filter(
        (value, index, self) => self.findIndex((v) => v.label === value.label) === index,
    );

    const normsText = distinctNorms.map((norm: ProductNorm, index) => (
        <Text key={index}>{norm.label}</Text>
    ));

    return normsText && <View style={pdfStyles.row}>{normsText}</View>;
};

const pdfStyles = StyleSheet.create({
    row: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
    },
});
