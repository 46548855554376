import { endpoints } from 'src/constants/variables';
import { httpService } from 'src/modules/app/service/httpService';
import { transformCatalogImageUrl } from 'src/helpers/utils';

async function fetchProduct(modelId: string): Promise<Product> {
    const result = await httpService<Product>({ url: endpoints.product(modelId) });

    return transformProductImages(result);
}

async function fetchVariantOverview(
    modelId: string,
    colorKey: string,
    weight?: number,
): Promise<ProductVariantOverview> {
    const result = await httpService<ProductVariantOverview>({
        url: endpoints.variant(modelId, colorKey, weight),
    });

    return {
        ...result,
        imageLink: transformCatalogImageUrl(result.imageLink),
    };
}

// Transform TIFF -> JPG
export function transformProductImages(product: Product): Product {
    const variants = product.variants.map((variant) => ({
        ...variant,
        imageLinks: variant.imageLinks.map((image) => transformCatalogImageUrl(image)),
    }));

    return { ...product, variants };
}

export const productService = {
    fetchProduct,
    fetchVariantOverview,
};
