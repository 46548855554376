/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Form, Row } from 'antd';
import { PartialDeep } from 'type-fest';
import { css } from '@emotion/react';
import { Input, Select } from 'src/components/FormElements';
import { offerUserCustomerOverviewStyles } from 'src/modules/offer/components/styles';

import { isCustomerFormValid } from 'src/modules/offer/helpers/offerForm';
import { ConfirmationButton } from '../MewaCustomButton';

export type CustomerDetailsValues = Required<
    Pick<OfferBasics, 'customerCompany' | 'customerContact'>
>;

type Props = {
    onSubmit: (values: CustomerDetailsValues) => void;
    buttonText?: string;
    initialValues?: PartialDeep<CustomerDetailsValues>;
};

export const CustomerDetailsForm: React.FC<Props> = ({
    onSubmit,
    buttonText = 'Daten einfügen',
    initialValues,
}) => (
    <Form initialValues={initialValues} onFinish={onSubmit} preserve={false}>
        <Row gutter={32}>
            <Col span={12}>
                <h4 css={styles.sectionTitle} data-test={'customerCompanySectionTitle'}>
                    UNTERNEHMEN/FIRMA
                </h4>
                <Input.Text
                    name={['customerCompany', 'name']}
                    placeholder="Name"
                    required
                    data-test={'customerCompanyName'}
                />
                <Input.Text
                    name={['customerCompany', 'street']}
                    placeholder="Adresse des Unternehmens"
                    required
                    data-test={'customerCompanyStreet'}
                />
                <Input.Text
                    name={['customerCompany', 'address']}
                    placeholder="PLZ/Ort"
                    required
                    data-test={'customerCompanyAddress'}
                />
                <Input.Text
                    name={['customerCompany', 'fax']}
                    placeholder="Fax"
                    data-test={'customerCompanyFax'}
                />
                <Input.Text
                    name={['customerCompany', 'website']}
                    placeholder="Webseite"
                    data-test={'customerCompanyWebsite'}
                />
            </Col>
            <Col span={12}>
                <h4 css={styles.sectionTitle} data-test={'customerContactSectionTitle'}>
                    ANSPRECHPARTNER/IN
                </h4>
                <Row gutter={[32, 0]}>
                    <Col span={12}>
                        <Select.Title
                            name={['customerContact', 'title']}
                            formItemCss={styles.titleInput}
                            getPopupContainer={(trigger) => trigger ?? document.body}
                            required
                            data-test={'customerContactPersonTitle'}
                        />
                    </Col>
                </Row>
                <Row gutter={[32, 0]}>
                    <Col span={12}>
                        <Input.Text
                            name={['customerContact', 'surname']}
                            placeholder="Name"
                            required
                            data-test={'customerContactPersonSurname'}
                        />
                    </Col>
                    <Col span={12}>
                        <Input.Text
                            name={['customerContact', 'name']}
                            placeholder="Vorname"
                            data-test={'customerContactPersonFirstname'}
                        />
                    </Col>
                </Row>
                <Input.Text
                    name={['customerContact', 'position']}
                    placeholder="Position"
                    data-test={'customerContactPersonPosition'}
                />
                <Input.Phone
                    name={['customerContact', 'phone']}
                    required
                    data-test={'customerContactPersonPhone'}
                />
                <Input.Text
                    name={['customerContact', 'email']}
                    placeholder="E-Mail"
                    data-test={'customerContactPersonEmail'}
                />
            </Col>
        </Row>
        <Row gutter={32} justify={'end'}>
            <Col span={8}>
                <Form.Item css={styles.formSubmit} shouldUpdate>
                    {({ getFieldsValue }) => (
                        <ConfirmationButton
                            disabled={!isCustomerFormValid(getFieldsValue())}
                            data-test={'customerContactSaveButton'}
                        >
                            {buttonText}
                        </ConfirmationButton>
                    )}
                </Form.Item>
            </Col>
        </Row>
    </Form>
);

const styles = {
    sectionTitle: css`
        ${offerUserCustomerOverviewStyles.formTitle};
    `,
    inlineInputs: css`
        display: flex;
        flex-direction: row;
    `,
    titleInput: css`
        max-width: unset;
    `,
    formSubmit: css`
        margin-bottom: 0;

        button {
            margin-top: 24px;
            border-radius: 5px;
        }
    `,
};
