/** @jsxImportSource @emotion/react */
import React from 'react';
import Icon from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { css } from '@emotion/react';

import { ArrowRight } from 'src/assets/icons';

type Props = Omit<AntdIconProps, 'ref'> & {
    isActive?: boolean;
};
export const CustomExpandIcon: React.FC<Props> = ({ isActive, ...rest }) => (
    <Icon component={ArrowRight} css={icon(isActive)} {...rest} />
);

const icon = (isActive = false) => css`
    &&&&.anticon {
        padding: 0;
        top: calc(50% - 8px);
        float: none;
        margin-left: 8px;
        svg {
            height: 16px;
            width: auto;
            fill: currentColor;
            transform: rotate(${isActive ? '-90deg' : '90deg'});
            transition-duration: 150ms;
        }
    }
`;
