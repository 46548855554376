/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { css } from '@emotion/react';

import { CustomModal } from 'src/components';
import { AsyncResourceHandler } from 'src/modules/app';
import { utcToLocalFormat } from 'src/helpers/utils';
import { colors } from 'src/constants/variables';

import { Header } from './Header';
import { ItemsArea } from './ItemsArea';
import { ContentHeader } from './ContentHeader';
import { MarketingDetailsReadonly } from './MainSectionReadonly';
import { OfferPreviewDetails } from './OfferPreviewDetails';
import { fetchOfferDetails } from '../../service/offerServices';
import {
    DELIVERY_14T,
    DELIVERY_7T,
} from 'src/modules/offerPdf/templates/pdfPages/delivery/deliveryOptionsData';
import { VariantItem } from '../../../../helpers/types';

type OfferOverview = {
    offerItems: VariantItem[];
    offerBasics: OfferBasics;
    offerDetails: OfferDetails;
};

type Props = {
    offerSummary?: OfferPreviewSummary;
    onClickApproveOffer?: (offerId: OfferSummary['id']) => Promise<void>;
    onClick: () => void;
};

export const OfferPreviewModal: React.FC<Props> = ({ offerSummary, onClick }) => {
    const [offerOverview, setOfferOverview] = useState<OfferOverview | null>(null);
    const [hasError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const delivery = offerOverview?.offerDetails?.rotation === '14T' ? DELIVERY_14T : DELIVERY_7T;

    useEffect(() => {
        if (offerSummary?.id) {
            setError(false);
            setLoading(true);
            fetchOfferDetails(offerSummary.id)
                .then((response) => {
                    setOfferOverview(response);
                    setLoading(false);
                })
                .catch(() => {
                    setError(true);
                    setLoading(false);
                });
        }
    }, [offerSummary]);

    return (
        <CustomModal
            onClick={onClick}
            visible={!!offerSummary?.id}
            destroyOnClose
            modalCss={styles.modal}
        >
            <AsyncResourceHandler data={{ ...offerOverview }} error={hasError} loading={isLoading}>
                {({ offerBasics, offerDetails, offerItems }) => (
                    <>
                        <Header
                            onClick={onClick}
                            offerName={offerDetails.name}
                            creationDate={utcToLocalFormat(offerSummary?.createAt, 'DD.MM.YYYY')}
                            creationTime={utcToLocalFormat(offerSummary?.createAt, 'HH:mm:ss')}
                        />
                        <div css={styles.content}>
                            <ContentHeader />
                            <MarketingDetailsReadonly
                                customerCompany={offerBasics.customerCompany}
                                customerContact={offerBasics.customerContact}
                                employeeContact={offerBasics.employeeContact}
                                selectedDate={moment(offerDetails.validUntil)}
                                selectedBackground={offerDetails.backgroundImageUri}
                            />
                            <div css={styles.wrapper}>
                                <ItemsArea items={offerItems} delivery={delivery} />
                                <Row gutter={[32, 0]}>
                                    <Col span={14} offset={10}>
                                        <OfferPreviewDetails
                                            details={offerDetails}
                                            services={offerBasics.wardrobeOptions}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </>
                )}
            </AsyncResourceHandler>
        </CustomModal>
    );
};

const styles = {
    modal: css`
        .ant-modal-content {
            padding: 0 !important;
        }

        .ant-modal-body {
            padding: 0 !important;
        }

        .ant-modal-close {
            display: none;
        }
    `,
    content: css`
        background-color: ${colors.primary};
    `,
    wrapper: css`
        padding: 90px 32px 32px;
    `,
    buttonWrapper: css`
        padding: 0 32px 32px;
    `,
};
