import moment from 'moment';

import { ItemWarnings } from 'src/helpers/types';
import { OfferFormProps } from 'src/modules/offer/components/types';

import { OfferActions } from '../actions/offerActions';

export type OfferPersistState = {
    form?: Partial<OfferFormProps>;
    includeTermsAndConditions?: boolean;
    background?: string;
    kmuDetails?: OfferKmu;
    colorWarnings?: ItemWarnings;
    weightWarnings?: ItemWarnings;
    expirationDate?: moment.Moment | null;
};

export function offerValuesReducer(
    state: OfferPersistState = {},
    action: OfferActions,
): Partial<OfferPersistState> {
    switch (action.type) {
        case 'OFFER|PERSIST_OFFER_VALUES':
            return {
                ...state,
                ...action.payload,
            };
        case 'OFFER|PERSIST_OFFER_WARNINGS':
            return {
                ...state,
                ...action.payload,
            };
        case 'OFFER|RESET_PERSISTED_VALUES':
            return {};
    }

    return state;
}
