import handtuch19586 from './Handtuch/100_19586.jpg';
import handtuch19591 from './Handtuch/100_19591.jpg';
import handtuch19595 from './Handtuch/100_19595.jpg';
import handtuch19598 from './Handtuch/100_19598.jpg';
import handtuch31896 from './Handtuch/100_31896.jpg';
import handtuch39020 from './Handtuch/100_39020.jpg';

import seife38801 from './Seife/100_38801.jpg';
import seife38802 from './Seife/100_38802.jpg';
import seife38803 from './Seife/100_38803.jpg';

export const waschraumhygieneImages = {
    handtuch19586,
    handtuch19591,
    handtuch19595,
    handtuch19598,
    handtuch31896,
    handtuch39020,
    seife38801,
    seife38802,
    seife38803,
};
