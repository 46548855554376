import olauffangmatten from './100_6204.jpg';
import olauffangmatten6207 from './100_6207.jpg';
import olauffangmatten6213 from './100_6213.jpg';
import olauffangmatten6218 from './100_6218.jpg';
import olauffangmatten40554 from './100_40554.jpg';

export const olauffangmattenImages = {
    olauffangmatten,
    olauffangmatten6207,
    olauffangmatten6213,
    olauffangmatten6218,
    olauffangmatten40554,
};
