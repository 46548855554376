import { ThunkAction } from '../../../store';
import { httpService } from '../../app/service/httpService';
import { endpoints } from '../../../constants/variables';
import { transformCatalogImageUrl } from '../../../helpers/utils';
import { pick } from 'ramda';
import { cartService } from '../../cart/store/cartService';
import { fetchCartStatus } from '../../cart/store/cartActions';
import { offersListsServices } from '../service/offersListsServices';
import {
    clearOfferProductsSuccess,
    fetchOffersListFailure,
    fetchOffersListRequest,
    fetchOffersListSuccess,
    loadOverviewFailure,
    loadOverviewRequest,
    loadOverviewSuccess,
    removeOfferItemSuccess,
    resetOfferForm,
    updateOverviewAssets,
    updateOverviewBasics,
    updateOverviewItems,
} from './actions/offerActions';
import { fetchOfferDataForDuplication, fetchOfferOverview } from '../service/offerServices';
import {
    fetchOfferAssetsSuccess,
    fetchOfferDetailsOverviewFailure,
    fetchOfferDetailsOverviewRequest,
    fetchOfferDetailsOverviewSuccess,
} from './actions/offerDetailsActions';

const EDIT_PAGE_TYPE_UPDATE = 'update';

export const loadOfferOverview = (): ThunkAction => async (dispatch) => {
    dispatch(loadOverviewRequest());

    try {
        const {
            items: cartItems,
            availableColors,
            availableWeights,
            rotationRates,
        } = await httpService<FetchCartResponse>({ url: endpoints.cart });
        dispatch(updateOverviewAssets(availableColors, availableWeights, rotationRates));

        const cartItemsIds = cartItems.map((item) => item.cartItemId);
        const { items, ...basics } = await httpService<OfferBasicsWithItems>({
            url: endpoints.offerInit(cartItemsIds, 'BASIC'),
        });

        const offerItems = items.map((item) => ({
            ...item,
            imageLink: transformCatalogImageUrl(item.imageLink, 250),
        }));
        dispatch(updateOverviewItems(offerItems));

        const offerBasics = pick(['includeTermsAndConditions', 'wardrobeOptions'], basics);
        dispatch(updateOverviewBasics(offerBasics));

        dispatch(loadOverviewSuccess());
    } catch (err) {
        dispatch(loadOverviewFailure(err.code));
    }
};

export const loadOfferOverviewAssets = (): ThunkAction => async (dispatch) => {
    const { availableColors, availableWeights, rotationRates } =
        await httpService<FetchCartResponse>({
            url: endpoints.cart,
        });
    dispatch(updateOverviewAssets(availableColors, availableWeights, rotationRates));
};

export const removeOfferItem =
    (cartItemId: number): ThunkAction =>
    async (dispatch) => {
        await cartService.removeCartItem(cartItemId);
        dispatch(removeOfferItemSuccess(cartItemId));

        dispatch(fetchCartStatus());
        dispatch(loadOfferOverviewAssets());
    };

export const removeAllOfferProducts = (): ThunkAction => async (dispatch) => {
    await cartService.clearCart();
    dispatch(clearOfferProductsSuccess());

    dispatch(fetchCartStatus());
    dispatch(loadOfferOverviewAssets());
};

export const clearOfferForm = (): ThunkAction => async (dispatch) => {
    await cartService.clearCart();
    dispatch(fetchCartStatus());
    dispatch(resetOfferForm());
};

export const fetchOffersListForAllUsers = (): ThunkAction => (dispatch) => {
    dispatch(fetchOffersListRequest());
    offersListsServices
        .fetchOffersList()
        .then((response) => {
            dispatch(fetchOffersListSuccess(response));
        })
        .catch(() => {
            dispatch(fetchOffersListFailure());
        });
};

export const loadDuplicateOverview =
    (offerId: number, type: string): ThunkAction =>
    async (dispatch) => {
        dispatch(fetchOfferDetailsOverviewRequest());
        try {
            let offerData;
            if (type === EDIT_PAGE_TYPE_UPDATE) {
                offerData = await fetchOfferOverview(offerId);
            } else {
                offerData = await fetchOfferDataForDuplication(offerId);
            }

            const { offerItems, offerAssets, offerBasics, offerDetails, offerSummary } = offerData;

            dispatch(fetchOfferAssetsSuccess(offerAssets));
            dispatch(
                fetchOfferDetailsOverviewSuccess(
                    offerItems,
                    offerBasics,
                    offerDetails,
                    offerSummary,
                ),
            );
        } catch (err) {
            dispatch(fetchOfferDetailsOverviewFailure(err.code));
        }
    };
