import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './style/antDesignConfig';
import './index.css';

import * as serviceWorker from './serviceWorker';
import { AppRouter } from './modules/app';
import { configureAmplify } from './amplify';
import { store } from './store';

configureAmplify();

/**
 * Get the translated warning message based on the current environment.
 */
const getEnvironmentString = () => {
    switch (true) {
        case process.env.REACT_APP_ENVIRONMENT === 'development': {
            return 'Sie befinden sich in der DEV-Umgebung';
        }
        case process.env.REACT_APP_ENVIRONMENT === 'integration': {
            return 'Sie befinden sich in der TEST Umgebung';
        }
    }

    return 'Sie befinden sich in der DEV-Umgebung';
};

/**
 * Create a canvas to use the text as an background image since we want to display the text repeatedly.
 */
const environmentCanvas = document.createElement('canvas');
environmentCanvas.setAttribute('height', '24');
environmentCanvas.setAttribute('margin-right', '16');

const environmentContext = environmentCanvas.getContext('2d');

if (environmentContext !== null) {
    environmentContext.fillText(getEnvironmentString(), 0, 14);
}

const App: React.FC = () => (
    <Provider store={store}>
        {process.env.REACT_APP_ENVIRONMENT !== 'production' ? (
            <div
                className="environment-header"
                style={{
                    backgroundImage: `url(${environmentCanvas.toDataURL('image/png')})`,
                    backgroundPosition: 'center',
                }}
            />
        ) : null}
        <AppRouter />
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
