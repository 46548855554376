/** @jsxImportSource @emotion/react */
import React from 'react';

import { Checkbox, Form } from 'antd';

import { NoPrice } from './NoPrice';
import { parseToPrice } from 'src/helpers/utils';
import { redCustomCheckboxStyle } from '../../styles';

type PriceCheckboxProps = {
    onChange?: () => void;
    value?: boolean;
    label: string;
    valueFieldName: string;
};

export const TotalPriceCheckbox: React.FC<PriceCheckboxProps> = ({
    onChange,
    value,
    label,
    valueFieldName,
}) => (
    <div css={redCustomCheckboxStyle.priceCheckbox} data-test={'totalPricePerWeekValueWrapper'}>
        <Checkbox checked={value} onChange={onChange} css={redCustomCheckboxStyle.checkbox}>
            {label}
        </Checkbox>
        <Form.Item shouldUpdate>
            {({ getFieldValue }) => (
                <div css={redCustomCheckboxStyle.price}>
                    {getFieldValue(valueFieldName) ? (
                        parseToPrice(getFieldValue(valueFieldName))
                    ) : (
                        <NoPrice />
                    )}
                </div>
            )}
        </Form.Item>
    </div>
);
