import sample_1 from './100_15565.jpg';
import sample_2 from './100_15574.jpg';
import sample_3 from './100_15918.jpg';
import sample_4 from './100_18621.jpg';
import sample_5 from './100_18622.jpg';
import sample_6 from './100_19806.jpg';

export const trendlineImpressions = {
    sample_1,
    sample_2,
    sample_3,
    sample_4,
    sample_5,
    sample_6,
};
