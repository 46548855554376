import { useEffect, useRef } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

export const useOutsideClick = <T extends HTMLElement = HTMLDivElement>(
    onOutsideClick: (event: AnyEvent) => void,
) => {
    const elementRef = useRef<T>(null);

    useEffect(() => {
        const listener = (event: AnyEvent) => {
            const element = elementRef?.current;
            if (!element || element.contains(event.target as Node)) {
                return;
            }
            onOutsideClick(event);
        };
        document.addEventListener(`mousedown`, listener);
        document.addEventListener(`touchstart`, listener);

        // Remove event listener on cleanup
        return () => {
            document.removeEventListener(`mousedown`, listener);
            document.removeEventListener(`touchstart`, listener);
        };
    }, [elementRef, onOutsideClick]);

    return elementRef;
};

export default useOutsideClick;
