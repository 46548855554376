import React, { CSSProperties, useRef, useState } from 'react';

import { Tooltip } from 'antd';
import { colors, fontSizes } from 'src/constants/variables';
import { useLongTouchPress } from './useLongTouchPress';

export interface ColorTooltipProps {
    title: string;
    selectedParentRef?: React.MutableRefObject<null | HTMLDivElement>;
}

export const ColorTooltip: React.FC<ColorTooltipProps> = ({
    title,
    children,
    selectedParentRef,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const parentRef = useRef(null);

    const onLongTouchPress = () => {
        setShowTooltip(true);
    };

    const handleLongTouchPress = useLongTouchPress(onLongTouchPress);

    return (
        <div
            ref={parentRef}
            {...handleLongTouchPress}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            data-test={'colorMark'}
        >
            <Tooltip
                placement="top"
                title={title}
                arrowPointAtCenter
                overlayInnerStyle={tooltipStyles}
                color={colors.secondaryBright}
                visible={showTooltip}
                getPopupContainer={() =>
                    selectedParentRef?.current || parentRef.current || document.body
                }
            >
                <div>{children}</div>
            </Tooltip>
        </div>
    );
};

const tooltipStyles = {
    color: colors.fontHeaderSecondary,
    fontFamily: 'OpenSans-Bold, sans-serif',
    textTransform: 'uppercase',
    fontSize: fontSizes.xxSmall,
    lineHeight: fontSizes.xSmall,
    textAlign: 'center',
    minHeight: fontSizes.xSmall,
} as CSSProperties;
