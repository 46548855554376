import React from 'react';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router';

import { AsyncResourceHandler } from 'src/modules/app';
import { notificationService } from 'src/modules/offer/service';
import { MapDispatch, MapState } from 'src/store';

import { wrapperCustomBackgroundStyles } from '../styles';
import { PageWrapper } from '../../components/PageWrapper';
import { OffersListLayout } from '../../components/OffersListLayout';
import { fetchOfferDetails } from '../../service/offerServices';
import { getOffersList, getOffersListStatus } from '../../store/selectors/offerSelectors';

import { createOfferPdf } from 'src/modules/offerPdf/services/createOfferPdf';
import { OfferPreviewModal } from '../../components/OfferPreviewModal';

import { paths } from 'src/constants/variables';
import { withRouter } from 'react-router-dom';
import { EditType } from '../DuplicateOffer/DuplicateOfferContainer';
import { fetchOffersListForAllUsers } from '../../store/offerStore';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';

type StateProps = {
    offers: OfferSummary[];
    status: {
        error: boolean;
        loading: boolean;
    };
};

type DispatchProps = {
    fetchOffers: typeof fetchOffersListForAllUsers;
};

type Props = StateProps & DispatchProps & RouteComponentProps;
type State = {
    offerPreviewSummary?: OfferPreviewSummary;
    isConfirmationOpen: boolean;
    selectedOfferId: number;
    discontinuedArticleIdsForSelectedOffer: string;
};

const DISCONTINUED_ARTICLE_DIALOG_TITLE = 'Ausgelaufener Artikel';
const YES_TEXT = 'Ja';
const CANCEL_TEXT = 'Abbrechen';

class SavedOffers extends React.PureComponent<Props, State> {
    state = {
        offerPreviewSummary: undefined,
        isConfirmationOpen: false,
        selectedOfferId: -1,
        discontinuedArticleIdsForSelectedOffer: '',
    };

    componentDidMount() {
        this.props.fetchOffers();
    }

    duplicateOfferAction = async (offerId: OfferSummary['id']) => {
        try {
            this.setState({ selectedOfferId: offerId });
            const { offerItems } = await fetchOfferDetails(offerId);
            const discontinuedItemsIds: string[] = [];
            offerItems.forEach((it) => {
                if (it.discontinued) {
                    discontinuedItemsIds.push(it.variantKey);
                }
            });

            if (discontinuedItemsIds.length > 0) {
                this.setState({ isConfirmationOpen: true });
                this.setState({
                    discontinuedArticleIdsForSelectedOffer: discontinuedItemsIds.join(', '),
                });
            } else {
                this.confirmedDuplication();
            }
        } catch (err) {
            notificationService.error();
            return false;
        }
    };

    confirmedDuplication = () => {
        const path = generatePath(paths.duplicateOffer, {
            id: this.state.selectedOfferId,
            type: EditType.duplicate,
        });
        this.props.history.push(path);
    };

    getDiscontinuedDialogText = () => {
        return `Der/die Artikel ${this.state.discontinuedArticleIdsForSelectedOffer} ist/sind nicht mehr verfügbar und wird/werden bei der Duplizierung entfernt.`;
    };

    editOffer = (offerId: OfferSummary['id']) => {
        const path = generatePath(paths.duplicateOffer, { id: offerId, type: EditType.update });
        this.props.history.push(path);
    };

    render() {
        const { offers, status } = this.props;
        const { offerPreviewSummary } = this.state;

        return (
            <PageWrapper contentCss={wrapperCustomBackgroundStyles}>
                {this.state.isConfirmationOpen && (
                    <ConfirmationDialog
                        title={DISCONTINUED_ARTICLE_DIALOG_TITLE}
                        text={this.getDiscontinuedDialogText()}
                        acceptButtonLabel={YES_TEXT}
                        acceptButtonAction={() => this.confirmedDuplication()}
                        cancelButtonLabel={CANCEL_TEXT}
                        cancelButtonAction={() => this.setState({ isConfirmationOpen: false })}
                    />
                )}

                <AsyncResourceHandler data={{ offers }} {...status}>
                    {(resources) => (
                        <OffersListLayout
                            {...resources}
                            offerActions={{
                                preview: this.previewOffer,
                                duplicate: this.duplicateOfferAction,
                                getPdf: this.downloadOfferPdf,
                                edit: this.editOffer,
                            }}
                        />
                    )}
                </AsyncResourceHandler>
                <OfferPreviewModal
                    offerSummary={offerPreviewSummary}
                    onClick={this.closeOfferPreview}
                />
            </PageWrapper>
        );
    }

    private previewOffer = (offerPreviewSummary: OfferPreviewSummary) => {
        this.setState({ offerPreviewSummary });
    };

    private closeOfferPreview = () => {
        this.setState({ offerPreviewSummary: undefined });
    };

    private downloadOfferPdf = async (offerId: OfferSummary['id']) => {
        try {
            const { offerItems, offerBasics, offerDetails } = await fetchOfferDetails(offerId);
            await createOfferPdf({
                items: offerItems,
                basics: offerBasics,
                details: offerDetails,
                displayCategory: false,
            });
        } catch (err) {
            notificationService.error();
            return false;
        }

        return true;
    };
}

const mapStateToProps: MapState<StateProps> = (state) => ({
    offers: getOffersList(state),
    status: getOffersListStatus(state),
});

const mapDispatchToProps: MapDispatch<DispatchProps> = (dispatch) => ({
    fetchOffers: () => dispatch(fetchOffersListForAllUsers()),
});

export const SavedOffersContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SavedOffers),
);
