import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { keys as ramdaKeys } from 'ramda';

import { DuplicateOfferContainer } from 'src/modules/offer/pages/DuplicateOffer/DuplicateOfferContainer';
import { NewOfferContainer } from 'src/modules/offer/pages/NewOffer/NewOfferContainer';
import { ProductsCatalogPage } from 'src/modules/productsCatalog/ProductsCatalogPage';
import { SavedOffersContainer } from 'src/modules/offer/pages/OffersList/SavedOffersContainer';
import { BrowserWarningOverlay } from 'src/components/BrowserWarningOverlay';

import { refreshToken } from 'src/modules/auth/authActions';
import { getIsTokenChecked } from 'src/modules/auth/authSelectors';

import { FullPageLoader } from '../../components';
import { Casted } from '../../helpers/types';
import { paths } from 'src/constants/variables';

import { browserName, browserVersion } from 'react-device-detect';

const offerPages: Casted<typeof paths, React.ComponentType> = {
    newOffer: NewOfferContainer,
    duplicateOffer: DuplicateOfferContainer,
    savedOffers: SavedOffersContainer,
    products: ProductsCatalogPage,
};

const history = createBrowserHistory();

const browserVersionNo = parseInt(browserVersion);
const browserCompatibilities = [
    {
        name: 'Chrome',
        minimumVersion: process.env.CHROME_MINIMUM_VERSION || 109,
    },
    {
        name: 'Edge',
        minimumVersion: process.env.EDGE_MINIMUM_VERSION || 108,
    },
];

const checkBrowserCompatibility = () => {
    const supportedBrowser = browserCompatibilities.find((browser) => browser.name === browserName);
    const initialLogin = history.location.search !== '';

    if (supportedBrowser && initialLogin) {
        return browserVersionNo < supportedBrowser.minimumVersion;
    } else if (initialLogin) {
        return true;
    }
    return false;
};

export const AppRouter: React.FC = () => {
    const [showBrowserOverlay, setShowBrowserOverlay] = useState<boolean>(
        checkBrowserCompatibility(),
    );

    const toggleBrowserOverlay = () => {
        setShowBrowserOverlay(false);
    };

    return (
        <div>
            <BrowserWarningOverlay onClick={toggleBrowserOverlay} visible={showBrowserOverlay} />
            <Router history={history}>
                <Switch>
                    <Route>
                        <PrivateRoutes />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
};

const PrivateRoutes: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isTokenChecked = useSelector(getIsTokenChecked);

    useEffect(() => {
        dispatch(refreshToken(history.location.search));
    });

    if (!isTokenChecked) {
        return <FullPageLoader viewHeight />;
    }

    return <Routes paths={paths} pages={offerPages} redirectPath={paths.newOffer} />;
};

type RoutesProps = {
    pages: typeof offerPages;
    paths: typeof paths;
    redirectPath?: string;
};

const Routes: React.FC<RoutesProps> = ({ pages, paths, redirectPath }) => (
    <Switch>
        {ramdaKeys(pages).map((name) => (
            <Route key={name} path={paths[name]} exact component={pages[name]} />
        ))}
        {redirectPath && <Redirect to={redirectPath} />}
    </Switch>
);
