/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { Plus, Minus } from 'src/assets/icons';
import { MAX_PRICE_VALUE } from 'src/constants';
import { DataField } from 'src/components/DataField';
import { NumberInput, simpleInputCss } from 'src/components/DataInputs';
import { colors } from 'src/constants/variables';

type SurchargeInputProps = {
    value?: number;
    onChange?: (value?: number) => void;
};

type SurchargeSign = 'plus' | 'minus';

export const SurchargeField: React.FC<SurchargeInputProps> = ({ value, onChange }) => {
    const [sign, setSign] = useState<SurchargeSign>(() => (Number(value) < 0 ? 'minus' : 'plus'));

    const onSignChange = (newValue: SurchargeSign) => {
        setSign(newValue);
        if (value !== undefined && sign !== newValue) {
            onChange?.(Number(value) * -1);
        }
    };

    const onValueChange = (surcharge?: number) => {
        if (surcharge !== undefined) {
            surcharge = Number(surcharge);

            surcharge < 0 && setSign('minus');
            surcharge === 0 && setSign('plus');

            if (surcharge > 0 && sign === 'minus') {
                surcharge *= -1;
            }
        }

        onChange?.(surcharge);
    };
    return (
        <DataField name={<SurchargeName sign={sign} onSignChange={onSignChange} />}>
            <NumberInput
                min={0.0}
                max={MAX_PRICE_VALUE}
                step={0.01}
                precision={2}
                value={value && Math.abs(value)}
                onChange={onValueChange}
                placeholder="0,00"
                currencySuffix
                css={simpleInputCss}
                addComma
                allowEmpty
            />
        </DataField>
    );
};

type SurchargeNameProps = {
    sign: SurchargeSign;
    onSignChange: (value: SurchargeSign) => void;
};

const SurchargeName: React.FC<SurchargeNameProps> = ({ sign, onSignChange }) => (
    <span css={nameStyles.wrapper}>
        <span>Zuschlag</span>
        <span css={nameStyles.icons}>
            <Icon
                component={Plus}
                onClick={() => onSignChange('plus')}
                css={sign === 'plus' && nameStyles.iconActive}
            />
            <Icon
                component={Minus}
                onClick={() => onSignChange('minus')}
                css={sign === 'minus' && nameStyles.iconActive}
            />
        </span>
    </span>
);

const nameStyles = {
    wrapper: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    icons: css`
        display: flex;
        flex-direction: column;
        padding-right: 8px;

        svg {
            opacity: 0.5;
        }
    `,
    iconActive: css`
        && svg {
            opacity: 1;
            fill: ${colors.secondary};
        }
    `,
};
