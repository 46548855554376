/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

import { wideScreenStyles } from 'src/style/styles';
import { colors } from 'src/constants/variables';

import { Image } from './Image';
import { ImagesCarousel } from './ImagesCarousel';
import ImagePlaceHolderSVG from 'src/assets/icons/no_image_placeholder.svg';

type Props = {
    productVariant: ProductVariant;
    updateFormPosition?: (arg: any, arg2: number) => void;
    slickGoTo?: number;
    customCss?: SerializedStyles | SerializedStyles[];
};

export const ImageSection: React.FC<Props> = ({
    productVariant,
    updateFormPosition,
    slickGoTo,
    customCss = [],
}) => {
    const variantImageLinks = productVariant?.imageLinks || [];
    const effectiveImageLinks = variantImageLinks.length
        ? variantImageLinks
        : [ImagePlaceHolderSVG];

    return (
        <div css={[styles.container, customCss]} id="imageSection">
            <ImagesCarousel slickGoTo={slickGoTo}>
                {effectiveImageLinks.map((image) => (
                    <Image
                        key={image}
                        image={image}
                        textUnder={
                            image === ImagePlaceHolderSVG
                                ? 'Produktbild nicht verfügbar'
                                : undefined
                        }
                        name={productVariant?.name}
                        updateFormPosition={updateFormPosition}
                    />
                ))}
            </ImagesCarousel>
        </div>
    );
};

const styles = {
    zoomIn: css`
        display: block;
        left: 24px;
        top: 24px;
        position: absolute;
        height: 24px;
        width: 24px;

        svg {
            height: 100%;
            width: 100%;
            stroke: ${colors.primaryGray};
        }
    `,
    container: css`
        .zoomIcon {
            visibility: hidden;
        }

        height: 100%;
        background-color: ${colors.primary};

        .slick-slide {
            overflow: hidden;
        }

        ${wideScreenStyles(css`
            position: relative;
            margin: 0 16px 0 32px;
        `)};
    `,
};
