export const DEFAULT_AMOUNT_OF_WEARERS = 1;

export const MAX_PRICE_VALUE = 999999999.99;

export const DEFAULT_OFFER_SERVICE_PRICE = 0.41;

export const DEFAULT_OFFER_KMU_INTRO =
    'wir freuen uns über Ihr Interesse an unserem Service zur MEWA Berufskleidung und unterbreiten Ihnen, wie mit Herrn XYZ besprochen, wunschgemäß nachstehendes Angebot.\n' +
    '\n' +
    'Sollten Sie zu dem vorliegenden Angebot weitere Fragen haben, wenden Sie sich bitte an Herrn XYZ (Tel.: XYZ).\n' +
    '\n' +
    'Mit freundlichem Gruß,\n' +
    '\n' +
    'Mewa SE & Co. Vertrieb OHG\n' +
    'Verkaufsleitung H&I XYZ\n' +
    '\n' +
    'i.v. XYZ';

export const DEFAULT_OFFER_KMU_OUTRO =
    'wir sind aufgrund der von Ihnen zur Verfügung gestellten Daten von ca. XYZ einzukleidenden Personen (XYZ Anfahrtsstelle) ausgegangen. Pro Anfahrt berechnen wir eine Pauschale in Höhe von XYZ Euro und gehen wir von einer XYZ Belieferung aus.\n' +
    '\n' +
    'Dies entspricht einem Gesamtpreis pro Woche in Höhe von XYZ Euro.\n' +
    '\n' +
    'An die vorgenannten Preise halten wir uns bis zum XYZ gebunden. Sie verstehen sich zuzüglich gesetzlicher Mehrwertsteuer. Die Abrechnung erfolgt während eines Jahres, da Urlaub und Krankheit in der Kalkulation bereits berücksichtigt wurden. Die Vertragslaufzeit  beträgt XYZ volle Kalenderjahre.\n' +
    '\n' +
    'Es gelten die allgemeinen Vertrags- und Ankaufbedingungen, die wir Ihnen gern auf Anfrage zusenden.\n' +
    '\n' +
    'Mewa SE & Co. Vertrieb OHG\n' +
    'Verkaufsleitung H&I XYZ\n' +
    'XYZ, den XYZ';

export const DEFAULT_OFFER_KMU_HINTS = undefined;
