export const prepareContactFullNameWithTitle = (contactData?: {
    title?: string;
    name?: string;
    surname?: string;
}) =>
    [contactData?.title, contactData?.name, contactData?.surname]
        .filter((element) => !!element)
        .join(' ');

export const prepareWardrobeOptionsData = (
    services: { [key: string]: number } = {},
): WardrobeOptionData[] =>
    Object.keys(services).map((key) => ({ id: parseInt(key, 10), price: services[key] }));
