import background_1 from './backgrounds/background_1.jpg';
import background_2 from './backgrounds/background_2.jpg';
import background_3 from './backgrounds/background_3.jpg';
import background_4 from './backgrounds/background_4.jpg';
import background_5 from './backgrounds/background_5.jpg';
import background_6 from './backgrounds/background_6.jpg';
import stadium from './stadium.png';
import delivery from './delivery.png';

export const offerImages: { [index: string]: string } = {
    background_1,
    background_2,
    background_3,
    background_4,
    background_5,
    background_6,
    stadium,
    delivery,
};
