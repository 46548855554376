import { parseToPrice } from 'src/helpers/utils';

type DetailsKeys =
    | 'weeklyTotalPriceAllItemsValue'
    | 'deliveryPricePerWeek'
    | 'totalPricePerWeekValue';

export const preparePriceFromDetails = (key: DetailsKeys, details?: OfferDetails): string => {
    const price = details && details[key] !== null ? parseToPrice(details[key]!) : '-';
    return `${price} €`;
};
