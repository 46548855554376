/** @jsxImportSource @emotion/react */
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';

type Props = {
    viewHeight?: boolean;
};

export const FullPageLoader: React.FC<Props> = ({ viewHeight }) => (
    <div css={wrapper(viewHeight)}>
        <LoadingOutlined spin css={icon} />
    </div>
);

const wrapper = (viewHeight?: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    height: ${viewHeight ? '100vh' : '100%'};
`;

const icon = css`
    svg {
        height: auto;
        width: 64px;

        fill: ${colors.secondary};
    }
`;
