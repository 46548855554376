import React from 'react';

import { MapObjectValuesToNullable, JsonObject } from '../../helpers/types';
import { ResultServerError, FullPageLoader, ErrorProps } from '../../components';

type Props<T> = {
    children: (data: T) => React.ReactNode;
    data: MapObjectValuesToNullable<T>;
    error: boolean;
    loading: boolean;
    loader?: React.ReactNode;
    errorDetails?: ErrorProps;
};

export const AsyncResourceHandler = <T extends JsonObject>({
    data,
    children,
    error,
    loading,
    loader = <FullPageLoader />,
    errorDetails,
}: Props<T>) => {
    if (error) return <ResultServerError {...errorDetails} />;

    if (loading || Object.values(data).includes(null))
        return <React.Fragment>{loader}</React.Fragment>;

    return <React.Fragment>{children(data as T)}</React.Fragment>;
};
