import {
    set as ramdaSet,
    view as ramdaView,
    lensPath as ramdaLensPath,
    update as ramdaUpdate,
} from 'ramda';

import { AsyncResource, defaultAsyncResource, VariantItem } from 'src/helpers/types';
import { OfferActions } from 'src/modules/offer/store/actions/offerActions';

export type OfferState = AsyncResource<{
    items: VariantItem[];
    basics: OfferBasics | undefined;
    details: OfferDetails | undefined;
}> & {
    errorCode: number | undefined;
};

const initialOfferState: OfferState = {
    ...defaultAsyncResource,
    errorCode: undefined,
};

const offerBasicsLens = ramdaLensPath(['data', 'basics']);

export function offerDataReducer(state = initialOfferState, action: OfferActions): OfferState {
    switch (action.type) {
        case 'OFFER|UPDATE_CONTACT_DETAILS':
        case 'OFFER|UPDATE_CUSTOMER_DETAILS':
            return ramdaSet(
                offerBasicsLens,
                { ...ramdaView(offerBasicsLens, state), ...action.payload },
                state,
            );

        case 'OFFER|RESET_PERSISTED_VALUES':
            return {
                ...initialOfferState,
            };

        case 'OFFER|LOAD_OVERVIEW_REQUEST':
            return {
                ...state,
                error: false,
                loading: true,
            };

        case 'OFFER|LOAD_OVERVIEW_SUCCESS':
            return {
                ...state,
                error: false,
                loading: false,
            };

        case 'OFFER|LOAD_OVERVIEW_FAILURE':
            return {
                ...state,
                error: true,
                errorCode: action.payload,
                loading: false,
            };

        case 'OFFER|UPDATE_OVERVIEW_ITEMS': {
            return {
                ...state,
                data: {
                    items: action.payload,
                    basics: state.data?.basics,
                    details: state.data?.details,
                },
            };
        }

        case 'OFFER_DETAILS|UPDATE_OFFER_ITEM': {
            const indexToUpdate = state.data?.items.findIndex(
                (item) =>
                    (action.payload.cartItemId && item.cartItemId === action.payload.cartItemId) ||
                    (action.payload.offerItemId && item.offerItemId === action.payload.offerItemId),
            );

            return indexToUpdate !== undefined && indexToUpdate >= 0
                ? ({
                      ...state,
                      data: {
                          ...state.data,
                          items: ramdaUpdate(
                              indexToUpdate,
                              action.payload,
                              state.data?.items || [],
                          ),
                      },
                  } as OfferState)
                : state;
        }

        case 'OFFER|UPDATE_OVERVIEW_BASICS':
            return ramdaSet(
                offerBasicsLens,
                { ...ramdaView(offerBasicsLens, state), ...action.payload },
                state,
            );

        case 'OFFER|REMOVE_ITEM_SUCCESS':
            return {
                ...state,
                data: {
                    items: (state.data?.items || []).filter(
                        (item) => item.cartItemId !== action.payload,
                    ),
                    basics: state.data?.basics,
                    details: state.data?.details,
                },
            };

        case 'OFFER|CLEAR_CART_SUCCESS':
            return {
                ...state,
                data: {
                    items: [],
                    basics: state.data?.basics,
                    details: state.data?.details,
                },
            };
    }

    return state;
}
