/** @jsxImportSource @emotion/react */
import React from 'react';
import { Row } from 'antd';
import { css } from '@emotion/react';

export const PageHeader: React.FC = () => (
    <Row css={styles.wrapper} justify="end">
        {/* TODO: Hidden until implementation MMI-2411 */}
        {/*<Col>*/}
        {/*    <ProductsSearch />*/}
        {/*</Col>*/}
    </Row>
);

const styles = {
    wrapper: css`
        top: 0;
        position: sticky;
        padding: 64px 0;
        background: rgba(240, 240, 240, 0.97);
    `,
};
