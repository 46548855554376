/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form } from 'antd';
import { css } from '@emotion/react';

import { MAX_PRICE_VALUE } from 'src/constants';
import { DataField } from 'src/components/DataField';
import { NumberInput, simpleInputCss } from 'src/components/DataInputs';

import { SurchargeField } from './SurchargeField';
import { TotalPrice } from './TotalPrice';
import { fourCharacters } from 'src/components/DataInputs/NumberInput';

type Props = {
    totalPrice: number;
};

export const PriceSection: React.FC<Props> = ({ totalPrice }) => (
    <div css={styles.wrapper}>
        <DataField name="Ausstattung x-fach">
            <Form.Item name="itemsPerWearer">
                <NumberInput
                    min={1}
                    precision={0}
                    placeholder="0"
                    css={simpleInputCss}
                    parser={fourCharacters}
                    allowEmpty
                />
            </Form.Item>
        </DataField>

        <DataField name="wöchentlicher Mietpreis je Stk">
            <Form.Item name="basePrice">
                <NumberInput
                    min={0.01}
                    max={MAX_PRICE_VALUE}
                    step={0.01}
                    precision={2}
                    placeholder="0,00"
                    currencySuffix
                    css={simpleInputCss}
                    addComma
                    allowEmpty
                />
            </Form.Item>
        </DataField>

        <Form.Item name="surcharge">
            <SurchargeField />
        </Form.Item>

        <TotalPrice totalPrice={totalPrice} />
    </div>
);

const styles = {
    wrapper: css`
        margin: 0 16px;
    `,
};
