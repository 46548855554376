/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Carousel } from 'antd';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { ArrowLeft, ArrowRight, Close, Resize } from 'src/assets/icons';
import { useCarousel } from 'src/hooks/useCarousel';
import { wideScreenStyles } from 'src/style/styles';
import { colors, zIndices } from 'src/constants/variables';
import { JsonObject } from 'src/helpers/types';

import { ImpressionImage } from './ImpressionImage';

type Props = {
    collectionImpressions: JsonObject;
};

export const ImpressionsCarousel: React.FC<Props> = ({ collectionImpressions }) => {
    const ref = useCarousel();
    const [fullscreen, setFullscreen] = useState<boolean>(false);

    const placeholderImage = Object.values(collectionImpressions)[0];

    return fullscreen ? (
        <div css={styles.fullscreenWrapper}>
            <div css={[styles.fullscreenHeader]}>
                <Icon
                    component={Close}
                    css={styles.fullscreenCloseIcon}
                    onClick={() => setFullscreen(false)}
                    data-test={'impressionsCarouselFullScreenCloseButton'}
                />
            </div>
            <Icon
                css={styles.navIcon}
                component={ArrowLeft}
                onClick={() => ref.current && ref.current.prev()}
                data-test={'impressionsCarouselFullScreenLeftArrow'}
            />
            <Carousel ref={ref} css={styles.carousel}>
                {Object.values(collectionImpressions).map((image) => (
                    <ImpressionImage key={image} image={image} />
                ))}
            </Carousel>
            <Icon
                css={styles.navIcon}
                component={ArrowRight}
                onClick={() => ref.current && ref.current.next()}
                data-test={'impressionsCarouselFullScreenRightArrow'}
            />
        </div>
    ) : (
        <div css={styles.placeholderImage(placeholderImage)}>
            {placeholderImage && (
                <Icon
                    component={Resize}
                    css={styles.openFullscreenIcon}
                    onClick={() => setFullscreen(true)}
                    data-test={'impressionsCarouselButton'}
                />
            )}
        </div>
    );
};

const styles = {
    placeholderImage: (imageUrl: string) => css`
        background-image: url(${imageUrl});
        background-color: ${colors.backgroundDark};
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: 50% 0;

        border-radius: 5px;

        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 20px);

        ${wideScreenStyles(css`
            height: 300px;
        `)};
    `,
    openFullscreenIcon: css`
        height: 52px;
        width: 52px;

        svg {
            height: 52px;
            width: 52px;
        }
    `,

    navIcon: css`
        position: absolute;

        padding: 12px;

        top: 50%;
        transform: translateY(-50%);

        :first-of-type {
            left: 16px;
        }

        :last-of-type {
            right: 16px;
        }

        svg {
            height: 24px;
            width: auto;

            polygon {
                fill: ${colors.secondary};
            }
        }

        :after {
            content: '';

            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            border-radius: 50%;

            background-color: ${colors.primary};
            opacity: 0.25;
        }
    `,
    carousel: css`
        .slick-slide {
            > div {
                overflow: hidden;
            }
        }

        .slick-dots li {
            width: 24px;

            & button {
                opacity: 1;
                background: ${colors.primary};
                border-radius: 50px;
            }
        }

        .slick-dots li.slick-active {
            width: 40px;

            & button {
                background: ${colors.secondary};
            }
        }
    `,

    fullscreenWrapper: css`
        position: fixed;
        z-index: ${zIndices.zIndex1200};

        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        .ant-carousel {
            height: 100%;
            background-color: white;

            div {
                height: 100%;

                div {
                    height: 100%;

                    .slick-slide {
                        height: 100%;
                    }
                }
            }
        }
    `,
    fullscreenHeader: css`
        position: absolute;
        top: 0;
        right: 0;
        width: 52px;
        height: 52px;
        margin: 32px;
        z-index: ${zIndices.zIndex1200};

        display: flex;
        font-size: 20px;
        background-color: rgba(31, 31, 40, 0.5);
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    `,
    fullscreenCloseIcon: css`
        padding: 18px;
        border-radius: inherit;

        svg {
            height: 16px;
            width: auto;

            path {
                fill: #fff;
            }
        }
    `,
};
