import { combineReducers } from 'redux';
import { productCollectionsReducer } from './reducers/productCollectionsReducer';

import { productGroupsReducer } from './reducers/productGroupsReducer';
import { productCategoriesReducer } from './reducers/productCategoriesReducer';

export type ProductsCatalogState = ReturnType<typeof productsCatalogReducer>;

export const productsCatalogReducer = combineReducers({
    groups: productGroupsReducer,
    categories: productCategoriesReducer,
    collections: productCollectionsReducer,
});
