/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Input as AppInput } from 'src/components/FormElements';
import { mobileScreenStyles } from 'src/style/styles';
import { fontSizes, colors } from 'src/constants/variables';

export const OfferName: React.FC<{ errorMsg?: false | string }> = ({ errorMsg }) => (
    <React.Fragment>
        <div css={styles.label}>
            {errorMsg ? (
                <span css={styles.error}>{errorMsg}</span>
            ) : (
                'Bitte geben Sie einen Namen für Ihr Angebot an'
            )}
        </div>
        <AppInput.Text
            placeholder="Namen Ihres Angebotes hier einfügen"
            name="name"
            required
            formItemCss={styles.input}
        />
    </React.Fragment>
);

const styles = {
    input: css`
        width: 400px;

        ${mobileScreenStyles(css`
            width: 100%;
            max-width: 400px;
        `)}
    `,
    label: css`
        font-family: 'Open Sans', sans-serif;
        font-size: ${fontSizes.mSmall};
        margin-bottom: 52px;
    `,
    error: css`
        color: ${colors.secondary};
    `,
};
