/** @jsxImportSource @emotion/react */
import { Radio } from 'antd';
import { css, SerializedStyles } from '@emotion/react';

import { CustomRadio } from './CustomRadio';

export interface Props<T = boolean> {
    value?: T;
    onChange?: (value: T) => void;
    values: RadioValue<T>[];
    customCss?: SerializedStyles;
}

export const RadioGroup = <P extends unknown>({ value, onChange, values, customCss }: Props<P>) => (
    <Radio.Group
        onChange={({ target: { value } }) => onChange?.(value)}
        value={value}
        css={[styles.radioGroup, customCss]}
    >
        {values.map((element) => (
            <CustomRadio<P> key={element.label} {...element} />
        ))}
    </Radio.Group>
);

const styles = {
    radioGroup: css`
        display: flex;
        justify-content: space-around;
    `,
};
