import { css } from '@emotion/react';
import { colors } from 'src/constants/variables';

export const simpleInputCss = css`
    &,
    :hover,
    :focus {
        border-bottom: 1px solid ${colors.secondaryDark};
        color: ${colors.fontHeaderSecondary};
    }
`;

export const toggleInputCss = css`
    margin-bottom: unset;

    .ant-form-item-control-input {
        min-height: unset;
    }
`;
