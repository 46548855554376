import * as Redux from 'redux';
import thunk from 'redux-thunk';
import { MapStateToProps } from 'react-redux';
import { compose } from 'redux';

import { productReducer, ProductState } from 'src/modules/product/store/productReducer';
import { CartState, cartReducer } from 'src/modules/cart/store/cartReducer';
import {
    productCollectionItems,
    ProductCollectionItemsState,
} from 'src/modules/productsList/store/productCollectionItemsReducer';
import { AuthState, authReducer } from 'src/modules/auth/authReducer';
import {
    productsCatalogReducer,
    ProductsCatalogState,
} from './modules/productsCatalog/store/productsCatalogReducer';
import { OfferState, offerReducer } from './modules/offer/store/reducers/offerReducer';
import { JsonObject } from 'src/helpers/types';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

export type ThunkAction = (dispatch: Redux.Dispatch<any> | any, getState: () => RootState) => void;

export type RootState = {
    auth: AuthState;
    cart: CartState;
    offer: OfferState;
    product: ProductState;
    productsCatalog: ProductsCatalogState;
    productCollectionItems: ProductCollectionItemsState;
};

export type MapState<TS, TO = JsonObject> = MapStateToProps<TS, TO, RootState>;
export type MapDispatch<TA, TO = JsonObject> = (dispatch: any, ownProps: TO) => TA; // Thunk actions crash redux types

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || Redux.compose;

export const rootReducer = Redux.combineReducers<RootState>({
    auth: authReducer,
    cart: cartReducer,
    offer: offerReducer,
    product: productReducer,
    productsCatalog: productsCatalogReducer,
    productCollectionItems: productCollectionItems,
});

export const store = Redux.createStore(
    rootReducer,
    Redux.compose(Redux.applyMiddleware(thunk), composeEnhancers()),
);
