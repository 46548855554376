import React from 'react';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';

import { StatusCodes } from 'http-status-codes';

import { AsyncResourceHandler } from 'src/modules/app';
import { ErrorProps } from 'src/components';
import { CustomPrompt } from 'src/components/CustomPrompt';
import { ContactDetailsValues } from 'src/components/ContactDetailsModal';
import { notificationService } from 'src/modules/offer/service';
import { paths } from 'src/constants/variables';
import { MapDispatch, MapState } from 'src/store';
import { JsonObject, VariantItem } from 'src/helpers/types';
import { noop } from 'src/helpers/utils';

import { CancelOfferOverlay } from 'src/modules/offer/components/CancelOfferOverlay';
import { clearProduct, loadOfferItem } from 'src/modules/product/store/productActions';
import { productService } from 'src/modules/product/store/productService';

import { OfferFormItem, OfferFormValues } from '../../components/types';
import { OfferLayout } from '../../components/OfferLayout';
import { CustomerDetailsValues } from 'src/components/CustomerDetailsModal';
import { prepareVariantItem } from '../../helpers/offerData';
import { getDuplicateFormInitValues } from '../../helpers/offerForm';
import { submitOfferForm } from '../../service/offerServices';
import {
    addOfferItems,
    removeOfferItem,
    updateOfferItem,
    updateOverviewContactDetails,
    updateOverviewCustomerDetails,
} from '../../store/actions/offerDetailsActions';
import { getOfferPreviewState, getOverviewAssets } from '../../store/selectors/offerSelectors';
import { ProductsCatalogModal } from '../../components/ProductsCatalogModal';
import { loadDuplicateOverview } from '../../store/offerStore';
import { DetailsOfferState } from '../../store/reducers';
import { PopupDialog } from '../../../../components/PopupDialog';

type StateProps = {
    offerPreviewState: DetailsOfferState;
    offerOverviewAssets: OfferAssets;
};

type DispatchProps = {
    loadDuplicateOverview: typeof loadDuplicateOverview;
    updateContactDetails: typeof updateOverviewContactDetails;
    updateCustomerDetails: typeof updateOverviewCustomerDetails;
    loadOfferItemDetails: typeof loadOfferItem;
    clearOfferItemDetails: typeof clearProduct;
    updateOfferItemDetails: typeof updateOfferItem;
    removeOfferItem: typeof removeOfferItem;
    addOfferItems: typeof addOfferItems;
};

type Props = StateProps & DispatchProps & RouteComponentProps<{ id: string; type: EditType }>;

type State = {
    selectedItemId: number | null;
    processComplete: boolean;
    showCancelOfferOverlay: boolean;
    showProductsCatalogModal: boolean;
    showDiscontinuedCannotOpenModal: boolean;
};

export enum EditType {
    duplicate = 'duplicate',
    update = 'update',
}

const DISCONTINUED_ARTICLE = 'Ausgelaufener Artikel';

const DISCONTINUED_ARTICLE_TEXT_POPUP =
    'Dieser Artikel kann nicht bearbeitet werden, da er aus dem Sortiment genommen wurde. Eine andere Farbe oder ein anderes Gewicht des Produkts kann aus dem Katalog ausgewählt werden.';

class DuplicateOffer extends React.PureComponent<Props, State> {
    state = {
        selectedItemId: null,
        offerItems: [],
        processComplete: false,
        showCancelOfferOverlay: false,
        showProductsCatalogModal: false,
        showDiscontinuedCannotOpenModal: false,
    };

    componentDidMount() {
        const offerId = Number.parseInt(this.props.match.params.id);
        this.props.loadDuplicateOverview(offerId, this.props.match.params.type);
    }

    setProductsCatalogModalState = (isVisible: boolean) => {
        this.setState({ showProductsCatalogModal: isVisible });
    };

    onItemInlineChange = async (item: VariantItem, value?: JsonObject) => {
        const newItem = { ...item, ...value };
        this.props.updateOfferItemDetails(newItem);
    };

    onOfferItemEdit = async (id: VariantItem['offerItemId'], item: VariantItem) => {
        if (item.discontinued) {
            this.setState({ showDiscontinuedCannotOpenModal: true });
        } else {
            if (id) {
                this.setState({ selectedItemId: id });
                this.props.loadOfferItemDetails(item!);
            } else {
                notificationService.error();
            }
        }
    };

    onOfferItemCancelEdit = () => {
        this.setState({ selectedItemId: null });
    };

    onOfferItemSubmit = async (
        product: Product,
        variant: ProductVariant,
        formData: OfferFormItem,
        totalPrice: number,
    ) => {
        try {
            const { selectedItemId } = this.state;
            const offerItemData = prepareVariantItem(product, variant, formData, totalPrice);
            this.props.updateOfferItemDetails({
                offerItemId: selectedItemId,
                cartItemId: null,
                ...offerItemData,
            });
        } catch {
            notificationService.error('Bitte aktualisieren Sie fehlende Dateneingaben');
        }

        this.setState({ selectedItemId: null });
        this.props.clearOfferItemDetails();
    };

    afterOfferSubmit = async () => {
        this.setState({ processComplete: true });
        this.setState({ showCancelOfferOverlay: true });
        this.props.history.push(generatePath(paths.savedOffers));
    };

    onFormSubmit = async (formValues: OfferFormValues) => {
        const { items, basics } = this.props.offerPreviewState.data!;
        const shouldEdit = this.props.match.params.type === EditType.update;
        const offerId = Number.parseInt(this.props.match.params.id);

        return submitOfferForm(
            items,
            basics!,
            formValues,
            this.afterOfferSubmit,
            shouldEdit,
            shouldEdit ? offerId : undefined,
        );
    };

    render() {
        const shouldEdit = this.props.match.params.type === EditType.update;
        const { offerPreviewState, offerOverviewAssets, ...rest } = this.props;

        const offerItems = offerPreviewState.data?.items;
        const offerBasics = offerPreviewState.data?.basics;
        const offerDetails = offerPreviewState.data?.details;
        const offerSummary = offerPreviewState.data?.summary;

        const errorDetails =
            offerPreviewState.errorCode === StatusCodes.INTERNAL_SERVER_ERROR
                ? ({
                      status: StatusCodes.INTERNAL_SERVER_ERROR,
                      subTitle: <React.Fragment />,
                  } as ErrorProps)
                : undefined;

        return (
            <>
                <CustomPrompt
                    when={!this.state.processComplete && !this.state.showCancelOfferOverlay}
                >
                    {(onConfirm, onCancel) => (
                        <CancelOfferOverlay onCancel={onCancel} onConfirm={onConfirm} />
                    )}
                </CustomPrompt>

                {this.state.showDiscontinuedCannotOpenModal && (
                    <PopupDialog
                        title={DISCONTINUED_ARTICLE}
                        text={DISCONTINUED_ARTICLE_TEXT_POPUP}
                        closeDialog={() =>
                            this.setState({ showDiscontinuedCannotOpenModal: false })
                        }
                    ></PopupDialog>
                )}

                <CancelOfferOverlay
                    onCancel={this.toggleCancelOfferOverlay}
                    onConfirm={noop}
                    visible={this.state.showCancelOfferOverlay}
                />

                <ProductsCatalogModal
                    isVisible={this.state.showProductsCatalogModal}
                    onAddProducts={this.props.addOfferItems}
                    onClick={() => this.setProductsCatalogModalState(false)}
                />

                <AsyncResourceHandler
                    data={{ offerBasics, offerItems }}
                    error={offerPreviewState.error}
                    loading={offerPreviewState.loading}
                    errorDetails={errorDetails}
                >
                    {({ offerBasics, offerItems }) => (
                        <OfferLayout
                            {...rest}
                            rotationRates={offerOverviewAssets.rotationRates}
                            initFormValues={getDuplicateFormInitValues(offerDetails, offerSummary)}
                            offerItems={offerItems || []}
                            offerDetails={offerDetails}
                            customerCompany={offerBasics?.customerCompany}
                            customerContact={offerBasics?.customerContact}
                            employeeContact={offerBasics?.employeeContact}
                            availableColors={offerOverviewAssets.availableColors}
                            availableWeights={offerOverviewAssets.availableWeights}
                            offerServices={offerBasics?.wardrobeOptions || []}
                            includeTermsAndConditions={
                                offerBasics?.includeTermsAndConditions || true
                            }
                            contactComments={offerBasics?.contactComments}
                            assistantContact={offerBasics?.assistantContact}
                            onContactDetailsSubmit={this.submitContactDetails}
                            onCustomerDetailsSubmit={this.submitCustomerDetails}
                            changeItemColor={this.changeItemColor}
                            changeItemWeight={this.changeItemWeight}
                            onOfferItemClick={this.onOfferItemEdit}
                            removeOfferItem={this.removeOfferItem}
                            onOfferSubmit={this.onFormSubmit}
                            onItemInlineChange={this.onItemInlineChange}
                            onOfferItemCancelEdit={this.onOfferItemCancelEdit}
                            submitOfferItem={this.onOfferItemSubmit}
                            selectedItemId={this.state.selectedItemId}
                            changeItemPricing={this.changeItemPricing}
                            onAddProductsFromCatalogButtonClick={() =>
                                this.setProductsCatalogModalState(true)
                            }
                            approveOfferOnSave
                            showAddProductsButton={true}
                            shouldEdit={shouldEdit}
                        />
                    )}
                </AsyncResourceHandler>
            </>
        );
    }

    private submitContactDetails = ({
        assistantContact = {},
        employeeContact = {},
        contactComments,
    }: ContactDetailsValues) => {
        this.props.updateContactDetails(
            employeeContact as ContactData,
            assistantContact as ContactData,
            contactComments,
        );
        notificationService.success('Kontaktdaten wurden gespeichert.');
    };

    private submitCustomerDetails = ({
        customerCompany,
        customerContact,
    }: Required<CustomerDetailsValues>) => {
        this.props.updateCustomerDetails(customerCompany, customerContact);
        notificationService.success('Kontaktdaten wurden gespeichert.');
    };

    private changeItemColor = async (offerItemId: number, colorKey: string) => {
        try {
            const offerItem = this.props.offerPreviewState.data?.items.find(
                (item) => item.offerItemId === offerItemId,
            );

            if (offerItem) {
                const variantOverview = await productService.fetchVariantOverview(
                    offerItem.modelId,
                    colorKey,
                );

                this.props.updateOfferItemDetails({ ...offerItem, ...variantOverview });

                return true;
            }
        } catch {
            noop();
        }

        return false;
    };

    private changeItemWeight = async (offerItemId: number, weight: number) => {
        try {
            const offerItem = this.props.offerPreviewState.data?.items.find(
                (item) => item.offerItemId === offerItemId,
            );

            if (offerItem) {
                const variantOverview = await productService.fetchVariantOverview(
                    offerItem.modelId,
                    offerItem.variantColor.key,
                    weight,
                );
                this.props.updateOfferItemDetails({ ...offerItem, ...variantOverview });

                return true;
            }
        } catch {
            noop();
        }

        return false;
    };

    private changeItemPricing = async (item: VariantItem, valueChanges: CartItemPricing) => {
        this.props.updateOfferItemDetails({
            ...item,
            itemsPerWearer: valueChanges.itemsPerWearer || null,
        });
    };

    private toggleCancelOfferOverlay = () => {
        this.setState({ showCancelOfferOverlay: !this.state.showCancelOfferOverlay });
    };

    private removeOfferItem = async (cartItemId: number) => {
        try {
            await this.props.removeOfferItem(cartItemId);
            notificationService.success('Artikel aus dem Warenkorb entfernt');
        } catch {
            notificationService.error();
        }
    };
}

const mapStateToProps: MapState<StateProps> = (state) => ({
    offerPreviewState: getOfferPreviewState(state),
    offerOverviewAssets: getOverviewAssets(state),
});

const mapDispatchToProps: MapDispatch<DispatchProps, RouteProps> = (dispatch) => ({
    loadDuplicateOverview: (offerId, type) => dispatch(loadDuplicateOverview(offerId, type)),
    updateCustomerDetails: (customerCompany, customerContact) =>
        dispatch(updateOverviewCustomerDetails(customerCompany, customerContact)),
    updateContactDetails: (employeeContact, assistantContact, contactComments) =>
        dispatch(updateOverviewContactDetails(employeeContact, assistantContact, contactComments)),
    loadOfferItemDetails: (offerItem) => dispatch(loadOfferItem(offerItem)),
    clearOfferItemDetails: () => dispatch(clearProduct()),
    updateOfferItemDetails: (offerItem) => dispatch(updateOfferItem(offerItem)),
    removeOfferItem: (cartItemId) => dispatch(removeOfferItem(cartItemId)),
    addOfferItems: (offerItems: VariantItem[]) => dispatch(addOfferItems(offerItems)),
});

//Todo rename according to usage
export const DuplicateOfferContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DuplicateOffer),
);
