/** @jsxImportSource @emotion/react */
import React from 'react';

import { listStyle, warningText } from 'src/style/styles';

type Props = { information: string[]; warningText1: boolean; warningText2: boolean };

export const Information: React.FC<Props> = ({ information, warningText1, warningText2 }) => (
    <>
        <ul css={listStyle}>
            {information.map((value) => (
                <li key={value}>{value}</li>
            ))}
        </ul>

        {warningText1 ? (
            <label css={warningText}>
                Wichtig: Die Datierung der Norm im jeweiligen gelieferten Produkt entspricht wegen
                regelmäßiger Re-Zertifizierung der Produkte nicht notwendigerweise der aktuellen
                Version.
            </label>
        ) : null}

        {warningText1 && warningText2 ? (
            <>
                <br />
                <br />
            </>
        ) : null}

        {warningText2 ? (
            <label css={warningText}>
                ** Die Schutzkleidung hat reflektierende Designelemente; sie ist kein Warnschutz.
            </label>
        ) : null}
    </>
);
