/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { WardrobeOptionsServiceList } from 'src/modules/offer/components/WardrobeOptionsServiceList';
import { preparePriceFromDetails } from './preparePriceFromDetails';
import {
    OfferPreviewDetailsElement,
    offerPreviewDetailsElementStyles,
} from './OfferPreviewDetailsElement';

type Props = {
    details?: OfferDetails;
    services: WardrobeOption[];
};

export const OfferPreviewDetails: React.FC<Props> = ({ details, services }) => (
    <div css={styles.wrapper}>
        <WardrobeOptionsServiceList
            wardrobeOptionsData={details?.wardrobeOptionsData}
            services={services}
            styles={offerPreviewDetailsElementStyles}
        />

        <OfferPreviewDetailsElement
            price={details?.amountOfWearers || '-'}
            title="Einzukleidende Personen"
        />

        <div css={styles.space}>
            {details?.weeklyTotalPriceAllItems && (
                <OfferPreviewDetailsElement
                    price={preparePriceFromDetails('weeklyTotalPriceAllItemsValue', details)}
                    title="Wöchentlicher Mietpreis/Person"
                />
            )}

            <OfferPreviewDetailsElement
                price={preparePriceFromDetails('deliveryPricePerWeek', details)}
                title="Servicepauschale pro Woche je Anfahrstelle"
            />

            {details?.totalPricePerWeek && (
                <OfferPreviewDetailsElement
                    price={preparePriceFromDetails('totalPricePerWeekValue', details)}
                    title="Wöchentlicher Mietpreis Gesamt"
                />
            )}
        </div>
    </div>
);

const styles = {
    wrapper: css`
        margin-top: 32px;
        margin-right: 40px;
    `,
    space: css`
        margin-top: 30px;
    `,
};
