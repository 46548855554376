import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { SerializedStyles } from '@emotion/react';

import { wideScreenBreakpoint } from 'src/constants/variables';

import { NavigationForMobile, listStates } from './NavigationForMobile';
import { NavigationWithCategories } from './NavigationWithCategories';
import { NavigationWithGroups } from './NavigationWithGroups';
import { NavigationWrapper } from './NavigationWrapper';

type Props = {
    open: boolean;
    showGroups: boolean;
    onClose: () => void;

    productGroups: ProductGroup[];
    productCategories: ProductCatalogItemsList;
    productCollections: ProductCatalogItemsList;
    onCollectionSelect: (collection: ProductCatalogItem) => void;
    selectedGroupId: string | undefined;
    selectedCollectionId: string;
    customCss?: SerializedStyles;
};

export const CatalogNavigation: React.FC<Props> = ({
    open,
    showGroups,
    customCss,
    onClose,
    ...props
}) => {
    const isWide = useMediaQuery('only screen and (min-width: ' + wideScreenBreakpoint);

    if (!isWide) {
        return (
            <NavigationWrapper visible={open} onClose={onClose} customCss={customCss}>
                <NavigationForMobile
                    onClose={onClose}
                    {...props}
                    currentList={showGroups ? listStates.GROUP : listStates.CATEGORY}
                />
            </NavigationWrapper>
        );
    }

    return showGroups ? (
        <NavigationWrapper visible={open} onClose={onClose} customCss={customCss}>
            <NavigationWithGroups onClose={onClose} {...props} />
        </NavigationWrapper>
    ) : (
        <NavigationWrapper visible={open} onClose={onClose} customCss={customCss}>
            <NavigationWithCategories onClose={onClose} {...props} />
        </NavigationWrapper>
    );
};
