/** @jsxImportSource @emotion/react */
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import React from 'react';
import { offerImages } from 'src/assets/images/offer';

import { colors } from 'src/constants/variables';
import { beautifyName } from '../../helpers/utils';

type Props = {
    onClick: () => void;
    selected: string;
    image: string;
    description: string;
    checked: string;
};

export const ImageTile: React.FC<Props> = ({ selected, description, image, onClick, checked }) => {
    const renderSelectedTile = () => (
        <>
            <div css={styles.selected} />
            <CheckOutlined css={styles.check} />
        </>
    );

    return (
        <div
            css={styles.imageTile}
            onClick={onClick}
            data-test={`${beautifyName(image)}_IMAGE_WRAPPER`}
        >
            <img
                src={offerImages[image]}
                alt={image}
                css={styles.image}
                data-test={`${beautifyName(image)}_IMAGE`}
            />

            {((!checked && selected === image) || checked === image) && renderSelectedTile()}

            <div css={styles.description}>{description}</div>
        </div>
    );
};

const styles = {
    description: css`
        margin: 12px;
        color: ${colors.fontSecondaryDark};
    `,
    image: css`
        width: 100%;
        height: 100%;
    `,
    imageTile: css`
        width: 260px;
        height: 167px;
        margin-right: 6px;
        margin-bottom: 55px;
        position: relative;
        cursor: pointer;
    `,
    selected: css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.25;
        background-color: ${colors.secondary};
    `,
    check: css`
        position: absolute;
        width: 60px;
        height: 60px;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        background-color: ${colors.primary};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 22px;
            height: 22px;
            fill: ${colors.success};
        }
    `,
};
