/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, Interpolation, Theme } from '@emotion/react';

import { Header } from './Header';

type Props = {
    wrapperStyles?: Interpolation<Theme>;
};

export const HeaderSticky: React.FC<Props> = ({ children, wrapperStyles, ...rest }) => (
    <div css={[sticky, wrapperStyles]}>
        <Header css={header} {...rest}>
            {children}
        </Header>
    </div>
);

const sticky = css`
    top: 0;
    position: sticky;
    padding-top: 32px;
    padding-bottom: 32px;

    background: rgba(240, 240, 240, 0.97);
`;
const header = css`
    margin-top: 0;
    margin-bottom: 0;
`;
