import { css } from '@emotion/react';

import { wideScreenStyles } from 'src/style/styles';
import { colors, zIndices } from 'src/constants/variables';

export const navigationStyles = {
    modal: (width: number) => css`
        top: 180px;
        position: fixed;
        z-index: ${zIndices.zIndex1200};
        width: ${width}px;
        height: calc(100vh - 180px - 70px);

        ${wideScreenStyles(css`
            top: 195px;
            height: calc(100vh - 82px);
        `)};
    `,
    mainRow: css`
        height: 100%;
        width: 100%;
    `,
    background: css`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent;
    `,
    content: css`
        width: 100%;
        height: 55vh;
        background-color: ${colors.primary};
        box-shadow: 0 3px 6px #00000029;

        border-radius: 5px;
        padding: 40px 32px;

        // -- @ToDo - Remove this temporary solution, when we've decide what next with MegaNavigation on smaller screens
        max-height: calc(100vh - 216px - 70px);
        @media (max-height: calc(420px + 70px + 216px)) {
            overflow-y: auto;
            max-height: calc(100vh - 216px - 70px);
        }
        @media (min-height: calc(420px + 70px + 216px + 1px)) {
            & > .ant-col {
                max-height: 100%;
            }
        }
        // --
    `,
};
