import { endpoints } from 'src/constants/variables';
import { fileDownload } from 'src/helpers/fileDownload';
import { httpService } from 'src/modules/app/service/httpService';

const fetchOffersList = async (): Promise<OfferSummary[]> =>
    httpService<OfferSummary[]>({
        url: endpoints.offersList(),
    });

const downloadOfferPdf = async (offerId: OfferSummary['id'], offerName: OfferSummary['name']) =>
    fileDownload({
        url: endpoints.offerPdf(offerId),
        fileName: `${offerName}.pdf`,
    });

export const offersListsServices = {
    fetchOffersList,
    downloadOfferPdf,
};
