export const api = process.env.REACT_APP_API;

export const endpoints = {
    collection: (id: string): string => `products/collection?collectionId=${id}`,
    product: (id: string): string => `products/model?modelId=${id}`,
    variant: (modelId: string, colorKey: string, weight?: number): string =>
        `products/variant?modelId=${modelId}&colorKey=${colorKey}` +
        (weight ? `&weight=${weight}` : ''),

    cart: 'cart',
    cartItem: (id: number): string => `cart/${id}`,
    cartItemColor: (id: number): string => `cart/color/${id}`,
    cartItemWeight: (id: number): string => `cart/weight/${id}`,
    cartItemPricing: (id: number): string => `cart/pricing/${id}`,
    cartStatus: 'cart/status',
    clearCart: 'cart/emptyCart',

    offers: (id?: number): string => `offer` + (id ? `/${id}` : ''),
    offersList: (): string => `offer`,
    offerInit: (cartItemIds: number[] | null, wardrobeOptions: WardrobeOptionType): string => {
        const cartItemIdsAvailable = !!cartItemIds;

        return (
            `offer/init?&wardrobeOptions=${wardrobeOptions}` +
            (cartItemIdsAvailable && cartItemIds ? `&cartItemIds=${cartItemIds.join(',')}` : '')
        );
    },
    offerEditInit: (offerId: number): string =>
        `offer/edit?offerId=${offerId}&&wardrobeOptions=BASIC`,
    offerDuplicate: (offerId: number): string =>
        `offer/duplicate?offerId=${offerId}&&wardrobeOptions=BASIC`,
    offerDetails: (offerId: number, wardrobeOptions: WardrobeOptionType = 'ALL'): string =>
        `offer/${offerId}?wardrobeOptions=${wardrobeOptions}`,
    offerPdf: (offerId: number): string => `offer/pdf/${offerId}`,
    initOfferItem: (): string => 'offer/init-offer-items',
};

export const paths = {
    newOffer: '/new-offer',
    duplicateOffer: '/edit/:type?/:id?',
    savedOffers: '/saved-offers',
    products: '/products/:id?',
};

export const externalPaths = {
    mewaArena:
        'https://mewa.de/newsroom/newsfeed/alle-news-im-ueberblick/2021/de/202107-mewa-arena/',
    mewaPrivacyStatement: 'https://www.mewa.de/sonstiges/datenschutzerklaerung/',
};

export const colors = {
    primary: '#ffffff',
    primaryGray: '#e4e5e9',
    primaryDark: '#575757',

    secondary: '#b82121',
    secondaryBright: '#f9f8f8',
    secondaryDark: '#d9d9d9',
    secondaryLight: '#f4abaa',

    fontPrimary: '#ffffff',
    fontPrimaryDark: '#868685',

    fontSecondary: '#575757',
    fontSecondaryBright: '#d9d9d9',
    fontSecondaryDark: '#1f1f28',
    fontSecondaryAccent: '#e2a6a6',

    fontAppointment: '#001667',

    fontHeader: '#1f1f28',
    fontHeaderSecondary: '#2b2b2a',

    fontMarketingInfo: '#7b8898',

    background: '#f0f0f0',
    backgroundDark: '#1F2828',
    backgroundModal: '#f9f8f8',
    backgroundError: '#f4abaa',
    backgroundTileBright: '#f8f8f8',

    selectedAppointment: '#E4E5E9',

    icon: '#1e3264',
    iconGray: '#bcbcbe',
    iconTime: '#bdbdbd',
    modalIconClose: '#202028',
    link: '#b6b6b6',

    done: '#0fb956',
    postpone: '#1f1f28',
    confirm: '#168843',

    hover: '#8d1a1b',
    horizontalLine: 'rgba(217, 217, 217, 0.5)',
    imageOpacity: 'rgba(0, 0, 0, 0.6)',
    shadow: 'rgba(0, 0, 0, 0.15)',
    ctaShadow: 'rgba(0, 0, 0, 0.50)',
    offerTileShadow: '#00000029',

    appointmentUnsubmitted: '#FCA500',

    success: '#008C3A',

    favourite: '#e0e0e0',

    offerMarketing: '#db342a',
    offerSectionBackgroundGray: '#eeeeee',

    upperFooter: '#242424',
    footer: '#1e1e1e',
    footerFont: '#7e7e7d',

    grey: '#c8c8c8',
    darkGrey: '#222222',
    white: '#FFF',
    darkRed: '#E40523',
    buttonDark: '#1f1f27',
    warningDot: '#ffba00',
    black: '#000000',

    itemDivider: '#e5e5e5',
    sectionDivider: '#e2e2e2',

    offerAppointmentNote: '#707070',

    activity: '#d2def1',

    newOfferSecondary: '#15387A',
    newOfferCheckbox: '#001667',
    newOfferIcon: '#1E1F28',
    newOfferTitle: '#2B2B2A',
    secondaryButton: '#1F1F28',
};

export const shadows = {
    light: `0 2px 4px 0 ${colors.shadow}`,
    normal: `0 7px 15px 0 ${colors.shadow}`,
    active: `0 0 0 1px ${colors.secondary}`,
    selected: `0 2px 12px 0 ${colors.shadow}`,
    card: `0 4px 12px 0 ${colors.shadow}`,
    ctaButton: `0 2px 4px 0 ${colors.ctaShadow}`,
};

export const sizes = {
    small: '8px',
    medium: '16px',
    large: '30px',

    addToCartBar: '58px',
    content: '600px',

    navigationBar: '80px',
    bottomBar: '70px',
    contentHeight: 'calc(100vh - 80px - 70px)', // connected with navigation and bottom bars
    contentDesktopHeight: 'calc(100vh - 80px)', // connected with navigation only

    borderRadius: '4px',

    icon: '24px',
    iconSmall: '18px',
    iconMedium: '22px',

    dialog: '500px',
};

export const fontSizes = {
    xxxSmall: '10px',
    xxSmall: '12px',
    xSmall: '14px',
    mSmall: '16px',
    small: '18px',
    medium: '20px',
    xMedium: '24px',
    large: '26px',
    xLarge: '30px',
    xxLarge: '40px',
};

export const breakpoints = {
    small: '576px',
    medium: '768px',
    large: '992px',
    xLarge: '1200px',
    xxLarge: '1600px',
};

export const wideScreenBreakpoint = breakpoints.large;

export const desktopScreenBreakpoint = breakpoints.xLarge;

export const zIndices = {
    zIndex010: 100,
    zIndex050: 500,
    zIndex1200: 1200,
};
