/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { ColorTooltip, ColorTooltipProps } from './ColorTooltip';
import { colorMarkStyles } from './colorMarkStyles';
import { NO_COLOR_RGB_PLACEHOLDER } from '../../constants';

interface Props {
    color: ProductColor;
    onClick?: (colorKey: string) => void;
    selected?: boolean;
    size?: number;
    selectedParentRef?: ColorTooltipProps['selectedParentRef'];
}

export const ColorMark: React.FC<Props> = ({
    color,
    onClick,
    selected = false,
    size = 2,
    selectedParentRef,
    ...rest
}) => {
    const multipleColorsCss = [];
    if (color.rgbList.length === 2) {
        multipleColorsCss.push(colorMarkStyles.dualColor(size, color.rgbList));
    } else if (color.rgbList.length === 3) {
        multipleColorsCss.push(colorMarkStyles.tripleColor(size, color.rgbList));
    }

    if (color.rgbList.length === 0) {
        console.error('NO COLOR RGB PRESENT!');
        color.rgbList.push(NO_COLOR_RGB_PLACEHOLDER);
    }

    return (
        <ColorTooltip title={color.name} selectedParentRef={selectedParentRef}>
            <div onClick={() => onClick && onClick(color.key)} css={styles.wrapper} {...rest}>
                <div>
                    <div
                        css={[
                            colorMarkStyles.mark(color?.rgbList[0], size),
                            colorMarkStyles.circle(size),
                            colorMarkStyles.mainCircle,
                            multipleColorsCss.pop(),
                            selected && colorMarkStyles.selected(size),
                        ]}
                    />
                    <div css={colorMarkStyles.background(size)} />
                </div>
            </div>
        </ColorTooltip>
    );
};

const styles = {
    wrapper: css`
        position: relative;
        padding: 6px 0;
    `,
};
