import sample_01 from './100_18937.jpg';
import sample_02 from './100_19041.jpg';
import sample_03 from './100_19780.jpg';
import sample_04 from './100_42390.jpg';
import sample_05 from './100_42411.jpg';

export const dynamicConstructImpressions = {
    sample_01,
    sample_02,
    sample_03,
    sample_04,
    sample_05,
};
