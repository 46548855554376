import { calculateItemTotalPrice } from '../product/helpers';
import { VariantItem } from '../../helpers/types';

export const calculateTotalPriceByCartItem = (
    cartItem: Pick<
        VariantItem,
        | 'basePrice'
        | 'surcharge'
        | 'stampPrice'
        | 'logoBackPrice'
        | 'logoFrontPrice'
        | 'labelPrice'
        | 'itemsPerWearer'
    >,
    valueChanges: CartItemPricing,
): number =>
    calculateItemTotalPrice({
        basePrice: valueChanges.basePrice || cartItem.basePrice || 0,
        surcharge: cartItem.surcharge,
        labelStampPrice: cartItem.stampPrice,
        selectedLabelPrice: cartItem.labelPrice,
        selectedLogoBackPrice: cartItem.logoBackPrice,
        selectedLogoFrontPrice: cartItem.logoFrontPrice,
        itemsPerWearer: valueChanges.itemsPerWearer || cartItem.itemsPerWearer || 0,
    });
