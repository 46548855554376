/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Collapse } from 'antd';

import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';

import { BrandHeaderAnchors } from './BrandHeaderAnchors';
import { BrandHeaderIcon } from './BrandHeaderIcon';
import { brandLogoLinkStyles } from './BrandHeaderLink';

type Props = {
    isWideScreen: boolean;
};

export const BrandHeaderReadonlyBody: React.FC<Props> = ({ isWideScreen }) => {
    const [menuExpanded, setMenuExpanded] = useState(false);

    const onClickIcon = () => {
        setMenuExpanded(!menuExpanded);
    };

    return isWideScreen ? (
        <div css={brandLogoLinkStyles.bottomRow}>
            <BrandHeaderAnchors mobile={false} />
        </div>
    ) : (
        <Collapse css={[brandLogoLinkStyles.bottomRow, styles.mobileRow]}>
            <Collapse.Panel
                css={styles.menuItems}
                extra={<BrandHeaderIcon onClickIcon={onClickIcon} />}
                key="collapse_anchors"
                header=""
            >
                <BrandHeaderAnchors mobile />
            </Collapse.Panel>
        </Collapse>
    );
};

const styles = {
    mobileRow: css`
        border: none;
        border-radius: 0 !important;
    `,
    menuItems: css`
        position: relative;
        width: 100%;
        border: none !important;
        border-radius: 0;

        & .ant-collapse-header {
            padding: 0 !important;
        }

        & .ant-collapse-extra {
            float: left !important;
            display: flex;
        }

        & .ant-collapse-content {
            border: none;
            border-radius: 0;
            position: absolute;
            width: 100%;

            & .ant-collapse-content-box {
                padding: 0;
                background-color: ${colors.fontSecondaryDark};
            }
        }
    `,
};
