export const productGroupsList: ProductGroup[] = [
    { id: 'G1', name: 'Berufsbekleidung' },
    { id: 'G2', name: 'Wischmopps', disabled: true },
    { id: 'G3', name: 'Ölauffangmatten', disabled: true },
    { id: 'G4', name: 'Waschraumhygiene', disabled: true },
    { id: 'G5', name: 'Teilreiniger', disabled: true },
    { id: 'G6', name: 'Putztücher', disabled: true },
    { id: 'G7', name: 'KZF-Schonbezüge', disabled: true },
    { id: 'G8', name: 'Schmutzfangmatten', disabled: true },
];
