import { OfferActions } from '../actions/offerActions';
import { OfferDetailsActions } from '../actions/offerDetailsActions';

type OverviewAssetsState = {
    availableColors: ProductColor[];
    availableWeights: number[];
    rotationRates: CartRotationRate[];
};

const initialState: OverviewAssetsState = {
    availableColors: [],
    availableWeights: [],
    rotationRates: [],
};

export function overviewAssetsReducer(
    state = initialState,
    action: OfferActions | OfferDetailsActions,
): OverviewAssetsState {
    switch (action.type) {
        case 'OFFER_DETAILS|FETCH_ASSETS_SUCCESS':
        case 'OFFER|UPDATE_OVERVIEW_ASSETS':
            return {
                availableColors: action.payload.availableColors || [],
                availableWeights: action.payload.availableWeights || [],
                rotationRates: action.payload.rotationRates || [],
            };
    }

    return state;
}
