/** @jsxImportSource @emotion/react */
import React from 'react';

import { listBasicStyles } from 'src/style/styles';

type Props = { availableIn: string[] };

export const Availability: React.FC<Props> = ({ availableIn }) => (
    <ul css={listBasicStyles}>
        {availableIn.map((value) => (
            <li key={value}>{value}</li>
        ))}
    </ul>
);
