import { createAction, ActionTypes, VariantItem } from 'src/helpers/types';
import { ThunkAction } from 'src/store';

import { notificationService } from 'src/modules/offer/service/notificationService';
import { cartService } from './cartService';

export const addCartItemRequest = () => createAction('ADD_CART_ITEM_REQUESTED', null);

export const addCartItemSuccess = () => createAction('ADD_CART_ITEM_SUCCEEDED', null);

export const addCartItemFailure = () => createAction('ADD_CART_ITEM_FAILED', null);

export const clearCartSuccess = () => createAction('CART|CLEAR_SUCCEEDED', null);

export const clearCartFailure = () => createAction('CLEAR_CART_FAILED', null);

const editCartItemRequest = () => createAction('EDIT_CART_ITEM_REQUESTED', null);

const editCartItemSuccess = () => createAction('EDIT_CART_ITEM_SUCCEEDED', null);

const editCartItemFailure = () => createAction('EDIT_CART_ITEM_FAILED', null);

export const fetchCartRequest = () => createAction('CART|FETCH_REQUESTED', null);

export const fetchCartSuccess = (cart: FetchCartResponse) =>
    createAction('CART|FETCH_SUCCEEDED', cart);

export const fetchCartFailure = (errorCode?: number) =>
    createAction('CART|FETCH_FAILED', errorCode);

export const setCartContentStatus = (hasContent: boolean) =>
    createAction('CART|SET_CONTENT_STATUS', hasContent);

export const removeCartItemRequest = () => createAction('REMOVE_CART_ITEM_REQUESTED', null);

export const removeCartItemSuccess = (cartItemId: number) =>
    createAction('CART|REMOVE_ITEM_SUCCEEDED', cartItemId);

export const removeCartItemFailure = () => createAction('REMOVE_CART_ITEM_FAILED', null);

export const updateCartItem = (cartItemId: number, cartItem: Partial<VariantItem>) =>
    createAction('CART|UPDATE_ITEM', { cartItemId, cartItem });

export const editCartItem = (
    cartItemId: number,
    cartItemDetails: ItemDetails,
    silent?: boolean,
): ThunkAction => async (dispatch) => {
    dispatch(editCartItemRequest());

    try {
        await cartService.editCartItem(cartItemId, cartItemDetails);
        dispatch(editCartItemSuccess());
        !silent && notificationService.success('Artikel aktualisiert');
    } catch {
        dispatch(editCartItemFailure());
        notificationService.error();
    }

    dispatch(fetchCartStatus());
};

export const clearCartAfterSubmit = (): ThunkAction => async (dispatch) => {
    cartService.clearCart().then(() => {
        dispatch(clearCartSuccess());
        dispatch(fetchCartStatus());
    });
};

export const fetchCartStatus = (): ThunkAction => async (dispatch) => {
    cartService
        .fetchCartStatus()
        .then((status) => {
            const hasContent = status === 200;
            dispatch(setCartContentStatus(hasContent));
        })
        .catch(() => {
            dispatch(setCartContentStatus(false));
        });
};

export const cartActions = {
    addCartItemRequest,
    addCartItemSuccess,
    addCartItemFailure,
    clearCartSuccess,
    editCartItemRequest,
    editCartItemSuccess,
    editCartItemFailure,
    fetchCartFailure,
    fetchCartRequest,
    fetchCartSuccess,
    setCartContentStatus,
    removeCartItemFailure,
    removeCartItemRequest,
    removeCartItemSuccess,
    updateCartItem,
};

export type CartActions = ActionTypes<typeof cartActions>;
