import FileSaver from 'file-saver';
import { Font, pdf } from '@react-pdf/renderer';
import { offerPdfTemplate } from 'src/modules/offerPdf/templates/offerPdfTemplate';
import GebauerRegular from 'src/style/fonts/Gebauer-Regular.otf';
import GebauerMedium from 'src/style/fonts/Gebauer-Medium.otf';
import GebauerBold from 'src/style/fonts/Gebauer-Bold.otf';
import PDFMerger from 'pdf-merger-js/browser';
import { termsAndConditionsPDF } from '../../../assets/pdf/termsAndConditions';
import { VariantItem } from '../../../helpers/types';

Font.register({ family: 'GebauerRegular', fonts: [{ src: GebauerRegular }] });
Font.register({ family: 'GebauerMedium', fonts: [{ src: GebauerMedium }] });
Font.register({ family: 'GebauerBold', fonts: [{ src: GebauerBold }] });

type offerForPdf = {
    items: VariantItem[];
    basics: OfferBasics | undefined;
    details: OfferDetails | undefined;
    displayCategory: boolean;
} | null;

const checkItemCategory = function (items: VariantItem[] | undefined, category: string): boolean {
    return items?.filter((item) => item.category === category).length !== 0;
};

const fetchFileAsBlob = async function (file: any): Promise<Blob> {
    const response = await fetch(file);
    return await response.blob();
};

export async function createOfferPdf(offerOverview: offerForPdf) {
    const mbsItems = checkItemCategory(offerOverview?.items, 'MBS');
    const mbkItems = checkItemCategory(offerOverview?.items, 'MBK');
    const offerPdf = pdf();

    offerOverview!.displayCategory =
        mbsItems && mbkItems && offerOverview!.details!.includeTermsAndConditions;
    offerPdf.updateContainer(offerPdfTemplate(offerOverview));

    const offerPdfBlob = await offerPdf.toBlob();

    const merger = new PDFMerger();
    await merger.add(offerPdfBlob);

    if (offerOverview!.details!.includeTermsAndConditions) {
        if (mbsItems) {
            const mbsBlob = await fetchFileAsBlob(termsAndConditionsPDF.MBS);
            await merger.add(mbsBlob);
        }
        if (mbkItems) {
            const mbkBlob = await fetchFileAsBlob(termsAndConditionsPDF.MBK);
            await merger.add(mbkBlob);
        }
    }

    const mergedPdf = await merger.saveAsBlob();
    return FileSaver.saveAs(mergedPdf, `${offerOverview?.details?.name}.pdf`);
}
