/** @jsxImportSource @emotion/react */
import React from 'react';
import { Row, Col } from 'antd';

import { css } from '@emotion/react';

import { offerImages } from 'src/assets/images/offer';
import { colors, externalPaths } from 'src/constants/variables';
import { mobileScreenStyles } from 'src/style/styles';

import {
    prepareContactFullName,
    prepareCompanyFullAddress,
} from 'src/modules/offer/helpers/offerData';

import { InfoTile } from './InfoTile';

type Props = Pick<OfferBasics, 'customerCompany' | 'customerContact' | 'employeeContact'> & {
    readOnly?: boolean;
    toggleContactDataModal?: () => void;
    toggleCustomerDataModal?: () => void;
    employeeTitle?: string;
    customerTitle?: string;
};

export const OfferTilesSection: React.FC<Props> = ({
    customerCompany,
    customerContact,
    employeeContact,
    readOnly = false,
    toggleContactDataModal,
    toggleCustomerDataModal,
    employeeTitle = 'MEWA ANSPRECHPARTNER',
    customerTitle = 'KUNDE KONTAKTDATEN',
}) => (
    <div css={styles.tiles}>
        <Row>
            <Col span={24} md={8}>
                <InfoTile
                    title={employeeTitle}
                    name={prepareContactFullName(employeeContact)}
                    phone={employeeContact?.phone}
                    email={employeeContact?.email}
                    position={employeeContact?.position}
                    onClickEdit={!readOnly ? toggleContactDataModal : undefined}
                    backgroundColor={colors.primaryDark}
                />
            </Col>

            <Col span={24} md={8}>
                <InfoTile
                    title={customerTitle}
                    companyName={customerCompany?.name}
                    companyAddress={prepareCompanyFullAddress(customerCompany)}
                    name={prepareContactFullName(customerContact)}
                    phone={customerContact?.phone}
                    email={customerContact?.email}
                    position={customerContact?.position}
                    backgroundColor={colors.fontPrimaryDark}
                    onClickEdit={!readOnly ? toggleCustomerDataModal : undefined}
                />
            </Col>

            <Col span={24} md={8}>
                <InfoTile.WithExternalLink
                    title="MEWA ARENA"
                    description="Die Spielstätte von Mainz 05 heißt jetzt MEWA ARENA"
                    backgroundImage={offerImages.stadium}
                    linkTo={externalPaths.mewaArena}
                />
            </Col>
        </Row>
    </div>
);

const styles = {
    tiles: css`
        ${mobileScreenStyles(css`
            flex-direction: column;
        `)};
    `,
    footer: css`
        position: absolute;
        bottom: 16px;
    `,
};
