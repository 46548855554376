import { createSelector } from 'reselect';

import { getProductsCatalogState } from '../productsCatalogSelectors';

const getCollectionsState = createSelector(
    getProductsCatalogState,
    (productsCatalogState) => productsCatalogState.collections,
);

export const getCollectionsList = createSelector(
    getCollectionsState,
    (productCollectionsState) => productCollectionsState.data,
);

export const getCollectionsLoadingStatus = createSelector(
    getCollectionsState,
    (productCollectionsState) => productCollectionsState.loading,
);

export const getCollectionsLoadingError = createSelector(
    getCollectionsState,
    (productCollectionsState) => productCollectionsState.error,
);
