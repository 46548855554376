import { uniq as ramdaUniq } from 'ramda';
import { createSelector } from 'reselect';

import { RootState } from 'src/store';
import { setMaterialValue } from './productActions';
import { useDispatch } from 'react-redux';

const getState = (state: Pick<RootState, 'product'>) => state.product;

export const getProductData = createSelector(getState, (state) => state.model);

export const getProductState = createSelector(getState, (state) => ({
    model: state.model,
    error: state.error,
    loading: state.loading,
}));

export const getProductVariants = createSelector(
    getProductData,
    (product) => product && product.variants,
);

export const getItemValues = createSelector(getState, (state) => state.values);

export const getColorKey = createSelector(getState, (state) => state.currentColorKey);
export const getWeight = createSelector(getState, (state) => state.currentWeight);
export const getMaterial = createSelector(getState, (state) => state.currentMaterial);

export const getCurrentVariant = createSelector(
    getProductData,
    getColorKey,
    getWeight,
    getMaterial,
    (product, colorKey, weight, material) => {
        if (product === null) return null;

        const resolvedWeightVariants = product.variants.filter(
            (variant) => variant.colorKey === colorKey && variant.weight === weight,
        );
        const resolvedMaterialVariant = resolvedWeightVariants.find(
            (variant) => variant.material === material,
        );

        if (!resolvedMaterialVariant) {
            const dispatch = useDispatch();
            dispatch(setMaterialValue(resolvedWeightVariants[0]?.material));

            return resolvedWeightVariants[0];
        }

        return resolvedMaterialVariant!;
    },
);

export const getAvailableWeights = createSelector(
    getColorKey,
    getProductVariants,
    (colorKey, variants) => {
        const weights =
            colorKey &&
            variants &&
            variants
                .filter((variant) => variant.colorKey === colorKey)
                .map((variant) => variant.weight);
        return ramdaUniq(weights || []);
    },
);

export const getAvailableMaterials = createSelector(
    getColorKey,
    getWeight,
    getProductVariants,
    (colorKey, weight, variants) => {
        const materials =
            colorKey &&
            weight &&
            variants &&
            variants
                .filter((variant) => variant.colorKey === colorKey && variant.weight === weight)
                .map((variant) => variant.material);
        return ramdaUniq(materials || []);
    },
);

export const getRemainingCollectionProducts = createSelector(getProductData, (product) => {
    if (!product) return [];

    return product.collectionModels.filter(({ modelId }) => modelId !== product.modelId);
});
