/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { Trash } from 'src/assets/icons';
import { colors } from 'src/constants/variables';
import { DeletePrompt } from 'src/components/Prompts';

type Props = {
    confirm: string;
    onConfirm: () => void;
    disabled?: boolean;
};

export const ClearButton: React.FC<Props> = ({ confirm, onConfirm, disabled }) => {
    const [promptVisible, setPromptVisible] = useState(false);

    return (
        <div css={styles.wrapper}>
            <button
                type="button"
                css={styles.button}
                onClick={() => {
                    setPromptVisible(true);
                }}
                disabled={disabled}
                data-test={'productsClearButton'}
            >
                <Icon component={Trash} />
            </button>

            {promptVisible && (
                <DeletePrompt
                    message={confirm}
                    onCancel={() => {
                        setPromptVisible(false);
                    }}
                    onConfirm={async () => {
                        onConfirm();
                        setPromptVisible(false);
                    }}
                    customCss={styles.prompt}
                />
            )}
        </div>
    );
};

const styles = {
    wrapper: css`
        position: relative;
    `,
    button: css`
        display: block;
        margin: 16px 0 16px auto;
        padding: 0;

        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;

        svg {
            height: 22px;
            width: auto;

            g path {
                fill: ${colors.secondary};
            }
        }

        &[disabled] {
            cursor: default;

            g path {
                fill: ${colors.iconGray};
            }
        }
    `,
    disabled: css`
        cursor: default;
        color: ${colors.iconGray};
    `,
    prompt: css`
        left: unset;
        transform: translateY(calc(-100% - 24px));

        span.anticon + span {
            align-self: center;
        }
    `,
};
