/** @jsxImportSource @emotion/react */
import React, { useRef, useState, useEffect } from 'react';
import { css } from '@emotion/react';

import { colors } from 'src/constants/variables';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Icon from '@ant-design/icons';
import { Close, MagnifyingGlass } from 'src/assets/icons';
import { CustomModal } from 'src/components';

const RESIZE_TIMEOUT = 200;

type Coordinations = {
    x: number;
    y: number;
};

type Positions = {
    [index: string]: Coordinations | undefined;
    CIRCLE?: Coordinations;
    NAMEPLATE?: Coordinations;
    OVAL?: Coordinations;
    RECTANGLE?: Coordinations;
    SQUARE?: Coordinations;
};

interface Props {
    image: string;
    name: string;
    textUnder?: string;
    updateFormPosition?: (arg: any, arg2: number) => void;
}

export const Image: React.FC<Props> = ({ image, textUnder }) => {
    const wrapperRef = useRef<HTMLHeadingElement>(null);
    const [position, setPosition] = useState<Positions>({});
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);
    const [fullscreen, setFullscreen] = useState<boolean>(false);

    const handleResize = () => {
        setHeight(wrapperRef?.current?.offsetHeight || 0);
        setWidth(wrapperRef?.current?.offsetWidth || 0);
    };

    const updatePosition = () => {
        const newPosition: Positions = { ...position };
        setPosition(newPosition);
    };

    useEffect(() => {
        window.addEventListener('resize', () => setTimeout(handleResize, RESIZE_TIMEOUT));
    }, []);

    useEffect(() => {
        updatePosition();
    }, [width, height]); // eslint-disable-line react-hooks/exhaustive-deps

    const Content = () => (
        <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
                <div css={[styles.wrapper(fullscreen), styles.imgWrapper()]} id={'wrapper'}>
                    {!textUnder && (
                        <Icon
                            component={MagnifyingGlass}
                            onClick={() => {
                                setFullscreen(true);
                                centerView(rest.state.scale >= 2 ? 1 : 2);
                            }}
                            css={styles.zoomIn}
                            className={'sliderZoomIcon'}
                            data-testid="sliderZoomIcon"
                        />
                    )}
                    {fullscreen && (
                        <Icon
                            component={Close}
                            onClick={() => {
                                setFullscreen(false);
                            }}
                            css={styles.close}
                            data-testid="sliderCloseIcon"
                        />
                    )}
                    <TransformComponent>
                        <div ref={wrapperRef} css={styles.imgWrapper(textUnder ? 'column' : 'row')}>
                            <img src={image} alt={textUnder || 'productImage'} css={styles.image} />
                            <div css={styles.textUnder}>{textUnder}</div>
                        </div>
                    </TransformComponent>
                </div>
            )}
        </TransformWrapper>
    );

    return fullscreen ? (
        <CustomModal
            visible={true}
            closable={false}
            onClick={() => setFullscreen(false)}
            modalCss={styles.modal}
        >
            <Content />
        </CustomModal>
    ) : (
        <Content />
    );
};

const emblemHeight = 27;

const styles = {
    modal: css`
        .ant-modal-content {
            padding: 0;
        }

        .ant-modal-body {
            padding: 0;
            width: 100vw;
            height: 100vh;
        }
    `,
    imgWrapper: (flexDirection = 'row') => css`
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        flex-direction: ${flexDirection};
    `,
    zoomIn: css`
        left: 24px;
        top: 24px;
        position: absolute;
        height: 24px;
        width: 24px;

        svg {
            height: 100%;
            width: 100%;
            stroke: ${colors.primaryGray};
        }
    `,
    close: css`
        height: 40px;
        width: 40px;
        right: 10px;
        top: 10px;
        position: absolute;
        background-color: ${colors.primary};
        opacity: 0.8;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            height: 21px;
            width: 21px;
        }
    `,
    emblem: (emblemWidth = 0, isActive = true) => css`
        position: absolute;
        left: calc(50% - ${emblemWidth / 2}px);
        top: calc(50% - ${emblemHeight / 2}px);

        border: 1px solid ${colors.secondary};
        background: ${colors.primary};
        cursor: ${isActive ? 'pointer' : 'default'};
    `,
    wrapper: (fullscreen: boolean) => css`
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 32px;
        padding-bottom: 48px;
        overflow: visible;

        .react-transform-wrapper {
            overflow: visible;
            height: 90%;
        }

        .react-transform-component {
            height: 100%;
        }

        ${fullscreen &&
        css`
            position: absolute;
            left: 0;
            top: 0;
            height: auto;
            width: 100vw;
            overflow: visible;
            padding: 0;
        `}
    `,
    image: css`
        overflow: visible;
        max-height: 100%;
        max-width: 100%;
        top: 24px;
        left: 24px;

        margin: 0 auto;
        object-fit: cover;
        user-select: none;
    `,
    textUnder: css`
        font-weight: bold;
        font-size: 3rem;
    `,
};
