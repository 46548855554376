import { collectionImages } from 'src/assets/images/collections';
import { wischmoppsImages } from 'src/assets/images/categories/wischmopps';
import { olauffangmattenImages } from 'src/assets/images/categories/olauffangmatten';
import { waschraumhygieneImages } from 'src/assets/images/categories/waschraumhygiene';
import { putztucherImages } from 'src/assets/images/categories/putztucher';
import { kzfSchonbezugeImages } from 'src/assets/images/categories/kzfSchonbezuge';
import { schmutzfangmattenImages } from 'src/assets/images/categories/schmutzfangmatten';
import { teilreinigerImages } from 'src/assets/images/categories/teilreiniger';

// GroupID: 1 - Berufsbekleidung
const berufsbekleidungCategories: ProductCategory[] = [
    {
        id: 'C11',
        parentId: 'G1',
        name: 'Arbeitskleidung',
        imageUrl: collectionImages.arbeitskleidungImage,
    },
    {
        id: 'C12',
        parentId: 'G1',
        name: 'Schutzkleidung',
        imageUrl: collectionImages.schutzkleidungImage,
    },
    {
        id: 'C13',
        parentId: 'G1',
        name: 'Hygienekleidung',
        imageUrl: collectionImages.hygienekleidungImage,
    },
    {
        id: 'C14',
        parentId: 'G1',
        name: 'Businesskleidung',
        imageUrl: collectionImages.businesskleidungImage,
    },
    {
        id: 'C15',
        parentId: 'G1',
        name: 'Gesundheits und Servicekleidung',
        imageUrl: collectionImages.gesundheitsUndServicekleidungImage,
    },
];

// GroupID: 2 - Wischmopps
const wischmoppsCategories: ProductCategory[] = [
    {
        id: 'C21',
        parentId: 'G2',
        name: 'Wischmopps',
        imageUrl: wischmoppsImages.wischmopps19528,
        disabled: true,
    },
];

// GroupID: 3 - Ölauffangmatten
const olauffangmattenCategories: ProductCategory[] = [
    {
        id: 'C31',
        parentId: 'G3',
        name: 'MULTITEX®',
        imageUrl: olauffangmattenImages.olauffangmatten40554,
        disabled: true,
    },
];

// GroupID: 4 - Waschraumhygiene
const waschraumhygieneCategories: ProductCategory[] = [
    {
        id: 'C41',
        parentId: 'G4',
        name: 'HANDTUCHSPENDER',
        imageUrl: waschraumhygieneImages.handtuch39020,
        disabled: true,
    },
    {
        id: 'C42',
        parentId: 'G4',
        name: 'SEIFENSPENDER',
        imageUrl: waschraumhygieneImages.seife38801,
        disabled: true,
    },
];

// GroupID: 5 - Teilereiniger
const teilreinigerCategories: ProductCategory[] = [
    {
        id: 'C51',
        parentId: 'G5',
        name: 'Pinselwaschtische',
        imageUrl: teilreinigerImages.pinselwaschtische,
        disabled: true,
    },
];

// GroupID: 6 - Putztücher
const putztucherCategories: ProductCategory[] = [
    {
        id: 'C61',
        parentId: 'G6',
        name: 'PUTZTÜCHER',
        imageUrl: putztucherImages.putztucher,
        disabled: true,
    },
    {
        id: 'C62',
        parentId: 'G6',
        name: 'Safety Containers',
        imageUrl: putztucherImages.safetyContainers,
        disabled: true,
    },
    {
        id: 'C63',
        parentId: 'G6',
        name: 'Sacon Fix®',
        imageUrl: putztucherImages.saconFix,
        disabled: true,
    },
    {
        id: 'C64',
        parentId: 'G6',
        name: 'RABC Putztücher',
        imageUrl: putztucherImages.RABCPutztucher,
        disabled: true,
    },
];

// GroupID: 7 - KZF-Schonbezüge
const kzfSchonbezugeCategories: ProductCategory[] = [
    {
        id: 'C71',
        parentId: 'G7',
        name: 'TOPTEX®',
        imageUrl: kzfSchonbezugeImages.kzfSchonbezuge,
        disabled: true,
    },
];

// GroupID: 8 - Schmutzfangmatten
const schmutzfangmattenCategories: ProductCategory[] = [
    {
        id: 'C81',
        parentId: 'G8',
        name: 'Fußmatten',
        imageUrl: schmutzfangmattenImages.fubmatten,
        disabled: true,
    },
    {
        id: 'C82',
        parentId: 'G8',
        name: 'Borstematten',
        imageUrl: schmutzfangmattenImages.borstematten38984,
        disabled: true,
    },
];

export const productCategoriesList: ProductCategoriesList = {
    G1: [...berufsbekleidungCategories],
    G2: [...wischmoppsCategories],
    G3: [...olauffangmattenCategories],
    G4: [...waschraumhygieneCategories],
    G5: [...teilreinigerCategories],
    G6: [...putztucherCategories],
    G7: [...kzfSchonbezugeCategories],
    G8: [...schmutzfangmattenCategories],
};
