import { colors } from 'src/constants/variables';

export const backgrounds = [
    {
        id: 'background_1',
        description: 'Arbeitskleidung',
        textColor: colors.white,
        logoColor: colors.darkRed,
    },
    {
        id: 'background_2',
        description: 'Schutzkleidung',
        textColor: colors.white,
        logoColor: colors.darkRed,
    },
    {
        id: 'background_3',
        description: 'Hygienekleidung',
        textColor: colors.black,
        logoColor: colors.darkRed,
    },
    {
        id: 'background_4',
        description: 'Gesundheitskleidung',
        textColor: colors.white,
        logoColor: colors.white,
    },
    {
        id: 'background_5',
        description: 'Businesskleidung',
        textColor: colors.white,
        logoColor: colors.white,
    },
    {
        id: 'background_6',
        description: 'Servicekleidung',
        textColor: colors.white,
        logoColor: colors.white,
    },
];
