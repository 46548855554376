import { ActionTypes, createAction, VariantItem } from 'src/helpers/types';
import { ThunkAction } from 'src/store';
import { cartService } from 'src/modules/cart/store/cartService';

import { productService } from './productService';
import { getWeight, getProductData } from './productSelectors';

const fetchProductSuccess = (product: Product) => createAction('FETCH_PRODUCT_SUCCESS', product);

export const fetchCartItem = (cartItemId: string | number): ThunkAction => async (dispatch) => {
    const id = typeof cartItemId === 'number' ? cartItemId : Number.parseInt(cartItemId);
    dispatch(fetchAsyncResourceRequest());

    cartService
        .fetchCartItem(id)
        .then((response) => dispatch(fetchCartItemSuccess(response.model, response.cartItem)))
        .catch(() => dispatch(fetchAsyncResourceFailure()));
};

const fetchCartItemSuccess = (model: Product, cartItem: ItemDetails) =>
    createAction('FETCH_CART_ITEM_SUCCESS', { model, cartItem });

export const loadOfferItem = (offerItem: VariantItem): ThunkAction => async (dispatch) => {
    dispatch(fetchAsyncResourceRequest());

    productService
        .fetchProduct(offerItem.modelId)
        .then((model) => dispatch(fetchOfferItemSuccess(model, offerItem)))
        .catch(() => dispatch(fetchAsyncResourceFailure()));
};

const fetchOfferItemSuccess = (model: Product, offerItem: VariantItem) =>
    createAction('FETCH_OFFER_ITEM_SUCCESS', {
        model,
        offerItem,
    });

export const setVariantColor = (colorKey: string): ThunkAction => (dispatch, getState) => {
    const state = getState();
    const product = getProductData(state);
    const currentWeight = getWeight(state);
    const newVariant =
        product &&
        product.variants.find(
            (variant) => variant.colorKey === colorKey && variant.weight === currentWeight,
        );
    if (!newVariant) {
        const newWeight =
            product && product.variants.find((variant) => variant.colorKey === colorKey)!.weight;
        dispatch(setWeightValue(newWeight || 0));
    }
    dispatch(setColorKey(colorKey));
};

const setColorKey = (colorKey: string) => createAction('SET_COLOR_KEY', colorKey);
export const setWeightValue = (weight: number) => createAction('SET_WEIGHT_VALUE', weight);
export const setMaterialValue = (material: string) => createAction('SET_MATERIAL_VALUE', material);

export const clearProduct = () => createAction('PRODUCT|CLEAR_STATE', null);

const fetchAsyncResourceRequest = () => createAction('PRODUCT|ASYNC_RESOURCE_REQUEST', null);

const fetchAsyncResourceFailure = () => createAction('PRODUCT|ASYNC_RESOURCE_FAILURE', null);

const productActions = {
    clearProduct,

    fetchProductSuccess,
    fetchCartItemSuccess,
    fetchOfferItemSuccess,

    fetchAsyncResourceRequest,
    fetchAsyncResourceFailure,

    setColorKey,
    setWeightValue,
    setMaterialValue,
};

export type ProductActions = ActionTypes<typeof productActions>;
