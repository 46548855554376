import React, { useContext } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { SerializedStyles } from '@emotion/react';

import { wideScreenBreakpoint } from 'src/constants/variables';

import { ReadOnlyContext } from 'src/helpers/ReadOnlyContext';
import { BrandHeaderLogo } from './BrandHeaderLogo';
import { BrandHeaderLink } from './BrandHeaderLink';
import { BrandHeaderReadonlyBody } from './BrandHeaderReadonlyBody';

type Props = {
    headerLogoCss?: SerializedStyles;
};

export const BrandHeader: React.FC<Props> = ({ headerLogoCss }) => {
    const readOnly = useContext(ReadOnlyContext);
    const isWide = useMediaQuery('only screen and (min-width: ' + wideScreenBreakpoint);

    return (
        <React.Fragment>
            <BrandHeaderLogo customCss={headerLogoCss} readOnly={readOnly} />
            {readOnly ? <BrandHeaderReadonlyBody isWideScreen={isWide} /> : <BrandHeaderLink />}
        </React.Fragment>
    );
};
