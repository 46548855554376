/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form, Select as AntdSelect } from 'antd';
import { SelectProps as AntdSelectProps } from 'antd/lib/select';
import { FormItemProps } from 'antd/lib/form';
import { SerializedStyles } from '@emotion/react';

import { styles } from './styles';

type CssProps = {
    formItemCss?: SerializedStyles;
};

type OptionsProps = {
    options: string[];
};

type SelectProps = Omit<AntdSelectProps<string>, 'options' | 'onChange'> &
    Pick<FormItemProps, 'name' | 'required'>;

type SelectComposition = {
    Title: React.FC<SelectProps & CssProps>;
};

export const Select: React.FC<SelectProps & OptionsProps & CssProps> & SelectComposition = ({
    name,
    options,
    formItemCss,
    required = false,
    ...rest
}) => (
    <Form.Item
        name={name}
        css={[styles.item, styles.selectItem, formItemCss, required && styles.required]}
    >
        <AntdSelect {...rest}>
            {options.map((item: string) => (
                <AntdSelect.Option key={item} value={item}>
                    {item}
                </AntdSelect.Option>
            ))}
        </AntdSelect>
    </Form.Item>
);

const SelectTitle: React.FC<SelectProps & CssProps> = ({ formItemCss, ...rest }) => (
    <Select
        placeholder="Herr"
        options={['Herr', 'Frau']}
        formItemCss={styles.selectTitle(formItemCss)}
        dropdownStyle={{ maxWidth: '167px', borderRadius: 0 }}
        {...rest}
    />
);

Select.Title = SelectTitle;
