import { AsyncResource, defaultAsyncResource } from 'src/helpers/types';
import { ProductCategoriesActionsType } from '../actions/productCategoriesActions';

export type ProductCategoriesState = AsyncResource<ProductCategoriesList>;

const initialState: ProductCategoriesState = defaultAsyncResource;

export function productCategoriesReducer(
    state: ProductCategoriesState = initialState,
    action: ProductCategoriesActionsType,
): ProductCategoriesState {
    switch (action.type) {
        case 'PRODUCT_CATEGORIES_LOADING':
            return {
                ...initialState,
                error: false,
                loading: true,
            };
        case 'SET_PRODUCT_CATEGORIES_LIST':
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        default:
            return state;
    }
}
