/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Close } from 'src/assets/icons';
import Icon from '@ant-design/icons';

import { colors } from 'src/constants/variables';
import { DeletePrompt } from 'src/components/Prompts';

type Props = {
    confirm: string;
    onConfirm: () => void;
    promptDirectionRight?: boolean;
    customWrapperCss?: SerializedStyles;
    customIconCss?: SerializedStyles;
};

export const RemoveItemButton: React.FC<Props> = ({
    confirm,
    onConfirm,
    promptDirectionRight,
    customWrapperCss,
    customIconCss,
}) => {
    const [promptVisible, setPromptVisible] = useState(false);

    return (
        <div css={[styles.wrapper, customWrapperCss]}>
            <button
                type="button"
                css={styles.button}
                onClick={() => {
                    setPromptVisible(true);
                }}
                data-test={'removeItemButton'}
            >
                <Icon component={Close} css={customIconCss} data-test={'removeItemButtonIcon'}/>
            </button>

            {promptVisible && (
                <DeletePrompt
                    message={confirm}
                    onCancel={() => {
                        setPromptVisible(false);
                    }}
                    onConfirm={async () => {
                        onConfirm();
                        setPromptVisible(false);
                    }}
                    customCss={styles.prompt(promptDirectionRight)}
                />
            )}
        </div>
    );
};

const styles = {
    wrapper: css`
        position: relative;
    `,
    button: css`
        display: block;
        margin: 16px 0 16px auto;
        padding: 0;

        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;

        svg {
            height: 22px;
            width: auto;

            g path {
                fill: ${colors.secondary};
            }
        }
    `,
    prompt: (promptDirectionRight?: boolean) => css`
        transform: translateY(calc(-100% - 24px));
        min-width: 350px;
        ${promptDirectionRight ? `left: -8px; right: unset;` : `left: unset;`}
        span.anticon + span {
            align-self: center;
        }
    `,
};
