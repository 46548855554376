/** @jsxImportSource @emotion/react */
import React from 'react';
import moment from 'moment';

import { css } from '@emotion/react';

import MainSectionDatePicker from '../MainSection/MainSectionDatePicker';

type Props = {
    selectedDate: moment.Moment | null;
    setSelectedDate: (date: moment.Moment | null) => void;
    allowDateClear?: boolean;
};

export const DatePickerFooter: React.FC<Props> = ({
    selectedDate,
    setSelectedDate,
    allowDateClear,
}) => (
    <>
        <span css={styles.dateBy} data-test={'companyInfoBoxDateBy'}>GÜLTIG BIS</span>
        <MainSectionDatePicker
            selected={selectedDate}
            setSelected={setSelectedDate}
            allowClear={allowDateClear}
        />
    </>
);

const styles = {
    dateBy: css`
        margin-right: 4px;
    `,
};
