/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

type Props = {
    children: React.ReactChild;
};

export const NumberColumnWrapper: React.FC<Props> = ({ children }) => (
    <div css={styles.wrapper}>{children}</div>
);

const styles = {
    wrapper: css`
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
    `,
};
