/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Carousel } from 'antd';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';
import { CarouselProps } from 'antd/lib/carousel';

import { ArrowRight, ArrowLeft } from 'src/assets/icons';
import { colors, zIndices } from 'src/constants/variables';
import { useCarousel } from 'src/hooks/useCarousel';

type Props = CarouselProps & {
    dots?: boolean;
    zoom?: boolean;
    swipe?: boolean;
    slickGoTo?: number;
};

export const ImagesCarousel: React.FC<Props> = ({
    dots = true,
    swipe = true,
    zoom = false,
    slickGoTo = -1,
    children,
    ...rest
}) => {
    const ref = useCarousel();

    useEffect(() => {
        if (slickGoTo >= 0 && ref.current?.innerSlider.state.currentSlide !== slickGoTo) {
            ref.current?.goTo(slickGoTo);
        }
    }, [slickGoTo, ref]);

    return (
        <div css={styles.carouselWrapper}>
            {!zoom && (
                <Icon
                    css={styles.icon}
                    component={ArrowLeft}
                    onClick={() => ref.current && ref.current.prev()}
                />
            )}
            <Carousel ref={ref} swipe={swipe} {...rest} dots={dots && !zoom} dotPosition="bottom">
                {children}
            </Carousel>

            {!zoom && (
                <Icon
                    css={styles.icon}
                    component={ArrowRight}
                    onClick={() => ref.current && ref.current.next()}
                    data-testid="arrowRight"
                />
            )}
        </div>
    );
};

const styles = {
    carouselWrapper: css`
        position: relative;
        padding: 0;
        height: 100%;

        & .slick-slider {
            height: 100%;
        }

        & .ant-carousel {
            height: 100%;

            .slick-slider {
                .slick-track,
                .slick-list {
                    height: 100%;
                }
            }
        }

        & .ant-carousel .slick-slide > div {
            height: 100%;
            text-align: center;
        }

        & .ant-carousel .slick-dots li {
            width: 24px;

            button {
                background: ${colors.fontPrimaryDark};
            }
        }

        & .ant-carousel .slick-dots li.slick-active button {
            background: ${colors.secondary};
        }

        & .ant-carousel .slick-dots-bottom {
            bottom: 48px;
            height: 24px;
            margin: 0;
            z-index: ${zIndices.zIndex050};
            align-items: center;
        }
    `,
    icon: css`
        position: absolute;
        top: 50%;
        z-index: ${zIndices.zIndex1200};
        transform: translateY(-50%);
        padding: 24px 12px;

        :first-of-type {
            left: 0;
        }

        :last-of-type {
            right: 0;
        }

        svg {
            height: 22px;
            width: auto;

            polygon {
                fill: ${colors.secondary};
            }
        }
    `,
};
