import { AuthActions } from './authActions';

export type AuthState = {
    isAuthenticated: boolean;
    isTokenChecked: boolean;
    user: {
        name: string;
        role: UserRole;
        email?: string;
    } | null;
};

export const defaultState: AuthState = {
    isAuthenticated: false,
    isTokenChecked: false,
    user: null,
};

export const authReducer = (state = defaultState, action: AuthActions): AuthState => {
    switch (action.type) {
        case 'REFRESH_TOKEN_SUCCEEDED':
            return {
                isAuthenticated: true,
                isTokenChecked: true,
                user: action.payload,
            };

        case 'REFRESH_TOKEN_FAILED':
            return {
                isAuthenticated: false,
                isTokenChecked: true,
                user: null,
            };

        case 'DROP_TOKEN_SUCCESS':
            return {
                isAuthenticated: false,
                isTokenChecked: false,
                user: null,
            };
        default:
            return state;
    }
};
