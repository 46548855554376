import { ThunkAction } from 'src/store';
import { createAction, ActionTypes } from 'src/helpers/types';

import { authService } from './authService';

const refreshTokenSuccess = (user: { name: string; role: UserRole; email?: string }) =>
    createAction('REFRESH_TOKEN_SUCCEEDED', user);

const refreshTokenFailure = () => createAction('REFRESH_TOKEN_FAILED', null);

const dropTokenSuccess = () => createAction('DROP_TOKEN_SUCCESS', null);

export const refreshToken = (code?: string): ThunkAction => async (dispatch) => {
    authService
        .refreshAccessToken()
        .then((currentUser) => {
            dispatch(refreshTokenSuccess(currentUser));
        })
        .catch(() => {
            refreshTokenFailure();
            code ? dispatch(refreshToken(code)) : authService.cognitoSignIn();
        });
};

export const dropToken = (): ThunkAction => async (dispatch) => {
    authService
        .dropAccessToken()
        .then(() => {
            dispatch(dropTokenSuccess());
        })
        .catch((error) => {
            console.error(error);
            dispatch(dropToken());
        });
};

const authActions = {
    refreshTokenSuccess,
    refreshTokenFailure,
    dropTokenSuccess,
};

export type AuthActions = ActionTypes<typeof authActions>;
