/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';

import { GroupColumn } from './GroupColumn';
import { CategoryColumn } from './CategoryColumn';
import { CollectionColumn } from './CollectionColumn';
import { NavigationProps } from './types';
import { navigationStyles } from './styles';

export const NavigationWithGroups: React.FC<NavigationProps> = ({
    productGroups,
    productCategories,
    productCollections,
    selectedCollectionId,
    onCollectionSelect,
    onClose,
}) => {
    const firstGroupId = productGroups[0].id;
    const firstCategoryId = productCategories[firstGroupId][0].id;
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
    const [selectedGroupId, setSelectedGroupId] = useState<string>(firstGroupId);

    useEffect(() => {
        setSelectedCategoryId(productCategories[selectedGroupId][0].id);
    }, [selectedGroupId, productCategories]);

    const onCollectionClick = (collection: ProductCatalogItem) => {
        onCollectionSelect(collection);
        onClose();
    };

    return (
        <Col span={20} offset={4}>
            <Row css={navigationStyles.content} onClick={(e) => e.stopPropagation()}>
                <Col span={6} offset={0}>
                    <GroupColumn productGroups={productGroups} onSelect={setSelectedGroupId} />
                </Col>
                <Col span={6} offset={0}>
                    <CategoryColumn
                        productCategories={productCategories}
                        onSelect={setSelectedCategoryId}
                        selectedGroup={selectedGroupId || firstGroupId}
                        selectedCategory={selectedCategoryId || firstCategoryId}
                    />
                </Col>
                <Col span={12} offset={0}>
                    <CollectionColumn
                        productCollections={productCollections}
                        selectedCategoryId={selectedCategoryId || firstCategoryId}
                        selectedCollectionId={selectedCollectionId}
                        onCollectionClick={onCollectionClick}
                    />
                </Col>
            </Row>
        </Col>
    );
};
