import React from 'react';

import { FormItemDetails } from './FormItemDetails';
import { ModalItemDetails } from './ModalItemDetails';
import { VariantItem } from '../../../../../helpers/types';

type Props = {
    item: VariantItem;
    form?: boolean;
};

export const ItemDetails: React.FC<Props> = ({ item, form }) =>
    form ? <FormItemDetails item={item} /> : <ModalItemDetails item={item} />;
