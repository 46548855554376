/** @jsxImportSource @emotion/react */
import React from 'react';
import { Form } from 'antd';
import Icon from '@ant-design/icons';
import { css } from '@emotion/react';

import { Trash } from 'src/assets/icons';
import { MAX_PRICE_VALUE } from 'src/constants';
import { DataField } from 'src/components/DataField';
import { NumberInput } from 'src/components/DataInputs';
import { textOverflowEllipsis, mobileScreenStyles } from 'src/style/styles';
import { colors, fontSizes } from 'src/constants/variables';

import { offerDetailsStyles } from 'src/modules/offer/components/styles';
import { serviceItemName } from 'src/modules/offer/components/Offer/Services/serviceItemName';

type Props = {
    serviceId: WardrobeOption['id'];
    serviceLabel: WardrobeOption['label'];
    onServiceRemove?: (serviceId: WardrobeOption['id']) => void;
};

export const ServiceItem: React.FC<Props> = ({ serviceId, serviceLabel, onServiceRemove }) => (
    <DataField name={serviceItemName(serviceLabel)} customCss={styles.field}>
        <Form.Item name={['services', serviceId.toString()]} preserve={false}>
            <NumberInput
                required
                min={0}
                max={MAX_PRICE_VALUE}
                step={0.01}
                precision={2}
                placeholder="0,00"
                css={[offerDetailsStyles.dataInput, styles.input]}
                currencySuffix
                addComma
            />
        </Form.Item>
        <div css={styles.icons}>
            <Icon component={Trash} onClick={() => onServiceRemove?.(serviceId)} />
        </div>
    </DataField>
);

const styles = {
    field: css`
        ${offerDetailsStyles.dataField};
        padding: 0 8px 0 16px;

        & > div {
            display: flex;
            align-items: center;
            width: calc(97px + 38px);
        }

        & > span {
            font-size: ${fontSizes.xSmall};

            max-height: 48px;
            max-width: calc(100% - 135px);

            ${textOverflowEllipsis};

            ${mobileScreenStyles(css`
                white-space: break-spaces;
            `)};
        }
    `,
    icons: css`
        margin-bottom: 24px;
        padding-left: 16px;
        vertical-align: top;

        .anticon {
            cursor: pointer;
        }

        .anticon svg {
            width: 22px;
            height: 22px;

            fill: ${colors.secondary};
        }
    `,
    input: css`
        font-size: ${fontSizes.xSmall};
    `,
};
